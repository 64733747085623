<template>
  <v-row justify="center">
    <v-dialog :value="value" persistent max-width="700">
      <v-form ref="update_feature">
        <v-card class="card mx-auto">
          <v-toolbar elevation="0">
            <v-btn
              icon
              fab
              color="#C62828"
              x-small
              v-on:click="$emit('popClose')"
              class="mr-1"
            >
              <Icon
                class="d-flex justify-center"
                icon="ic:outline-close"
                color="#000000"
                width="25"
              />
            </v-btn>

            <v-toolbar-title class="mx-auto pl-16" style="color: #50535b">
              <h3 class="font-semiBold">تعديل الميزة</h3>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-title class="mt-2 d-flex justify-center">
            <v-row class="">
              <v-col cols="12" sm="12" md="12" lg="12">
                <Textarea
                  @update-value="title = $event"
                  :value="title"
                  :rows="2"
                  type="text"
                  label=" الميزة ..."
                  class="mt-5"
                  :rules="RulseAll"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-actions class="mt-3 pb-5">
            <v-btn
              large
              @click="send_title_feature"
              :loading="loading"
              color="#f28218"
              class="btn-a py-5 mx-auto"
              elevation="4"
            >
              <h3 style="color: white" class="data_table">حفظ</h3>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
import Textarea from "@/components/layout/Textarea.vue";
export default {
  components: { Textarea },
  props: {
    value: Boolean,
    title_feature: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      title: null,
      RulseAll: [
        (value) => !!value || "هذا الحقل مطلوب",

        (value) => (value || "").length >= 3 || "الحد الادنى لعدد الاحرف هوه 3",
        (value) =>
          (value || "").length < 256 || "الحد الاقصى لعدد الاحرف هوه 255",
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.state.admin_mempership.update_feature_loading;
    },
  },
  methods: {
    send_title_feature() {
      this.$emit("update_feature", this.title);
    },
  },
  watch: {
    title_feature: {
      handler(val) {
        this.title = val;
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.btn-a {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 174px !important;
}
</style>
