import axios from "axios";
import Vue from "vue";
const trainees = {
  namespaced: true,
  state: () => ({
    trainees: [],
    member_Ships: [],
    player_information: [],
    trainee_id: localStorage.getItem("trainee_id") ?? null,
    trainer_request_id: localStorage.getItem("trainer_request_id") ?? null,
    trainee_weight: localStorage.getItem("trainee_weight") ?? null,
    subscription_session_end_date: null,
    trainees_loading: false,
    info_trainee_loading: false,
    delete_trainee_loading: false,
    select_app_subscription_loading: false,
    change_trainee_weight_loading: false,
    update_meal_end_date_loading: false,
    update_subscription_session_loading: false,
    renew_Subscription_loading: null,
    trainee_query: "",
    pageCount: 1,

    params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
    // trainees_from_search
    players: [],
    trainees_from_search_loading: false,
    trainee_query2: "",
    trainees_state: "done",
    params2: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 3,
    },

    remaining_course_days:
      localStorage.getItem("remaining_course_days") ?? null,
    remaining_meal_plan_days:
      localStorage.getItem("remaining_meal_plan_days") ?? null,

    // trinees pending
    player_pending: null,
    pending_trainee_id: localStorage.getItem("pending_trainee_id") ?? null,

    info_member_ship: JSON.parse(localStorage.getItem("info_member_ship")),
  }),
  mutations: {
    setIdTrainee(state, trainee_id) {
      state.trainee_id = trainee_id;
    },
    setIdTraineePending(state, trainee_id) {
      state.pending_trainee_id = trainee_id;
    },
    setInfoMemberShip(state, info_member_ship) {
      state.info_member_ship = info_member_ship;
    },
    MEMBER_SHIPS(state, member_Ships) {
      state.member_Ships.splice(0, state.member_Ships.length);
      member_Ships.forEach((element) => {
        state.member_Ships.push(element);
      });
    },

    GET_INFO_TRAINEE(state, info_trainee) {
      state.player_information = info_trainee;
      if (info_trainee.trainee.profile != null) {
        localStorage.setItem(
          "trainee_weight",
          info_trainee.trainee.profile.weight
        );
        state.trainee_weight = info_trainee.trainee.profile.weight;
      }
    },
    GET_INFO_TRAINEE_PENDING(state, info_trainee) {
      state.player_pending = info_trainee;
    },

    UPDATE_INFO_TRAINEE(state, info_trainee) {
      state.player_information.trainee.profile = info_trainee;
    },
    UPDATE_APP_SUBSCRIPTIONS(state, app_subscriptions) {
      state.player_information.trainee.app_subscriptions = app_subscriptions;
    },
    CHANGE_WEIGHT_TRAINEE(state, info_trainee) {
      localStorage.setItem("trainee_weight", info_trainee.weight);
      state.trainee_weight = info_trainee.weight;
    },
    GET_REMAINING_DAYS(state, info_remaining) {
      localStorage.setItem(
        "remaining_course_days",
        info_remaining.remaining_course_days
      );
      localStorage.setItem(
        "remaining_meal_plan_days",
        info_remaining.remaining_meal_plan_days
      );

      state.remaining_course_days = info_remaining.remaining_course_days;
      state.remaining_meal_plan_days = info_remaining.remaining_meal_plan_days;
    },
    
    CHANGE_SUBSCRIPTIONS_SESSION_END_DATE(state, remaining_course_days) {
      localStorage.setItem("remaining_course_days", remaining_course_days);
      state.remaining_course_days = remaining_course_days;
    },

    CHANGE_MEAL_END_DATE(state, remaining_meal_plan_days) {
      localStorage.setItem(
        "remaining_meal_plan_days",
        remaining_meal_plan_days
      );
      state.remaining_meal_plan_days = remaining_meal_plan_days;
    },

    RENEW_SUBSCRIPTIONS_TRAINEE(state, newSubscription) {
      const subscriptionIndex =
        state.player_information.trainee.subscriptions.findIndex(
          (subscription) => subscription.id === newSubscription.id
        );

      if (subscriptionIndex !== -1) {
        // Use Vue.set to update the subscription to ensure reactivity
        Vue.set(
          state.player_information.trainee.subscriptions,
          subscriptionIndex,
          newSubscription
        );
      } else {
        // Add the new subscription if it doesn't exist
        state.player_information.trainee.subscriptions.push(newSubscription);
      }
    },

    GET_TRAINEES(state, trainees) {
      state.trainees.splice(0, state.trainees.length);
      trainees.forEach((element) => {
        state.trainees.push(element);
      });
    },

    DELETE_TRAINEE(state) {
      localStorage.removeItem("trainee_id");
      localStorage.removeItem("subscribtion_session_id");
      localStorage.removeItem("previous_session_id");
      state.trainee_id = null;
    },
    // get trainees from search

    GET_TRAINEES_SEARCH(state, players) {
      state.players.splice(0, state.players.length);
      players.forEach((element) => {
        state.players.push(element);
      });
    },
  },
  actions: {
    async reset_table({ state }) {
      state.trainees = [];
      state.pageCount = 1;
      state.trainee_query = "";
      state.trainees_loading = false;
      state.params = {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 10,
      };
    },

    get_trainees({ commit, state, rootState }) {
      state.trainees_loading = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;
        let query = "";
        if (
          state.trainee_query != undefined &&
          state.trainee_query != null &&
          state.trainee_query.length > 0
        )
          query = `&query=${state.trainee_query}`;
        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/trainees" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            query,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;

            commit("GET_TRAINEES", response.data.result);

            state.trainees_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.trainees_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_info_trainee({ commit, state, rootState }) {
      state.info_trainee_loading = true;
      return new Promise((resolve) => {
        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/trainee_detail" +
            "?trainee_id=" +
            state.trainee_id,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;

            commit("GET_INFO_TRAINEE", response.data.result);

            state.info_trainee_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.info_trainee_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة 1111اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_info_trainee_to_display({ commit, state, rootState }) {
      state.info_trainee_loading = true;
      return new Promise((resolve) => {
        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/trainee_detail" +
            "?trainee_id=" +
            state.pending_trainee_id,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;

            commit("GET_INFO_TRAINEE_PENDING", response.data.result);

            state.info_trainee_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.info_trainee_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة 1111اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_remaining_days({ commit, state, rootState }) {
      state.info_trainee_loading = true;
      return new Promise((resolve) => {
        let subscribtion_session_id = "";
        subscribtion_session_id = `&subscribtion_session_id=${localStorage.getItem(
          "subscribtion_session_id"
        )}`;
        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/getRemainingDays" +
            "?trainee_id=" +
            state.trainee_id +
            subscribtion_session_id,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;

            commit("GET_REMAINING_DAYS", response.data.result);

            state.info_trainee_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.info_trainee_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_trainees_from_search({ commit, state, rootState }) {
      state.trainees_from_search_loading = true;
      let data = state.params2;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;
        let query = "";
        if (
          state.trainee_query2 != undefined &&
          state.trainee_query2 != null &&
          state.trainee_query2.length > 0
        )
          query = `&query=${state.trainee_query2}`;
        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/trainees" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            query,
          method: "get",
        })
          .then((response) => {
            commit("GET_TRAINEES_SEARCH", response.data.result);

            state.trainees_from_search_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.trainees_from_search_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    delete_trainee({ commit, state, rootState }, data) {
      state.delete_trainee_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/deleteTrainee",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("DELETE_TRAINEE", response.data.result[0]);
            state.delete_trainee_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.delete_trainee_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    update_trainee_hero_state({ commit, rootState }, data) {
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/updateTraineeHeroState",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("UPDATE_INFO_TRAINEE", response.data.result[0]);

            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    selectAppSubscription({ commit, state, rootState }, data) {
      state.select_app_subscription_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/selectAppSubscription",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("UPDATE_APP_SUBSCRIPTIONS", response.data.result[0]);
            state.select_app_subscription_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.select_app_subscription_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    renewAppSubscription({ commit, state, rootState }, data) {
      state.select_app_subscription_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/renewAppSubscription",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("UPDATE_APP_SUBSCRIPTIONS", response.data.result[0]);
            state.select_app_subscription_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.select_app_subscription_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    resetAppSubscription({ commit, state, rootState }, data) {
      state.select_app_subscription_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/changeSubscriptionType",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("UPDATE_APP_SUBSCRIPTIONS", response.data.result[0]);
            state.select_app_subscription_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.select_app_subscription_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    renewSubscription({ commit, state, rootState }, data) {
      state.renew_Subscription_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/renewSubscriptionByMonth",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("RENEW_SUBSCRIPTIONS_TRAINEE", response.data.result[0]);
            state.renew_Subscription_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.renew_Subscription_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    changeTraineeWeight({ commit, state, rootState }, data) {
      state.change_trainee_weight_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/changeTraineeWeight",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("CHANGE_WEIGHT_TRAINEE", response.data.result[0]);
            state.change_trainee_weight_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.change_trainee_weight_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    updateSubscriptionSessionEndDate({ commit, state, rootState }, data) {
      state.update_subscription_session_loading = true;
      return new Promise((resolve) => {
        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/updateSubscriptionSessionEndDate",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit(
              "CHANGE_SUBSCRIPTIONS_SESSION_END_DATE",
              response.data.result[0]
            );
            state.update_subscription_session_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.update_subscription_session_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    updateMealEndDate({ commit, state, rootState }, data) {
      state.update_meal_end_date_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/updateMealEndDate",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("CHANGE_MEAL_END_DATE", response.data.result[0]);
            state.update_meal_end_date_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.update_meal_end_date_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default trainees;
