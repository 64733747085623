<template>
  <v-col
    cols="12"
    sm="12"
    md="12"
    lg="12"
    class="d-flex justify-center px-0 mx-0"
  >
    <v-col cols="auto">
      <v-sheet
        class="sheet d-flex justify-center py-8 mx-3"
        height="50"
        width="100%"
        color="#F3F3F4"
      >
        <span style="display: flex; align-items: center">
          <h4 style="color: #f97316" class="data_table">وزن اللاعب:</h4>
          <v-btn
            icon
            x-large
            style="background-color: #f97316"
            class="mx-2"
            @mousedown="startIncrement"
            @mouseup="stopAction"
            @mouseleave="stopAction"
          >
            <Icon icon="ic:round-plus" color="#ffffff" width="20" />
          </v-btn>

          <v-text-field
            v-model="formattedWeight"
            :disabled="loading == true"
            @focus="startEditing"
            @keyup.enter="changeTraineeWeight"
            class="data_table"
            outlined
            solo
            hide-details
            type="text"
            :readonly="!isEditing"
            style="max-width: 80px; text-align: center"
          />
          <v-btn
            icon
            x-large
            style="background-color: #f97316"
            class="mx-2"
            @mousedown="startDecrement"
            @mouseup="stopAction"
            @mouseleave="stopAction"
          >
            <Icon icon="line-md:minus" color="#ffffff" width="20" />
          </v-btn>
        </span>
      </v-sheet>
    </v-col>
    <v-col cols="auto">
      <v-sheet
        class="sheet d-flex justify-center py-8 mx-3"
        height="50"
        color="#F3F3F4"
        width="100%"
      >
        <span style="display: flex; align-items: center">
          <h4 style="color: #f97316" class="data_table">
            أيام المنهاج الغذائي:
          </h4>
          <v-btn
            @mousedown="startIncrementMeal"
            @mouseup="stopActionMeal"
            @mouseleave="stopActionMeal"
            icon
            x-large
            style="background-color: #f97316"
            class="mx-2"
          >
            <Icon icon="ic:round-plus" color="#ffffff" width="20" />
          </v-btn>
          <v-text-field
            v-model="formatted_remaining_meal_plan_days"
            :disabled="loading2 == true"
            @focus="startEditingMealPlan"
            @keyup.enter="updateMeal"
            class="data_table"
            outlined
            solo
            hide-details
            style="max-width: 80px; text-align: center"
            :readonly="!isEditingMeal"
          />
          <v-btn
            @mousedown="startDecrementMeal"
            @mouseup="stopActionMeal"
            @mouseleave="stopActionMeal"
            icon
            x-large
            style="background-color: #f97316"
            class="mx-2"
          >
            <Icon icon="line-md:minus" color="#ffffff" width="20" />
          </v-btn>
        </span>
      </v-sheet>
    </v-col>
    <v-col cols="auto">
      <v-sheet
        class="sheet d-flex justify-center py-8 mx-3"
        height="50"
        width="100%"
        color="#F3F3F4"
      >
        <span style="display: flex; align-items: center">
          <h4 style="color: #f97316" class="data_table">
            أيام الكورس التدريبي:
          </h4>
          <v-btn
            @mousedown="startIncrementSubscrption"
            @mouseup="stopActionSubscrption"
            @mouseleave="stopActionSubscrption"
            icon
            x-large
            style="background-color: #f97316"
            class="mx-2"
          >
            <Icon icon="ic:round-plus" color="#ffffff" width="20" />
          </v-btn>
          <v-text-field
            v-model="formatted_remaining_course_days"
            :disabled="loading3 == true"
            @focus="startEditingSubscription"
            @keyup.enter="updateSubscriptionSession"
            class="data_table"
            outlined
            solo
            hide-details
            style="max-width: 80px; text-align: center"
            :readonly="!isEditingSubscription"
          />
          <v-btn
            @mousedown="startDecrementSubscrption"
            @mouseup="stopActionSubscrption"
            @mouseleave="stopActionSubscrption"
            icon
            x-large
            style="background-color: #f97316"
            class="mx-2"
          >
            <Icon icon="line-md:minus" color="#ffffff" width="20" />
          </v-btn>
        </span>
      </v-sheet>
    </v-col>
    <v-col cols="auto" class="d-flex justify-end">
      <v-btn
        x-large
        style="background-color: #f97316; border-radius: 16px"
        class="mx-2 mt-1"
        @click="save(value)"
        :loading="loading_add"
      >
        <h4 class="data_table" style="color: #ffffff">حفظ</h4>
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon color="white">mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
    </v-col>
  </v-col>
</template>
<script>
// import Input from "@/components/layout/Input.vue";
export default {
  // components: { Input },
  props: {
    value: {
      type: Number,
      default: 2,
    },
    loading_add: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEditing: false,
      isEditingSubscription: false,
      isEditingMeal: false,

      days_subscription: 0,
      days_meals: 0,

      timerId: null,
      intervalId: null,

      timerIdMeal: null,
      intervalIdMeal: null,
      previous_meal_days: 0,

      previous_subscription_days: 0,
      timerIdSubscrption: null,
      intervalIdSubscrption: null,
    };
  },

  created() {
    this.getRemainingDays();
  },
  computed: {
    loading() {
      return this.$store.state.trainees.change_trainee_weight_loading;
    },
    loading2() {
      return this.$store.state.trainees.update_meal_end_date_loading;
    },
    loading3() {
      return this.$store.state.trainees.update_subscription_session_loading;
    },

    trainee_weight: {
      set(val) {
        this.$store.state.trainees.trainee_weight = val;
      },
      get() {
        return this.$store.state.trainees.trainee_weight;
      },
    },
    remaining_course_days: {
      set(val) {
        this.$store.state.trainees.remaining_course_days = val;
      },
      get() {
        return this.$store.state.trainees.remaining_course_days;
      },
    },

    remaining_meal_plan_days: {
      set(val) {
        this.$store.state.trainees.remaining_meal_plan_days = val;
      },
      get() {
        return this.$store.state.trainees.remaining_meal_plan_days;
      },
    },
    formatted_remaining_course_days: {
      get() {
        const days = this.$store.state.trainees.remaining_course_days;
        return this.isEditingSubscription
          ? this.remaining_course_days
          : `${days} ${days > 10 ? "يوم" : "ايام"}`;
      },
      set(newValue) {
        // Update the weight value only when editing
        if (this.isEditingSubscription) {
          this.remaining_course_days = newValue;
        }
      },
    },
    formatted_remaining_meal_plan_days: {
      get() {
        const days = this.$store.state.trainees.remaining_meal_plan_days;
        return this.isEditingMeal
          ? this.remaining_meal_plan_days
          : `${days} ${days > 10 ? "يوم" : "ايام"}`;
      },
      set(newValue) {
        // Update the weight value only when editing
        if (this.isEditingMeal) {
          this.remaining_meal_plan_days = newValue;
        }
      },
    },

    formattedWeight: {
      get() {
        // Return the weight with 'كغم' appended unless in editing mode
        return this.isEditing
          ? this.trainee_weight
          : `${this.trainee_weight} كغم`;
      },
      set(newValue) {
        // Update the weight value only when editing
        if (this.isEditing) {
          this.trainee_weight = newValue;
        }
      },
    },
  },
  methods: {
    getRemainingDays() {
      this.$store.dispatch("trainees/get_remaining_days");
    },

    save(value) {
      if (value == 0) {
        this.$emit("add_exercises");
      } else if (value == 1) {
        this.$emit("add_ingredients");
      }
    },

    startEditingSubscription() {
      // Enable editing mode
      this.isEditingSubscription = true;
      this.previous_subscription_days = Number(this.remaining_course_days);
    },
    updateSubscriptionSession() {
      if (Number(this.previous_subscription_days) != null) {
        var new_days_subscription =
          Number(this.remaining_course_days) -
          Number(this.previous_subscription_days);
        const data = {
          subscribtion_session_id: this.$store.state.subscribtion_session_id,
          days: new_days_subscription,
        };

        this.$store
          .dispatch("trainees/updateSubscriptionSessionEndDate", data)
          .then(() => {
            this.previous_subscription_days = 0;
            this.isEditingSubscription = false;
          });
      }
    },
    updateSubscriptionSessionEndDate() {
      if (this.days_subscription != null) {
        let data = {};
        data["subscribtion_session_id"] =
          this.$store.state.subscribtion_session_id;
        data["days"] = this.days_subscription;
        this.$store
          .dispatch("trainees/updateSubscriptionSessionEndDate", data)
          .then(() => {
            this.days_subscription = 0;
          });
      }
    },

    plus_subscription() {
      this.days_subscription += 1;
      this.remaining_course_days = Number(this.remaining_course_days) + 1;

      console.log("days_subscription", this.days_subscription);
      clearTimeout(this.timerIdSubscrption);
      this.timerIdSubscrption = setTimeout(() => {
        this.updateSubscriptionSessionEndDate();
      }, 3000);
    },
    minus_subscription() {
      if (Number(this.remaining_course_days) > 0) {
        this.days_subscription -= 1;
        this.remaining_course_days = Number(this.remaining_course_days) - 1;
        console.log("days_subscription", this.days_subscription);
        clearTimeout(this.timerIdSubscrption);
        this.timerIdSubscrption = setTimeout(() => {
          this.updateSubscriptionSessionEndDate();
        }, 3000);
      }
    },

    startIncrementSubscrption() {
      this.plus_subscription();
      this.intervalIdSubscrption = setInterval(() => {
        // Updated name
        this.plus_subscription();
      }, 200); // Repeat every 200ms (adjust as needed)
    },
    startDecrementSubscrption() {
      this.minus_subscription();
      this.intervalIdSubscrption = setInterval(() => {
        this.minus_subscription();
      }, 200); // Repeat every 200ms (adjust as needed)
    },

    stopActionSubscrption() {
      clearInterval(this.intervalIdSubscrption); // Updated name
      this.intervalIdSubscrption = null; // Updated name
    },

    startEditingMealPlan() {
      // Enable editing mode
      this.isEditingMeal = true;
      this.previous_meal_days = Number(this.remaining_meal_plan_days);
    },
    updateMeal() {
      if (Number(this.previous_meal_days) != 0) {
        var new_days_meals =
          Number(this.remaining_meal_plan_days) -
          Number(this.previous_meal_days);
        const data = {
          meal_plan_id: this.$store.state.meals.meal_plan_id,
          days: new_days_meals,
        };
        this.$store.dispatch("trainees/updateMealEndDate", data).then(() => {
          this.previous_meal_days = 0;
          this.isEditingMeal = false;
        });
      }
    },
    updateMealEndDate() {
      if (this.days_meals != null) {
        const data = {
          meal_plan_id: this.$store.state.meals.meal_plan_id,
          days: this.days_meals,
        };
        this.$store.dispatch("trainees/updateMealEndDate", data).then(() => {
          this.days_meals = 0;
        });
      }
    },

    plus_meal_plan() {
      this.days_meals += 1;
      this.remaining_meal_plan_days = Number(this.remaining_meal_plan_days) + 1;

      clearTimeout(this.timerIdMeal);
      this.timerIdMeal = setTimeout(() => {
        this.updateMealEndDate();
      }, 3000);
    },

    minus_meal_plan() {
      if (Number(this.remaining_meal_plan_days) > 0) {
        this.days_meals -= 1;
        this.remaining_meal_plan_days =
          Number(this.remaining_meal_plan_days) - 1;

        clearTimeout(this.timerIdMeal);
        this.timerIdMeal = setTimeout(() => {
          this.updateMealEndDate();
        }, 3000);
      }
    },

    startIncrementMeal() {
      this.plus_meal_plan(); // Trigger the action immediately
      this.intervalIdMeal = setInterval(() => {
        this.plus_meal_plan();
      }, 200); // Repeat every 200ms
    },

    startDecrementMeal() {
      this.minus_meal_plan(); // Trigger the action immediately
      this.intervalIdMeal = setInterval(() => {
        this.minus_meal_plan();
      }, 200); // Repeat every 200ms
    },

    stopActionMeal() {
      clearInterval(this.intervalIdMeal); // Stop the action
      this.intervalIdMeal = null;
    },

    startEditing() {
      // Enable editing mode
      this.isEditing = true;
    },
    changeTraineeWeight() {
      if (this.trainee_weight != null) {
        let data = {};
        data["trainee_id"] = localStorage.getItem("trainee_id");
        data["weight"] = this.trainee_weight;
        this.$store.dispatch("trainees/changeTraineeWeight", data).then(() => {
          this.isEditing = false;
        });
      }
    },
    plus_weight() {
      if (Number(this.trainee_weight) < 150) {
        this.trainee_weight = Number(this.trainee_weight) + 1;

        // Clear any existing timer before setting a new one
        clearTimeout(this.timerId);

        this.timerId = setTimeout(() => {
          this.changeTraineeWeight();
        }, 3000);
      }
    },
    minus_weight() {
      if (Number(this.trainee_weight) > 30) {
        this.trainee_weight = Number(this.trainee_weight) - 1;
        // Clear any existing timer before setting a new one
        clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
          this.changeTraineeWeight();
        }, 3000);
      }
    },

    startIncrement() {
      this.plus_weight(); // Trigger the action immediately
      this.intervalId = setInterval(() => {
        // Updated name
        this.plus_weight();
      }, 200); // Repeat every 200ms (adjust as needed)
    },
    startDecrement() {
      this.minus_weight(); // Trigger the action immediately
      this.intervalId = setInterval(() => {
        // Updated name
        this.minus_weight();
      }, 200); // Repeat every 200ms (adjust as needed)
    },
    stopAction() {
      clearInterval(this.intervalId); // Updated name
      this.intervalId = null; // Updated name
    },
  },
};
</script>
<style scoped>
.sheet {
  border-radius: 15px !important;
}
</style>
