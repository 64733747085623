import axios from "axios";
const admin_mempership = {
  namespaced: true,
  state: () => ({
    member_ships: [],
    add_mempership_loading: false,
    member_ships_loading: false,
    update_mempership_loading: false,
    add_feature_loading: false,
    update_feature_loading: false,
    delete_feature_loading: false,
  }),
  mutations: {
    GET_MEMPERSHIP(state, mempership) {
      state.member_ships.splice(0, state.member_ships.length);
      mempership.forEach((element) => {
        state.member_ships.push(element);
      });
    },

    ADD_MEMPERSHIP(state, mempership) {
      state.member_ships.push(mempership);
    },
    UPDATE_MEMPERSHIP(state, mempership) {
      let index = state.member_ships.findIndex((m) => m.id === mempership.id);
      if (index !== -1) {
        // Update the element in place
        state.member_ships[index] = mempership;
      }
    },

    ADD_FEATURE(state, feature) {
      const member_ships = state.member_ships.find(
        (item) => item.id === feature.membership_id
      );
      if (member_ships) {
        member_ships.features.push(feature);
      }
    },

    DELETE_FEATURE(state, feature) {
      state.member_ships.forEach((membership) => {
        const featureIndex = membership.features.findIndex(
          (item) => item.id === feature.id
        );

        // If the feature exists, remove it
        if (featureIndex !== -1) {
          membership.features.splice(featureIndex, 1);
        }
      });
    },

    UPDATE_FEATURE(state, feature) {
      const membership = state.member_ships.find(
        (item) => item.id === feature.membership_id
      );

      if (membership) {
        const featureIndex = membership.features.findIndex(
          (item) => item.id === feature.id
        );

        if (featureIndex !== -1) {
          membership.features[featureIndex] = {
            ...membership.features[featureIndex],
            ...feature,
          };
        }
      }
    },
  },
  actions: {
    get_member_ships({ commit, state, rootState }) {
      state.member_ships_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/mempership",
          method: "get",
        })
          .then((response) => {
            commit("GET_MEMPERSHIP", response.data.result);
            state.member_ships_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.member_ships_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    add_memper_ship({ commit, state, rootState }, data) {
      state.add_mempership_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/mempership",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_MEMPERSHIP", response.data.result[0]);
            state.add_mempership_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_mempership_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    update_memper_ship({ commit, state, rootState }, data) {
      state.update_mempership_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/membership/update",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("UPDATE_MEMPERSHIP", response.data.result[0]);
            state.update_mempership_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.update_mempership_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    add_features({ commit, state, rootState }, data) {
      state.add_feature_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/addFeature",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_FEATURE", response.data.result[0]);
            state.add_feature_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_feature_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    update_feature({ commit, state, rootState }, data) {
      state.update_feature_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/feature/update",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("UPDATE_FEATURE", response.data.result[0]);
            state.update_feature_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.update_feature_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    delete_feature({ commit, state, rootState }, data) {
      state.delete_feature_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/feature/delete",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("DELETE_FEATURE", response.data.result[0]);
            state.delete_feature_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.delete_feature_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default admin_mempership;
