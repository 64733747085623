import axios from "axios";
const training_requests = {
  namespaced: true,
  state: () => ({
    requests: [],

    trainer_requests_loading: false,
    requests_state: "done",
    reject_request_loading: false,
    accept_request_loading: false,

    params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
    // Statistics
    statistics: [],
    loading_statistics: false,

    // Trainees
    trainees: [],
    trainee_query: "",
    trainees_loading: false,
    trainees_state: "done",
    params_trainees: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
    // invites
    invites_loading: false,
  }),
  mutations: {
    REQUEST_STATUS_CHANGED(state, request) {
      let index = state.requests.findIndex((e) => e.id == request.id);
      state.requests.splice(index, 1);
    },

    GET_All_REQUEST_DREPDWON(state, requests) {
      if (requests.length === 0) {
        state.requests_state = "finished";
        if (state.params.page > 1) state.params.page -= 1;
        return;
      }
      state.params.page += 1;
      const newRequests = requests.filter(
        (request) => !state.requests.some((r) => r.id === request.id)
      );

      state.requests.push(...newRequests);

      state.requests_state = "done";
    },

    GET_STATISTICS(state, statistics) {
      let data = [
        {
          icon: "mdi:users",
          color: "#A855F7",
          title: "عدد اللاعبين",
          number: statistics.total_trainees,
          type: "لاعب",
        },
        {
          icon: "flowbite:dollar-outline",
          color: "#3B82F6",
          title: "اجمالي وارد اليوم",
          number: statistics.total_payment_today,
          type: "دينار",
        },
        {
          icon: "streamline:arrow-reload-horizontal-1-solid",
          color: "#f97316",
          title: " تحديثات اليوم",
          number: statistics.total_subscriptions_count,
          type: "لاعب",
        },
        {
          icon: "mdi:users",
          color: "#84CC16",
          title: "اشتراكات التطبيق الفعالة",
          number: statistics.active_subscriptions_count,
          type: "لاعب",
        },
      ];
      state.statistics = data;
    },

    GET_All_TRAINEES(state, trainees) {
      state.trainees.splice(0, state.trainees.length);
      trainees.forEach((element) => {
        state.trainees.push(element);
      });
    },
  },
  actions: {
    get_all_trainer_request({ commit, state, rootState }) {
      state.trainer_requests_loading = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/getAllRequest" +
            "?skip=" +
            skip +
            "&limit=" +
            limit,
          method: "get",
        })
          .then((response) => {
            commit("GET_All_REQUEST_DREPDWON", response.data.result);

            state.trainer_requests_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.trainer_requests_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    acceptRequest({ commit, state, rootState }, data) {
      state.accept_request_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/acceptRequest",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("REQUEST_STATUS_CHANGED", response.data.result[0]);
            state.accept_request_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.accept_request_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    rejectRequest({ commit, state, rootState }, data) {
      state.reject_request_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/rejectRequest",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("REQUEST_STATUS_CHANGED", response.data.result[0]);
            state.reject_request_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.reject_request_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_statistics({ commit, state, rootState }) {
      state.loading_statistics = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/getStatistics",
          method: "get",
        })
          .then((response) => {
            commit("GET_STATISTICS", response.data.result);

            state.loading_statistics = false;
            resolve(response);
          })
          .catch(() => {
            state.loading_statistics = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    getTraineesList({ commit, state, rootState }) {
      state.trainees_loading = true;
      let data = state.params_trainees;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;
        let query = "";
        if (
          state.trainee_query != undefined &&
          state.trainee_query != null &&
          state.trainee_query.length > 0
        )
          query = `&query=${state.trainee_query}`;
        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/trainees/list" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            query,
          method: "get",
        })
          .then((response) => {
            commit("GET_All_TRAINEES", response.data.result);

            state.trainees_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.trainees_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    createInvite({ commit, state, rootState }, data) {
      state.invites_loading = true;

      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/invites/create",
          data: data,
          method: "post",
        })
          .then((response) => {
            // commit("GET_All_TRAINEES", response.data.result);
            state.invites_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch(() => {
            state.invites_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default training_requests;
