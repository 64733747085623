<template>
    <v-row justify="center">
      <v-dialog :value="value" persistent max-width="450">
        <v-card class="card mx-auto">
          <v-toolbar elevation="0">
           
              <v-btn
                icon
                fab
                color="#C62828"
                x-small
                v-on:click="$emit('popClose')"
                class="mr-1"
              >
                <Icon
                  class="icon_download"
                  icon="ic:outline-close"
                  color="#000000"
                  width="25"
                />
              </v-btn>
          
  
            <v-toolbar-title class="mx-auto pl-16" style="color: #50535b">
              <h3 style="color: #50535b" class="font-semiBold">حذف وجبة</h3>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-title class="mt-4 d-flex justify-stert"
            ><h3 style="color: #50535b" class="data_table">
              هل أنت متأكد من عملية الحذف
            </h3></v-card-title
          >
          <v-card-actions class="mt-2 pb-5 mr-3">
            <v-btn
              large
              v-on:click="$emit('deleteIngredient')"
              :loading="loading"
              color="#f28218"
              class="btn py-5 mx-auto"
              elevation="4"
            >
  
              <h3 style="color: white;" class="data_table">تاكيد</h3>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <script>
  export default {
    props: {
      value: Boolean,
    },
    computed: {
      loading() {
        return this.$store.state.meals.delete_ingredient_loading;
      },
    },
  };
  </script>
  <style scoped>
  .card {
    border-radius: 25px !important;
    box-shadow: 0px 0px 0px 0px !important;
  }
  .btn {
    border-radius: 13px !important;
    box-shadow: 0px 0px 0px 0px !important;
    padding: 0 174px !important;
  }
  </style>
  