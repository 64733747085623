<template>
  <v-container fluid class="mt-0">
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" class="pb-8">
        <v-form ref="add_news">
          <v-card
            class="mx-auto card"
            width="97%"
            style="background-color: #fdfdfd"
            :height="cardHeight"
          >
            <v-card-title class="mx-3" v-if="loading_profile == false">
              <h3 class="ml-3 font-semiBold" style="color: #50535b">
                {{ profile.full_name }}
              </h3>
              <v-avatar size="50" v-if="profile.images != null">
                <img
                  :src="server + profile.image"
                  width="50"
                  height="50"
                  alt="error"
                  class="name_coach"
                />
              </v-avatar>
              <v-avatar size="50" v-else>
                <img
                  src="@/assets/Elift-logo.png"
                  width="50"
                  height="50"
                  alt="error"
                  class="name_coach"
                />
              </v-avatar>
            </v-card-title>
            <v-card-title class="d-flex justify-end" v-else dir="ltr">
              <v-skeleton-loader
                width="15%"
                type="list-item-avatar"
              ></v-skeleton-loader>
            </v-card-title>

            <v-card-text class="mt-3 py-0">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <Input
                    @update-value="title = $event"
                    :value="title"
                    type="text"
                    label="اكتب عنوانك هنا"
                    :rules="TitleRules"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="9" class="mt-0 pt-0">
                  <Textarea
                    @update-value="text = $event"
                    :value="text"
                    :rows="9"
                    type="text"
                    label="اكتب هنا"
                    :rules="TextRules"
                    class="mt-5"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="3" class="">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :options="dropzoneOptions"
                    :includeStyling="true"
                    :useCustomSlot="true"
                    @vdropzone-removed-file="removeFile"
                    @vdropzone-success="uploadSuccess"
                    @vdropzone-error="fileUploadError"
                  >
                    <div class="dropzone-custom-content">
                      <v-img
                        src="@/assets/upload_image.png"
                        width="70"
                        class="mx-auto"
                      ></v-img>
                    </div>
                  </vue-dropzone>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-title class="card-text mt-5 mx-5">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <h3 style="color: #50535b" class="font-semiBold">
                    من تريد رؤية هذا ؟
                  </h3>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4">
                  <v-text-field
                    v-model="trainee_query"
                    @input="query_change"
                    append-icon="mdi-magnify"
                    label="إبحث عن لاعب اخر ..."
                    class="data_table"
                    color="#f97316"
                    rounded
                    solo
                    clearable
                    hide-details
                  />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-sheet
                    class="mx-auto sheet d-flex justify-center"
                    height="50"
                    width="150"
                    color="#f3f3f3"
                  >
                    <span style="display: flex; align-items: center">
                      <v-btn
                        icon
                        small
                        :outlined="group_type != 0"
                        :style="
                          group_type == 0
                            ? 'background-color: #f97316'
                            : 'background-color: #EFF2FC'
                        "
                        @click="categoryGroup(0)"
                      >
                        <Icon
                          icon="material-symbols:check"
                          :color="group_type == 0 ? '#ffffff' : '#000000'"
                          width="25"
                        />
                      </v-btn>
                      <h5 style="color: #50535b" class="mx-3 data_table">
                        الجميع
                      </h5>

                      <Icon
                        icon="solar:global-linear"
                        color="#292D32"
                        width="25"
                      />
                    </span>
                  </v-sheet>
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-sheet
                    class="mx-auto sheet d-flex justify-center"
                    height="50"
                    width="150"
                    color="#f3f3f3"
                  >
                    <span style="display: flex; align-items: center">
                      <v-btn
                        icon
                        small
                        :outlined="group_type != 1"
                        :style="
                          group_type == 1
                            ? 'background-color: #f97316'
                            : 'background-color: #EFF2FC'
                        "
                        @click="categoryGroup(1)"
                      >
                        <Icon
                          icon="material-symbols:check"
                          :color="group_type == 1 ? '#ffffff' : '#000000'"
                          width="25"
                        />
                      </v-btn>
                      <h5 style="color: #50535b" class="mx-3 data_table">
                        الأبطال
                      </h5>

                      <Icon
                        icon="solar:cup-outline"
                        color="#292D32"
                        width="25"
                      />
                    </span>
                  </v-sheet>
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                  <v-sheet
                    class="mx-auto sheet d-flex justify-center"
                    height="50"
                    width="150"
                    color="#f3f3f3"
                  >
                    <span style="display: flex; align-items: center">
                      <v-btn
                        icon
                        small
                        :outlined="group_type != 2"
                        :style="
                          group_type == 2
                            ? 'background-color: #f97316'
                            : 'background-color: #EFF2FC'
                        "
                        @click="categoryGroup(2)"
                      >
                        <Icon
                          icon="material-symbols:check"
                          :color="group_type == 2 ? '#ffffff' : '#000000'"
                          width="25"
                        />
                      </v-btn>
                      <h5 style="color: #50535b" class="mx-3 data_table">
                        اللاعبين
                      </h5>

                      <Icon
                        icon="solar:user-linear"
                        color="#292D32"
                        width="25"
                      />
                    </span>
                  </v-sheet>
                </v-col>
                <v-col
                  cols="auto"
                  v-for="(group, index) in groups"
                  :key="index"
                >
                  <v-sheet
                    class="mx-2 sheet d-flex justify-center"
                    height="50"
                    width="100%"
                    color="#f3f3f3"
                  >
                    <span style="display: flex; align-items: center">
                      <v-btn
                        icon
                        small
                        class="ml-3"
                        :loading="
                          loading4 == true && loading_btn == index
                            ? true
                            : false
                        "
                        @click="pop_delete_groups(group.id, index)"
                      >
                        <Icon
                          icon="material-symbols:close-rounded"
                          color="#111214"
                          width="25"
                        />
                        <template v-slot:loader>
                          <span class="custom-loader">
                            <v-icon color="#f97300">mdi-cached</v-icon>
                          </span>
                        </template>
                      </v-btn>

                      <v-btn
                        icon
                        small
                        :outlined="group_id.includes(group.id) ? false : true"
                        :style="
                          group_id.includes(group.id)
                            ? 'background-color: #f97316'
                            : 'background-color: #EFF2FC'
                        "
                        @click="categorys(group.users_groups, group.id)"
                      >
                        <Icon
                          icon="material-symbols:check"
                          :color="
                            group_id.includes(group.id) ? '#ffffff' : '#000000'
                          "
                          width="25"
                        />
                      </v-btn>
                      <h5 style="color: #50535b" class="mx-2 data_table">
                        {{ group.title }}
                      </h5>
                      <Icon
                        icon="solar:global-linear"
                        color="#292D32"
                        width="25"
                      />
                    </span>
                  </v-sheet>
                </v-col>

                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-list
                    class="list"
                    style="max-height: 200px; overflow-y: auto"
                    @scroll.native="onScroll"
                    ref="scrollContainer"
                  >
                    <v-list-item
                      v-for="trainee in trainees"
                      :key="trainee.id"
                      class="py-1 my-2 mx-2 list-item"
                      @click="add_trainee(trainee.id)"
                    >
                      <v-list-item-action>
                        <span>
                          <v-btn
                            icon
                            small
                            :outlined="
                              trainee_id.includes(trainee.id) ? false : true
                            "
                            :style="
                              trainee_id.includes(trainee.id)
                                ? 'background-color: #f97316'
                                : 'background-color: #EFF2FC'
                            "
                          >
                            <Icon
                              :color="
                                trainee_id.includes(trainee.id)
                                  ? '#ffffff'
                                  : '#000000'
                              "
                              icon="material-symbols:check"
                              width="20"
                            />
                          </v-btn>
                        </span>
                      </v-list-item-action>

                      <v-list-item-content dir="ltr">
                        <v-list-item-title class="data_table">{{
                          trainee.full_name
                        }}</v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-avatar
                        size="50"
                        v-if="trainee.images.length > 0"
                      >
                        <img
                          :src="server + trainee.images[0].url"
                          width="50"
                          height="50"
                          alt="error"
                          class="name_coach"
                        />
                      </v-list-item-avatar>
                      <v-list-item-avatar size="50" v-else>
                        <img
                          src="@/assets/Elift-logo.png"
                          width="50"
                          height="50"
                          alt="error"
                          class="name_coach"
                        />
                      </v-list-item-avatar>
                    </v-list-item>
                    <v-list-item v-if="loading2 == true">
                      <h4 class="mx-auto font-semiBold" style="color: #50535b">
                        جاري التحميل يرجى الأنتظار
                      </h4>
                    </v-list-item>
                    <v-list-item
                      v-if="trainees.length == 0 && loading2 == false"
                    >
                      <h3 class="mx-auto font-semiBold" style="color: #50535b">
                        لايوجد متدربين حاليا
                      </h3>
                    </v-list-item>
                  </v-list>
                  <v-card-avatar>
                    <v-btn
                      style="border-radius: 16px"
                      color="#f97316"
                      class="btn ml-2 mt-4 py-5"
                      @click="update_groups"
                    >
                      <h3 style="color: #ffffff" class="data_table">حفظ</h3>
                    </v-btn>
                    <v-btn
                      style="border-radius: 16px"
                      color="#f97316"
                      class="btn ml-2 mt-4 py-5"
                      @click="add_group"
                    >
                      <h3 style="color: #ffffff" class="data_table">
                        حفظ الاختيارات بفئة
                      </h3>
                    </v-btn>
                    <v-btn
                      rounded
                      class="btn mt-4"
                      color="#ffffff"
                      @click="reset_group"
                    >
                      <h3 style="color: #f97316" class="data_table">
                        اعادة تعيين الكل
                      </h3>
                    </v-btn>
                  </v-card-avatar>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-title>
              <v-btn
                rounded
                block
                x-large
                class="btn mt-4"
                color="#f97316"
                :loading="loading3"
                @click="create_news"
              >
                <h2 style="color: #ffffff" class="data_table">نشر</h2>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon color="white">mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-card-title>
          </v-card>
        </v-form>
      </v-col>
      <CreateGroup
        :value="pop_add_group"
        v-on:popClose="pop_add_group = !pop_add_group"
        v-on:add_group="create_group_with_trainees"
      />
      <UpdateGroup
        :value="pop_update_group"
        :title_group="title_group"
        v-on:popClose="pop_update_group = !pop_update_group"
        v-on:update_group="update_group"
      />
      <DeleteGroup
        :value="pop_delete_group"
        v-on:popClose="pop_delete_group = !pop_delete_group"
        v-on:delete_group="delete_group"
      />
    </v-row>
  </v-container>
</template>
<script>
import Input from "@/components/layout/Input.vue";
import Textarea from "@/components/layout/Textarea.vue";
import CreateGroup from "../components/new/PopCreateGroup";
import UpdateGroup from "../components/new/UpdateGroup";
import DeleteGroup from "../components/new/PopDeleteGroup";

export default {
  components: { Input, Textarea, CreateGroup, UpdateGroup, DeleteGroup },
  data() {
    return {
      title: "",
      text: "",
      group_type: null,
      pop_add_group: false,
      pop_update_group: false,
      pop_delete_group: false,
      group_id_delete: null,
      loading_btn: null,
      title_group: "",
      trainee_id: [],
      group_id: [],
      TextRules: [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 3 || "الحد الادنى لعدد الاحرف هوه 3",
      ],

      image: "",
      dropzoneOptions: {
        url: "https://efredgvrergv34345435.online/api/api/upload_file",
        method: "post",
        thumbnailWidth: 200,
        maxFilesize: 2,
        parallelUploads: 1,
        uploadMultiple: false,
        addRemoveLinks: true,
        acceptedFiles: ".pdf,.png,.jpeg,.jpg",
        maxFiles: 3,

        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"), // or wherever your token is stored
        },
      },
    };
  },
  created() {
    this.get_groups();
    this.get_trainees();
    this.get_profile();
  },
  computed: {
    TitleRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 3 || "الحد الادنى لعدد الاحرف هوه 3",
        (value) =>
          (value || "").length < 256 || "الحد الاقصى لعدد الاحرف هوه 255",
      ];
    },

    groups() {
      return this.$store.state.news.groups;
    },
    server() {
      return this.$store.state.server;
    },
    trainees() {
      return this.$store.state.news.trainees;
    },
    profile() {
      return this.$store.state.trainer.profile;
    },
    loading_profile() {
      return this.$store.state.trainer.profile_loading;
    },

    loading() {
      return this.$store.state.news.groups_loading;
    },
    loading2() {
      return this.$store.state.news.trainees_loading;
    },
    loading3() {
      return this.$store.state.news.create_news_loading;
    },
    loading4() {
      return this.$store.state.news.delete_groups_loading;
    },

    trainee_query: {
      set(val) {
        this.$store.state.news.trainee_query = val;
      },
      get() {
        return this.$store.state.news.trainee_query;
      },
    },
    cardHeight() {
      if (this.groups.length <= 1) {
        return "1100px"; // Height for less than 4 trainers
      } else if (this.groups.length <= 7 && this.groups.length > 1) {
        return "1150px"; // Height for less than 4 trainers
      } else if (this.groups.length >= 8) {
        return "1175px"; // Height for 9 or more trainers
      } else {
        return "1045px"; // Default height (shouldn't be reached with the above conditions)
      }
    },
  },
  methods: {
    get_profile() {
      this.$store.dispatch("trainer/get_profile");
    },
    get_groups() {
      this.$store.dispatch("news/get_groups");
    },
    get_trainees() {
      this.$store.dispatch("news/get_trainees");
    },
    categoryGroup(val) {
      if (this.group_type == val) {
        this.group_type = null;
      } else {
        this.group_type = val;
      }
    },
    categorys(users_groups, id) {
      if (!this.group_id.includes(id)) {
        this.group_id.push(id);

        users_groups.forEach((e) => {
          this.trainee_id.push(e.trainee_id);
        });
      } else {
        this.group_id = this.group_id.filter((groupId) => groupId !== id);

        users_groups.forEach((e) => {
          var index = this.trainee_id.findIndex((el) => el.id === e.trainee_id);
          this.trainee_id.splice(index, 1);
        });
      }
    },
    reset_group() {
      this.trainee_id = [];

      let snack_message = {};
      snack_message["color"] = "#21ce8f";
      snack_message["icon"] = "clarity:success-standard-solid";
      snack_message["text"] = "تم اعادة تعيين الكل بنجاح.";
      this.$store.commit("SNACK_MESSAGE", snack_message);
      setTimeout(() => {
        this.$store.commit("TIME_OUT", snack_message);
      }, 4000);
    },
    update_groups() {
      if (this.group_id.length == 1 && this.trainee_id.length > 0) {
        var title = this.groups.filter(
          (groupId) => groupId.id == this.group_id[0]
        );
        this.pop_update_group = true;
        this.title_group = title[0].title;
      } else if (this.trainee_id.length == 0) {
        let snack_message = {};
        snack_message["color"] = "#ef4444";
        snack_message["icon"] = "ri:close-circle-fill";
        snack_message["text"] = "لايمكنك حفظ فئة بدون اختيار متدربين";
        this.$store.commit("SNACK_MESSAGE", snack_message);
        setTimeout(() => {
          this.$store.commit("TIME_OUT", snack_message);
        }, 4000);
      } else if (this.group_id.length > 1) {
        let snack_message = {};
        snack_message["color"] = "#ef4444";
        snack_message["icon"] = "ri:close-circle-fill";
        snack_message["text"] = "لا يمكنك اختيار اكثر من فئة لتحديثها.";
        this.$store.commit("SNACK_MESSAGE", snack_message);
        setTimeout(() => {
          this.$store.commit("TIME_OUT", snack_message);
        }, 4000);
      } else {
        let snack_message = {};
        snack_message["color"] = "#ef4444";
        snack_message["icon"] = "ri:close-circle-fill";
        snack_message["text"] = "يجب اختيار فئة واحدة على الاقل.";
        this.$store.commit("SNACK_MESSAGE", snack_message);
        setTimeout(() => {
          this.$store.commit("TIME_OUT", snack_message);
        }, 4000);
      }
    },
    update_group(title) {
      let data = {};
      data["trainees"] = this.trainee_id;
      data["group_id"] = this.group_id[0];
      data["title"] = title;
      this.$store.dispatch("news/update_group", data).then(() => {
        this.pop_update_group = false;
      });
    },
    pop_delete_groups(group_id, index) {
      this.group_id_delete = group_id;
      this.loading_btn = index;
      this.pop_delete_group = true;
    },
    delete_group() {
      this.pop_delete_group = false;
      let data = {};
      data["group_id"] = this.group_id_delete;
      this.$store.dispatch("news/delete_group", data);
    },

    query_change() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.get_trainees();
        // this.pagination.page = 1;
      }, 1000);
    },
    add_trainee(id) {
      if (!this.trainee_id.includes(id)) {
        this.trainee_id.push(id);
      } else {
        this.trainee_id = this.trainee_id.filter(
          (traineeId) => traineeId !== id
        );
      }
    },
    add_group() {
      this.pop_add_group = true;
    },
    create_group_with_trainees(title_groups) {
      let data = {};
      data["trainees"] = this.trainee_id;
      data["title"] = title_groups;
      this.$store.dispatch("news/add_groups", data).then(() => {
        this.pop_add_group = false;
      });
    },
    create_news() {
      if (this.$refs.add_news.validate()) {
        let data = {};
        data["title"] = this.title;
        data["description"] = this.text;
        data["image"] = this.image;

        if (this.group_type == 0) {
          data["is_global"] = true;
        } else if (this.group_type == 1) {
          data["is_hero"] = true;
        } else if (this.group_type == 2) {
          data["is_hero"] = false;
        }
        if (this.trainee_id.length > 0) {
          data["trainees"] = this.trainee_id;
        }
        this.$store.dispatch("news/create_news", data).then(() => {
          this.$refs.add_news.reset();
          this.removeFile();
          this.everyone = false;
        });
      }
    },

    onScroll(event) {
      var trainees_state = this.$store.state.news.trainees_state;
      const container = event.target;
      const isScrollingDown = container.scrollTop > this.lastScrollTop;
      this.lastScrollTop = container.scrollTop;
      if (
        isScrollingDown &&
        container.scrollTop + container.clientHeight >=
          container.scrollHeight - 50 &&
        !this.loading2 &&
        trainees_state == "done"
      ) {
        this.get_trainees();
      }
    },

    uploadSuccess(file, response) {
      this.image = response["file"];
    },
    fileUploadError() {
      let snack_message = {};
      snack_message["color"] = "#ef4444";
      snack_message["icon"] = "ri:close-circle-fill";
      snack_message["text"] = "لا يمكنك تحميل ملفات من هذا النوع.";
      this.$store.commit("SNACK_MESSAGE", snack_message);
      setTimeout(() => {
        this.$store.commit("TIME_OUT", snack_message);
      }, 4000);
    },

    removeFile() {
      this.image = "";
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 25px !important;
  border-color: #c0c2c6 !important;
  border-style: solid;
  border-width: 2px;
}
.card-text {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 25px !important;
  border-color: #c0c2c6 !important;
  border-style: solid;
  border-width: 1px;
}
.name_coach {
  border-color: #f28218 !important;
  border-style: solid;
  border-width: 2px;
}
.sheet {
  border-radius: 25px !important;
}

.list-item {
  background-color: #f3f3f3;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
}
.btn {
  box-shadow: 0px 0px !important;
}

/*  dropzone */

.vue-dropzone > .dz-preview .dz-remove {
  margin-right: 80px !important;
  margin-left: 0 !important;
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 500 !important;
}
.dropzone-custom-content {
  margin-top: 50px;
  text-align: center;
}

.dropzone-custom-title {
  font-family: "Cairo", sans-serif;
  margin-top: 0;
  color: #000000;
}

.subtitle {
  font-family: "Cairo", sans-serif;
  color: #314b5f;
}
.dropzone {
  height: 270px;
  border-radius: 25px;
  border-color: #ffffff;
  /* border-style: dotted !important; */
}
@media (min-width: 1182px) {
  /* Adjust for web (desktop and larger screens) */
  .dropzone {
    height: 270px !important;
  }
}

.vue-dropzone {
  background-color: #f3f3f3 !important;
}
.dz-preview {
  height: 150px !important;
  display: block !important;
}
.dz-details {
  top: 0px !important;
}
.dz-error-message {
  top: 150px !important;
}
</style>
