<template>
  <v-dialog :value="value" max-width="700px" persistent scrollable>
    <v-card class="card mx-auto" width="100%" height="100%">
      <v-toolbar elevation="0">
        <v-btn
          icon
          fab
          color="#C62828"
          x-small
          v-on:click="$emit('popClose')"
          class="mr-1"
        >
          <Icon
            class="icon_download"
            icon="ic:outline-close"
            color="#000000"
            width="25"
          />
        </v-btn>

        <v-toolbar-title class="mx-auto pl-16" style="color: #50535b">
          <h3 style="color: #50535b" class="font-semiBold">سبب الرفض</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-form ref="add_notes">
        <v-card-text class="mt-7">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <Textarea
                @update-value="message = $event"
                :value="message"
                type="text"
                label="اكتب سبب الرفض لمدرب ..."
                :rules="RulseAll"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-2 pb-5 mr-3">
          <v-btn
            large
            @click="add_message"
            :loading="loading"
            color="#f28218"
            class="btn-a py-5 mx-auto"
            elevation="4"
          >
            <h3 style="color: white;" class="data_table">ارسال</h3>
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon color="white">mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import Textarea from "@/components/layout/Textarea.vue";

export default {
  components: { Textarea },
  data() {
    return {
      message: null,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    RulseAll() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 3 || "الحد الادنى لعدد الاحرف هوه 3",
        (value) =>
          (value || "").length < 512 || "الحد الاقصى لعدد الاحرف هوه 510",
      ];
    },
    loading() {
      return this.$store.state.admin_requests.reject_request_loading;
    },
  },
  methods: {
    add_message() {
      this.$emit("rejectRequest", this.message);
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.btn-a {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 174px !important;
}
</style>
