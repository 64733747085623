import axios from "axios";
const courses = {
  namespaced: true,
  state: () => ({
    // get additional_courses
    additional_courses: [],
    additional_courses_loading: false,
    add_additional_courses_loading: false,
    delete_course_loading: false,
    edit_course_loading: false,
    pageCount: 1,
    params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 50,
    },

    // get courses
    courses: [],
    courses_loading: false,
    add_courses_loading: false,
    update_course_loading: false,
    add_days_loading: false,
    delete_day_loading: false,
    pageCount2: 1,
    courses_params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 50,
    },
    // get courses
    previous_courses: [],
    previous_courses_loading: false,
  }),
  mutations: {
    // additional_courses
    ADD_ADDITIONAL_COURESE(state, course) {
      course.count_of_day = Array.from(String(course.count_of_day), Number);
      state.additional_courses.push(course);
    },

    GET_ADDITIONAL_COURESE(state, courses) {
      state.additional_courses.splice(0, state.additional_courses.length);
      courses.forEach((element) => {
        // Transform `count_of_day` into an array of numbers if it exists
        if (element.count_of_day && typeof element.count_of_day === "number") {
          // Convert the number into an array of its digits
          element.count_of_day = Array.from(
            String(element.count_of_day),
            Number
          );
        }
        state.additional_courses.push(element);
      });
    },
    EDIT_ADDITIONAL_COURESE(state, course) {
      let index = state.additional_courses.findIndex((e) => e.id === course.id);
      if (index !== -1) {
        // Ensure count_of_day is a string for proper display
        course.count_of_day = Array.from(String(course.count_of_day), Number);

        // Use Vue's reactivity system by updating properties in place
        Object.assign(state.additional_courses[index], course);
      }
    },
    DELETE_ADDITIONAL_COURESE(state, course) {
      let index = state.additional_courses.findIndex((e) => e.id == course.id);
      state.additional_courses.splice(index, 1);
    },

    // courses
    ADD_COURESES(state, course) {
      // Find the existing day in the state
      const existingDay = state.courses.find(
        (entry) => entry.day === course.day
      );

      if (existingDay) {
        // If the day exists, add the course to its exercises
        existingDay.exercises.push({
          id: course.id,
          title: course.title,
          priority: course.priority,
        });
      } else {
        // If the day doesn't exist, create a new entry
        state.courses.push({
          day: course.day,
          exercises: [
            { id: course.id, title: course.title, priority: course.priority },
          ],
        });
      }
      state.courses.sort((a, b) => parseInt(a.day) - parseInt(b.day));
    },
    ADD_MAIN_COURESES(state, course) {
      // Find the existing day in the state
      const existingDayIndex = state.courses.findIndex(
        (entry) => entry.day === course[0].day
      );
      if (existingDayIndex !== -1) {
        // If the day exists, remove it
        state.courses.splice(existingDayIndex, 1);
      }

      state.courses.push({
        day: course[0].day,
        exercises: course.map((element) => ({
          id: element.id,
          title: element.title,
          priority: element.priority,
        })),
      });

      state.courses.sort((a, b) => parseInt(a.day) - parseInt(b.day));
    },

    GET_COURESES(state, courses) {
      // Clear the current state
      state.courses.splice(0, state.courses.length);

      // Create a map to group exercises by day
      const groupedCourses = {};

      courses.forEach((course) => {
        if (!groupedCourses[course.day]) {
          groupedCourses[course.day] = { day: course.day, exercises: [] };
        }
        groupedCourses[course.day].exercises.push({
          id: course.id,
          title: course.title,
          priority: course.priority,
        });
      });

      // Convert the grouped object into an array
      const transformedCourses = Object.values(groupedCourses);

      // Push the transformed courses into the state
      transformedCourses.forEach((course) => state.courses.push(course));
    },

    EDIT_COURESE(state, course) {
      // Find the day the course belongs to
      const dayIndex = state.courses.findIndex(
        (entry) => entry.day === course.day
      );

      if (dayIndex !== -1) {
        // Find the course in the exercises array
        const exerciseIndex = state.courses[dayIndex].exercises.findIndex(
          (exercise) => exercise.id === course.id
        );

        if (exerciseIndex !== -1) {
          // Update the course in the exercises array
          state.courses[dayIndex].exercises[exerciseIndex] = {
            ...state.courses[dayIndex].exercises[exerciseIndex],
            ...course,
          };
        }
      }
    },
    DELETE_COURESE(state, course) {
      // Find the day the course belongs to
      const dayIndex = state.courses.findIndex(
        (entry) => entry.day === course.day
      );

      if (dayIndex !== -1) {
        // Find the index of the course in the exercises array
        const exerciseIndex = state.courses[dayIndex].exercises.findIndex(
          (exercise) => exercise.id === course.id
        );

        if (exerciseIndex !== -1) {
          // Remove the course from the exercises array
          state.courses[dayIndex].exercises.splice(exerciseIndex, 1);

          // If the exercises array is empty, remove the entire day
          if (state.courses[dayIndex].exercises.length === 0) {
            state.courses.splice(dayIndex, 1);
          }
        }
      }
    },
    DELETE_DAY(state, course) {
      // Find the index of the day to delete
      const dayIndex = state.courses.findIndex(
        (entry) => entry.day === course.day
      );

      if (dayIndex !== -1) {
        // Remove the day and its exercises from the state
        state.courses.splice(dayIndex, 1);
      }
    },

    UPDATE_TOGGLE(state, { toggleUpDay, toggleDownDay }) {
      // Find the indices of the two days
      const toggleUpIndex = state.courses.findIndex(
        (entry) => parseInt(entry.day) === parseInt(toggleUpDay)
      );

      const toggleDownIndex = state.courses.findIndex(
        (entry) => parseInt(entry.day) === parseInt(toggleDownDay)
      );

      if (toggleUpIndex !== -1 && toggleDownIndex !== -1) {
        // Swap the `day` values between the two groups
        const tempDay = state.courses[toggleUpIndex].day;
        state.courses[toggleUpIndex].day = state.courses[toggleDownIndex].day;
        state.courses[toggleDownIndex].day = tempDay;

        // Sort the courses array by day to maintain order
        state.courses.sort((a, b) => parseInt(a.day) - parseInt(b.day));

        // Ensure Vue reactivity is triggered
        state.courses = [...state.courses];
      }
    },

    // previous_courses

    GET_PREVIOUS_COURESES(state, courses) {
      state.previous_courses.splice(0, state.previous_courses.length);
      courses.forEach((element) => {
        state.previous_courses.push(element);
      });
    },
  },
  actions: {
    async reset_table_courses({ state }) {
      state.courses = [];
      state.pageCount2 = 1;
      state.courses_loading = false;
      state.courses_params = {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 50,
      };
    },
    async reset_table_additional_courses({ state }) {
      state.additional_courses = [];
      state.pageCount = 1;
      state.courses_loading = false;
      state.params = {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 50,
      };
    },

    get_additional_courses({ commit, state, rootState }) {
      state.additional_courses_loading = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;
        let type = "";
        type = `&type=sub`;
        let subscribtion_session_id = "";
        subscribtion_session_id = `&subscribtion_session_id=${localStorage.getItem(
          "subscribtion_session_id"
        )}`;
        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/exercises" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            type +
            subscribtion_session_id,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;
            commit("GET_ADDITIONAL_COURESE", response.data.result);

            state.additional_courses_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.additional_courses_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    add_additional_courses({ commit, state, rootState }, data) {
      state.add_additional_courses_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/exercise/create",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_ADDITIONAL_COURESE", response.data.result[0]);
            state.add_additional_courses_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_additional_courses_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    edit_additional_course({ commit, state, rootState }, data) {
      state.edit_course_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/exercise/update",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("EDIT_ADDITIONAL_COURESE", response.data.result[0]);
            state.edit_course_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.edit_course_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    delete_additional_course({ commit, state, rootState }, data) {
      state.delete_course_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/exercise/delete",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("DELETE_ADDITIONAL_COURESE", response.data.result[0]);
            state.delete_course_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.delete_course_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    // courese
    get_current_courses({ commit, state, rootState }) {
      state.courses_loading = true;
      let data = state.courses_params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        let type = "";
        type = `&type=main`;
        let subscribtion_session_id = "";
        subscribtion_session_id = `&subscribtion_session_id=${localStorage.getItem(
          "subscribtion_session_id"
        )}`;
        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/exercises" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            type +
            subscribtion_session_id,
          method: "get",
        })
          .then((response) => {
            commit("GET_COURESES", response.data.result);

            state.courses_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.courses_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_previous_courses({ commit, state, rootState }) {
      state.previous_courses_loading = true;

      return new Promise((resolve) => {
        let type = "";
        type = `?type=main`;
        let subscribtion_session_id = "";
        subscribtion_session_id = `&subscribtion_session_id=${localStorage.getItem(
          "previous_session_id"
        )}`;
        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/exercises" +
            type +
            subscribtion_session_id,
          method: "get",
        })
          .then((response) => {
            commit("GET_PREVIOUS_COURESES", response.data.result);

            state.previous_courses_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.previous_courses_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    add_main_courses({ commit, state, rootState }, data) {
      state.add_courses_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/exercise/main/create",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_MAIN_COURESES", response.data.result);
            state.add_courses_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_courses_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    update_course({ commit, state, rootState }, data) {
      state.update_course_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/exercise/update",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("EDIT_COURESE", response.data.result[0]);
            state.update_course_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.update_course_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    delete_course({ commit, state, rootState }, data) {
      state.delete_course_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/exercise/delete",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("DELETE_COURESE", response.data.result[0]);
            state.delete_course_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.delete_course_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    add_days({ commit, state, rootState }, data) {
      state.add_days_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/exercise/add/days",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_COURESES", response.data.result[0]);
            state.add_days_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_days_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    delete_main_course({ commit, state, rootState }, data) {
      state.delete_day_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/exercise/delete/days",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("DELETE_DAY", response.data.result[0]);
            state.delete_day_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.delete_day_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    toggle_courses({ commit, state, rootState }, data) {
      state.toggle_courses_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/toggle/courses",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("UPDATE_TOGGLE", {
              toggleUpDay: data.toggle_up, // Directly access toggle_up
              toggleDownDay: data.toggle_down, // Directly access toggle_down
            });
            state.toggle_courses_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.toggle_courses_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    toggle_single_courses({ commit, state, rootState }, data) {
      state.add_courses_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/toggle/single/courses",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_MAIN_COURESES", response.data.result);
            state.add_courses_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_courses_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default courses;
