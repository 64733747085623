<template>
  <v-app class="app">
    <SideBar v-if="!$route.meta.hideNavbar" />
    <v-main style="min-height: calc(100vh - 100px)">
      <router-view />

      <SnackBar v-if="snackBar" :snack_message="snack_message" />
    </v-main>
  </v-app>
</template>

<script>
import SnackBar from "./components/layout/Snackbar";
import SideBar from "./components/layout/SideBar.vue";
export default {
  name: "App",
  components: { SideBar, SnackBar },
  data: () => ({
    //
  }),
  computed: {
    snackBar() {
      return this.$store.getters.snack_bar;
    },
    snack_message() {
      return this.$store.state.snack_message;
    },
  },
};
</script>
<style>
.data_table {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 500 !important;
}
.font-semiBold {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 600 !important;
}
.font-toolbar-title {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 700 !important;
  color: #50535b;
}
.font-toolbar-title2 {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 700 !important;
}

.v-dialog {
  border-radius: 25px !important;
}
.scroll {
  margin-left: 2px;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
  border-left: 1px solid #ffffff;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(180deg, #e6b10a 0%, #f28218 99%);
  border: solid 1px #e6e6e6;
  border-radius: 100px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-card {
  margin-top: 100px;
  box-shadow: 0px 0px 0px 0px !important;
}
.dz-filename {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 500 !important;
}
.dz-size {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 500 !important;
}
h1 {
  letter-spacing: 0px !important;
}
h2 {
  letter-spacing: 0px !important;
}
h3 {
  letter-spacing: 0px !important;
}
h4 {
  letter-spacing: 0px !important;
}
h5 {
  letter-spacing: 0px !important;
}
h6 {
  letter-spacing: 0px !important;
}
div {
  letter-spacing: 0px !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}
</style>
<style lang="scss">
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background-color: #f28218 !important;
  color: #ffffff !important;
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 550 !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: #f28218 !important;
}
</style>
