<template>
  <v-row>
    <v-col cols="12" sm="12" md="12" lg="12" class="pb-8 mt-3">
      <v-card class="mx-auto card" width="98%">
        <v-toolbar color="#F97316">
          <v-card-actions class="mt-2 pb-5">
            <v-btn icon fab color="#C62828" x-small v-on:click="go_back">
              <Icon
                class="icon_download"
                icon="ic:outline-close"
                color="#ffffff"
                width="25"
              />
            </v-btn>
          </v-card-actions>
          <v-toolbar-title style="color: #ffffff"
            class="mx-auto font-toolbar-title2"
            v-if="loading == false"
          >
            السجل المالي [{{ payments[0].trainee.full_name }}]
          </v-toolbar-title>
          <v-toolbar-title style="color: #ffffff" class="mx-auto font-toolbar-title2" v-else>
            السجل المالي [ ...... ]
          </v-toolbar-title>
        </v-toolbar>
        <v-list
          two-line
          class="list"
          min-height="550"
          v-if="loading == false"
          @scroll.native="onScroll"
          ref="scrollContainer"
        >
          <template v-for="(payment, index) in payments">
            <v-list-item :key="index" class="list-item my-2 mx-2">
              <v-list-item-title class="d-flex justify-space-around">
                <span style="display: flex; align-items: center">
                  <h3 style="color: #50535b" class="data_table">نوع العملية :</h3>
                  <h3
                    style="color: #393c43" class="data_table"
                    v-if="payment.operation_type == 'subscription'"
                  >
                    اشتراك جديد
                  </h3>
                  <h3 style="color: #393c43" class="data_table" v-else>تجديد اشتراك</h3></span
                >
              </v-list-item-title>
              <v-list-item-title class="d-flex justify-space-around">
                <span style="display: flex; align-items: center">
                  <h3 style="color: #50535b" class="data_table">التاريخ :</h3>
                  <h3 style="color: #393c43" class="data_table">
                    {{ payment.payment_date }}
                  </h3></span
                >
              </v-list-item-title>
              <v-list-item-title class="d-flex justify-space-around">
                <span style="display: flex; align-items: center">
                  <h3 style="color: #50535b" class="data_table">السعر الاجمالي :</h3>
                  <h3 style="color: #393c43" class="data_table">
                    {{ payment.total_amount | formatNumber }}
                  </h3></span
                >
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
        <v-list v-else style="max-height: 550px; overflow-y: auto" class="list">
          <v-list-item
            v-for="(n, index) in 5"
            :key="'A' + index"
            class="list-item py-1 my-2 mx-2"
          >
            <v-skeleton-loader
              class="mx-auto"
              width="100%"
              type="list-item-avatar-two-line"
            ></v-skeleton-loader>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    this.get_details_payments();
  },
  computed: {
    payments() {
      return this.$store.state.payments.details_payments;
    },
    loading() {
      return this.$store.state.payments.details_payments_loading;
    },
    pageCount() {
      return this.$store.state.payments.pageCount2;
    },
  },
  methods: {
    get_details_payments() {
      this.$store.dispatch("payments/get_details_payments");
    },

    onScroll(event) {
      var payment_state = this.$store.state.payments.payment_state;
      const container = event.target;
      const isScrollingDown = container.scrollTop > this.lastScrollTop;
      this.lastScrollTop = container.scrollTop;
      if (
        isScrollingDown &&
        container.scrollTop + container.clientHeight >=
          container.scrollHeight - 50 &&
        !this.loading &&
        payment_state == "done"
      ) {
        this.get_details_payments;
      }
    },
    go_back() {
      this.$router.push({ name: "Financial_record_players" });
    },
  },

  watch: {
    params: {
      handler() {
        this.get_details_payments();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.card {
  background-color: #f3f3f4 !important; /*f3f3f4*/
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 25px !important;
}
.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px !important;
}

.list-item {
  background-color: #f3f3f4;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 16px !important;
}
.list {
  background-color: #d5d6d7;
}
</style>
