<template>
  <v-row justify="center">
    <v-dialog :value="value" persistent max-width="700">
      <v-card class="card mx-auto">
        <v-toolbar elevation="0">
          <v-btn
            icon
            fab
            color="#C62828"
            x-small
            v-on:click="$emit('popClose')"
            class="mr-1"
          >
            <Icon
              class="d-flex justify-center"
              icon="ic:outline-close"
              color="#000000"
              width="25"
            />
          </v-btn>

          <v-toolbar-title class="mx-auto pl-16">
            <h3 style="color: #50535b" class="font-semiBold">حذف المجموعة</h3>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-title class="mt-4 d-flex justify-center">
          <h5
            class="pb-5 data_table"
            style="
              color: #50535b;
              white-space: normal;
              word-wrap: break-word;
              text-align: start;
              max-width: 99%;
            "
          >
            أنت على وشك حذف هذه المجموعة ولا يمكنك التراجع عن هذا القرار بعد
            الضغط على الزر.
          </h5>
        </v-card-title>
        <v-card-actions class="mt-3 pb-5">
          <v-btn
            large
            @click="$emit('delete_group')"
            color="#EF4444"
            class="btn-a py-5 mx-auto"
            elevation="4"
          >
            <h3 style="color: white" class="data_table ml-2">حذف المجموعة</h3>
            <Icon icon="mingcute:delete-3-fill" color="#ffffff" width="26" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    value: Boolean,
    title_group: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      title: null,
      RulseAll: [
        (value) => !!value || "هذا الحقل مطلوب",

        (value) => (value || "").length >= 3 || "الحد الادنى لعدد الاحرف هوه 3",
        (value) =>
          (value || "").length < 256 || "الحد الاقصى لعدد الاحرف هوه 255",
      ],
    };
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.btn-a {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 174px !important;
}
</style>
