<template>
  <v-container fluid class="mt-0">
    <v-row class="mb-10" v-if="loading == false">
      <v-col cols="12" sm="12" md="12" lg="12" class="pb-8">
        <v-card class="mx-auto card" width="98%">
          <v-card-title class="d-flex justify-center">
            <v-text-field
              v-model="trainer_query"
              @input="query_change"
              append-icon="mdi-magnify"
              label="إبحث عن المدرب لرؤية سجله المالي"
              solo
              class="data_table"
              color="#f97316"
              clearable
              hide-details
            />
            <v-menu
              v-if="trainers.length > 0"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="310"
              max-height="300"
              origin="top right"
              attach="body"
              offset-x
              right
            >
              <v-list v-if="trainers.length > 0">
                <v-list-item
                  v-for="(trainer, index) in trainers"
                  :key="index"
                  @click="selectTrainer(trainer)"
                >
                  <v-list-item-title style="text-align: right">
                    <h4 class="data_table">{{ trainer.full_name }}</h4>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer> </v-spacer>
            <h5 style="color: #393c43" class="mx-5 data_table">
              <v-btn icon style="background-color: #f97316" class="mr-2">
                <Icon icon="flowbite:user-solid" color="#ffffff" width="30"
              /></v-btn>
              {{ info_trainer.full_name }}
            </h5>

            <h5 style="color: #393c43" class="mx-5 data_table">
              <v-btn icon style="background-color: #f97316" class="mr-2">
                <Icon icon="flowbite:phone-solid" color="#ffffff" width="26"
              /></v-btn>
              {{ info_trainer.phone }}
            </h5>

            <h5
              style="color: #393c43"
              class="mx-5 data_table"
              v-if="info_trainer.address != null"
            >
              <v-btn icon style="background-color: #f97316" class="mr-2">
                <Icon icon="fluent:home-32-filled" color="#ffffff" width="26"
              /></v-btn>
              {{ info_trainer.address }}
            </h5>

            <h5 style="color: #393c43" class="mx-5 data_table" v-else>
              <v-btn icon style="background-color: #f97316" class="mr-2">
                <Icon icon="fluent:home-32-filled" color="#ffffff" width="26"
              /></v-btn>
              لايوجد
            </h5>
            <!--  @click="delete_trainee(player_information.trainee.id)" -->
            <v-btn
              :loading="loading2"
              large
              style="background-color: #ef4444"
              class="mr-2 btn mx-5"
            >
             
              <h3 style="color: #ffffff" class="data_table ml-1">حذف المدرب</h3>
              <Icon icon="mingcute:delete-3-fill" color="#ffffff" width="26" />
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
            <v-spacer> </v-spacer>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" class="mt-0">
        <v-card class="mx-auto card2 py-0" width="98%" min-height="400px">
          <v-card-title
            class="d-flex justify-center py-5"
            v-if="info_trainer.status == 1"
          >
            <h3 style="color: #50535b" class="font-semiBold">مدة التعليق</h3></v-card-title
          >
          <v-card-title
            class="d-flex justify-center py-5 title-select"
            v-if="info_trainer.status == 1"
          >
            <v-select
              v-model="duration"
              :items="durations"
              @change="add_time(duration)"
              color="#f97316"
              item-color="orange lighten-1"
              item-text="title"
              item-value="duration"
              outlined
              rounded
              solo
              single-line
              hide-details
              label="مدة التعليق"
              class="d-flex justify-center px-2 data_table"
            ></v-select>
          </v-card-title>
          <v-card-title
            class="d-flex justify-center"
            v-if="info_trainer.status == 1"
          >
            <v-btn
              large
              style="background-color: #ee4444"
              class="btn2"
              @click="RedefineDuration"
            >
              <h3 class="ml-1 data_table" style="color: white">
                اعادة تحديد المدة
              </h3>
              <Icon icon="mingcute:time-fill" color="#ffffff" width="26" />
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-title>
          <v-card-title class="d-flex justify-center pt-6 pb-0">
            <h3 style="color: #50535b" class="font-semiBold">أيام التعليق</h3>
          </v-card-title>
          <v-card-title class="d-flex justify-center pb-4 pt-4">
            <v-btn
              :disabled="
                info_trainer.status == 2 || info_trainer.status == 3
                  ? true
                  : false
              "
              fab
              large
              style="background-color: #393c43"
              class="ml-2"
              @click="progress_circular(1)"
            >
              <Icon icon="ic:round-plus" color="#ffffff" width="26" />
            </v-btn>

            <v-progress-circular
              v-if="info_trainer.status == 1"
              :rotate="360"
              :size="100"
              :width="15"
              :value="days == null ? 0 : days * 3.3"
              color="#F97300"
            >
              <h4 style="color: #393c43">{{ days }}</h4>
            </v-progress-circular>
            <v-progress-circular
              v-if="info_trainer.status == 2 || info_trainer.status == 3"
              :rotate="360"
              :size="100"
              :width="15"
              :value="banDuration == null ? 0 : banDuration * 3.3"
              color="#F97300"
            >
              <h4 style="color: #393c43">{{ banDuration }}</h4>
            </v-progress-circular>
            <v-btn
              :disabled="
                info_trainer.status == 2 || info_trainer.status == 3
                  ? true
                  : false
              "
              fab
              large
              style="background-color: #393c43"
              class="mr-2"
              @click="progress_circular(0)"
            >
              <Icon icon="line-md:minus" color="#ffffff" width="26" /> </v-btn
          ></v-card-title>
          <v-card-title
            class="d-flex justify-center mt-3 pb-5"
            v-if="info_trainer.status == 2 || info_trainer.status == 3"
          >
            <v-text-field
              v-model="info_trainer.ban_reasone"
              type="text"
              class="data_table v-input__slot"
              color="#ffffff"
              hide-details
              rounded
              :disabled="true"
            />
          </v-card-title>
          <v-card-title
            class="d-flex justify-center mt-3 pb-5"
            v-if="info_trainer.status == 1"
          >
            <v-btn
              large
              block
              style="background-color: #f97300"
              class="btn3"
              :loading="loading3"
              @click="pop_add_message"
            >
             
              <h3 class="ml-1 data_table" style="color: white">تعليق</h3>
              <Icon icon="uim:lock-alt" color="#ffffff" width="26" />
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-title>
          <v-card-title
            class="d-flex justify-center mt-3 pb-5"
            v-if="info_trainer.status == 2 || info_trainer.status == 3"
          >
            <v-btn
              large
              block
              style="background-color: #ef4444"
              class="btn3"
              :loading="loading4"
              @click="open_ban"
            >
              <h3 class="ml-1 data_table" style="color: white;">
                الغاء التعليق
              </h3>
              <Icon icon="mingcute:delete-fill" color="#ffffff" width="26" />
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" sm="6" md="3" lg="3" >
        <v-card color="#F3F3F4" class="py-0 card2">
          <v-card-title class="pb-1 d-flex justify-center">
            <Icon icon="flowbite:user-solid" color="#f97316" width="26" />
          </v-card-title>
          <v-card-title class="py-0 d-flex justify-center">
            <h5 class="font-semiBold" v-if="info_trainer.inactive_memberships_count != null">
              {{ player_information.inactive_memberships_count }}
            </h5>
            <h5 class="font-semiBold" v-else>لايوجد</h5>
          </v-card-title>
          <v-card-title class="pt-0 d-flex justify-center">
            <h5 class="data_table" style="color: #f97316">إشتراكات لاعبي المدرب المنتهية</h5>
          </v-card-title>
        </v-card>
      </v-col>

      <v-col cols="6" sm="6" md="3" lg="3" class="data_table">
        <v-card color="#F3F3F4" class="py-0 card2 ">
          <v-card-title class="pb-1 d-flex justify-center">
            <Icon icon="flowbite:user-solid" color="#f97316" width="26" />
          </v-card-title>
          <v-card-title class="py-0 d-flex justify-center">
            <h5 class="font-semiBold" v-if="info_trainer.trainee_count != null">
              {{ info_trainer.trainee_count }}
            </h5>
            <h5 class="font-semiBold" v-else>لايوجد</h5>
          </v-card-title>
          <v-card-title class="pt-0 d-flex justify-center">
            <h5 class="data_table" style="color: #f97316">إجمالي عدد لاعبين المدرب</h5>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" sm="6" md="3" lg="3" class="data_table">
        <v-card color="#F3F3F4" class="py-0 card2">
          <v-card-title class="pb-1 d-flex justify-center">
            <Icon icon="flowbite:user-solid" color="#f97316" width="26" />
          </v-card-title>
          <v-card-title class="py-0 d-flex justify-center">
            <h5 class="font-semiBold" v-if="info_trainer.active_memberships_count != null">
              {{ info_trainer.active_memberships_count }}
            </h5>
            <h5 class="font-semiBold" v-else>لايوجد</h5>
          </v-card-title>
          <v-card-title class="pt-0 d-flex justify-center">
            <h5 class="data_table" style="color: #f97316">إشتراكات المدرب الفعالة</h5>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" sm="6" md="3" lg="3" class="data_table">
        <v-card color="#F3F3F4" class="py-0 card2">
          <v-card-title class="pb-1 d-flex justify-center">
            <Icon icon="flowbite:user-solid" color="#f97316" width="26" />
          </v-card-title>
          <v-card-title class="py-0 d-flex justify-center">
            <h5 class="font-semiBold">
              {{ moment(info_trainer.created_at).format('MM/DD/YYYY') }}
            </h5>
          </v-card-title>
          <v-card-title class="pt-0 d-flex justify-center">
            <h5 class="data_table" style="color: #f97316">تاريخ إنضمام المدرب</h5>
          </v-card-title>
        </v-card>
      </v-col>

      <PopReasonComment
        :value="add_message"
        v-on:popClose="add_message = !add_message"
        v-on:ban="add_ban"
      />
    </v-row>
    <v-row v-if="loading == true">
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="11"
        lg="11"
      >
        <v-card class="mx-auto card" width="88%" height="100%">
          <v-skeleton-loader type="image"></v-skeleton-loader>
          <v-skeleton-loader type="image"></v-skeleton-loader>
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-card>
       
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        v-for="(n, index) in 8"
        :key="'A1' + index"
      >
        <v-card class="mx-auto card" width="95%">
          <v-skeleton-loader type="image" height="100"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import PopReasonComment from "@/components/admin/PopReasonComment";
export default {
  components: { PopReasonComment },
  data() {
    return {
      progress: 10,
      days: 1,
      add_message: false,
      duration: { title: "يوم", duration: 1 },
      durations: [
        { title: "يوم", duration: 1 },
        { title: "اسبوع", duration: 7 },
        { title: "شهر", duration: 30 },
        { title: "نهائي", duration: "نهائي" },
      ],

      menu: false,
      menu_props: {
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 300,
        offsetY: true,
        offsetOverflow: true,
        transition: false,
      },
    };
  },
  created() {
    this.get_info_trainer();
    this.get_trainers_from_search();
  },
  computed: {
    info_trainer() {
      return this.$store.state.admin_requests.info_trainer;
    },
    trainers() {
      return this.$store.state.admin_requests.trainers;
    },
    loading() {
      return this.$store.state.admin_requests.info_trainer_loading;
    },
    loading2() {
      return this.$store.state.admin_requests.delete_trainer_loading;
    },
    loading3() {
      return this.$store.state.admin_requests.ban_trainer_loading;
    },
    loading4() {
      return this.$store.state.admin_requests.open_ban_trainer_loading;
    },

    trainer_query: {
      set(val) {
        this.$store.state.admin_requests.trainer_query = val;
      },
      get() {
        return this.$store.state.admin_requests.trainer_query;
      },
    },

    banDuration() {
      if (!this.info_trainer || !this.info_trainer.ban_duration) {
        return 0; // Default value if no subscription
      }

      const currentDate = new Date();
      const banDuration = new Date(this.info_trainer.ban_duration);

      // Calculate the difference in time (milliseconds)
      const timeDifference = banDuration - currentDate;

      // Convert time difference to days
      const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      // Return the days remaining or handle expired subscriptions
      return daysRemaining >= 0 ? daysRemaining : 0;
    },
  },
  methods: {
    get_info_trainer() {
      this.$store.dispatch("admin_requests/get_info_trainer");
    },
    get_trainers_from_search() {
      this.$store.dispatch("admin_requests/get_trainers_from_search");
    },
    progress_circular(val) {
      if (val == 0 && this.days != 0) {
        this.days -= 1;
      } else if (val == 1 && this.days != 100) {
        this.days += 1;
      }
    },

    add_time(days) {
      this.days = days;
    },
    RedefineDuration() {
      this.days = 1;
    },

    pop_add_message() {
      this.add_message = true;
    },

    add_ban(ban_reasone) {
      let data = {};
      data["trainer_id"] = this.$store.state.admin_requests.trainer_id;
      data["ban_reasone"] = ban_reasone;
      data["days"] = this.days;
      this.add_message = false;
      this.$store.dispatch("admin_requests/add_ban", data).then(() => {});
    },
    open_ban() {
      let data = {};
      data["trainer_id"] = this.$store.state.admin_requests.trainer_id;
      this.$store.dispatch("admin_requests/open_ban", data);
    },

    selectTrainer(trainer) {
      localStorage.setItem("trainer_id", trainer.id);
      this.$store.commit("admin_requests/setIdTrainer", trainer.id);
      this.menu = false;
      this.get_info_trainer();
    },
    query_change() {
      this.menu = true;
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.get_trainers_from_search();
        this.$store.state.admin_requests.params.page = 1;
      }, 1000);
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
  background-color: #ffffff; /*f3f3f4*/
}
.card2 {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
  background-color: #f3f3f4; /*f3f3f4*/
}
.btn {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 40px !important;
}
.btn2 {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 25px 70px !important;
}
.btn3 {
  border-radius: 16px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 27px 0px !important;
}
.title-select {
  padding-left: 450px !important;
  padding-right: 450px !important;
}
.v-menu__content {
  left: auto !important; /* Ignore the `left` style */
  right: 48px !important; /* Apply a right alignment */
  top: 85px !important;
}
.v-input__slot {
  background: #ffffff !important;
  padding: 12px !important;
}
</style>
