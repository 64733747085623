import axios from "axios";
const news = {
  namespaced: true,
  state: () => ({
    groups: [],
    groups_loading: false,
    add_groups_loading: false,
    update_groups_loading: false,
    delete_groups_loading: false,
    create_news_loading: false,
    pageCount: 1,

    params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },

    // get trainees
    trainees: [],
    trainees_loading: false,
    trainee_query: "",
    trainees_state: "done",
    trainees_params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 3,
    },
  }),
  mutations: {
    ADD_GROUPS(state, group) {
      state.groups.push(group);
    },
    UPDATE_GROUPS(state, group) {
      let index = state.groups.findIndex((g) => g.id === group.id);
      if (index !== -1) {
        // Update the element in place
        state.groups[index] = group;
      }
    },
    DELETE_GROUPS(state, group) {
      let index = state.groups.findIndex((e) => e.id == group.id);
      state.groups.splice(index, 1);
    },

    GET_GROUPS(state, groups) {
      state.groups.splice(0, state.groups.length);
      groups.forEach((element) => {
        state.groups.push(element);
      });
    },

    GET_TRAINEES_DREPDWON(state, trainees) {
      if (trainees.length === 0) {
        state.trainees_state = "finished";
        if (state.trainees_params.page > 1) state.trainees_params.page -= 1;
        return;
      }
      state.trainees_params.page += 1;
      const newRequests = trainees.filter(
        (request) => !state.trainees.some((r) => r.id === request.id)
      );

      state.trainees.push(...newRequests);

      state.trainees_state = "done";
    },
  },
  actions: {
    async reset_table({ state }) {
      state.groups = [];
      state.pageCount = 1;
      state.groups_loading = false;
      state.params = {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 10,
      };
    },

    get_groups({ commit, state, rootState }) {
      state.groups_loading = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/groups" +
            "?skip=" +
            skip +
            "&limit=" +
            limit,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;
            commit("GET_GROUPS", response.data.result);

            state.groups_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.groups_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    add_groups({ commit, state, rootState }, data) {
      state.add_groups_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/group/create",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_GROUPS", response.data.result[0]);
            state.add_groups_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_groups_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    update_group({ commit, state, rootState }, data) {
      state.update_groups_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/group/update",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("UPDATE_GROUPS", response.data.result[0]);
            state.update_groups_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.update_groups_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    delete_group({ commit, state, rootState }, data) {
      state.delete_groups_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/group/delete",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("DELETE_GROUPS", response.data.result[0]);
            state.delete_groups_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.delete_groups_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    create_news({ commit, state, rootState }, data) {
      state.create_news_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/news/create",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            state.create_news_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.create_news_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    deleteNote({ commit, state, rootState }, data) {
      state.delete_note_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/note/delete",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("DELETE_NOTE", response.data.result[0]);
            state.delete_note_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.delete_note_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_trainees({ commit, state, rootState }) {
      state.trainees_loading = true;
      let data = state.trainees_params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;
        let query = "";
        if (
          state.trainee_query != undefined &&
          state.trainee_query != null &&
          state.trainee_query.length > 0
        )
          query = `&query=${state.trainee_query}`;
        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/trainees" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            query,
          method: "get",
        })
          .then((response) => {
            commit("GET_TRAINEES_DREPDWON", response.data.result);

            state.trainees_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.trainees_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default news;
