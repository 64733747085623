<template>
  <v-navigation-drawer
    app
    permanent
    color="#F97316"
    width="80"
    class="navigation-drawer"
  >
    <v-list dense>
      <v-list-item class="d-flex justify-center mb-10 my-3">
        <img src="@/assets/Elift-logo-dashboard2.png" alt="Logo" width="80" />
      </v-list-item>
      <v-list-item-group v-model="model" active-class="white--text">
        <v-list-item
          v-for="(item, index) in filteredItems"
          :key="index"
          link
          :to="item.link"
          class="d-flex justify-center py-2"
          @click="link(index)"
        >
          <v-list-item-icon class="white--text mx-auto">
            <Icon
              :icon="item.icon"
              width="30"
              :class="model == index ? 'color-icon-orange' : 'color-icon-grey'"
            />
          </v-list-item-icon>
        </v-list-item>
        <!-- <v-list-item class="d-flex justify-center py-2" @click="redirect">
          <v-list-item-icon class="white--text mx-auto">
            <v-badge offset-x="38" offset-y="8" color="#ffffff" content="6">
              <Icon
                icon="zondicons:notification"
                width="25"
                class="white--text"
              />
            </v-badge>
          </v-list-item-icon>
        </v-list-item> -->
      </v-list-item-group>

      <v-spacer></v-spacer>
    </v-list>
    <template v-slot:append>
      <div class="d-flex justify-center mt-n6">
        <!-- <v-btn icon class="mt-n6" @click="redirect">
          <v-badge offset-x="38" offset-y="8" color="#ffffff" content="6">
            <Icon
              icon="zondicons:notification"
              width="25"
              class="white--text"
            />
          </v-badge>
        </v-btn> -->
        <v-btn icon class="mt-n6" @click="logout" :loading="loading">
          <Icon
            icon="material-symbols:logout-rounded"
            width="32"
            class="white--text"
          />
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      model: 0,

      opations: [
        { link: "/", icon: "solar:home-2-bold", type: 0 },
        { link: "users", icon: "mage:file-2-fill", type: 0 },
        { link: "news", icon: "ph:pencil-simple", type: 0 },
        { link: "trainees-profile", icon: "solar:user-linear", type: 0 },
        { link: "settings", icon: "weui:setting-filled", type: 0 },
        { link: "financial-record", icon: "mage:chart-fill", type: 0 },
        { link: "notifications", icon: "zondicons:notification", type: 0 },

        { link: "/home", icon: "solar:home-2-bold", type: 200 },
        { link: "/admin/users", icon: "mage:file-2-fill", type: 200 },
        { link: "/admin/news", icon: "ph:pencil-simple", type: 200 },
        { link: "/admin/coach-profile", icon: "solar:user-linear", type: 200 },
        { link: "/admin/financial-record", icon: "mage:chart-fill", type: 200 },
        {
          link: "/admin/memberships",
          icon: "lets-icons:filter",
          type: 200,
        },
        {
          link: "/admin/notifications",
          icon: "zondicons:notification",
          type: 200,
        },
     
        // { link: "news", icon: "ph:pencil-simple"  , type: 200 },
        // { link: "calisthenics", icon: "solar:user-linear"  , type: 200 },
        // { link: "settings", icon: "weui:setting-filled"  , type: 200 },
        // { link: "financial-record", icon: "mage:chart-fill"  , type: 200 },
      ],
    };
  },
  computed: {
    filteredItems() {
      return this.opations.filter(
        (opation) => opation.type == localStorage.getItem("user_type")
      );
    },
    loading() {
      return this.$store.state.logout_loading;
    },
  },
  methods: {
    link(index) {
      this.model = index;
    },
    redirect() {
      if (localStorage.getItem("user_type") == "200") {
        this.$router.push("/admin/notifications");
      } else {
        this.$router.push("/notifications");
      }
    },
    logout() {
      if (localStorage.getItem("user_type") == "200") {
        this.$store.dispatch("logout_admin");
      } else {
        this.$store.dispatch("logout_trainer");
      }
    },
  },
};
</script>

<style>
.v-navigation-drawer {
  background-color: #f28218;
  border-radius: 35px !important;
  margin-left: 8px;
}
.v-badge__badge {
  color: #f28218;
  font-weight: 800 !important;
  border-radius: 12px !important;
}
.color-icon-grey {
  color: #fcb686;
}
.color-icon-orange {
  color: white;
}
</style>
