import axios from "axios";
const admin_notifications = {
  namespaced: true,
  state: () => ({
    notifications: [],
    notifications_loading: false,
    create_notifications_loading: false,
    delete_groups_loading: false,
    notifications_state: "done",

    params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },

    
    // groups
    groups:[],
    get_groups_loading: false,
    add_group_loading: false,
    update_groups_loading: false,
    groups_state: "done",
    pageCount:1,
    params_groups: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },

  }),
  mutations: {
  

    GET_All_NOTIFICATIONS_DREPDWON(state, notifications) {
      if (notifications.length === 0) {
        state.notifications_state = "finished";
        if (state.params.page > 1) state.params.page -= 1;
        return;
      }
      state.params.page += 1;
      const newNotifications = notifications.filter(
        (notifications) => !state.notifications.some((r) => r.id === notifications.id)
      );
      state.notifications.push(...newNotifications);
      state.notifications_state = "done";
    },


    // Groups 
    GET_NOTIFICATION_GROUPS(state, groups) {
      state.groups.splice(0, state.groups.length);
      groups.forEach((element) => {
        state.groups.push(element);
      });
    },

    ADD_GROUPS(state, group) {
      state.groups.push(group);
    },
    DELETE_GROUPS(state, group) {
      let index = state.groups.findIndex((e) => e.id == group.id);
      state.groups.splice(index, 1);
    },

    UPDATE_GROUPS(state, group) {
      let index = state.groups.findIndex((g) => g.id === group.id);
      if (index !== -1) {
        // Update the element in place
        state.groups[index] = group;
      }
    },
  },
  actions: {
    get_notifications({ commit, state, rootState }) {
      state.notifications_loading = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        axios({
          url:
            `${rootState.server}` +
            "/api/admin/trainer/notifications" +
            "?skip=" +
            skip +
            "&limit=" +
            limit,
          method: "get",
        })
          .then((response) => {

            commit("GET_All_NOTIFICATIONS_DREPDWON", response.data.result);

            state.notifications_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.notifications_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },


    get_notification_groups({ commit, state, rootState }) {
      state.get_groups_loading = true;
      let data = state.params_groups;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        axios({
          url:
            `${rootState.server}` +
            "/api/admin/notificationGroups" +
            "?skip=" +
            skip +
            "&limit=" +
            limit,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;
            commit("GET_NOTIFICATION_GROUPS", response.data.result);

            state.get_groups_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.get_groups_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    create_notification_group({ commit, state, rootState }, data) {
      state.add_group_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/createNotificationGroup",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_GROUPS", response.data.result[0]);
            state.add_group_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_group_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    update_notification_group({ commit, state, rootState }, data) {
      state.update_groups_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/updateNotificationGroup",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("UPDATE_GROUPS", response.data.result[0]);
            state.update_groups_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.update_groups_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    delete_notification_group({ commit, state, rootState }, data) {
      state.delete_groups_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/deleteNotificationGroup",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("DELETE_GROUPS", response.data.result[0]);
            state.delete_groups_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.delete_groups_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    create_notifications({ commit, state, rootState }, data) {
      state.create_notifications_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/sendAdminNotification",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            state.create_notifications_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.create_notifications_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default admin_notifications;
