<template>
  <v-row>
    <v-col
      cols="12"
      sm="12"
      md="12"
      lg="12"
      class="pb-8"
      v-if="loading == false"
    >
      <v-card class="mx-auto card" width="100%">
        <v-card-title>
          <h3 class="font-toolbar-title">السجل المالي</h3>
        </v-card-title>
        <v-divider class="ml-10"></v-divider>
        <v-card-title class="mt-1 d-flex justify-center">
          <v-date-picker
            v-model="filter_date"
            full-width
            locale="ar-iq"
            :first-day-of-week="6"
            color="#F97316"
            class="mx-5 d-flex justify-center data_table"
          ></v-date-picker>
        </v-card-title>

        <h3 class="d-flex justify-center data_table" style="color: #f97316">
          عدد اللاعبين المسجلين : {{ payments_today["allTotalActiveTrainees"] }}
        </h3>
        <h3 class="d-flex justify-center mt-3">
          <v-btn
            color="#F97316"
            style="border-radius: 10px !important"
            @click="redirect"
          >
            <h3 style="color: #ffffff" class="data_table ml-2">
              عرض السجل المالي
            </h3>
            <Icon
              class="icon_download"
              icon="fluent:payment-28-filled"
              color="#ffffff"
              width="25"
            />
          </v-btn>
        </h3>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
      lg="12"
      class="pb-8"
      v-if="loading == false"
    >
      <v-card class="mx-auto card" width="100%">
        <v-list two-line>
          <v-list-item>
            <v-list-item-action>
              <Icon
                class="icon_download"
                icon="solar:user-bold"
                color="#F97316"
                width="25"
              />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <h3 style="color: #50535b" class="font-semiBold">
                  عدد اللاعبين المحدثين اليوم :
                </h3></v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-action>
              <h3 style="color: #50535b" class="font-semiBold">
                {{ payments_today["total_active_trainees"] }} لاعب
              </h3>
            </v-list-item-action>
          </v-list-item>

          <template v-for="(item, index) in items2">
            <v-list-item :key="item.id + 2">
              <template>
                <v-list-item-action>
                  <Icon
                    class="icon_download"
                    icon="proicons:dollar"
                    color="#F97316"
                    width="25"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <h3 style="color: #50535b" class="font-semiBold">
                      {{ item.title }}
                    </h3></v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <h3 style="color: #f28218" class="font-semiBold">
                    {{ payments_today[item.type] | formatNumber2 }}
                  </h3>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider v-if="index < items2.length" :key="index"></v-divider>
          </template>
        </v-list>
      </v-card>
      <v-card class="mx-auto card" width="100%">
        <v-list two-line class="list" min-height="50">
          <v-list-item class="list-item-m py-1 mx-4 px-2">
            <v-list-item-title class="d-flex justify-start">
              <v-btn
                :ripple="false"
                fab
                class="mr-2 update-price"
                style="
                  box-shadow: 0px 0px 0px 0px !important;
                  background-color: #ffffff;
                "
                :loading="loading2"
                @click="update_profile"
              >
                <Icon icon="octicon:download-16" color="#f97316" width="26" />
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon color="#f97316">mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-list-item-title>
            <v-list-item-title class="d-flex justify-center">
              <span style="display: flex; align-items: center">
                <h3 style="color: #ffffff" class="font-semiBold">
                  يتم حساب سعر الإشتراك على:
                </h3>
              </span>
            </v-list-item-title>
            <v-list-item-title class="d-flex justify-start">
              <v-text-field
                v-model="profile.course_price"
                label="سعر الكورس"
                class="data_table input-settings23"
                style="border-radius: 19px"
                color="#ffffff"
                solo
                clearable
                hide-details
                :disabled="loading2 == true"
              />
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
      lg="12"
      class="mt-2 px-10"
      v-if="loading == true"
    >
      <v-skeleton-loader
        type="card-heading ,image,article,article"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      check_course_price: null,
      items2: [
        {
          id: 1,
          title: "اجمالي واردات اليوم:",
          type: "total_payments",
        },
        {
          id: 2,
          title: "اجمالي واردات اشتراكات التطبيق:",
          type: "total_app_payments",
        },
        {
          id: 3,
          title: "اجمالي واردات اشتراكات المناهج:",
          type: "total_trainer_payments",
        },
      ],
    };
  },
  created() {
    this.get_payments_today();
    this.get_profile();
  },
  computed: {
    payments_today() {
      return this.$store.state.payments.payments_today;
    },
    profile() {
      return this.$store.state.trainer.profile;
    },
    loading() {
      return this.$store.state.payments.payments_today_loading;
    },
    loading2() {
      return this.$store.state.trainer.profile_loading;
    },

    filter_date: {
      set(val) {
        this.$store.state.payments.filter_date = val;
      },
      get() {
        return this.$store.state.payments.filter_date;
      },
    },
  },
  methods: {
    get_payments_today() {
      this.$store.dispatch("payments/get_payments_today").then(() => {
        this.check_course_price = this.profile.course_price;
      });
    },
    redirect() {
      this.$router.push({ name: "Financial_record_players" });
    },

    get_profile() {
      this.$store.dispatch("trainer/get_profile");
    },

    update_profile() {
      if (this.check_course_price != this.profile.course_price) {
        let data = {};
        data["course_price"] = this.profile.course_price;
        this.$store.dispatch("trainer/update_profile", data).then(() => {
          this.check_course_price = this.profile.course_price;
        });
      }
    },
  },
  watch: {
    filter_date: {
      handler() {
        this.get_payments_today();
      },
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
}
.v-application--is-rtl .v-list-item__action:first-child,
.v-application--is-rtl .v-list-item__icon:first-child {
  margin-left: 5px;
}
.list-item-m {
  background-color: #f97300;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 25px !important;
}
@media (min-width: 390px) and (max-width: 428px) {
  .input-settings23 {
    padding: 0px 150px 0px 0px;
  }
}
@media (min-width: 429px) and (max-width: 767px) {
  .input-settings23 {
    padding: 0px 50px 0px 0px;
  }
}

@media (min-width: 768px) {
  .input-settings23 {
    padding: 0px 100px 0px 0px;
  }
}

/* Large screens (desktop) */
@media (min-width: 1024px) {
  .input-settings23 {
    padding: 0px 250px 0px 0px;
  }
}

/* Extra large screens */
@media (min-width: 1440px) {
  .input-settings23 {
    padding: 0px 250px 0px 0px;
  }
}
.v-btn:before {
  background-color: #ffffff !important;
}
</style>
<style>
.v-picker__title {
  border-radius: 20px 20px 0px 0px !important;
}
.v-date-picker-table .v-btn {
  font-size: 16px !important;
}
.v-date-picker-table th {
  font-size: 16px !important;
}
.v-date-picker-title__year {
  font-size: 16px !important;
}
</style>
