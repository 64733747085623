import axios from "axios";
const admin = {
  namespaced: true,
  state: () => ({
    profile_admin: null,
    profile_loading: false,

    // trainers
    trainers: [],
    trainers_loading: false,
    trainer_query: "",
    trainers_state: "done",
    trainers_params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 3,
    },
  }),
  mutations: {
    GET_PROFILE_ADMIN(state, profile) {
      state.profile_admin = profile;
    },

    GET_TRAINERS_DREPDWON(state, trainers) {
      if (trainers.length === 0) {
        state.trainers_state = "finished";
        if (state.trainers_params.page > 1) state.trainers_params.page -= 1;
        return;
      }
      state.trainers_params.page += 1;
      const newRequests = trainers.filter(
        (request) => !state.trainers.some((r) => r.id === request.id)
      );

      state.trainers.push(...newRequests);

      state.trainers_state = "done";
    },
  },
  actions: {
    get_profile_admin({ commit, state, rootState }) {
      state.profile_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/profile",
          method: "get",
        })
          .then((response) => {
            commit("GET_PROFILE_ADMIN", response.data.result[0]);
            state.profile_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.profile_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    get_trainers({ commit, state, rootState }) {
      state.trainers_loading = true;
      let data = state.trainers_params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        let query = "";
        if (
          state.trainer_query != undefined &&
          state.trainer_query != null &&
          state.trainer_query.length > 0
        )
          query = `&query=${state.trainer_query}`;
        axios({
          url:
            `${rootState.server}` +
            "/api/admin/trainers" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            query,
          method: "get",
        })
          .then((response) => {
            commit("GET_TRAINERS_DREPDWON", response.data.result);
            state.trainers_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.trainers_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default admin;
