<template>
  <v-container fluid class="mt-0">
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" class="">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" class="pb-8">
            <v-card class="mx-auto card" width="98%">
              <v-toolbar dark color="#F97316">
                <v-toolbar-title class="mx-auto font-toolbar-title2">
                  طلبات تسجيل المدربين</v-toolbar-title
                >
              </v-toolbar>
              <v-list
                class="list"
                style="max-height: 200px; overflow-y: auto"
                @scroll.native="onScroll"
                ref="scrollContainer"
              >
                <v-list-item
                  v-for="(request, index) in requests"
                  :key="index"
                  class="py-1 my-2 mx-2 list-item"
                >
                  <v-list-item-avatar size="50">
                    <!-- Default icon when no images are available -->
                    <template v-if="request.images.length === 0">
                      <Icon
                        icon="solar:user-bold"
                        color="#f97316"
                        width="30"
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      />
                    </template>

                    <!-- Display image if available -->
                    <template v-else>
                      <v-img
                        :src="`${server}${request.images[0].url}`"
                        aspect-ratio="1"
                        max-height="100"
                        max-width="100"
                        class="rounded-full"
                        alt="Avatar"
                        contain
                      >
                        <!-- Fallback in case the image fails to load -->
                        <template #placeholder>
                          <Icon
                            icon="solar:user-bold"
                            color="#f97316"
                            width="30"
                            style="
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            "
                          />
                        </template>
                      </v-img>
                    </template>
                  </v-list-item-avatar>

                  <v-list-item-content dir="rtl">
                    <v-list-item-title class="data_table">{{
                      request.full_name
                    }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <span>
                      <v-btn
                        @click="view_trainer(request.id)"
                        icon
                        style="background-color: #f97316"
                        class="mr-2"
                      >
                        <Icon icon="mdi:eye" color="#ffffff" width="25" />
                      </v-btn>
                    </span>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="loading == true">
                  <h3 class="mx-auto font-semiBold" style="color: #000000">
                    جاري التحميل يرجى الأنتظار
                  </h3>
                </v-list-item>

                <v-list-item v-if="requests.length == 0 && loading == false">
                  <h3 class="mx-auto font-semiBold" style="color: #000000">
                    لايوجد طلبات معلقة
                  </h3>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- card analysis -->
      <CardAnalysis />
    </v-row>
  </v-container>
</template>
<script>
import CardAnalysis from "@/components/admin/CardAnalysis";
export default {
  components: { CardAnalysis },
  data() {
    return {
      lastScrollTop: 0, // To track scroll direction
      check_index: null,
    };
  },
  created() {
    this.$store.dispatch("admin_requests/get_all_trainer_request");
  },
  computed: {
    server() {
      return this.$store.state.server;
    },
    requests() {
      return this.$store.state.admin_requests.requests;
    },
    loading() {
      return this.$store.state.admin_requests.admin_requests_loading;
    },
  },
  methods: {
    view_trainer(id) {
      localStorage.setItem("trainer_id", id);
      this.$store.commit("admin_requests/setIdTrainer", id);
      this.$router.push({ name: "TrainerRegistrationRequests" });
    },
    onScroll(event) {
      var requests_state = this.$store.state.admin_requests.requests_state;
      const container = event.target;
      const isScrollingDown = container.scrollTop > this.lastScrollTop;
      this.lastScrollTop = container.scrollTop;
      if (
        isScrollingDown &&
        container.scrollTop + container.clientHeight >=
          container.scrollHeight - 50 &&
        !this.loading &&
        requests_state == "done"
      ) {
        this.$store.dispatch("admin_requests/get_all_trainer_request");
      }
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
  background-color: #d4d6d7; /*f3f3f4*/
}

.list-item {
  background-color: #ffffff;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
}
.list {
  background-color: #d5d6d7;
  border-radius: 20px !important;
}
</style>
