<template>
  <v-container class="login" fluid>
    <v-row class="justify-center">
      <v-col cols="12" v-if="check == 0" class="mb-10">
        <v-form ref="forgot_password">
          <v-card class="card" width="1200">
            <v-row class="justify-center">
              <v-col cols="12" sm="8" md="5" lg="5" class="justify-center">
                <v-card-title class="d-flex justify-center mt-0 py-0">
                  <v-avatar size="100">
                    <img
                      src="@/assets/Elift-logo.png"
                      width="70"
                      height="70"
                      alt="error"
                    />
                  </v-avatar>
                </v-card-title>
                <v-card-title class="d-flex justify-center mt-0">
                  <h3 style="color: #f97316" class="font-semiBold">
                    هل نسيت كلمة المرور
                  </h3>
                </v-card-title>
                <v-card-text class="mt-3 py-0">
                  <h3 class="mb-1 mr-2 data_table">رقم الهاتف</h3>
                  <Input
                    @update-value="number_phone = $event"
                    :value="number_phone"
                    type="text"
                    label="رقم الهاتف"
                    icon="mdi-phone"
                    :rules="NumberPhonelRules"
                  />
                </v-card-text>

                <v-card-actions class="mt-5">
                  <v-btn
                    @click="check1"
                    :loading="loading"
                    color="#F97316"
                    large
                    block
                    class="px-10 py-7 mx-auto"
                    style="
                      box-shadow: 0px 0px 0px 0px !important;
                      border-radius: 20px;
                    "
                    elevation="4"
                  >
                    <v-avatar size="25">
                      <v-img src="@/assets/arrow.png"> </v-img>
                    </v-avatar>
                    <h3 class="mr-1 ml-4 data_table" style="color: white">
                      استمرارا
                    </h3>
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon color="white">mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-col>
      <v-col cols="12" v-if="check == 1">
        <v-form ref="forgot_password_1">
          <v-card class="card" width="1200">
            <v-row class="justify-center">
              <v-col cols="12" sm="8" md="5" lg="5" class="justify-center">
                <v-card-title class="d-flex justify-center mt-0 py-0">
                  <v-avatar size="100">
                    <img
                      src="@/assets/Elift-logo.png"
                      width="70"
                      height="70"
                      alt="error"
                    />
                  </v-avatar>
                </v-card-title>
                <v-card-title class="d-flex justify-center mt-0">
                  <h3 style="color: #f97316" class="font-semiBold">
                    هل نسيت كلمة المرور
                  </h3>
                </v-card-title>
                <v-card-text class="mt-3 py-0">
                  <h3 class="mb-1 mr-2 data_table">رقم الهاتف</h3>
                  <Input
                    @update-value="phone = $event"
                    :value="phone"
                    type="text"
                    label="رقم الهاتف"
                    icon="mdi-phone"
                    :disabled="true"
                  />
                  <h3 class="mb-1 mt-4 mr-2 data_table">
                    قم بادخال رمز التحقق المكون من 6 ارقام
                  </h3>
                  <v-text-field
                    color="#F97316"
                    v-model="otp"
                    solo
                    style="border-radius: 19px"
                    clearable
                    class="data_table pt-1"
                    type="number"
                    label="123456"
                    prepend-inner-icon="mdi-lock"
                    :rules="OtpRules"
                  />
                </v-card-text>

                <v-card-actions class="mt-n1">
                  <v-btn
                    @click="check2"
                    :loading="loading2"
                    color="#F97316"
                    large
                    block
                    class="px-10 py-7 mx-auto"
                    style="
                      box-shadow: 0px 0px 0px 0px !important;
                      border-radius: 20px;
                    "
                    elevation="4"
                  >
                    <v-avatar size="25">
                      <v-img src="@/assets/arrow.png"> </v-img>
                    </v-avatar>
                    <h3 class="mr-1 ml-4 data_table" style="color: white">
                      استمرارا
                    </h3>
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon color="white">mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-col>
      <v-col cols="12" v-if="check == 2">
        <v-form ref="forgot_password_2">
          <v-card class="card" width="1200">
            <v-row class="justify-center">
              <v-col cols="12" sm="8" md="5" lg="5" class="justify-center">
                <v-card-title class="d-flex justify-center mt-0 py-0">
                  <v-avatar size="100">
                    <img
                      src="@/assets/Elift-logo.png"
                      width="70"
                      height="70"
                      alt="error"
                    />
                  </v-avatar>
                </v-card-title>
                <v-card-title class="d-flex justify-center mt-0">
                  <h3 style="color: #f97316" class="font-semiBold">
                    هل نسيت كلمة المرور
                  </h3>
                </v-card-title>
                <v-card-text class="mt-3 py-0">
                  <h3 class="mb-1 mr-2 data_table">
                    قم بادخال كلمة المرور الجديدة
                  </h3>
                  <v-text-field
                    color="#F97316"
                    v-model="password"
                    solo
                    style="border-radius: 19px"
                    clearable
                    class="data_table pt-1 password"
                    :type="showPassword ? 'text' : 'password'"
                    label="قم بادخال كلمة المرور الجديدة"
                    prepend-inner-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    :rules="PasswordsRules"
                  />

                  <h3 class="mb-1 pt-0 mr-2 data_table">
                    قم بتاكيد كلمة المرور
                  </h3>
                  <v-text-field
                    color="#F97316"
                    v-model="confirm_password"
                    solo
                    style="border-radius: 19px"
                    clearable
                    class="data_table pt-1 password"
                    :type="showConfirmPpassword ? 'text' : 'password'"
                    label="قم بتاكيد كلمة المرور"
                    prepend-inner-icon="mdi-lock"
                    :append-icon="
                      showConfirmPpassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @click:append="showConfirmPpassword = !showConfirmPpassword"
                    :rules="ConfirmPasswordRules"
                  />
                </v-card-text>

                <v-card-actions class="mt-n1">
                  <v-btn
                    @click="change_password"
                    :loading="loading3"
                    color="#F97316"
                    large
                    block
                    class="px-10 py-7 mx-auto"
                    style="
                      box-shadow: 0px 0px 0px 0px !important;
                      border-radius: 20px;
                    "
                    elevation="4"
                  >
                    <v-avatar size="25">
                      <v-img src="@/assets/arrow.png"> </v-img>
                    </v-avatar>
                    <h3 class="mr-1 ml-4 data_table" style="color: white">
                      تاكيد
                    </h3>
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon color="white">mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-col>
      <v-col cols="12" sm="8" md="5" lg="5" class="justify-center mt-16">
        <v-card class="card">
          <v-card-title class="d-flex justify-center my-0 py-0">
            <img
              class="footer-login"
              src="@/assets/footer-login.png"
              width="190"
              height="100"
              alt="error"
            />
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Input from "@/components/layout/Input.vue";
export default {
  components: { Input },
  data: () => ({
    number_phone: null,
    password: "",
    confirm_password: "",
    showPassword: false,
    showConfirmPpassword: false,
    check: 0,
    otp: null,
  }),
  computed: {
    phone() {
      return this.$store.state.phone;
    },
    NumberPhonelRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) =>
          (value || "").length >= 11 || "الحد الادنى لعدد الارقام هوه 11",
        (value) =>
          (value || "").length < 12 || "الحد الاقصى لعدد الارقام هوه 11",
      ];
    },
    OtpRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 6 || "الحد الادنى لعدد الاحرف هوه 6",
        (value) => (value || "").length < 7 || "الحد الاقصى لعدد الارقام هوه 6",
      ];
    },
    PasswordsRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 8 || "الحد الادنى لعدد الاحرف هوه 6",
        (value) =>
          (value || "").length < 255 || "الحد الاقصى لعدد الارقام هوه 255",
      ];
    },
    ConfirmPasswordRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 8 || "الحد الادنى لعدد الاحرف هوه 8",
        (value) => value === (this.password || "") || "كلمة المرور غير متطابقه",
      ];
    },

    loading() {
      return this.$store.state.send_otp_loading;
    },
    loading2() {
      return this.$store.state.check_otp_loading;
    },
    loading3() {
      return this.$store.state.change_password_loading;
    },
  },
  methods: {
    check1() {
      if (this.$refs.forgot_password.validate()) {
        let data = {};
        data["phone"] = this.number_phone;
        this.$store.dispatch("send_otp", data).then(() => {
          this.check = 1;
        });
      }
    },
    check2() {
      if (this.$refs.forgot_password_1.validate()) {
        let data = {};
        data["phone"] = this.phone;
        data["otp"] = Number(this.otp);
        this.$store.dispatch("check_otp", data).then(() => {
          this.check = 2;
        });
      }
    },
    change_password() {
      if (this.$refs.forgot_password_2.validate()) {
        let data = {};
        data["phone"] = this.phone;
        data["otp"] = localStorage.getItem("otp");
        data["password"] = this.password;
        this.$store.dispatch("change_password", data).then(() => {
          this.$router.push({ name: "login" });
        });
      }
    },
    redirect() {
      this.$router.push({ name: "register" });
    },
    ContactUs() {
      this.contact_us = true;
    },
  },
};
</script>
<style scoped>
.footer-login {
  border-radius: 24px;
}

.card {
  margin: auto;
  border-radius: 15px;
  font-family: "Alexandria", sans-serif !important;
  box-shadow: 0px 0px !important;
}
.img {
  border-radius: 15px;
}
.it-logo {
  margin-top: 5px !important;
  margin-bottom: 120px;
}
.it {
  margin-bottom: 120px;
}
.card-img {
  background: rgba(255, 255, 255, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.7px);
  -webkit-backdrop-filter: blur(8.7px);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  margin-top: 100px;
}
.link-h4 {
  cursor: pointer;
  text-decoration: underline;
  color: #f97316;
}
</style>
