<template>
  <v-row justify="center">
    <v-dialog :value="value" max-width="700px" persistent scrollable>
      <v-card class="card mx-auto" width="100%" height="100%">
        <v-toolbar elevation="0">
          <v-btn
            icon
            fab
            color="#C62828"
            x-small
            v-on:click="$emit('popClose')"
            class="mr-1"
          >
            <Icon
              class="icon_download"
              icon="ic:outline-close"
              color="#000000"
              width="25"
            />
          </v-btn>

          <v-toolbar-title class="mx-auto pl-16" style="color: #50535b">
            <h3 style="color: #50535b" class="font-semiBold">
              اضافة تمارين اضافية
            </h3>
          </v-toolbar-title>
        </v-toolbar>

        <v-form ref="add_additional_courses">
          <v-card-text class="mt-7">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <Input
                  @update-value="title = $event"
                  :value="title"
                  type="text"
                  label="اسم التمرين"
                  :rules="RulseAll"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <Selects
                  @update-value="is_before = $event"
                  :value="is_before"
                  color="#f97316"
                  itemValue="value"
                  itemText="type"
                  :items="befor_after"
                  label="قبل /و بعد الوجبة"
                  :rules="RulseAll"
                />
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="12">
                <!-- <v-text-field
                  v-model="count_of_day"
                  @input="onInput"
                  @keypress="onKeyPress"
                  type="text"
                  label="أيام التمرين"
                  class="data_table py-2"
                  color="#F97316"
                  rounded
                  solo
                  clearable
                  hide-details="auto"
                  persistent-hint
                  :rules="RulseAll"
                /> -->
                <template v-for="(digit, index) in courses_length">
                  <v-btn
                    @click="
                      count_days.includes(index + 1)
                        ? count_days.splice(count_days.indexOf(index + 1), 1)
                        : count_days.push(index + 1)
                    "
                    :key="index"
                    class="mx-2"
                    style="box-shadow: 0px 0px 0px 0px !important"
                    fab
                    small
                    :ripple="false"
                    :color="
                      count_days.includes(index + 1) ? '#f28218' : '#F3F3F4'
                    "
                  >
                    <h3
                      class="data_table"
                      :style="
                        count_days.includes(index + 1)
                          ? 'color: #ffffff'
                          : 'color: #f28218'
                      "
                    >
                      {{ digit }}
                    </h3>
                  </v-btn>
                </template>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-2 pb-5 mr-3">
            <v-btn
              large
              @click="add_additional_courses"
              :loading="loading"
              color="#f28218"
              class="btn-a py-5 mx-auto"
              elevation="4"
            >
              <h3 style="color: white" class="data_table">اضافة</h3>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Input from "@/components/layout/Input.vue";
import Selects from "@/components/layout/Selects.vue";
export default {
  components: { Input, Selects },
  data() {
    return {
      title: null,
      count_of_day: null,
      is_before: null,
      befor_after: [
        { type: "قبل", value: 0 },
        { type: "بعد", value: 1 },
      ],
      count_days: [],
      RulseAll: [(value) => !!value || "هذا الحقل مطلوب"],
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    courses_length: {
      type: Number,
      require: true,
    },
  },

  computed: {
    loading() {
      return this.$store.state.courses.add_additional_courses_loading;
    },
  },
  methods: {
    add_additional_courses() {
      //
      if (
        this.$refs.add_additional_courses.validate() &&
        this.count_days.length != 0
      ) {
        let data = {};
        data["subscribtion_session_id"] =
          this.$store.state.subscribtion_session_id;
        data["title"] = this.title;
        data["type"] = "sub";
        data["is_after"] = this.is_before.value;
        data["count_of_day"] = Number(this.count_days.join(""));
        data["course_length"] = Number(this.courses_length);

        this.$store
          .dispatch("courses/add_additional_courses", data)
          .then(() => {
            this.$refs.add_additional_courses.reset();
            this.count_days = [];
          });
      }
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.btn-a {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 174px !important;
}
</style>
