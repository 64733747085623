<template>
  <v-container fluid class="mt-0">
    <v-row class="mb-10" v-if="loading == false">
      <v-col cols="12" sm="12" md="12" lg="12" class="pb-8">
        <v-card class="mx-auto card" width="98%">
          <v-card-title class="d-flex justify-center">
            <v-text-field
              v-if="$vuetify.breakpoint.smAndUp"
              v-model="trainee_query"
              @input="query_change"
              append-icon="mdi-magnify"
              label="إبحث عن لاعب لرؤية سجله المالي"
              solo
              class="data_table"
              color="#f97316"
              clearable
              hide-details
            />
            <v-menu
              v-if="players.length > 0"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="310"
              max-height="300"
              origin="top right"
              attach="body"
              offset-x
              right
            >
              <v-list class="list-font" v-if="players.length > 0">
                <v-list-item
                  v-for="(trainee, index) in players"
                  :key="index"
                  @click="selectTrainee(trainee)"
                >
                  <v-list-item-title style="text-align: right">
                    <h4 class="data_table">{{ trainee.full_name }}</h4>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer> </v-spacer>

            <span style="display: flex; align-items: center">
              <v-sheet
                class="sheet d-flex justify-center"
                height="40"
                width="40"
                color="#f97316"
                style="
                  border-radius: 13px;
                  box-shadow: 0px 0px 0px 0px !important;
                "
              >
                <span style="display: flex; align-items: center">
                  <Icon icon="flowbite:user-solid" color="#ffffff" width="30"
                /></span>
              </v-sheet>
              <h5 style="color: #393c43" class="mx-3 data_table">
                {{ player_information.trainee.full_name }}
              </h5>
            </span>

            <span style="display: flex; align-items: center">
              <v-sheet
                class="sheet d-flex justify-center"
                height="40"
                width="40"
                color="#f97316"
                style="
                  border-radius: 13px;
                  box-shadow: 0px 0px 0px 0px !important;
                "
              >
                <span style="display: flex; align-items: center">
                  <Icon icon="flowbite:phone-solid" color="#ffffff" width="26"
                /></span>
              </v-sheet>
              <h5 style="color: #393c43" class="mx-3 data_table">
                {{ player_information.trainee.phone }}
              </h5>
            </span>
            <span style="display: flex; align-items: center">
              <v-sheet
                class="sheet d-flex justify-center"
                height="40"
                width="40"
                color="#f97316"
                style="
                  border-radius: 13px;
                  box-shadow: 0px 0px 0px 0px !important;
                "
              >
                <span style="display: flex; align-items: center">
                  <Icon icon="fluent:home-32-filled" color="#ffffff" width="26"
                /></span>
              </v-sheet>
              <h5
                style="color: #393c43"
                class="mx-3 data_table"
                v-if="
                  player_information.trainee.profile != null &&
                  player_information.trainee.profile.address != null
                "
              >
                {{ player_information.trainee.profile.address }}
              </h5>
              <h5 style="color: #393c43" class="mx-3 data_table" v-else>
                لايوجد
              </h5>
            </span>

            <v-btn
              @click="delete_trainee(player_information.trainee.id)"
              :loading="loading2"
              large
              style="background-color: #ef4444"
              class="mr-2 btn mx-5"
            >
              <h3 style="color: #ffffff" class="data_table ml-1">حذف اللاعب</h3>
              <Icon icon="mingcute:delete-3-fill" color="#ffffff" width="26" />
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
            <v-spacer> </v-spacer>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="4" class="pb-8">
        <v-img
          v-if="
            player_information.trainee != null &&
            player_information.trainee.profile_image != null
          "
          :src="server + player_information.trainee.profile_image"
          max-width="387px"
          max-height="421px"
          class="mx-auto"
          style="border-radius: 25px !important"
        >
        </v-img>
        <v-img
          v-else
          src="../assets/profile.jpeg"
          max-width="387px"
          max-height="421px"
          class="mx-auto"
          style="border-radius: 25px !important"
        >
        </v-img>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="4" class="mt-0">
        <v-card class="mx-auto card py-0" width="98%">
          <v-card-title class="d-flex justify-center py-0 mb-3 mt-0">
            <h3 style="color: #50535b" class="font-semiBold">
              الأيام المتبقية على إنتهاء الكورس
            </h3></v-card-title
          >
          <v-card-title class="d-flex justify-center py-0">
            <v-progress-circular
              :rotate="360"
              :size="100"
              :width="15"
              :value="daysRemaining == null ? 0 : daysRemaining * 3.3"
              color="#EF4444"
              class="data_table"
            >
              {{ daysRemaining }}
            </v-progress-circular></v-card-title
          >
          <v-card-title class="d-flex justify-center mt-0">
            <v-btn
              large
              style="background-color: #f97316"
              class="btn11"
              @click="renewSubscription"
              :loading="loading3"
            >
              <v-avatar size="25">
                <v-img src="@/assets/arrow.png"> </v-img>
              </v-avatar>
              <h3 class="mr-1 ml-4 data_table" style="color: white">
                إنشاء أو تجديد الكورس
              </h3>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-title>
          <v-card-title class="d-flex justify-center py-0">
            <!-- @click="create_meal_plan" -->
            <v-btn
              :loading="loading5"
              large
              style="background-color: #f97316"
              class="btn2"
              :disabled="
                player_information.trainee.subscriptions == null ? true : false
              "
            >
              <v-avatar size="25">
                <v-img src="@/assets/arrow.png"> </v-img>
              </v-avatar>
              <h3 class="mr-1 ml-4 data_table" style="color: white">
                تعديل الكورس
              </h3>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-title>
          <v-card-title class="d-flex justify-center">
            <v-btn
              large
              style="background-color: #f97316"
              class="btn2"
              @click="redirect_to_meals"
              :disabled="
                player_information.trainee.app_subscriptions != null
                  ? false
                  : true
              "
              :loading="loading6"
            >
              <v-avatar size="25">
                <v-img src="@/assets/arrow.png"> </v-img>
              </v-avatar>
              <h3 class="mr-1 ml-4 data_table" style="color: white">
                معاينة الكورس
              </h3>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-title>
          <v-card-title class="d-flex justify-center py-0">
            <v-sheet
              color="#F97300"
              class="d-flex justify-space-around py-1 sheet"
              height="45"
              width="350"
            >
              <v-btn
                @click="toggle_hero_state(player_information.trainee.id)"
                v-if="
                  player_information.trainee.profile != null &&
                  player_information.trainee.profile.is_hero != null
                "
                class="px-16"
                style="border-radius: 13px"
                :class="
                  player_information.trainee.profile.is_hero == 0
                    ? 'selected-btn'
                    : 'selected-btn-off'
                "
              >
                <h3 class="data_table">لاعب</h3>
              </v-btn>
              <v-btn
                @click="toggle_hero_state(player_information.trainee.id)"
                v-if="
                  player_information.trainee.profile != null &&
                  player_information.trainee.profile.is_hero != null
                "
                class="px-16"
                style="border-radius: 13px"
                :class="
                  player_information.trainee.profile.is_hero == 1
                    ? 'selected-btn'
                    : 'selected-btn-off'
                "
              >
                <h3 class="data_table">بطل</h3>
              </v-btn>
            </v-sheet>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="4" class="mt-0">
        <v-card class="mx-auto card py-0" width="98%">
          <v-card-title class="d-flex justify-center py-0 mb-3 mt-0">
            <h3 style="color: #50535b" class="font-semiBold">
              اضافة او تغيير اشتراك
            </h3></v-card-title
          >
          <v-card-title class="d-flex justify-center py-0">
            <v-btn
              fab
              large
              style="background-color: #393c43"
              class="ml-2"
              @click="progress_circular(1)"
            >
              <Icon icon="ic:round-plus" color="#ffffff" width="26" />
            </v-btn>

            <v-progress-circular
              :rotate="360"
              :size="100"
              :width="15"
              :value="progress"
              color="#F97300"
            >
              <h4 style="color: #393c43" class="data_table">
                {{ progress2 }}/شهر
              </h4>
            </v-progress-circular>
            <v-btn
              fab
              large
              style="background-color: #393c43"
              class="mr-2"
              @click="progress_circular(0)"
            >
              <Icon icon="line-md:minus" color="#ffffff" width="26" /> </v-btn
          ></v-card-title>

          <v-card-title class="d-flex justify-center">
            <h3 style="color: #50535b" class="data_table">
              نوع الاشتراك
            </h3></v-card-title
          >
          <v-card-title class="d-flex justify-center">
            <v-select
              v-model="subscription_type"
              @change="member_Ship"
              :items="member_Ships"
              color="#f97316"
              item-color="orange lighten-1"
              solo
              itemValue="id"
              itemText="title"
              single-line
              hide-details
              label="نوع الاشتراك"
              class="d-flex justify-center data_table px-5"
            ></v-select>
          </v-card-title>
          <v-card-title class="d-flex justify-center py-0 data_table">
            <h5 style="color: #50535b" class="data_table">
              المبلغ الاجمالي: {{ total_price | formatNumber }}
            </h5>
          </v-card-title>
          <v-card-title class="d-flex justify-center py-0 mt-3">
            <v-btn
              large
              style="background-color: #f97316"
              class="mr-2 btn5"
              @click="selectAppSubscription"
              :loading="loading4"
            >
              <v-avatar size="25">
                <v-img src="@/assets/arrow.png"> </v-img>
              </v-avatar>
              <h3 class="mr-1 ml-4 data_table" style="color: white">تأكيد</h3>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>

      <v-col cols="6" sm="6" md="6" lg="6">
        <v-card color="#F3F3F4" class="py-0 card2">
          <v-card-title class="pb-1 d-flex justify-center">
            <Icon icon="flowbite:user-solid" color="#f97316" width="26" />
          </v-card-title>
          <v-card-title class="py-0 d-flex justify-center">
            <h5
              class="font-semiBold"
              v-if="
                player_information.trainee.app_subscriptions != null &&
                player_information.trainee.app_subscriptions.membership != null
              "
            >
              {{
                player_information.trainee.app_subscriptions.membership.title
              }}
            </h5>

            <h5 class="font-semiBold" v-else>لايوجد</h5>
          </v-card-title>
          <v-card-title class="pt-0 d-flex justify-center">
            <h5 class="data_table" style="color: #f97316">
              فئة اشتراك التطبيق
            </h5>
          </v-card-title>
        </v-card>
      </v-col>

      <v-col cols="6" sm="6" md="6" lg="6">
        <v-card color="#F3F3F4" class="py-0 card2">
          <v-card-title class="pb-1 d-flex justify-center">
            <Icon icon="flowbite:user-solid" color="#f97316" width="26" />
          </v-card-title>
          <v-card-title class="py-0 d-flex justify-center">
            <h5 class="font-semiBold">
              {{ daysRemainingAppSubscriptions }} ايام
            </h5>
          </v-card-title>
          <v-card-title class="pt-0 d-flex justify-center">
            <h5 class="data_table" style="color: #f97316">
              المتبقى على انتهاء اشتراك التطبيق
            </h5>
          </v-card-title>
        </v-card>
      </v-col>

      <v-col cols="6" sm="6" md="4" lg="4">
        <v-card color="#F3F3F4" class="py-0 card2">
          <v-card-title class="pb-1 d-flex justify-center">
            <Icon icon="flowbite:user-solid" color="#f97316" width="26" />
          </v-card-title>
          <v-card-title class="py-0 d-flex justify-center">
            <h5
              class="font-semiBold"
              v-if="
                player_information.trainee.profile != null &&
                player_information.trainee.profile.weight != null
              "
            >
              {{ player_information.trainee.profile.weight }}kg
            </h5>
            <h5 class="font-semiBold" v-else>لايوجد</h5>
          </v-card-title>
          <v-card-title class="pt-0 d-flex justify-center">
            <h5 class="data_table" style="color: #f97316">الوزن</h5>
          </v-card-title>
        </v-card>
      </v-col>

      <v-col cols="6" sm="6" md="4" lg="4">
        <v-card color="#F3F3F4" class="py-0 card2">
          <v-card-title class="pb-1 d-flex justify-center">
            <Icon icon="flowbite:user-solid" color="#f97316" width="26" />
          </v-card-title>
          <v-card-title class="py-0 d-flex justify-center">
            <h5
              class="font-semiBold"
              v-if="
                player_information.trainee.profile != null &&
                player_information.trainee.profile.age != null
              "
            >
              {{ player_information.trainee.profile.age }} سنة
            </h5>
            <h5 class="font-semiBold" v-else>لايوجد</h5>
          </v-card-title>
          <v-card-title class="pt-0 d-flex justify-center">
            <h5 class="data_table" style="color: #f97316">العمر</h5>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" sm="6" md="4" lg="4">
        <v-card color="#F3F3F4" class="py-0 card2">
          <v-card-title class="pb-1 d-flex justify-center">
            <Icon icon="flowbite:user-solid" color="#f97316" width="26" />
          </v-card-title>
          <v-card-title class="py-0 d-flex justify-center">
            <h5
              class="font-semiBold"
              v-if="
                player_information.trainee.profile != null &&
                player_information.trainee.profile.length != null
              "
            >
              {{ player_information.trainee.profile.length }}cm
            </h5>
            <h5 class="font-semiBold" v-else>لايوجد</h5>
          </v-card-title>
          <v-card-title class="pt-0 d-flex justify-center">
            <h5 class="data_table" style="color: #f97316">الطول</h5>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" sm="6" md="4" lg="4">
        <v-card color="#F3F3F4" class="py-0 card2">
          <v-card-title class="pb-1 d-flex justify-center">
            <Icon icon="flowbite:user-solid" color="#f97316" width="26" />
          </v-card-title>
          <v-card-title class="py-0 d-flex justify-center">
            <h5
              class="font-semiBold"
              v-if="
                player_information.trainee.profile != null &&
                player_information.trainee.profile.days_of_training_system !=
                  null
              "
            >
              {{ player_information.trainee.profile.days_of_training_system }}
            </h5>
            <h5 class="font-semiBold" v-else>لايوجد</h5>
          </v-card-title>
          <v-card-title class="pt-0 d-flex justify-center">
            <h5 class="data_table" style="color: #f97316">السعرات المستهلكة</h5>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" sm="6" md="4" lg="4">
        <v-card color="#F3F3F4" class="py-0 card2">
          <v-card-title class="pb-1 d-flex justify-center">
            <Icon icon="flowbite:user-solid" color="#f97316" width="26" />
          </v-card-title>
          <v-card-title class="py-0 d-flex justify-center">
            <h5
              class="font-semiBold"
              v-if="
                player_information.trainee.profile != null &&
                player_information.trainee.profile.days_of_training_system !=
                  null
              "
            >
              {{ player_information.trainee.profile.days_of_training_system }}
            </h5>
            <h5 class="font-semiBold" v-else>لايوجد</h5>
          </v-card-title>
          <v-card-title class="pt-0 d-flex justify-center">
            <h5 class="data_table" style="color: #f97316">
              كمية الماء المستهلكة
            </h5>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" sm="6" md="4" lg="4">
        <v-card color="#F3F3F4" class="py-0 card2">
          <v-card-title class="pb-1 d-flex justify-center">
            <Icon icon="flowbite:user-solid" color="#f97316" width="26" />
          </v-card-title>
          <v-card-title class="py-0 d-flex justify-center">
            <h5
              class="font-semiBold"
              v-if="
                player_information.trainee.profile != null &&
                player_information.trainee.profile.days_of_training_system !=
                  null
              "
            >
              {{ player_information.trainee.profile.days_of_training_system }}
            </h5>
            <h5 class="font-semiBold" v-else>لايوجد</h5>
          </v-card-title>
          <v-card-title class="pt-0 d-flex justify-center">
            <h5 class="data_table" style="color: #f97316">السعرات المحروقة</h5>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" sm="6" md="3" lg="3">
        <v-card color="#F3F3F4" class="py-3 card2">
          <v-card-title class="pt-1 pb-0 d-flex justify-center">
            <h5 class="data_table" style="color: #f97316">جودة النوم</h5>
          </v-card-title>
          <v-card-title class="pt-0 pb-1 d-flex justify-center">
            <h5
              class="font-semiBold"
              v-if="
                player_information.trainee.profile != null &&
                player_information.trainee.profile.sleep_quality == 'poor'
              "
            >
              سيئة
            </h5>
            <h5
              class="font-semiBold"
              v-if="
                player_information.trainee.profile != null &&
                player_information.trainee.profile.sleep_quality == 'poor'
              "
            >
              سيئة
            </h5>
            <h5
              class="font-semiBold"
              v-else-if="
                player_information.trainee.profile != null &&
                player_information.trainee.profile.sleep_quality == 'average'
              "
            >
              متوسطة
            </h5>
            <h5
              class="font-semiBold"
              v-else-if="
                player_information.trainee.profile != null &&
                player_information.trainee.profile.sleep_quality == 'good'
              "
            >
              جيدة
            </h5>
            <h5
              class="font-semiBold"
              v-else-if="
                player_information.trainee.profile != null &&
                player_information.trainee.profile.sleep_quality == 'excellent'
              "
            >
              ممتازة
            </h5>
            <h5 class="font-semiBold" v-else>لايوجد</h5>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" sm="6" md="3" lg="3">
        <v-card color="#F3F3F4" class="py-3 card2">
          <v-card-title class="pt-1 pb-0 d-flex justify-center">
            <h5 class="data_table" style="color: #f97316">العمر التدريبي</h5>
          </v-card-title>
          <v-card-title class="pt-0 pb-1 d-flex justify-center">
            <h5
              class="font-semiBold"
              v-if="
                player_information.trainee.profile != null &&
                player_information.trainee.profile.years_of_training > 2
              "
            >
              {{ player_information.trainee.profile.years_of_training }} سنوات
            </h5>
            <h5
              class="font-semiBold"
              v-if="
                player_information.trainee.profile != null &&
                player_information.trainee.profile.years_of_training < 2
              "
            >
              {{ player_information.trainee.profile.years_of_training }} سنة
            </h5>
            <h5 class="font-semiBold" v-else>لايوجد</h5>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" sm="6" md="3" lg="3">
        <v-card color="#F3F3F4" class="py-3 card2">
          <v-card-title class="pt-1 pb-0 d-flex justify-center">
            <h5 class="data_table" style="color: #f97316">الاعاقات الجسدية</h5>
          </v-card-title>
          <v-card-title class="pt-0 pb-1 d-flex justify-center">
            <h5
              class="font-semiBold"
              v-if="
                player_information.trainee.profile != null &&
                player_information.trainee.profile.physical_disabilities != null
              "
            >
              {{ player_information.trainee.profile.physical_disabilities }}
            </h5>
            <h5 class="font-semiBold" v-else>غير معرف</h5>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6" sm="6" md="3" lg="3">
        <v-card color="#F3F3F4" class="py-3 card2">
          <v-card-title class="pt-1 pb-0 d-flex justify-center">
            <h5 class="data_table" style="color: #f97316">الوجبات الخارجية</h5>
          </v-card-title>
          <v-card-title class="pt-0 pb-1 d-flex justify-center">
            <h5
              class="font-semiBold"
              v-if="
                player_information.trainee.profile != null &&
                player_information.trainee.profile.days_of_training_system !=
                  null
              "
            >
              {{ player_information.trainee.profile.days_of_training_system }}
            </h5>
            <h5 class="font-semiBold" v-else>لايوجد</h5>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="loading == true">
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="4"
        v-for="(n, index) in 3"
        :key="'A12' + index"
      >
        <v-card class="mx-auto card" width="88%" height="100%">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="4"
        v-for="(n, index) in 6"
        :key="'A' + index"
      >
        <v-card class="mx-auto card" width="100%">
          <v-skeleton-loader type="image" height="100"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        v-for="(n, index) in 6"
        :key="'A1' + index"
      >
        <v-card class="mx-auto card" width="95%">
          <v-skeleton-loader type="image" height="100"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <SubscriptionResetAlert
      :value="show_alert"
      v-on:popClose="show_alert = !show_alert"
      v-on:confirmReset="confirmReset"
    />
  </v-container>
</template>
<script>
import SubscriptionResetAlert from "@/components/layout/SubscriptionResetAlert.vue";
export default {
  components: {
    SubscriptionResetAlert,
  },
  data() {
    return {
      show_alert: false,

      subscription_type: "",
      menu: false,
      selectedPlayer: null,
      selected: 1,
      progress: 10,
      progress2: 1,

      total_price: 0,
      subscription_price: 100,
      membership_id: null,

      menu_props: {
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 300,
        offsetY: true,
        offsetOverflow: true,
        transition: false,
      },
    };
  },
  created() {
    if (localStorage.getItem("trainee_id") == null) {
      this.$router.push({ name: "users" });
    } else {
      this.$store.dispatch("trainer/getMemberShips");
      this.get_info_trainee();
      this.get_trainees();
    }
  },
  computed: {
    server() {
      return this.$store.state.server;
    },
    member_Ships() {
      return this.$store.state.trainer.member_Ships;
    },
    player_information() {
      return this.$store.state.trainees.player_information;
    },
    players() {
      return this.$store.state.trainees.players;
    },
    loading() {
      return this.$store.state.trainees.info_trainee_loading;
    },
    loading2() {
      return this.$store.state.trainees.delete_trainee_loading;
    },
    loading3() {
      return this.$store.state.trainees.renew_Subscription_loading;
    },
    loading4() {
      return this.$store.state.trainees.select_app_subscription_loading;
    },
    loading5() {
      return this.$store.state.meals.add_meals_plan_loading;
    },
    loading6() {
      return this.$store.state.meals.meal_plans_loading;
    },
    trainee_query: {
      set(val) {
        this.$store.state.trainees.trainee_query2 = val;
      },
      get() {
        return this.$store.state.trainees.trainee_query2;
      },
    },

    daysRemaining() {
      if (
        !this.player_information ||
        !this.player_information.trainee.subscriptions[0]?.end_date
      ) {
        return 0; // Default value if no subscription
      }

      const currentDate = new Date();
      const subscriptionEndDate = new Date(
        this.player_information.trainee.subscriptions[0].end_date
      );

      // Calculate the difference in time (milliseconds)
      const timeDifference = subscriptionEndDate - currentDate;

      // Convert time difference to days
      const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      // Return the days remaining or handle expired subscriptions
      return daysRemaining >= 0 ? daysRemaining : 0;
    },
    daysRemainingAppSubscriptions() {
      if (
        !this.player_information ||
        !this.player_information.trainee.app_subscriptions?.end_date
      ) {
        return 0; // Default value if no subscription
      }

      const currentDate = new Date();
      const subscriptionEndDate = new Date(
        this.player_information.trainee.app_subscriptions.end_date
      );

      // Calculate the difference in time (milliseconds)
      const timeDifference = subscriptionEndDate - currentDate;

      // Convert time difference to days
      const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      // Return the days remaining or handle expired subscriptions
      return daysRemaining >= 0 ? daysRemaining : 0;
    },
  },
  methods: {
    get_info_trainee() {
      this.$store.dispatch("trainees/get_info_trainee");
    },

    get_trainees() {
      this.$store.dispatch("trainees/get_trainees_from_search");
    },

    delete_trainee(id) {
      let data = {};
      data["trainee_id"] = id;
      this.$store.dispatch("trainees/delete_trainee", data).then(() => {
        this.$store.state.previous_session_id = null;
        this.$store.state.subscribtion_session_id = null;
        this.$router.push({ name: "users" });
      });
    },
    create_meal_plan() {
      let data = {};
      let endDate = new Date();
      endDate.setMonth(endDate.getMonth() + 1);
      data["subscribtion_session_id"] =
        this.player_information.current_session.id;
      data["start_date"] = new Date().toISOString().split("T")[0];
      data["end_date"] = endDate.toISOString().split("T")[0];
      this.$store.dispatch("meals/addMealPlan", data);
    },

    selectTrainee(trainee) {
      localStorage.setItem("trainee_id", trainee.id);
      this.$store.commit("trainees/setIdTrainee", trainee.id);
      this.menu = false;
      this.get_info_trainee();
    },

    progress_circular(val) {
      if (val == 0 && this.progress != 10) {
        this.progress = this.progress - 10;
        this.progress2 -= 1;
        this.total_price -= this.subscription_price;
      } else if (val == 1 && this.progress != 100) {
        this.progress = this.progress + 10;
        this.progress2 += 1;
        this.total_price += this.subscription_price;
      }
    },

    member_Ship(selectedId) {
      const selectedItem = this.member_Ships.find(
        (item) => item.id === selectedId
      );

      if (selectedItem) {
        this.total_price = this.progress2 * Number(selectedItem.price);
        this.subscription_price = Number(selectedItem.price);
        this.membership_id = selectedItem.id;
      }
    },
    selectAppSubscription() {
      if (this.membership_id != null) {
        let data = {};
        data["months"] = this.progress2;
        data["membership_id"] = this.membership_id;

        if (this.player_information.trainee.app_subscriptions == null) {
          data["trainee_id"] = this.player_information.trainee.id;
          this.$store
            .dispatch("trainees/selectAppSubscription", data)
            .then(() => {
              this.reset_data_app_subscription();
            });
        } else if (
          this.membership_id ==
          this.player_information.trainee.app_subscriptions.membership.id
        ) {
          data["app_subscription_id"] =
            this.player_information.trainee.app_subscriptions.id;
          this.$store
            .dispatch("trainees/renewAppSubscription", data)
            .then(() => {
              this.reset_data_app_subscription();
            });
        } else {
          // Trigger dialog for confirmation
          this.show_alert = true;
        }
      } else {
        let snack_message = {};
        snack_message["color"] = "#FB8C00";
        snack_message["icon"] = "cuida:alert-outline";
        snack_message["text"] = "لم تقم بأختيار اشتراك";
        this.$store.commit("SNACK_MESSAGE", snack_message);
        setTimeout(() => {
          this.$store.commit("TIME_OUT", snack_message);
        }, 4000);
      }
    },
    confirmReset() {
      this.show_alert = false;
      // Perform the reset action and close the dialog
      let data = {};
      data["months"] = this.progress2;
      data["membership_id"] = this.membership_id;
      data["app_subscription_id"] =
        this.player_information.trainee.app_subscriptions.id;

      this.$store.dispatch("trainees/resetAppSubscription", data).then(() => {
        this.reset_data_app_subscription();
        // Close the dialog
      });
    },

    reset_data_app_subscription() {
      this.progress = 10;
      this.progress2 = 1;
      this.membership_id = null;
      this.subscription_type = "";
      this.total_price = 0;
    },
    renewSubscription() {
      let data = {};
      data["months"] = 1;
      data["subscription_id"] =
        this.player_information.trainee.subscriptions[0].id;
      this.$store.dispatch("trainees/renewSubscription", data);
    },
    redirect_to_meals() {
      id2 = null;
      if (this.player_information.previous_session != null) {
        var id2 = this.player_information.previous_session.id;
      }
      this.$store.commit("SELECT_previous_session_ID", id2);
      localStorage.setItem("previous_session_id", id2);

      var id = this.player_information.current_session.id;
      this.$store.commit("SELECT_SUBSCRIBTION_SESSION_ID", id);
      localStorage.setItem("subscribtion_session_id", id);

      if (this.daysRemainingAppSubscriptions != 0) {
        this.$store.dispatch("meals/get_meal_plans").then(() => {
          this.$router.push({ name: "Calisthenics" });
        });
      }
    },

    toggle_hero_state(id) {
      let data = {};
      data["trainee_id"] = id;
      this.$store.dispatch("trainees/update_trainee_hero_state", data);
    },

    query_change() {
      this.menu = true;
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.get_trainees();
        this.$store.state.trainees.params.page = 1;
      }, 1000);
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
  background-color: #ffffff; /*f3f3f4*/
}
.card2 {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
  background-color: #ffffff; /*f3f3f4*/
}
.carousel {
  border-radius: 20px !important;
}
.btn {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 40px !important;
}
.btn2 {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 100px !important;
}
.btn11 {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 70px !important;
}
.btn5 {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 125px !important;
}
.btn3 {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 10px !important;
}
.selected-btn {
  background-color: white !important;
  color: #f97300 !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.selected-btn-off {
  background-color: #f97300 !important;
  color: white !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.sheet {
  border-radius: 15px !important;
}
.subscription-type-btn {
  background-color: #f97316 !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.subscription-type-btn-off {
  background-color: #d7d8d9 !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.list-font {
  font-family: "Cairo", sans-serif;
}
</style>
<style scoped>
.v-menu__content {
  left: auto !important; /* Ignore the `left` style */
  right: 48px !important; /* Apply a right alignment */
  top: 85px !important;
}
</style>
