<template>
  <v-row>
    <v-col cols="12" sm="12" md="12" lg="12" class="pb-8 mt-3">
      <v-card class="mx-auto card" width="98%">
        <v-toolbar dark color="#F97316">
          <v-toolbar-title class="mx-auto toolbar-title font-toolbar-title2">
            الاشعارات
          </v-toolbar-title>
        </v-toolbar>
        <v-list
          two-line
          class="list"
          min-height="550"
          @scroll.native="onScroll"
          ref="scrollContainer"
        >
          <template v-for="(notification, index) in notifications">
            <v-list-item :key="index" class="list-item my-2 mx-2">
              <v-list-item-content style="color: #000000">
                <v-list-item-title class="d-flex justify-start">
                  <h3 style="color: #000000" class="font-semiBold">
                    {{ notification.title }}
                  </h3>
                </v-list-item-title>
                <v-list-item-subtitle class="d-flex justify-start data_table">
                  <h3 style="color: #000000">
                    {{ notification.content }}
                  </h3>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action class="d-flex justify-end">
                <h3 style="color: #50535b" class="data_table">
                  <timeago
                    class="timeago"
                    :datetime="notification.created_at"
                    :auto-update="60"
                    locale="ar"
                  ></timeago>
                </h3>
              </v-list-item-action>
            </v-list-item>
          </template>
          <v-list-item v-if="notifications.length == 0 && loading == true">
            <h3 class="mx-auto font-semiBold" style="color: #000000">
              جاري التحميل يرجى الأنتظار
            </h3>
          </v-list-item>
          <v-list-item v-if="notifications.length == 0 && loading == false">
            <h3 class="mx-auto mt-16 font-semiBold" style="color: #000000">لايوجد اشعارات</h3>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    this.get_notifications();
  },
  computed: {
    notifications() {
      return this.$store.state.notifications.notifications;
    },
    loading() {
      return this.$store.state.notifications.notifications_loading;
    },
  },
  methods: {
    get_notifications() {
      this.$store.dispatch("notifications/get_notifications");
    },

    onScroll(event) {
      var notifications_state =
        this.$store.state.notifications.notifications_state;
      const container = event.target;
      const isScrollingDown = container.scrollTop > this.lastScrollTop;
      this.lastScrollTop = container.scrollTop;
      if (
        isScrollingDown &&
        container.scrollTop + container.clientHeight >=
          container.scrollHeight - 50 &&
        !this.loading &&
        notifications_state == "done"
      ) {
        this.get_notifications();
      }
    },
  },
};
</script>
<style scoped>
.card {
  background-color: #f3f3f4 !important; /*f3f3f4*/
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 25px !important;
}
.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px !important;
}

.list-item {
  background-color: #f3f3f4;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
}
.list {
  background-color: #d5d6d7;
}
</style>
