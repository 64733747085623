import { render, staticRenderFns } from "./PopCreateGroup.vue?vue&type=template&id=4ae2737a&scoped=true"
import script from "./PopCreateGroup.vue?vue&type=script&lang=js"
export * from "./PopCreateGroup.vue?vue&type=script&lang=js"
import style0 from "./PopCreateGroup.vue?vue&type=style&index=0&id=4ae2737a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ae2737a",
  null
  
)

export default component.exports