<template>
  <v-row>
    <v-col cols="12" sm="12" md="12" lg="12" class="pb-8">
      <v-card class="mx-auto card" width="98%">
        <v-toolbar dark color="#F97316">
          <v-toolbar-title class="mx-auto font-toolbar-title2">
            اضافة او تحديث لاعب
          </v-toolbar-title>
        </v-toolbar>
        <v-form ref="add_banners">
          <v-list
            style="
              max-height: 200px;
              overflow-y: auto;
              background-color: #d5d6d7;
              border-radius: 20px !important;
            "
            ref="scrollContainer"
          >
            <!-- @scroll.native="onScroll" -->
            <v-list-item
              class="my-2 px-0 mx-2 list-item-title d-flex justify-center"
            >
              <v-text-field
                v-model="trainee_query"
                @input="query_change"
                append-icon="mdi-magnify"
                label="ادخل اسم او معرف اللاعب ..."
                solo
                class="data_table"
                color="#f97316"
                clearable
                hide-details
              />
            </v-list-item>

            <v-list-item
              v-for="(trainee, index) in trainees"
              :key="index"
              class="py-1 my-2 mx-2 list-item"
            >
              <v-list-item-action>
                <span>
                  <v-btn
                    @click="
                      trainee.is_subscribed == 0
                        ? null
                        : open_player_information(trainee.id)
                    "
                    :style="
                      trainee.is_subscribed == 0
                        ? 'background-color: #babbbe'
                        : 'background-color: #f97316'
                    "
                    style="border-radius: 12px"
                    class="btn-title mx-1"
                  >
                    <h5 style="color: #ffffff" class="data_table">
                      معاينة معلومات اللاعب
                    </h5>
                  </v-btn>

                  <v-btn
                    @click="
                      trainee.is_subscribed == 0
                        ? null
                        : open_player_meals(trainee)
                    "
                    :style="
                      trainee.is_subscribed == 0
                        ? 'background-color: #babbbe'
                        : 'background-color: #f97316'
                    "
                    style="border-radius: 12px"
                    class="btn-title mx-1"
                  >
                    <h5 style="color: #ffffff" class="data_table">
                      معاينة المنهاج
                    </h5>
                  </v-btn>
                  <v-btn
                    @click="
                      trainee.is_subscribed == 0
                        ? crate_invite(trainee.id)
                        : null
                    "
                    :style="
                      trainee.is_subscribed == 0
                        ? 'background-color: #f97316'
                        : 'background-color: #babbbe'
                    "
                    style="border-radius: 12px"
                    class="btn-title mx-1"
                  >
                    <h5 style="color: #ffffff" class="data_table">
                      ارسال دعوة اضافة
                    </h5>
                  </v-btn>
                </span>
              </v-list-item-action>

              <v-list-item-content dir="ltr">
                <v-list-item-title
                  v-if="trainee.is_subscribed == 0"
                  class="d-flex justify-center"
                >
                  <h5 style="color: #ff3131" class="data_table">
                    ليس من ضمن فريقك
                  </h5>
                </v-list-item-title>
                <v-list-item-title v-else class="d-flex justify-center">
                  <h5 style="color: #84cc16" class="data_table">ضمن فريقك</h5>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-content dir="ltr">
                <v-list-item-title class="d-flex justify-center"
                  ><h5 class="data_table">المعرف : {{ trainee.id }}</h5>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-content dir="ltr">
                <v-list-item-title class="d-flex justify-center"
                  ><span
                    ><h5 class="data_table">{{ trainee.full_name }}</h5>
                  </span></v-list-item-title
                >
              </v-list-item-content>

              <v-list-item-avatar v-if="trainee.images.length > 0">
                <img
                  :src="server + trainee.images[0].url"
                  width="50"
                  height="50"
                  alt="error"
                  class="name_coach"
                />
              </v-list-item-avatar>
              <v-list-item-avatar v-else>
                <img
                  src="@/assets/Elift-logo.png"
                  width="50"
                  height="50"
                  alt="error"
                  class="name_coach"
                />
              </v-list-item-avatar>
            </v-list-item>
            <v-list-item v-if="loading == true">
              <h4 class="mx-auto data_table" style="color: #000000">
                جاري التحميل يرجى الأنتظار
              </h4>
            </v-list-item>
            <v-list-item
              v-if="
                trainee_query.length > 0 &&
                trainees.length == 0 &&
                loading == false
              "
            >
              <h3 class="mx-auto py-5 data_table" style="color: #000000">
                لايوجد نتائج
              </h3>
            </v-list-item>
          </v-list>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      // lastScrollTop: 0, // To track scroll direction
      // check_index: null,
    };
  },

  computed: {
    server() {
      return this.$store.state.server;
    },
    trainees() {
      return this.$store.state.training_requests.trainees;
    },
    loading() {
      return this.$store.state.training_requests.trainees_loading;
    },
    trainee_query: {
      set(val) {
        this.$store.state.training_requests.trainee_query = val;
      },
      get() {
        return this.$store.state.training_requests.trainee_query;
      },
    },
  },
  methods: {
    getTraineesList() {
      if (this.trainee_query != null && this.trainee_query.length > 0) {
        this.$store.dispatch("training_requests/getTraineesList");
      } else {
        this.$store.state.training_requests.trainees = [];
      }
    },
    open_player_meals(trainee) {
      console.log(trainee);
      var meal_plan_id = trainee.active_meal_plan.id;
      localStorage.setItem("meal_plan_id", meal_plan_id);
      this.$store.state.meals.meal_plan_id = meal_plan_id;

      var id = trainee.active_session.id;
      this.$store.commit("SELECT_SUBSCRIBTION_SESSION_ID", id);
      localStorage.setItem("subscribtion_session_id", id);

      this.$store.commit("meals/setTab", 1);
      localStorage.setItem("tab", 1);

      this.$router.push("/calisthenics");
    },
    crate_invite(id) {
      let data = {};
      data["trainee_id"] = id;
      this.$store.dispatch("training_requests/createInvite", data);
    },
    open_player_information(id) {
      localStorage.setItem("trainee_id", id);
      this.$store.commit("trainees/setIdTrainee", id);
      this.$router.push({ name: "trainees_profile" });
    },
    query_change() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getTraineesList();
        this.$store.state.training_requests.params_trainees.page = 1;
      }, 1000);
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
  background-color: #d4d6d7; /*f3f3f4*/
}

.list-item {
  background-color: #ffffff;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
}
.list-item-title {
  border-color: #f97316 !important;
  border-style: solid;
  border-width: 1px;
  background-color: #ffffff;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
}
.btn-title {
  box-shadow: 0px 0px 0px 0px !important;
}
</style>
