<template>
  <v-col cols="12" sm="12" md="12" lg="12" class="pb-8">
    <v-card class="mx-auto card" width="98%">
      <v-toolbar dark color="#F97316">
        <v-toolbar-title class="mx-auto font-toolbar-title2">
          احصائيات سريعة
        </v-toolbar-title>
      </v-toolbar>

      <v-list
        style="
          max-height: 800px;
          overflow-y: auto;
          background-color: #d5d6d7;
          border-radius: 0px 0px 20px 20px !important;
        "
      >
        <v-list-item
          class="py-1 my-2 mx-2 list-item-title d-flex justify-center"
        >
          <v-btn
            :class="check_index == 1 ? 'btn-filter' : 'btn-filter-disabled'"
            :outlined="check_index == 1 ? false : true"
            @click="day(1)"
          >
            <h4
              :style="check_index == 1 ? 'color: #ffffff' : '#49454f'"
              class="data_table"
            >
              اليوم
            </h4>
          </v-btn>
          <v-btn
            class="mr-2 ml-1"
            :class="check_index == 2 ? 'btn-filter' : 'btn-filter-disabled'"
            :outlined="check_index == 2 ? false : true"
            @click="week(2)"
          >
            <h4
              :style="check_index == 2 ? 'color: #ffffff' : '#49454f'"
              class="data_table"
            >
              أسبوع
            </h4>
          </v-btn>
          <v-btn
            class="mr-1 ml-2"
            :class="check_index == 3 ? 'btn-filter' : 'btn-filter-disabled'"
            :outlined="check_index == 3 ? false : true"
            @click="month(3)"
          >
            <h4
              :style="check_index == 3 ? 'color: #ffffff' : '#49454f'"
              class="data_table"
            >
              شهر
            </h4>
          </v-btn>
          <v-btn
            :class="check_index == 4 ? 'btn-filter' : 'btn-filter-disabled'"
            :outlined="check_index == 4 ? false : true"
            @click="all(4)"
          >
            <h4
              :style="check_index == 4 ? 'color: #ffffff' : '#49454f'"
              class="data_table"
            >
              الإحصائيات الإجمالية
            </h4>
          </v-btn>
        </v-list-item>

        <v-row class="pa-0 ma-0" v-if="loading == false">
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="3"
            v-for="(statistic, index) in statistics"
            :key="index"
          >
            <v-card class="card">
              <v-card-title>
                <h4 class="data_table" style="color: #393c43">
                  {{ statistic.title }}
                </h4>

                <v-sheet
                  class="mx-auto sheet d-flex justify-center"
                  style="border-radius: 6px"
                  height="34"
                  width="34"
                  :color="statistic.color"
                >
                  <Icon
                    :icon="statistic.icon"
                    color="ffffff"
                    height="34"
                    width="30"
                  />
                </v-sheet>
              </v-card-title>
              <v-card-title class="mt-0 d-flex justify-center">
                <h2
                  class="data_table"
                  style="color: #000000"
                  v-if="statistic.color == '#3B82F6'"
                >
                  {{ statistic.number | formatNumber2 }}
                </h2>
                <h2 class="data_table" style="color: #000000" v-else>
                  {{ statistic.number }}
                </h2>
              </v-card-title>
              <v-card-text class="mt-0 d-flex justify-center">
                <h4 class="data_table" style="color: #637381">
                  {{ statistic.type }}
                </h4>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center pa-0 ma-0" v-else-if="loading == true">
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="3"
            class="px-2 py-5 mx-auto"
            v-for="(n, index) in 8"
            :key="'A' + index"
          >
            <v-skeleton-loader type="card" height="100"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-list>
    </v-card>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      check_index: localStorage.getItem("check_index") ?? 1,
    };
  },
  created() {
    // Retrieve the saved check_index value from localStorage
    const savedIndex = localStorage.getItem("check_index") ?? 1;
    if (savedIndex) {
      this.check_index = parseInt(savedIndex, 10);

      // Call the corresponding method based on the saved check_index
      switch (this.check_index) {
        case 1:
          this.day(this.check_index);
          break;
        case 2:
          this.week(this.check_index);
          break;
        case 3:
          this.month(this.check_index);
          break;
        case 4:
          this.all(this.check_index);
          break;
        default:
          this.day(this.check_index);
      }
    } else {
      // Default behavior (optional)
      this.get_statistics();
    }
  },
  computed: {
    server() {
      return this.$store.state.server;
    },
    statistics() {
      return this.$store.state.admin_requests.statistics;
    },
    loading() {
      return this.$store.state.admin_requests.loading_statistics;
    },
  },
  methods: {
    get_statistics() {
      this.$store.dispatch("admin_requests/get_statistics");
    },

    day(check_index) {
      this.check_index = check_index;
      localStorage.setItem("check_index", check_index); // Save to localStorage
      const currentDate = new Date().toISOString().split("T")[0];
      this.$store.state.admin_requests.start_date = currentDate;
      this.$store.state.admin_requests.end_date = currentDate;
      this.get_statistics();
    },
    week(check_index) {
      this.check_index = check_index;
      localStorage.setItem("check_index", check_index); // Save to localStorage
      const currentDate = new Date();
      // Calculate the date 7 days ago
      const pastDate = new Date();
      pastDate.setDate(currentDate.getDate() - 7);
      const startDate = pastDate.toISOString().split("T")[0];
      const endDate = currentDate.toISOString().split("T")[0];

      this.$store.state.admin_requests.start_date = startDate;
      this.$store.state.admin_requests.end_date = endDate;
      this.get_statistics();
    },
    month(check_index) {
      this.check_index = check_index;
      localStorage.setItem("check_index", check_index); // Save to localStorage
      const currentDate = new Date();

      // Calculate the date 30 days ago
      const pastDate = new Date();
      pastDate.setDate(currentDate.getDate() - 30);

      // Format the dates as YYYY-MM-DD
      const startDate = pastDate.toISOString().split("T")[0];
      const endDate = currentDate.toISOString().split("T")[0];

      this.$store.state.admin_requests.start_date = startDate;
      this.$store.state.admin_requests.end_date = endDate;
      this.get_statistics();
    },
    all(check_index) {
      this.check_index = check_index;
      localStorage.setItem("check_index", check_index); // Save to localStorage
      this.$store.state.admin_requests.start_date = null;
      this.$store.state.admin_requests.end_date = null;
      this.get_statistics();
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
  background-color: #f3f3f4 !important; /*f3f3f4*/
}

.list-item {
  background-color: #ffffff;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
}
.list-item-title {
  border-color: #f97316 !important;
  border-style: solid;
  border-width: 1px;
  background-color: #ffffff;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
}
.btn-filter {
  background-color: #f97316 !important;
  border-radius: 8px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.btn-filter-disabled {
  background-color: #ffffff !important;
  border-radius: 8px !important;
  box-shadow: 0px 0px 0px 0px !important;
  border-color: #cac4d0 !important;
}
.card {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 8px !important;
  background-color: #ffffff; /*f3f3f4*/
}
</style>
