<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card color="#F3F3F4" class="py-2 card">
          <v-list two-line style="background-color: #f3f3f4">
            <v-list-item class="list-item mx-2">
              <v-list-item-title class="d-flex justify-start">
                <v-btn
                  style="
                    border-radius: 16px;
                    box-shadow: 0px 0px 0px 0px !important;
                  "
                  color="#f97316"
                  class="btn ml-2 py-7"
                  @click="upload_image"
                >
                  <h3 style="color: #ffffff" class="data_table ml-2">
                    تعديل الصورة الشخصية
                  </h3>
                  <Icon
                    icon="clarity:pencil-solid"
                    width="30"
                    class="white--text"
                  />
                </v-btn>
              </v-list-item-title>
              <v-list-item-title class="d-flex justify-end">
                <span style="display: flex; align-items: center">
                  <v-avatar
                    size="100"
                    v-if="profile.image != null"
                    class="avatar-img"
                  >
                    <img
                      :src="server + profile.image"
                      width="100"
                      height="100"
                      alt="error"
                    />
                  </v-avatar>
                  <v-avatar size="100" v-else class="avater-img">
                    <img
                      src="@/assets/Elift-logo.png"
                      width="100"
                      height="100"
                      alt="error"
                      class="name_coach"
                    />
                  </v-avatar>
                </span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-card color="#F3F3F4" class="py-7 card">
          <v-card-title class="pb-1 pt-1 mt-0 d-flex justify-center">
            <Icon icon="mingcute:phone-fill" color="#f97316" width="26" />
          </v-card-title>
          <v-card-title class="py-1 d-flex justify-center">
            <h4 class="data_table">تعيين رقم الهاتف</h4>
          </v-card-title>
          <v-card-title class="d-flex justify-center input-settings">
            <v-text-field
              v-model="profile.phone"
              label="رقم الهاتف"
              class="data_table"
              color="#f97316"
              type="number"
              rounded
              outlined
              solo
              clearable
              hide-details
            />
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-card color="#F3F3F4" class="py-7 card">
          <v-card-title class="pb-1 pt-1 mt-0 d-flex justify-center">
            <Icon icon="ion:location" color="#f97316" width="26" />
          </v-card-title>
          <v-card-title class="py-1 d-flex justify-center">
            <h4 class="data_table">تعيين الموقع</h4>
          </v-card-title>
          <v-card-title class="d-flex justify-center input-settings">
            <v-text-field
              v-model="profile.address"
              label="الموقع"
              class="data_table"
              color="#f97316"
              rounded
              solo
              outlined
              clearable
              hide-details
            />
          </v-card-title>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" sm="12" md="6" lg="3" class="">
        <v-card color="#F3F3F4" class="trainer-info card">
          <v-card-title class="py-1 d-flex justify-center">
            <h5 class="data_table">    تحديد سعر الكورس     </h5>
          </v-card-title>

          <v-card-title class="pt-0 mt-2 d-flex justify-center">
            <v-text-field
              v-model="profile.course_price"
              label="سعر الكورس"
              class="data_table"
              color="#f97316"
              rounded
              solo
              outlined
              clearable
              hide-details
              type="number"
            />
          </v-card-title>
        </v-card>
      </v-col> -->
      <v-col cols="12" sm="12" md="6" lg="4" class="">
        <v-card color="#F3F3F4" class="trainer-info card">
          <v-card-title class="py-1 d-flex justify-center">
            <h5 class="data_table">رقم المحفظة الالكترونية الاولى</h5>
          </v-card-title>
          <v-card-title class="pt-0 mt-2 d-flex justify-center">
            <v-text-field
              v-model="profile.payment_number"
              label="رقم المحفظة الالكترونية الاولى "
              class="data_table"
              color="#f97316"
              rounded
              solo
              outlined
              clearable
              hide-details
              type="number"
            />
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="4" class="px-0">
        <v-card color="#F3F3F4" class="card trainer-info">
          <v-card-title class="py-1 d-flex justify-center">
            <h5 class="data_table">نوع المحفظة الالكترونية</h5>
          </v-card-title>
          <v-card-title class="pt-0 mt-2 d-flex justify-center">
            <v-text-field
              v-model="profile.payment_type"
              label="نوع المحفظة الالكترونية"
              class="data_table"
              color="#f97316"
              rounded
              solo
              outlined
              clearable
              hide-details
            />
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="4" class="">
        <v-card color="#F3F3F4" class="card">
          <vue-dropzone
            ref="myVueDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            :includeStyling="true"
            :useCustomSlot="true"
            @vdropzone-removed-file="remove_file"
            @vdropzone-success="uploadSuccess"
            @vdropzone-error="fileUploadError"
          >
            <div class="dropzone-custom-content">
              <v-img
                src="@/assets/upload_image.png"
                width="70"
                class="mx-auto"
              ></v-img>
              <h3 class="dropzone-custom-title mt-2 data_table">
                صورة QR للمحفظة
              </h3>
            </div>
          </vue-dropzone>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="4" class="">
        <v-card color="#F3F3F4" class="trainer-info card">
          <v-card-title class="py-1 d-flex justify-center">
            <h5 class="data_table">رقم المحفظة الالكترونية الثانية</h5>
          </v-card-title>
          <v-card-title class="pt-0 mt-2 d-flex justify-center">
            <v-text-field
              v-model="profile.payment_number_2"
              label="رقم المحفظة الالكترونية الثانية "
              class="data_table"
              color="#f97316"
              rounded
              solo
              outlined
              clearable
              hide-details
              type="number"
            />
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="4" class="px-0">
        <v-card color="#F3F3F4" class="card trainer-info">
          <v-card-title class="py-1 d-flex justify-center">
            <h5 class="data_table">نوع المحفظة الالكترونية</h5>
          </v-card-title>
          <v-card-title class="pt-0 mt-2 d-flex justify-center">
            <v-text-field
              v-model="profile.payment_type_2"
              label="نوع المحفظة الالكترونية"
              class="data_table"
              color="#f97316"
              rounded
              solo
              outlined
              clearable
              hide-details
            />
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="4" class="">
        <v-card color="#F3F3F4" class="card">
          <vue-dropzone
            ref="myVueDropzone_2"
            id="dropzone_2"
            :options="dropzoneOptions"
            :includeStyling="true"
            :useCustomSlot="true"
            @vdropzone-removed-file="remove_file_2"
            @vdropzone-success="uploadSuccess_2"
            @vdropzone-error="fileUploadError"
          >
            <div class="dropzone-custom-content">
              <v-img
                src="@/assets/upload_image.png"
                width="70"
                class="mx-auto"
              ></v-img>
              <h3 class="dropzone-custom-title mt-2 data_table">
                صورة QR للمحفظة
              </h3>
            </div>
          </vue-dropzone>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" class="py-0 my-0">
        <v-list two-line>
          <v-list-item class="list-item my-2 py-1 mx-2">
            <v-list-item-title class="d-flex justify-start">
              <v-text-field
                v-model="profile.course_price"
                label="سعر الكورس"
                class="data_table input-settings23"
                color="#f97316"
                rounded
                solo
                outlined
                clearable
                hide-details
                type="number"
              />
            </v-list-item-title>
            <v-list-item-title class="d-flex justify-end">
              <span style="display: flex; align-items: center">
                <h3 style="color: #50535b" class="data_table">
                  تحديد سعر الكورس
                </h3>

                <v-sheet
                  class="px-2 mr-2 sheet d-flex justify-center"
                  height="100%"
                  width="100%"
                  color="#f97316"
                  style="
                    border-radius: 13px;
                    box-shadow: 0px 0px 0px 0px !important;
                  "
                >
                  <span style="display: flex; align-items: center">
                    <Icon icon="mdi:user" color="#ffffff" width="25"
                  /></span>
                </v-sheet>
              </span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="list-item my-2 py-1 mx-2">
            <v-list-item-title class="d-flex justify-start">
              <v-text-field
                v-model="profile.year_of_experience"
                class="data_table input-settings23"
                color="#f97316"
                outlined
                solo
                clearable
                hide-details
              />
            </v-list-item-title>
            <v-list-item-title class="d-flex justify-end">
              <span style="display: flex; align-items: center">
                <h3 style="color: #50535b" class="data_table">
                  عدد سنوات الخبرة
                </h3>

                <v-sheet
                  class="px-2 mr-2 sheet d-flex justify-center"
                  height="100%"
                  width="100%"
                  color="#f97316"
                  style="
                    border-radius: 13px;
                    box-shadow: 0px 0px 0px 0px !important;
                  "
                >
                  <span style="display: flex; align-items: center">
                    <Icon icon="mdi:user" color="#ffffff" width="25"
                  /></span>
                </v-sheet>
              </span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" class="pt-0 pb-16">
        <v-list
          two-line
          class="list py-0"
          style="max-height: 270px; overflow-y: auto"
          @scroll.native="onScroll"
          ref="scrollContainer"
        >
          <v-list-item
            v-for="(achievement, index) in achievements"
            :key="index"
            class="my-2 mb-1 py-1"
          >
            <v-list-item-title class="d-flex justify-start">
              <v-expansion-panels
                multiple
                v-model="panels[index]"
                accordion
                style="border-radius: 10px"
              >
                <v-expansion-panel
                  :class="panels[index]?.length ? '' : 'item-achievements'"
                  style="border-radius: 10px"
                >
                  <v-expansion-panel-header
                    class="d-flex justify-start py-6"
                    :color="panels[index]?.length ? '#f97316' : '#F5F5F5'"
                    :style="panels[index]?.length ? '' : 'border-radius: 10px'"
                  >
                    <h4
                      class="data_table"
                      :style="
                        panels[index]?.length
                          ? 'color: #ffffff'
                          : 'color: #000000'
                      "
                    >
                      الانجاز {{ index + 1 }}
                    </h4>
                    <template v-slot:actions>
                      <v-icon
                        :color="panels[index]?.length ? '#ffffff' : '#000000'"
                      >
                        $expand
                      </v-icon>
                      <v-btn
                        icon
                        small
                        @click="pop_delete_achievement(achievement.id)"
                      >
                        <Icon
                          icon="mingcute:delete-fill"
                          :color="panels[index]?.length ? '#ffffff' : '#C62828'"
                          width="25"
                        />
                      </v-btn>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    color="#f97316"
                    style="
                      border-radius: 0px 0px 10px 10px;
                      box-shadow: 0px 0px 0px 0px;
                    "
                  >
                    <h4 style="color: #ffffff" class="data_table mr-3 py-2">
                      {{ achievement.title }}
                    </h4>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="loading2 == true">
            <h3 class="mx-auto font-semiBold" style="color: #50535b">
              جاري التحميل يرجى الأنتظار
            </h3>
          </v-list-item>

          <v-list-item v-if="achievements.length == 0 && loading2 == false">
            <h3 class="mx-auto py-10 font-semiBold" style="color: #000000">
              لايوجد انجازات
            </h3>
          </v-list-item>
          <v-list-item
            class="list-item2 py-0 mx-4 mt-2"
            @click="add_achievements"
          >
            <v-list-item-title class="d-flex justify-start">
              <span style="display: flex; align-items: center">
                <Icon
                  icon="lets-icons:add-duotone"
                  color="#f97316"
                  width="30"
                />

                <h3 style="color: #393c43" class="data_table">اضافة انجاز</h3>
              </span>
            </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-btn
          rounded
          block
          x-large
          class="btn mt-10"
          color="#f97316"
          @click="update_profile"
          :loading="loading"
        >
          <h2 style="color: #ffffff" class="data_table">حفظ</h2>
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon color="white">mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-col>
      <PopAddAchievements
        :value="pop_add_achievements"
        v-on:popClose="pop_add_achievements = !pop_add_achievements"
      />
      <PopDeleteAchievement
        :value="pop_delete_achievements"
        v-on:popClose="pop_delete_achievements = !pop_delete_achievements"
        v-on:deleteAchievement="deleteAchievement()"
      />
      <PopUploadImage
        :value="pop_upload_image"
        v-on:popClose="pop_upload_image = !pop_upload_image"
      />
    </v-row>
  </v-container>
</template>
<script>
import PopDeleteAchievement from "@/components/achievements/PopDeleteAchievement.vue";
import PopAddAchievements from "../components/achievements/PopAddAchievements";
import PopUploadImage from "../components/achievements/PopUploadImage";
export default {
  components: { PopAddAchievements, PopDeleteAchievement, PopUploadImage },
  data() {
    return {
      payment_qr_photo: null,
      payment_qr_photo_2: null,
      pop_add_achievements: false,
      pop_upload_image: false,

      achievements_id: null,
      pop_delete_achievements: false,
      panels: {},
      dropzoneOptions: {
        url: "https://efredgvrergv34345435.online/api/api/upload_file",
        method: "post",
        thumbnailWidth: 200,
        maxFilesize: 1,
        parallelUploads: 1,
        uploadMultiple: false,
        addRemoveLinks: true,
        acceptedFiles: ".png,.jpeg,.jpg",
        maxFiles: 1,

        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"), // or wherever your token is stored
        },
      },
    };
  },
  created() {
    this.get_profile();
    this.get_achievements();
  },
  computed: {
    server() {
      return this.$store.state.server;
    },
    profile() {
      return this.$store.state.trainer.profile;
    },
    achievements() {
      return this.$store.state.trainer.achievements;
    },
    loading() {
      return this.$store.state.trainer.profile_loading;
    },
    loading2() {
      return this.$store.state.trainer.achievements_loading;
    },
  },
  methods: {
    get_profile() {
      this.$store.dispatch("trainer/get_profile");
    },
    get_achievements() {
      this.$store.dispatch("trainer/get_achievements");
    },

    update_profile() {
      let data = {};
      data["phone"] = this.profile.phone;
      data["address"] = this.profile.address;
      data["course_price"] = this.profile.course_price;
      data["payment_number"] = this.profile.payment_number;
      data["payment_type"] = this.profile.payment_type;
      data["payment_number_2"] = this.profile.payment_number_2;
      data["payment_type_2"] = this.profile.payment_type_2;

      if (this.payment_qr_photo != null) {
        data["payment_qr_photo"] = this.payment_qr_photo;
      }
      if (this.payment_qr_photo_2 != null) {
        data["payment_qr_photo_2"] = this.payment_qr_photo_2;
      }
      data["year_of_experience"] = this.profile.year_of_experience;
      this.$store.dispatch("trainer/update_profile", data).then(() => {});
    },

    upload_image() {
      this.pop_upload_image = true;
    },
    pop_delete_achievement(id) {
      this.achievements_id = id;
      this.pop_delete_achievements = true;
    },
    deleteAchievement() {
      let data = {};
      data["achievement_id"] = this.achievements_id;
      this.$store.dispatch("trainer/delete_achievement", data).then(() => {
        this.pop_delete_achievements = false;
        this.achievements_id = null;
      });
    },

    add_achievements() {
      this.pop_add_achievements = true;
    },

    uploadSuccess(file, response) {
      this.payment_qr_photo = response["file"];
    },
    uploadSuccess_2(file, response) {
      this.payment_qr_photo_2 = response["file"];
    },
    fileUploadError() {
      let snack_message = {};
      snack_message["color"] = "#ef4444";
      snack_message["icon"] = "ri:close-circle-fill";
      snack_message["text"] = "لا يمكنك تحميل ملفات من هذا النوع.";
      this.$store.commit("SNACK_MESSAGE", snack_message);
      setTimeout(() => {
        this.$store.commit("TIME_OUT", snack_message);
      }, 4000);
    },

    remove_file() {
      this.payment_qr_photo = null;
    },
    remove_file_2() {
      this.payment_qr_photo_2 = null;
    },

    onScroll(event) {
      var achievements_state = this.$store.state.trainer.achievements_state;
      const container = event.target;
      const isScrollingDown = container.scrollTop > this.lastScrollTop;
      this.lastScrollTop = container.scrollTop;
      if (
        isScrollingDown &&
        container.scrollTop + container.clientHeight >=
          container.scrollHeight - 50 &&
        !this.loading2 &&
        achievements_state == "done"
      ) {
        this.get_achievements();
      }
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
  background-color: #ffffff; /*f3f3f4*/
}
.input-settings {
  padding: 10px 140px !important;
}
.input-settings2 {
  padding: 10px 95px !important;
}
.input-settings3 {
  padding: 10px 80px !important;
}

@media (min-width: 390px) and (max-width: 428px) {
  .input-settings23 {
    padding: 0px 0px 0px 150px;
  }
}
@media (min-width: 429px) and (max-width: 767px) {
  .input-settings23 {
    padding: 0px 0px 0px 50px;
  }
}

@media (min-width: 768px) {
  .input-settings23 {
    padding: 0px 0px 0px 100px;
  }
}

/* Large screens (desktop) */
@media (min-width: 1024px) {
  .input-settings23 {
    padding: 0px 0px 0px 260px;
  }
}

/* Extra large screens */
@media (min-width: 1440px) {
  .input-settings23 {
    padding: 0px 0px 0px 300px;
  }
}

/*  dropzone */

.vue-dropzone > .dz-preview .dz-remove {
  margin-right: 80px !important;
  margin-left: 0 !important;
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 500 !important;
}
.dropzone-custom-content {
  margin-top: 50px;
  text-align: center;
}

.dropzone-custom-title {
  font-family: "Cairo", sans-serif;
  margin-top: 0;
  color: #000000;
}

.subtitle {
  font-family: "Cairo", sans-serif;
  color: #314b5f;
}
.dropzone {
  height: 270px;
  border-radius: 25px;
  border-color: #ffffff;
  padding: 0px 20px !important;
}
@media (min-width: 1182px) {
  /* Adjust for web (desktop and larger screens) */
  .dropzone {
    height: 230px !important;
  }
}

.vue-dropzone {
  background-color: #f3f3f3 !important;
}
.dz-preview {
  height: 150px !important;
  display: block !important;
}

.dz-details {
  top: 0px !important;
}
.dz-error-message {
  top: 150px !important;
}

.list-item {
  background-color: #f3f3f4;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
}
.list {
  background-color: #ffffff;
}
.list-item2 {
  border: 2px solid #f97316 !important;
  border-radius: 10px !important;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 0px 0px !important;
  cursor: pointer;
}
.item-achievements {
  border: 2px solid #f97316 !important;
}
.trainer-info {
  padding-top: 54px !important;
  padding-bottom: 54px !important;
}
.avatar-img {
  border-color: #f97316 !important; /* Bright orange border */
  border-style: solid !important;
  border-width: 3px !important; /* Slightly thicker for emphasis */
  border-radius: 50% !important; /* Ensure a circular shape */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06); /* Add subtle shadow */
  overflow: hidden; /* Crop any overflow from the image */
}

.avatar-img img {
  object-fit: cover; /* Ensure the image scales properly within the circle */
  object-position: center; /* Center the image focus */
  border-radius: 50%; /* Match the avatar border radius */
}
</style>
