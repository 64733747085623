<template>
  <v-container fluid class="mt-0">
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" class="pb-6">
        <v-card class="mx-auto mt-8 card" width="100%">
          <v-toolbar dark color="#F97300" dense class="d-flex justify-center">
            <v-toolbar-title align-center class="font-semiBold py-1">
              المنهاج التدريبي القديم
            </v-toolbar-title>
          </v-toolbar>

          <v-list
            two-line
            class="list py-0"
            style="max-height: 500px; overflow-y: auto"
            ref="scrollContainer"
          >
            <v-list-item
              v-for="(course, index) in previous_courses"
              :key="index"
              class="pa-0"
            >
              <v-list-item-title class="d-flex justify-start">
                <v-expansion-panels
                  v-model="panels2"
                  class="d-flex justify-center"
                  :flat="true"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      expand-icon=""
                      class="d-flex justify-start"
                      style="background-color: #ffedd5"
                      @click="add_panel2(index)"
                    >
                      <span style="display: flex; align-items: center">
                        <Icon
                          :icon="
                            panels.includes(index)
                              ? 'ic:outline-close'
                              : 'ph:list-bold'
                          "
                          color="F97300"
                          width="25"
                        />

                        <h4 class="mr-3" style="color: #f97300">
                          {{ courseTitles[meal.day] }}
                        </h4>
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-for="(exercise, index2) in course.exercises"
                      :key="index2"
                    >
                      <span style="display: flex; align-items: center">
                        <h4
                          class="mb-4 mt-3"
                          style="
                            white-space: normal;
                            word-wrap: break-word;
                            max-width: 500px;
                            color: #50535b;
                          "
                        >
                          <span style="color: #f97300">{{ index2 + 1 }}.</span>
                          {{ exercise.title }}
                        </h4>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="delete_course(exercise.id)"
                          fab
                          icon
                          x-small
                          style="min-width: 25px"
                          class="mx-auto btn-close mb-4 mt-3"
                          elevation="4"
                        >
                          <Icon
                            icon="solar:close-square-bold"
                            color="#F28218"
                            width="25"
                          />
                          <template v-slot:loader>
                            <span class="custom-loader">
                              <v-icon color="white">mdi-cached</v-icon>
                            </span>
                          </template>
                        </v-btn>
                      </span>
                      <v-divider :key="index2"></v-divider>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="loading2 == true">
              <h3 class="mx-auto font-semiBold" style="color: #50535b">
                جاري التحميل يرجى الأنتظار
              </h3>
            </v-list-item>

            <v-list-item
              v-if="previous_courses.length == 0 && loading2 == false"
            >
              <h3 class="mx-auto py-10 font-semiBold" style="color: #000000">
                لايوجد تمارين
              </h3>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" class="pb-6">
        <v-card class="mx-auto mt-8 card" width="100%">
          <v-toolbar dark color="#F97300" dense class="d-flex justify-center">
            <v-toolbar-title align-center class="font-semiBold py-1">
              المنهاج التدريبي الجديد</v-toolbar-title
            >
          </v-toolbar>
          <v-list
            two-line
            class="list py-0"
            style="max-height: 500px; overflow-y: auto"
            ref="scrollContainer"
          >
            <v-list-item
              v-for="(course, index) in courses"
              :key="index"
              class="pa-0"
            >
              <v-list-item-title class="d-flex justify-start">
                <v-expansion-panels class="d-flex justify-center" :flat="true">
                  <v-expansion-panel :value="panels[index]">
                    <v-expansion-panel-header
                      disable-icon-rotate
                      expand-icon=""
                      class="d-flex justify-start"
                      style="background-color: #ffedd5"
                      @click="add_panel(index)"
                    >
                      <span style="display: flex; align-items: center">
                        <Icon
                          :icon="
                            panels.includes(index)
                              ? 'ic:outline-close'
                              : 'ph:list-bold'
                          "
                          color="F97300"
                          width="25"
                        />

                        <h4 class="mr-3 data_table" style="color: #f97300">
                          {{ courseTitles[course.day] }}
                        </h4>
                      </span>
                      <template v-slot:actions>
                        <v-btn
                          fab
                          icon
                          x-small
                          class=""
                          @click="toggle_up(course.day)"
                          :disabled="index == 0"
                        >
                          <Icon
                            icon="fluent:arrow-up-12-filled"
                            :color="index == 0 ? 'D5D5D6' : 'F97300'"
                            width="25"
                          />
                        </v-btn>
                        <v-btn
                          fab
                          icon
                          x-small
                          class="ml-1"
                          @click="toggle_down(course.day)"
                          :disabled="index > courses.length - 2"
                        >
                          <Icon
                            icon="fluent:arrow-down-12-filled"
                            :color="
                              index > courses.length - 2 ? 'D5D5D6' : 'F97300'
                            "
                            width="25"
                          />
                        </v-btn>
                        <v-btn
                          fab
                          icon
                          x-small
                          class="ml-1"
                          @click="add_and_edit_courses(course)"
                        >
                          <Icon
                            icon="rivet-icons:pencil-solid"
                            color="F97300"
                            width="25"
                          />
                        </v-btn>
                        <v-btn
                          fab
                          icon
                          x-small
                          @click="delete_main_course(course.day, index)"
                          :loading="index == check_index ? loading4 : null"
                        >
                          <Icon
                            icon="material-symbols:close"
                            color="F97300"
                            width="30"
                          />
                        </v-btn>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-text-field
                        :ref="'exercise-' + index"
                        v-model="localTitle[index]"
                        type="text"
                        label="اضافة التمرين"
                        class="data_table py-2"
                        color="#F97316"
                        rounded
                        solo
                        outlined
                        clearable
                        hide-details="auto"
                        persistent-hint
                        @keydown="handleKeydown"
                        @keyup.enter="add_exercise(course.day, index)"
                      />
                    </v-expansion-panel-content>
                    <v-expansion-panel-content
                      v-for="(exercise, index2) in course.exercises"
                      :key="index2"
                    >
                      <span
                        style="display: flex; align-items: center"
                        @dblclick="
                          exercise.temp == true
                            ? edit_exercise_temp(exercise)
                            : edit_exercise(exercise)
                        "
                      >
                        <h4
                          v-if="edit != exercise.id"
                          class="mb-4 mt-3 data_table"
                          style="
                            white-space: normal;
                            word-wrap: break-word;
                            max-width: 500px;
                            color: #50535b;
                          "
                        >
                          <span style="color: #f97300">{{ index2 + 1 }}.</span>
                          {{ exercise.title }}
                        </h4>
                        <v-text-field
                          v-if="edit == exercise.id"
                          v-model="edit_title"
                          type="text"
                          label="تعديل التمرين"
                          class="data_table py-2"
                          color="#F97316"
                          rounded
                          solo
                          outlined
                          clearable
                          hide-details="auto"
                          persistent-hint
                          :disabled="loading2 == true"
                          @keyup.enter="
                            exercise.temp == true
                              ? update_exercise_temp(exercise.id, exercise.day)
                              : update_exercise(exercise.id)
                          "
                          @keydown="handleKeydown2"
                        />
                        <v-spacer></v-spacer>
                        <v-btn
                          v-if="
                            (exercise.temp == true &&
                              exercises_titles.length > 0) ||
                            (exercise.temp == null &&
                              exercises_titles.length == 0)
                          "
                          fab
                          icon
                          x-small
                          class=""
                          @click="
                            exercise.temp == true
                              ? toggle_up_single_temp(
                                  exercise,
                                  index2,
                                  course.day
                                )
                              : toggle_up_single(exercise, index2, course.day)
                          "
                          :disabled="
                            isDisabledToggleUp(
                              exercise,
                              index2,
                              course.exercises.length
                            )
                          "
                        >
                          <Icon
                            icon="fluent:arrow-up-12-filled"
                            :color="
                              isDisabledColor(
                                exercise,
                                index2,
                                course.exercises.length
                              )
                            "
                            width="25"
                          />
                        </v-btn>

                        <v-btn
                          v-if="
                            (exercise.temp == true &&
                              exercises_titles.length > 0) ||
                            (exercise.temp == null &&
                              exercises_titles.length == 0)
                          "
                          fab
                          icon
                          x-small
                          class="ml-1"
                          :ripple="false"
                          @click="
                            exercise.temp == true
                              ? toggle_down_single_temp(
                                  exercise,
                                  index2,
                                  course.day
                                )
                              : toggle_down_single(exercise, index2, course.day)
                          "
                          :disabled="index2 > course.exercises.length - 2"
                        >
                          <Icon
                            icon="fluent:arrow-down-12-filled"
                            :color="
                              index2 > course.exercises.length - 2
                                ? 'D5D5D6'
                                : 'F97300'
                            "
                            width="25"
                          />
                        </v-btn>

                        <v-btn
                          @click="
                            exercise.temp == true
                              ? delete_course_temp(exercise.id, exercise.day)
                              : delete_course(exercise.id)
                          "
                          fab
                          icon
                          x-small
                          style="min-width: 25px"
                          class="mx-auto btn-close mb-4 mt-3"
                          elevation="4"
                        >
                          <Icon
                            icon="solar:close-square-bold"
                            color="#F28218"
                            width="25"
                          />
                          <template v-slot:loader>
                            <span class="custom-loader">
                              <v-icon color="white">mdi-cached</v-icon>
                            </span>
                          </template>
                        </v-btn>
                      </span>
                      <v-divider
                        v-if="index2 < course.exercises.length - 1"
                        :key="index2"
                      ></v-divider>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="loading == true">
              <h3 class="mx-auto font-semiBold" style="color: #50535b">
                جاري التحميل يرجى الأنتظار
              </h3>
            </v-list-item>

            <v-list-item v-if="courses.length == 0 && loading == false">
              <h3 class="mx-auto font-semiBold py-10" style="color: #000000">
                لايوجد تمارين
              </h3>
            </v-list-item>
            <v-list-item class="list-item2 py-0 mt-1" v-if="loading == false">
              <v-list-item-title
                @click="add_days(courses.length)"
                class="d-flex justify-start"
              >
                <span style="display: flex; align-items: center">
                  <v-progress-circular
                    v-if="loading3"
                    indeterminate
                    color="#f97316"
                    size="20"
                    class="mr-2"
                  ></v-progress-circular>
                  <Icon
                    v-if="!loading3"
                    class="mr-1"
                    icon="ic:baseline-plus"
                    color="#f97316"
                    width="30"
                  />

                  <h3 class="mr-3 data_table" style="color: #f97300">
                    اضافة يوم
                  </h3>
                </span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <TraineeInformationUpdateBar
        :style="
          courses.length < 4 ? ' margin-top: 140px;' : 'margin-top: 25px;'
        "
        :value="0"
        :loading_add="loading5"
        v-on:add_exercises="add_and_update_exercises()"
      />
      <PopAddCourses
        :value="pop_add_and_edit_course"
        v-on:popClose="pop_add_and_edit_course = !pop_add_and_edit_course"
        :course="data_course"
      />
      <PopDelete
        :value="pop_delete"
        v-on:popClose="pop_delete = !pop_delete"
        v-on:deleteCourse="deleteCourse()"
      />
    </v-row>
  </v-container>
</template>

<script>
import TraineeInformationUpdateBar from "@/components/layout/TraineeInformationUpdateBar.vue";
import PopAddCourses from "@/components/courses/PopAddCourses.vue";
import PopDelete from "@/components/additional_courses/PopDelete.vue";
export default {
  components: { PopAddCourses, PopDelete, TraineeInformationUpdateBar },
  data() {
    return {
      panels: [],
      panels2: [],
      exercises_titles: [],

      edit_title: null,
      edit: null,

      pop_add_and_edit_course: false,
      data_course: null,

      pop_delete: false,
      data_deleted: null,

      day: null,
      // add_day: false,

      check_index: false,
      priority: 0,

      title: "",
      spacebarCount: 0, // Track spacebar presses
      spacebarCount2: 0,
      courseTitles: {
        1: "اليوم الأول",
        2: "اليوم الثاني",
        3: "اليوم الثالث",
        4: "اليوم الرابع",
        5: " اليوم الخامس",
        6: "اليوم السادس",
        7: "اليوم السابع",
      },
      day_exercises_toggling: null,
      localTitle:[],
    };
  },
  created() {
    this.getBasicExercises();
    this.getBasicExercisesPrevious();
  },

  computed: {
    courses() {
      return this.$store.state.courses.courses;
    },
    previous_courses() {
      return this.$store.state.courses.previous_courses;
    },
    loading() {
      return this.$store.state.courses.courses_loading;
    },
    loading2() {
      return this.$store.state.courses.previous_courses_loading;
    },
    loading3() {
      return this.$store.state.courses.add_days_loading;
    },
    loading4() {
      return this.$store.state.courses.delete_day_loading;
    },
    loading5() {
      return this.$store.state.courses.add_courses_loading;
    },
  },
  methods: {
    add_panel(index) {
      if (!this.panels.includes(index)) {
        this.panels.push(index);
      } else {
        this.panels = this.panels.filter(
          (panelsIndex) => panelsIndex !== index
        );
      }
      localStorage.setItem("panels", JSON.stringify(this.panels));
    },
    add_panel2(index) {
      if (!this.panels2.includes(index)) {
        this.panels2.push(index);
      } else {
        this.panels2 = this.panels2.filter(
          (panelsIndex) => panelsIndex !== index
        );
      }
    },

    getBasicExercises() {
      this.$store.dispatch("courses/get_current_courses");
    },
    getBasicExercisesPrevious() {
      if (localStorage.getItem("previous_session_id") != "null") {
        this.$store.dispatch("courses/get_current_courses");
      }
    },

    edit_exercise(exercise) {
      if (this.edit == exercise.id) {
        this.edit = null;
      } else {
        this.edit_title = exercise.title;
        this.edit = exercise.id;
      }
    },
    update_exercise(id) {
      if (this.edit_title != null && this.edit_title != "") {
        let data = {};
        data["course_id"] = id;
        data["title"] = this.edit_title;
        this.$store.dispatch("courses/update_course", data).then(() => {
          // this.edit_title = null;
          this.edit = null;
        });
      } else {
        let snack_message = {};
        snack_message["color"] = "#FB8C00";
        snack_message["icon"] = "cuida:alert-outline";
        snack_message["text"] = "لا يمكن ترك الحقل فارغ";
        this.$store.commit("SNACK_MESSAGE", snack_message);
        setTimeout(() => {
          this.$store.commit("TIME_OUT", snack_message);
        }, 4000);
      }
    },
    generateRandomId() {
      // Generate a random number between 1 and 10,000
      return Math.floor(Math.random() * 10000) + 1;
    },
    add_exercise(day,index) {
        // Access the unique ref
        const exercise = this.localTitle[index].trim();

      if (exercise !== "") {
        var existingDay = this.$store.state.courses.courses.find(
          (entry) => entry.day === day
        );
        var randomId = this.generateRandomId();
        let data = {};
        var priority;

        if (existingDay.exercises.length === 0) {
          priority = data["priority"] = this.priority + 1;
        } else {
          priority = data["priority"] =
            existingDay.exercises[existingDay.exercises.length - 1].priority +
            1;
        }
        data["title"] = exercise;
        data["day"] = day;
        data["priority"] = priority;
        data["id"] = randomId;
        data["temp"] = true;
        this.exercises_titles.push(data);

        existingDay.exercises.push({
          id: randomId,
          title: exercise,
          priority: priority,
          temp: true,
          day: day,
        });
        this.$set(this.localTitle, index, "");
      }
    },
    edit_exercise_temp(exercise) {
      if (this.edit == exercise.id) {
        this.edit = null;
      } else {
        this.edit_title = exercise.title;
        this.edit = exercise.id;
      }
    },

    update_exercise_temp(id, day) {
      let data = {};
      data["id"] = id;
      data["temp"] = true;
      data["day"] = day;
      data["title"] = this.edit_title;

      if (this.edit_title != null && this.edit_title != "") {
        let index = this.exercises_titles.findIndex((e) => e.id === id);
        if (index !== -1) {
          // Update the element in place
          this.exercises_titles[index].title = this.edit_title;
        }

        const dayIndex = this.$store.state.courses.courses.findIndex(
          (entry) => entry.day === day
        );

        if (dayIndex !== -1) {
          // Find the course in the exercises array
          const exerciseIndex = this.$store.state.courses.courses[
            dayIndex
          ].exercises.findIndex((exercise) => exercise.id === id);

          if (exerciseIndex !== -1) {
            // Update the course in the exercises array
            this.$store.state.courses.courses[dayIndex].exercises[
              exerciseIndex
            ] = {
              ...this.$store.state.courses.courses[dayIndex].exercises[
                exerciseIndex
              ],
              ...data,
            };
          }
        }

        this.edit = null;
      } else {
        let snack_message = {};
        snack_message["color"] = "#FB8C00";
        snack_message["icon"] = "cuida:alert-outline";
        snack_message["text"] = "لا يمكن ترك الحقل فارغ";
        this.$store.commit("SNACK_MESSAGE", snack_message);
        setTimeout(() => {
          this.$store.commit("TIME_OUT", snack_message);
        }, 4000);
      }
    },
    add_and_update_exercises() {
      if (this.exercises_titles.length > 0) {
        this.add_exercises();
      } else {
        this.update_exercises();
      }
    },

    add_exercises() {
      let data = {};
      data["subscribtion_session_id"] =
        this.$store.state.subscribtion_session_id;
      data["exercises"] = this.exercises_titles;
      data["type"] = "main";

      this.$store.dispatch("courses/add_main_courses", data).then(() => {
        this.exercises_titles = [];
      });
    },

    update_exercises() {
      if (this.day_exercises_toggling != null) {
        let data = {};
        data["subscribtion_session_id"] =
          this.$store.state.subscribtion_session_id;
        data["exercises"] = this.$store.state.courses.courses.find(
          (entry) => entry.day === this.day_exercises_toggling
        ).exercises;

        data["day"] = this.day_exercises_toggling;
        console.log("data", data);

        this.$store.dispatch("courses/toggle_single_courses", data).then(() => {
          this.day_exercises_toggling = null;
        });
      }
    },

    toggle_up(day) {
      let data = {};
      data["toggle_down"] = parseInt(day) - 1; // Adjust for the item to be swapped up
      data["toggle_up"] = parseInt(day);
      data["subscribtion_session_id"] =
        this.$store.state.subscribtion_session_id;
      this.$store.dispatch("courses/toggle_courses", data);
    },

    toggle_down(day) {
      let data = {};
      data["toggle_down"] = parseInt(day);
      data["toggle_up"] = parseInt(day) + 1;
      data["subscribtion_session_id"] =
        this.$store.state.subscribtion_session_id;
      this.$store.dispatch("courses/toggle_courses", data);
    },

    toggle_up_single(exercise, index2, day) {
      //check if the Modification mod if on
      this.day_exercises_toggling = day;

      var index_toggle_down = index2 - 1;
      var index_toggle_up = index2;
      const dayIndex = this.$store.state.courses.courses.findIndex(
        (entry) => entry.day === day
      );
      if (dayIndex !== -1) {
        // Find the index of the course in the exercises array
        const courses = this.$store.state.courses.courses[dayIndex].exercises;
        // Swap priorities using destructuring
        [
          courses[index_toggle_up].priority,
          courses[index_toggle_down].priority,
        ] = [
          courses[index_toggle_down].priority,
          courses[index_toggle_up].priority,
        ];
        // Sort exercises by priority
        courses.sort((a, b) => a.priority - b.priority);
      }
    },

    toggle_down_single(exercise, index2, day) {
      //check if the Modification mod if on

      this.day_exercises_toggling = day;
      var index_toggle_down = index2;
      var index_toggle_up = index2 + 1;

      const dayIndex = this.$store.state.courses.courses.findIndex(
        (entry) => entry.day === day
      );

      if (dayIndex !== -1) {
        // Find the index of the course in the exercises array
        const courses = this.$store.state.courses.courses[dayIndex].exercises;

        // Swap priorities using destructuring
        [
          courses[index_toggle_down].priority,
          courses[index_toggle_up].priority,
        ] = [
          courses[index_toggle_up].priority,
          courses[index_toggle_down].priority,
        ];

        // Sort exercises by priority
        courses.sort((a, b) => a.priority - b.priority);
      }
    },

    toggle_up_single_temp(exercise, index2, day) {
      //check if the Modification mod if off
      this.day_exercises_toggling = null;

      var local_toggle_up = this.exercises_titles.findIndex(
        (e) => e.id == exercise.id
      );
      var local_toggle_down = local_toggle_up - 1;

      var exercises = this.exercises_titles;

      [
        exercises[local_toggle_up].priority,
        exercises[local_toggle_down].priority,
      ] = [
        exercises[local_toggle_down].priority,
        exercises[local_toggle_up].priority,
      ];
      exercises.sort((a, b) => a.priority - b.priority);

      var index_toggle_down = index2 - 1;
      var index_toggle_up = index2;

      const dayIndex = this.$store.state.courses.courses.findIndex(
        (entry) => entry.day === day
      );

      if (dayIndex !== -1) {
        // Find the index of the course in the exercises array
        const courses = this.$store.state.courses.courses[dayIndex].exercises;

        // Swap priorities using destructuring
        [
          courses[index_toggle_up].priority,
          courses[index_toggle_down].priority,
        ] = [
          courses[index_toggle_down].priority,
          courses[index_toggle_up].priority,
        ];

        // Sort exercises by priority
        courses.sort((a, b) => a.priority - b.priority);
      }
    },
    toggle_down_single_temp(exercise, index2, day) {
      //check if the Modification mod if off
      this.day_exercises_toggling = null;

      var local_toggle_down = this.exercises_titles.findIndex(
        (e) => e.id == exercise.id
      );
      var local_toggle_up = local_toggle_down + 1;

      var exercises = this.exercises_titles;

      [
        exercises[local_toggle_down].priority,
        exercises[local_toggle_up].priority,
      ] = [
        exercises[local_toggle_up].priority,
        exercises[local_toggle_down].priority,
      ];
      exercises.sort((a, b) => a.priority - b.priority);

      var index_toggle_down = index2;
      var index_toggle_up = index2 + 1;

      const dayIndex = this.$store.state.courses.courses.findIndex(
        (entry) => entry.day === day
      );

      if (dayIndex !== -1) {
        // Find the index of the course in the exercises array
        const courses = this.$store.state.courses.courses[dayIndex].exercises;

        // Swap priorities using destructuring
        [
          courses[index_toggle_down].priority,
          courses[index_toggle_up].priority,
        ] = [
          courses[index_toggle_up].priority,
          courses[index_toggle_down].priority,
        ];

        // Sort exercises by priority
        courses.sort((a, b) => a.priority - b.priority);
      }
    },

    isDisabledToggleUp(exercise, index2, course_exercises_length) {
      var index =
        index2 - (course_exercises_length - this.exercises_titles.length);

      if (exercise.temp == true && index == 0) {
        return true;
      } else if (exercise.temp == null && index2 == 0) {
        return true;
      } else {
        return false;
      }
    },
    isDisabledColor(exercise, index2, course_exercises_length) {
      var index =
        index2 - (course_exercises_length - this.exercises_titles.length);

      if (exercise.temp == true && index == 0) {
        return "D5D5D6";
      } else if (exercise.temp == null && index2 == 0) {
        return "D5D5D6";
      } else {
        return "F97300";
      }
    },

    add_and_edit_courses(course) {
      this.pop_add_and_edit_course = true;
      this.data_course = course;
    },
    delete_course(id) {
      this.pop_delete = true;
      this.data_deleted = id;
    },
    deleteCourse() {
      let data = {};
      data["course_id"] = this.data_deleted;
      this.$store.dispatch("courses/delete_course", data).then(() => {
        this.pop_delete = false;
        this.data_deleted = null;
      });
    },
    delete_course_temp(id, day) {
      var index_local = this.exercises_titles.findIndex((e) => e.id == id);
      this.exercises_titles.splice(index_local, 1);

      const dayIndex = this.$store.state.courses.courses.findIndex(
        (entry) => entry.day === day
      );

      if (dayIndex !== -1) {
        // Find the index of the course in the exercises array
        const exerciseIndex = this.$store.state.courses.courses[
          dayIndex
        ].exercises.findIndex((exercise) => exercise.id === id);

        if (exerciseIndex !== -1) {
          // Remove the course from the exercises array
          this.$store.state.courses.courses[dayIndex].exercises.splice(
            exerciseIndex,
            1
          );

          // If the exercises array is empty, remove the entire day
          if (
            this.$store.state.courses.courses[dayIndex].exercises.length === 0
          ) {
            this.$store.state.courses.courses.splice(dayIndex, 1);
          }
        }
      }
    },
    delete_main_course(day, index) {
      this.check_index = index;
      let data = {};
      data["day"] = day;
      data["type"] = "main";
      data["subscribtion_session_id"] =
        this.$store.state.subscribtion_session_id;
      this.$store.dispatch("courses/delete_main_course", data).then(() => {});
    },

    add_days(courses_length) {
      if (courses_length < 7) {
        let days = this.$store.state.courses.courses.map((course) =>
          parseInt(course.day)
        ); // Extract days
        let missingDay = null;

        // Sort days in ascending order
        days.sort((a, b) => a - b);

        if (days[0] > 1) {
          missingDay = 1; // If the smallest day is greater than 1, the first day is missing
        } else {
          // Check for gaps between consecutive days
          for (let i = 0; i < days.length - 1; i++) {
            if (days[i + 1] - days[i] > 1) {
              // Find the first missing day
              missingDay = days[i] + 1;
              break;
            }
          }
        }

        var day;
        if (missingDay != null) {
          day = missingDay;
        } else {
          day = courses_length + 1;
        }

        let data = {};
        data["subscribtion_session_id"] =
          this.$store.state.subscribtion_session_id;
        data["type"] = "main";
        data["title"] = "التمرين الاول";
        data["priority"] = 1;
        data["day"] = String(day);
        this.$store.dispatch("courses/add_days", data);
      } else {
        let snack_message = {};
        snack_message["color"] = "#FB8C00";
        snack_message["icon"] = "cuida:alert-outline";
        snack_message["text"] = "لقد وصلت الى عدد الاقصى لعدد الايام";
        this.$store.commit("SNACK_MESSAGE", snack_message);
        setTimeout(() => {
          this.$store.commit("TIME_OUT", snack_message);
        }, 5000);
      }
    },
    handleKeydown(event) {
      // Check if spacebar (keyCode 32) is pressed
      if (event.key === " ") {
        this.spacebarCount++;

        // If spacebar is pressed twice, insert a comma '-'
        if (this.spacebarCount === 2) {
          this.title += "-";
          this.spacebarCount = 0; // Reset count after inserting comma
        }
      } else {
        // Reset count if other key is pressed
        this.spacebarCount = 0;
      }
    },
    handleKeydown2(event) {
      // Check if spacebar (keyCode 32) is pressed
      if (event.key === " ") {
        this.spacebarCount2++;

        // If spacebar is pressed twice, insert a comma '-'
        if (this.spacebarCount2 === 2) {
          this.edit_title += "-";
          this.spacebarCount2 = 0; // Reset count after inserting comma
        }
      } else {
        // Reset count if other key is pressed
        this.spacebarCount2 = 0;
      }
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.btn-close {
  box-shadow: 0px 0px 0px 0px !important;
}
.list-item2 {
  /* border: 2px solid #f97316 !important; */
  border-radius: 4px !important;
  background-color: #ffedd5;
  box-shadow: 0px 0px 0px 0px !important;
  cursor: pointer;
}
.sheet {
  border-radius: 25px !important;
}
</style>
<style>
.v-expansion-panel-content__wrap {
  padding: 0px 9px 0px !important;
}
</style>
