import axios from "axios";
const admin_users = {
  namespaced: true,
  state: () => ({
    trainers: [],

    trainers_loading: false,
    trainers_query:"",
    pageCount:1,
    params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
  }),
  mutations: {
    // SELECT_APP_SUBSCRIPTION(state, AppSubscription) {
    //   state.AppSubscription = AppSubscription;
    // },
    // GET_PROFILE(state, profile) {
    //   state.profile = profile;
    // },

    // MEMBER_SHIPS(state, member_Ships) {
    //   state.member_Ships.splice(0, state.member_Ships.length);
    //   member_Ships.forEach((element) => {
    //     state.member_Ships.push(element);
    //   });
    // },
    GET_TRAINERS(state, trainer) {
      state.trainers.splice(0, state.trainers.length);
      trainer.forEach((element) => {
        state.trainers.push(element);
      });
    },

  },
  actions: {
    async reset_table({ state }) {
        state.trainers = [];
        state.pageCount = 1;
        state.trainers_query = "";
        state.trainers_loading = false;
        state.params = {
          sortBy: [],
          sortDesc: [],
          page: 1,
          itemsPerPage: 10,
        };
      },

    get_trainers({ commit, state, rootState }) {
      state.trainers_loading = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        let query = "";
        if (
          state.trainers_query != undefined &&
          state.trainers_query != null &&
          state.trainers_query.length > 0
        )
          query = `&query=${state.trainers_query}`;
        axios({
          url:
            `${rootState.server}` +
            "/api/admin/trainers" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            query,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;
            commit("GET_TRAINERS", response.data.result);
            state.trainers_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.trainers_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    update_profile({ commit, state, rootState }, data) {
      state.profile_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/update-profile",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("GET_PROFILE", response.data.result[0]);
            state.profile_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.profile_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_profile({ commit, state, rootState }) {
      state.profile_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/profile",
          method: "get",
        })
          .then((response) => {
            commit("GET_PROFILE", response.data.result[0]);
            state.profile_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.profile_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    get_achievements({ commit, state, rootState }) {
      state.achievements_loading = true;
      let data = state.achievements_params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;
        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/achievements" +
            "?skip=" +
            skip +
            "&limit=" +
            limit,
          method: "get",
        })
          .then((response) => {
            commit("GET_ACHIEVEMENTS_DREPDWON", response.data.result);

            state.achievements_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.achievements_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    add_achievement({ commit, state, rootState }, data) {
      state.add_achievement_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/achievement/create",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_ACHIEVEMENTS", response.data.result[0]);
            state.add_achievement_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_achievement_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    delete_achievement({ commit, state, rootState }, data) {
      state.delete_achievement_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/achievement/delete",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("DELETE_ACHIEVEMENTS", response.data.result[0]);
            state.delete_achievement_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.delete_achievement_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default admin_users;
