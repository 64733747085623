import Vue from "vue";

Vue.filter("formatNumber", function (value) {
  if (!value && value !== 0) return "0 IQD"; // Handle null or undefined values
  let val = (value * 1000).toFixed(0).replace(".", ""); // Multiply by 1000
  val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return `${val} دينار عراقي`;
});
Vue.filter("formatNumber2", function (value) {
  if (!value && value !== 0) return "0 IQD"; // Handle null or undefined values
  let val = (value * 1000).toFixed(0).replace(".", ""); // Multiply by 1000
  val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return `${val}`;
});
