<template>
  <v-container fluid class="mt-0">
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        class="pb-8"
        v-if="loading == false"
      >
        <v-form ref="accept_request">
          <v-card class="mx-auto card" width="99%">
            <v-toolbar dark color="#F97316">
              <v-toolbar-title class="mx-auto font-toolbar-title2">
                طلب تدريب</v-toolbar-title
              >
            </v-toolbar>
            <span class="py-4 d-flex justify-center list">
              <v-card-title
                style="
                  background-color: #ffffff;
                  border-radius: 0px 20px 20px 0px !important;
                "
              >
                <h4 style="color: #393c43" class="data_table">
                  <v-btn icon style="background-color: #f97316" class="mr-2">
                    <Icon icon="solar:user-id-bold" color="#ffffff" width="30"
                  /></v-btn>
                  اسم المدرب : {{ info_trainer.full_name }}
                </h4>
              </v-card-title>
              <v-card-title style="background-color: #ffffff">
                <h4 style="color: #393c43" class="data_table">
                  <v-btn icon style="background-color: #f97316" class="mr-2">
                    <Icon icon="solar:calendar-bold" color="#ffffff" width="25"
                  /></v-btn>
                  تاريخ الميلاد: {{ info_trainer.birth_date }}
                </h4>
              </v-card-title>
              <v-card-title style="background-color: #ffffff">
                <h4
                  class="data_table"
                  style="color: #393c43"
                  v-if="info_trainer != null && info_trainer.address != null"
                >
                  <v-btn icon style="background-color: #f97316" class="mr-2">
                    <Icon
                      icon="fluent:home-32-filled"
                      color="#ffffff"
                      width="26"
                  /></v-btn>
                  العنوان : {{ info_trainer.address }}
                </h4>
              </v-card-title>
              <v-card-title style="background-color: #ffffff; border-radius: 20px 0px 0px 20px !important;" >
                <h4 style="color: #393c43" class="data_table">
                  <v-btn icon style="background-color: #f97316" class="mr-2">
                    <Icon
                      icon="flowbite:phone-solid"
                      color="#ffffff"
                      width="26"
                  /></v-btn>
                  رقم الهاتف : {{ info_trainer.phone }}
                </h4>
              </v-card-title>
            </span>
            <v-card class="card mx-auto" width="1200">
              <v-carousel
                hide-delimiters
                class="mt-5 carousel"
                v-if="info_trainer.images.length > 0"
              >
                <v-carousel-item
                  v-for="(image, index) in info_trainer.images"
                  :key="index"
                >
                  <div class="carousel-image-container">
                    <img
                      :src="server + image.url"
                      alt="Trainer Image"
                      class="carousel-image"
                    />
                  </div>
                </v-carousel-item>
              </v-carousel>
              <v-carousel hide-delimiters class="mt-5 carousel" v-else>
                <v-carousel-item>
                  <div class="carousel-image-container">
                    <img
                      src="@/assets/profile.jpeg"
                      alt="Default Profile"
                      class="carousel-image"
                    />
                  </div>
                </v-carousel-item>
              </v-carousel>

              <v-list class="py-8">
                <v-list-item
                  class="list-item-title d-flex justify-center mx-0 px-0"
                >
                  <v-text-field
                    v-model="national_number"
                    append-icon="mdi-card-account-details"
                    label=" قم بأدخال الرقم الوطني للمدرب للموافقة عليه ..."
                    solo
                    type="number"
                    class="data_table"
                    color="#f97316"
                    clearable
                    hide-details
                    :rules="NumberRules"
                  />
                </v-list-item>
              </v-list>
            </v-card>
            <v-card-actions class="d-flex justify-center">
              <v-btn
                large
                style="background-color: #84cc16"
                class="mr-2 btn"
                :loading="accept_loading"
                :disabled="reject_loading"
                @click="acceptRequest(info_trainer.id)"
              >
                <Icon
                  icon="akar-icons:check-box-fill"
                  color="#ffffff"
                  width="26"
                />
                <h3 style="color: #ffffff" class="data_table">موافقة</h3>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon color="white">mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                large
                style="background-color: #ef4444"
                class="mr-2 btn"
                :disabled="accept_loading"
                :loading="reject_loading"
                @click="PopRejectRequest(info_trainer.id)"
              >
                <Icon
                  icon="solar:close-square-bold"
                  color="#ffffff"
                  width="26"
                />
                <h3 style="color: #ffffff" class="data_table">رفض</h3>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon color="white">mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        class="pb-8"
        v-if="loading == true"
      >
        <v-row v-if="loading == true" class="d-flex justify-center">
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-skeleton-loader
              class="mx-auto"
              max-width="510"
              height="350"
              type="image, actions"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <PopMessages
        :value="pop_message"
        v-on:popClose="pop_message = !pop_message"
        v-on:rejectRequest="rejectRequest"
      />
    </v-row>
  </v-container>
</template>
<script>
import PopMessages from "@/components/admin/PopMessages";
export default {
  components: { PopMessages },
  data() {
    return {
      national_number: null,
      pop_message: false,
    };
  },
  created() {
    this.$store.dispatch("admin_requests/get_info_trainer");
  },
  computed: {
    NumberRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) =>
          (value || "").length >= 10 || "الحد الادنى لعدد الارقام هوه 10",
        (value) =>
          (value || "").length < 15 || "الحد الاقصى لعدد الارقام هوه 14",
      ];
    },

    server() {
      return this.$store.state.server;
    },
    info_trainer() {
      return this.$store.state.admin_requests.info_trainer;
    },
    loading() {
      return this.$store.state.admin_requests.info_trainer_loading;
    },
    accept_loading() {
      return this.$store.state.admin_requests.accept_request_loading;
    },
    reject_loading() {
      return this.$store.state.admin_requests.reject_request_loading;
    },
  },
  methods: {
    acceptRequest() {
      let data = {};
      data["trainer_id"] = localStorage.getItem("trainer_id");
      data["national_number"] = this.national_number;
      this.$store.dispatch("admin_requests/acceptRequest", data).then(() => {
        localStorage.setItem("trainer_id", null);
        this.$store.state.trainer_id = null;
        this.$router.push("/home");
      });
    },
    PopRejectRequest() {
      this.pop_message = true;
    },
    rejectRequest(message) {
      let data = {};
      data["trainer_id"] = localStorage.getItem("trainer_id");
      data["message"] = message;
      this.$store.dispatch("admin_requests/rejectRequest", data).then(() => {
        this.pop_message = false;
        localStorage.setItem("trainer_id", null);
        this.$store.state.trainer_id = null;
        this.$router.push("/home");
      });
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
  background-color: #ffffff;
}
.carousel {
  border-radius: 20px !important;
}
.btn {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 25px 174px !important;
}
.list {
  border-radius: 0px 0px 20px 20px !important;
  background-color: #d5d6d7 !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.px-span {
  padding-left: 135px !important;
  padding-right: 135px !important;
}
.carousel {
  max-height: 400px; /* Set maximum carousel height */
}

.carousel-image-container {
  display: flex; /* Center the image */
  justify-content: center;
  align-items: center;
  height: 100%; /* Take full height of the carousel item */
  overflow: hidden; /* Prevent content overflow */
}

.carousel-image {
  max-width: 100%; /* Ensure the image width fits */
  max-height: 100%; /* Ensure the image height fits */
  object-fit: contain; /* Prevent cropping while maintaining aspect ratio */
}
.list-item-title {
  border-color: #f97316 !important;
  border-style: solid;
  border-width: 1px;
  background-color: #ffffff;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
}
</style>
