<template>
  <v-row style="background-color: #fdfdfd">
    <v-col cols="12" sm="12" md="12" lg="12" v-if="loading == false">
      <v-card
        class="mx-auto card"
        width="100%"
        style="background-color: #fdfdfd"
      >
        <v-card-title>
          <h3 class="font-toolbar-title">السجل المالي</h3>
          <v-spacer> </v-spacer>
          <v-col align-self="center" cols="6" sm="6" md="2" lg="2">
            <v-pagination
              v-model="params.page"
              :length="pageCount"
              circle
              color="#f97316"
               class="data_table"
            ></v-pagination>
          </v-col>
          <v-col align-self="center" cols="6" sm="6" md="2" lg="2">
            <v-select
              v-model="params.itemsPerPage"
              :items="items"
              outlined
              rounded
              item-color="orange darken-1"
              color="#f97316"
              single-line
              hide-details
              class="data_table"
            ></v-select>
          </v-col>
          <v-text-field
            v-if="$vuetify.breakpoint.smAndUp"
            v-model="payments_query"
            @input="query_change"
            label="إبحث عن لاعب لرؤية سجله المالي  ..."
            :class="$vuetify.breakpoint.smOnly ? 'mb-2' : ''"
            class="data_table"
            color="#f97316"
            solo
            rounded
            clearable
            hide-details
          />
        </v-card-title>

        <v-divider class="ml-10"></v-divider>
      </v-card>
    </v-col>
    <v-col cols="12" sm="12" md="12" lg="12" v-else>
      <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
      lg="12"
      class="mx-0 px-0"
      v-if="loading == false"
    >
      <v-row class="pa-0 ma-0">
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          class="pb-8"
          v-for="(payment, index) in payments"
          :key="index"
          @click="payment_details(payment.trainee.id)"
        >
          <v-card class="mx-auto card" width="90%" style="cursor: pointer">
            <v-toolbar dark color="#F97316">
              <v-toolbar-title class="mx-auto toolbar-title">
                <h3  class="data_table">
                  {{ payment.trainee.full_name }}
                  <v-avatar size="40">
                    <img
                      src="@/assets/Elift-logo.png"
                      width="40"
                      height="40"
                      alt="error"
                    />
                  </v-avatar>
                </h3>
              </v-toolbar-title>
            </v-toolbar>
            <v-list two-line>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <h4 style="color: #50535b" class="data_table">
                      معرف اللاعب
                    </h4></v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <h4 style="color: #50535b" class="data_table">{{ payment.trainee.phone }}</h4>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <h4 style="color: #50535b" class="data_table">
                      نوع العملية
                    </h4></v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <h4
                    style="color: #50535b" class="data_table"
                    v-if="payment.operation_type == 'subscription'"
                  >
                    اشتراك جديد
                  </h4>
                  <h4 style="color: #50535b" class="data_table" v-else>تجديد اشتراك</h4>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <h4 style="color: #50535b" class="data_table">التاريخ</h4></v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <h4 style="color: #50535b" class="data_table">
                    {{ payment.payment_date }}
                  </h4>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <h4 style="color: #50535b" class="data_table">
                      المبلغ الكلي المستلم
                    </h4></v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <h4 style="color: #50535b" class="data_table">
                    {{ payment.total_amount }}
                  </h4>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-row v-if="loading == true" class="pb-3">
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="4"
        v-for="(n, index) in 6"
        :key="'A' + index"
      >
        <v-card class="mx-auto card" width="88%" style="cursor: pointer">
          <v-skeleton-loader type="card-heading ,image"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="loading == false && payments.length == 0"
      class="pb-3 d-flex justify-center"
    >
      <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center">
        <v-card class="d-flex justify-center mt-11 card" width="88%">
          <v-card-title class="mx-auto">
            <h2 class="data_table">لايوجد سجل مالي للاعبين</h2>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      items: [5, 10, 15, 25, 50, 100],
    };
  },
  created() {
    this.get_payments();
  },
  computed: {
    payments() {
      return this.$store.state.payments.payments;
    },
    loading() {
      return this.$store.state.payments.payments_loading;
    },
    pageCount() {
      return this.$store.state.payments.pageCount;
    },
    params: {
      set(val) {
        this.$store.state.payments.params = val;
      },
      get() {
        return this.$store.state.payments.params;
      },
    },
    payments_query: {
      set(val) {
        this.$store.state.payments.payments_query = val;
      },
      get() {
        return this.$store.state.payments.payments_query;
      },
    },
  },
  methods: {
    get_payments() {
      this.$store.dispatch("payments/get_payments");
    },
    query_change() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.params.page = 1;
        this.get_payments();
      }, 500);
    },
    payment_details(id) {
      localStorage.setItem("trainee_id_payments", id);
      this.$store.commit("payments/setIdTraineeGetPaymentDetails", id);
      this.$router.push('details-playeres-financial-record');
    },
  },

  watch: {
    params: {
      handler() {
        this.get_payments();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 25px !important;
}
.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px !important;
}

.v-application--is-rtl .v-list-item__action:first-child,
.v-application--is-rtl .v-list-item__icon:first-child {
  margin-left: 5px;
}
</style>
