import axios from "axios";
import Vue from "vue";
const meals = {
  namespaced: true,
  state: () => ({
    meals: [],
    meal_plan_id: localStorage.getItem("meal_plan_id") ?? null,
    ingredients: [],
    meals_loading: false,
    add_meals_loading: false,
    delete_meal_loading: false,
    add_ingredient_loading: false,
    edit_meal_loading: false,
    delete_ingredient_loading: false,
    add_meals_plan_loading: false,
    meal_plans_loading: false,
    update_ingredient_loading: false,
    update_time_between_meals_loading: false,
    tab: Number(localStorage.getItem("tab")) || 0,

    trainee_query: "",
    pageCount: 1,

    params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 50,
    },

    timeDifference: parseInt(localStorage.getItem("timeDifference"), 10),
  }),
  mutations: {
    setTimeDifference(state, timeDifference) {
      state.timeDifference = timeDifference;
    },

    setTab(state, tab) {
      state.tab = tab;
    },
    ADD_MEAL(state, meal) {
      state.meals.push(meal);
      state.meals.sort((a, b) => parseInt(a.title) - parseInt(b.title));
    },
    ADD_INGREDIENTS(state, ingredients) {
      let index = state.meals.findIndex((e) => e.id == ingredients[0].meal_id);
      state.meals[index].ingredients = ingredients;
    },

    setIngredients(state, ingredients) {
      state.ingredients = ingredients;
    },
    EDIT_MEAL(state, meal) {
      let index = state.meals.findIndex((e) => e.id === meal.id);
      if (index !== -1) {
        state.meals.splice(index, 1, meal); // Replace the meal at its original index
      }
    },

    UPDATE_INGREDIENT(state, ingredient) {
      const mealIndex = state.meals.findIndex((e) => e.id === ingredient.meal_id);
      if (mealIndex !== -1) {
        const ingredientIndex = state.meals[mealIndex].ingredients.findIndex(
          (e) => e.id === ingredient.id
        );
        if (ingredientIndex !== -1) {
          // Ensure Vue reactivity is maintained
          Vue.set(
            state.meals[mealIndex].ingredients,
            ingredientIndex,
            ingredient
          );
        }
      }
    },

    DELETE_INGREDIENT(state, ingredient) {
      let index = state.ingredients.findIndex((e) => e.id === ingredient.id);
      if (index !== -1) {
        // Update the element in place
        state.ingredients[index] = ingredient;
      }
    },
    DELETE_MEAL(state, meal) {
      let index = state.meals.findIndex((e) => e.id == meal.id);
      state.meals.splice(index, 1);
    },
    GET_MEALS(state, meals) {
      state.meals.splice(0, state.meals.length);
      meals.forEach((element) => {
        state.meals.push(element);
      });
      state.meals.sort((a, b) => parseInt(a.title) - parseInt(b.title));
    },

    DELETE_INGREDIENT_IN_MEAL(state, ingredientId) {
      state.meals = state.meals.map((meal) => {
        return {
          ...meal,
          ingredients: meal.ingredients.filter(
            (ingredient) => ingredient.id !== ingredientId.id
          ),
        };
      });
    },

    ADD_MEALS_PLAN(state, meal_plan) {
      localStorage.setItem("meal_plan_id", meal_plan.id);
      state.meal_plan_id = meal_plan.id;
    },
    GET_MEAL_PLANS(state, meal_plan) {
      localStorage.setItem("meal_plan_id", meal_plan.id);
      state.meal_plan_id = meal_plan.id;
    },
  },
  actions: {
    async reset_table({ state }) {
      state.meals = [];
      state.pageCount = 1;
      state.meals_loading = false;
      state.params = {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 10,
      };
    },

    get_meals({ commit, state, rootState }) {
      state.meals_loading = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;
        let meal_plan_id = "";
        meal_plan_id = `&meal_plan_id=${localStorage.getItem("meal_plan_id")}`;

        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/meals" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            meal_plan_id,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;

            commit("GET_MEALS", response.data.result);

            state.meals_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.meals_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_meal_plans({ commit, state, rootState }) {
      state.meal_plans_loading = true;
      return new Promise((resolve) => {
        let subscribtion_session_id = "";
        subscribtion_session_id = `?subscribtion_session_id=${localStorage.getItem(
          "subscribtion_session_id"
        )}`;

        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/mealPlans" +
            subscribtion_session_id,
          method: "get",
        })
          .then((response) => {
            commit("GET_MEAL_PLANS", response.data.result[0]);

            state.meal_plans_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.meal_plans_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    addMeals({ commit, state, rootState }, data) {
      state.add_meals_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/meal/create",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_MEAL", response.data.result[0]);
            state.add_meals_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_meals_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    addMealPlan({ commit, state, rootState }, data) {
      state.add_meals_plan_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/mealPlan/create",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_MEALS_PLAN", response.data.result[0]);
            state.add_meals_plan_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_meals_plan_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    editMeal({ commit, state, rootState }, data) {
      state.edit_meal_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/meal/update",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("EDIT_MEAL", response.data.result[0]);
            state.edit_meal_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.edit_meal_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    deleteMeals({ commit, state, rootState }, data) {
      state.delete_meal_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/meal/delete",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("DELETE_MEAL", response.data.result[0]);
            state.delete_meal_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.delete_meal_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    deleteIngredientInMeal({ commit, state, rootState }, data) {
      state.delete_ingredient_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/meals/ingredients/delete",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "delete",
        })
          .then((response) => {
            commit("DELETE_INGREDIENT_IN_MEAL", response.data.result[0]);
            state.delete_ingredient_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.delete_ingredient_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    addIngredients({ commit, state, rootState }, data) {
      state.add_ingredient_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/meals/ingredients/create",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_INGREDIENTS", response.data.result);
            state.add_ingredient_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_ingredient_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    deleteIngredient({ commit, state, rootState }, data) {
      state.delete_ingredient_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/meals/ingredients/delete",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "delete",
        })
          .then((response) => {
            commit("DELETE_INGREDIENT", response.data.result[0]);
            state.delete_ingredient_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.delete_ingredient_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    updateTimeBetweenMeals({ commit, state, rootState }, data) {
      state.update_time_between_meals_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/changing/meal/times",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("GET_MEALS", response.data.result);
            state.update_time_between_meals_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.update_time_between_meals_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default meals;
