<template>
  <v-col cols="12" sm="12" md="12" lg="12" class="">
    <v-row class="d-flex justify-center" v-if="loading == false">
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="3"
        class="px-4 mx-auto"
        v-for="(option, index) in statistics"
        :key="index"
      >
        <v-card class="card" width="100%" height="100%">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="mt-2">
                <h2 class="data_table">{{ option.number }}</h2>
              </v-list-item-title>
              <v-list-item-subtitle class="mb-3">
                <h3 class="data_table">{{ option.title }}</h3></v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-avatar tile size="55">
              <Icon :icon="option.icon" :color="option.color" width="30" />
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center py-5" v-else-if="loading == true">
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="3"
        class="px-2 mx-auto"
        v-for="(n, index) in 4"
        :key="'A' + index"
      >
        <v-skeleton-loader type="card" height="100"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    this.getStatistics();
  },
  computed: {
    statistics() {
      return this.$store.state.training_requests.statistics;
    },
    loading() {
      return this.$store.state.training_requests.loading_statistics;
    },
  },
  methods: {
    getStatistics() {
      this.$store.dispatch("training_requests/get_statistics");
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px;
  background-color: #f3f3f4;
}
</style>
