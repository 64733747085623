<template>
  <v-container class="login" fluid>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-form ref="login" @keyup.enter="login">
          <v-card class="card" width="1200">
            <v-row class="justify-center">
              <v-col cols="12" sm="8" md="5" lg="5" class="justify-center">
                <v-card-title class="d-flex justify-center mt-0 py-0">
                  <v-avatar size="100">
                    <img
                      src="@/assets/Elift-logo.png"
                      width="70"
                      height="70"
                      alt="error"
                    />
                  </v-avatar>
                </v-card-title>
                <v-card-title class="d-flex justify-center mt-0">
                  <h3 style="color: #f97316" class="font-semiBold">
                    قم بتسجيل الدخول
                  </h3>
                </v-card-title>
                <v-card-text class="mt-3 py-0">
                  <h3 class="mb-1 mr-2 data_table">رقم الهاتف</h3>
                  <Input
                    @update-value="number_phone = $event"
                    :value="number_phone"
                    type="text"
                    label="رقم الهاتف"
                    icon="mdi-phone"
                    :rules="NumberPhonelRules"
                  />

                  <h3 class="mb-1 mt-4 mr-2 data_table">كلمة المرور</h3>
                  <v-text-field
                    color="#F97316"
                    v-model="password"
                    solo
                    style="border-radius: 19px"
                    clearable
                    class="data_table pt-1 password"
                    :type="showPassword ? 'text' : 'password'"
                    label="كلمة المرور"
                    prepend-inner-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    :rules="PasswordsRules"
                    @keyup.enter="login"
                  />
                </v-card-text>

                <v-card-actions class="mt-n3">
                  <v-btn
                    @click="login"
                    :loading="loading_login"
                    color="#F97316"
                    large
                    block
                    class="px-10 py-7 mx-auto"
                    style="
                      box-shadow: 0px 0px 0px 0px !important;
                      border-radius: 20px;
                    "
                    elevation="4"
                  >
                    <v-avatar size="25">
                      <v-img src="@/assets/arrow.png"> </v-img>
                    </v-avatar>
                    <h3 class="mr-1 ml-4 data_table" style="color: white">
                      تسجيل دخول
                    </h3>
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon color="white">mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-card-actions>
                <v-card-actions class="d-flex justify-center">
                  <h4 class="data_table">ليس لديك حساب ?</h4>
                  <h4 class="link-h4 mr-2 data_table" @click="redirect">
                    قم بإنشاء حساب
                  </h4>
                </v-card-actions>
                <v-card-actions class="d-flex justify-center">
                  <h4 class="link-h4 mr-2 data_table" @click="forgot_password">
                    نسيت كلمة المرور
                  </h4>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-col>
      <v-col cols="12" sm="8" md="5" lg="5" class="justify-center">
        <v-card class="card">
          <v-card-title class="d-flex justify-center my-0 py-0">
            <img
              class="footer-login"
              src="@/assets/footer-login.png"
              width="190"
              height="100"
              alt="error"
            />
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Input from "@/components/layout/Input.vue";
export default {
  components: { Input },
  data: () => ({
    number_phone: null,
    password: null,
    showPassword: false,
    contact_us: false,
  }),
  computed: {
    NumberPhonelRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) =>
          (value || "").length >= 11 || "الحد الادنى لعدد الارقام هوه 11",
        (value) =>
          (value || "").length < 12 || "الحد الاقصى لعدد الارقام هوه 11",
      ];
    },
    PasswordsRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 6 || "الحد الادنى لعدد الاحرف هوه 6",
      ];
    },

    loading_login() {
      return this.$store.state.loading_login;
    },
  },
  methods: {
    login() {
      if (this.$refs.login.validate()) {
        let data = {};
        data["phone"] = this.number_phone;
        data["password"] = this.password;
        this.$store.dispatch("login", data).then(() => {
          // this.$router.push({ name: "home" });
        });
      }
    },
    redirect() {
      this.$router.push({ name: "register" });
    },
    forgot_password() {
      this.$router.push({ name: "ForgotPassword" });
    },
    ContactUs() {
      this.contact_us = true;
    },
  },
};
</script>
<style scoped>
.footer-login {
  border-radius: 24px;
}

.card {
  margin: auto;
  border-radius: 15px;
  font-family: "Alexandria", sans-serif !important;
  box-shadow: 0px 0px !important;
}
.img {
  border-radius: 15px;
}
.it-logo {
  margin-top: 5px !important;
  margin-bottom: 120px;
}
.it {
  margin-bottom: 120px;
}
.card-img {
  background: rgba(255, 255, 255, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.7px);
  -webkit-backdrop-filter: blur(8.7px);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  margin-top: 100px;
}
.link-h4 {
  cursor: pointer;
  text-decoration: underline;
  color: #f97316;
}
</style>
