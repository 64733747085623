import axios from "axios";
const admin_payments = {
  namespaced: true,
  state: () => ({
    trainers_payments: [],
    details_payments: [],
    payments_today: null,
    trainers_payments_loading: false,
    payments_today_loading: false,
    details_payments_loading: false,
    mark_all_received_loading: false,
    mark_received_loading: false,
    _loading: false,
    download_pdf_loading: false,
    download_pdf2_loading: false,
    pageCount: 1,
    trainer_name: "",
    trainer_id: localStorage.getItem("trainer_id_payments") || "",
    previous_trainer_id: null,
    filter_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    date_from: null,
    date_to: null,
    params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
    params_details: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
    payment_state: "done",

    sort: null,
  }),
  mutations: {
    GET_TRAINERS_PAYMENTS(state, payments) {
      state.trainers_payments.splice(0, state.trainers_payments.length);
      payments.forEach((element) => {
        state.trainers_payments.push(element);
      });
    },
    CHEANGE_TRAINERS_PAYMENT(state, data) {
      let index = state.trainers_payments.findIndex(
        (m) => m.trainer_id === data.trainer_id
      );
      if (index !== -1) {
        // Update the element in place
        state.trainers_payments[index].is_received = 1;
        state.trainers_payments[index].total_app_subscription_amount = 0;
      }
    },
    setIdTrainerGetPaymentDetails(state, trainer_id) {
      state.trainer_id = trainer_id;
    },

    GET_PAYMENTS_DETAILS_DREPDWON(state, payment) {
      if (payment.length === 0) {
        state.payment_state = "finished";
        if (state.params_details.page > 1) state.params_details.page -= 1;
        return;
      }

      state.params_details.page += 1;
      const newRequests = payment.filter(
        (payment) => !state.details_payments.some((r) => r.id === payment.id)
      );

      state.details_payments.push(...newRequests);

      state.payment_state = "done";
    },
    GET_PAYMENTS_DETAILS(state, payments) {
      state.details_payments.splice(0, state.details_payments.length);
      payments.forEach((element) => {
        state.details_payments.push(element);
      });
    },

    CHEANGE_TRAINER_PAYMENT(state, payment) {
      let index = state.details_payments.findIndex((m) => m.id === payment.id);
      if (index !== -1) {
        // Update the element in place
        state.details_payments[index].is_received = 1;
      }
    },

    GET_PAYMENTS_TODAY(state, payments) {
      state.payments_today = payments;
    },
  },
  actions: {
    async details_payments_table({ state }) {
      state.details_payments = [];
      state.details_payments_loading = false;
      state.date_from = null;
      state.date_to = null;
      state.sort = null;
      state.params_details = {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 10,
      };
    },

    get_trainers_payments({ commit, state, rootState }) {
      state.trainers_payments_loading = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        let trainer_name = "";
        if (
          state.trainer_name != undefined &&
          state.trainer_name != null &&
          state.trainer_name.length > 0
        )
          trainer_name = `&trainer_name=${state.trainer_name}`;
        axios({
          url:
            `${rootState.server}` +
            "/api/admin/getTrainersPayment" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            trainer_name,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;

            commit("GET_TRAINERS_PAYMENTS", response.data.result[0]);

            state.trainers_payments_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.trainers_payments_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_details_payments({ commit, state, rootState }) {
      state.details_payments_loading = true;
      let data = state.params_details;

      if (state.trainer_id !== state.previous_trainer_id) {
        state.details_payments = []; // Clear existing data
        state.params_details.page = 1; // Reset the page to 1
        state.previous_trainer_id = state.trainer_id; // Store the current trainer_id to detect changes
      }

      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        // filter date
        var filter_date = "";
        if (state.date_from != null) {
          if (state.date_to == null || state.date_to == undefined) {
            state.date_to = state.date_from;
          } else if (state.date_to < state.date_from) {
            state.date_to = state.date_from;
          }
          let date = { start_date: state.date_from, end_date: state.date_to };
          filter_date = "&filter_date=" + JSON.stringify(date);
        }

        // sort
        var sort = "";
        if (state.sort != null) {
          sort = `&sort=${state.sort}`;
        }

        let trainer_id = "";
        if (
          state.trainer_id &&
          state.trainer_id !== null &&
          state.trainer_id !== ""
        ) {
          trainer_id = `&trainer_id=${state.trainer_id}`;
        }

        axios({
          url:
            `${rootState.server}` +
            "/api/admin/getTrainerPaymentsDetails" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            trainer_id +
            filter_date +
            sort,
          method: "get",
        })
          .then((response) => {
            commit("GET_PAYMENTS_DETAILS_DREPDWON", response.data.result);

            state.details_payments_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.details_payments_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_payments_today({ commit, state, rootState }) {
      state.payments_today_loading = true;
      return new Promise((resolve) => {
        var filter_date = "";
        if (state.filter_date != null) {
          filter_date = "?filter_date=" + state.filter_date;
        }

        axios({
          url:
            `${rootState.server}` +
            "/api/admin/getPaymentStatistics" +
            filter_date,
          method: "get",
        })
          .then((response) => {
            commit("GET_PAYMENTS_TODAY", response.data.result);

            state.payments_today_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.payments_today_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    mark_all_received({ commit, state, rootState }, data) {
      state.mark_all_received_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/payments/mark-all-received",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("CHEANGE_TRAINERS_PAYMENT", data);

            state.mark_all_received_loading = false;

            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch(() => {
            state.mark_all_received_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    mark_received({ commit, state, rootState }, data) {
      state.mark_received_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/payments/mark-received",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("CHEANGE_TRAINER_PAYMENT", response.data.result[0]);

            state.mark_received_loading = false;

            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.mark_received_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    download_pdf_payments({ commit, state, rootState }, data) {
      state.download_pdf_loading = true;
      return new Promise((resolve) => {
        // sort
        var sort = "";
        if (state.sort != null) {
          sort = `?sort=${state.sort}`;
        }

        // filter date
        var filter_date = "";
        if (state.date_from != null) {
          if (state.date_to == null || state.date_to == undefined) {
            state.date_to = state.date_from;
          } else if (state.date_to < state.date_from) {
            state.date_to = state.date_from;
          }
          let date = { start_date: state.date_from, end_date: state.date_to };
          filter_date = "&filter_date=" + JSON.stringify(date);
        }

        axios({
          url:
            `${rootState.server}` +
            "/api/admin/download_pdf_payments" +
            sort +
            filter_date,
          data: data,
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            const pdfBlob = response.data;

            const link = document.createElement("a");
            const url = URL.createObjectURL(pdfBlob);

            link.href = url;
            const now = new Date();
            const timestamp = now.toISOString().replace(/[:.]/g, "-"); // Format the timestamp
            // Generate the filename
            const filename = `${timestamp}_payments.pdf`;
            link.download = filename; // Customize file name
            link.click();
            // Cleanup after download
            URL.revokeObjectURL(url);
            state.download_pdf_loading = false;

            resolve(response);
          })
          .catch(() => {
            state.download_pdf_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    download_pdf_payment({ commit, state, rootState }, data) {
      state.download_pdf2_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/download_pdf_payment",
          data: data,
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            const pdfBlob = response.data;

            const link = document.createElement("a");
            const url = URL.createObjectURL(pdfBlob);

            link.href = url;
            const now = new Date();
            const timestamp = now.toISOString().replace(/[:.]/g, "-"); // Format the timestamp
            // Generate the filename
            const filename = `${timestamp}_payments.pdf`;
            link.download = filename; // Customize file name
            link.click();
            // Cleanup after download
            URL.revokeObjectURL(url);
            state.download_pdf2_loading = false;

            resolve(response);
          })
          .catch(() => {
            state.download_pdf2_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default admin_payments;
