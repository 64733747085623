<template>
  <v-row>
    <v-col cols="12" sm="12" md="12" lg="12" class="pb-8 mt-3">
      <v-card class="mx-auto card" width="98%">
        <v-toolbar dark color="#F97316">
          <v-card-actions class="mt-2 pb-5">
            <v-btn icon fab color="#C62828" x-small v-on:click="go_back">
              <Icon
                class="icon_download"
                icon="ic:outline-close"
                color="#ffffff"
                width="25"
              />
            </v-btn>
          </v-card-actions>
          <!-- <v-toolbar-title
            class="mx-auto font-toolbar-title2"
            v-if="loading == false"
          >
            السجل المالي [{{ details_payments.trainer_name }}]
          </v-toolbar-title>
          <v-toolbar-title class="mx-auto font-toolbar-title2" v-else>
            السجل المالي [ ...... ]
          </v-toolbar-title> -->
        </v-toolbar>
        <v-list
          two-line
          class="list"
          style="max-height: 550px; min-height: 550px; overflow-y: auto"
          v-if="loading == false"
          @scroll.native="onScroll"
          ref="scrollContainer"
        >
          <v-list-item class="list-item-filter mt-2 mx-2">
            <v-list-item-title class="d-flex justify-start">
              <span style="display: flex; align-items: center">
                <h4 style="color: #50535b" class="data_table ml-2">البحث من</h4>

                <InputDate
                  @update-value="date_from = $event"
                  @clearInput="date_from = null"
                  :value="date_from"
                  color="#f97316"
                  label="ادخل التاريخ"
                />
              </span>
              <span style="display: flex; align-items: center">
                <h4 style="color: #50535b" class="data_table ml-2 mr-8">إلى</h4>

                <InputDate
                  @update-value="date_to = $event"
                  @clearInput="date_to = null"
                  :value="date_to"
                  label="ادخل التاريخ"
                  color="#f97316"
                />
              </span>
              <span style="display: flex; align-items: center" class="mr-16">
                <Selects
                  @update-value="subscription_type = $event"
                  :value="subscription_type"
                  color="#f97316"
                  itemValue="value"
                  itemText="type"
                  :items="types_subscriptions"
                  label="نوع الاشتراك"
                  class="mx-5"
                />

                <Selects
                  @update-value="asc_desc = $event"
                  :value="asc_desc"
                  color="#f97316"
                  itemValue="value"
                  itemText="type"
                  :items="ascending_descending"
                  label="تصاعدي"
                  class="mx-8"
                />

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="reset_table"
                      fab
                      icon
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <Icon
                        icon="solar:smartphone-update-bold"
                        color="#f97316"
                        width="32"
                      />
                    </v-btn>
                  </template>
                  <span>تحديث معلومات الجدول</span>
                </v-tooltip>

                <v-btn
                  icon
                  @click="download_pdf(details_payments[0].trainer_id)"
                  :loading="loading2"
                  style="
                    border-radius: 13px;
                    box-shadow: 0px 0px 0px 0px !important;
                  "
                  class="py-5 px-5 mr-5 btn-download-pdf"
                >
                  <Icon
                    icon="octicon:download-16"
                    class="btn-icon"
                    color="#f97316"
                    width="26"
                  />
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon color="#f97316" class="btn-icon"
                        >mdi-cached</v-icon
                      >
                    </span>
                  </template>
                </v-btn>
              </span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="list-item-filter2 pt-0 mt-0 mx-2">
            <v-btn
              @click="search"
              block
              x-large
              class="btn"
              color="#f97316"
              style="
                border-radius: 16px;
                box-shadow: 0px 0px 0px 0px !important;
              "
            >
              <h3 style="color: #ffffff" class="data_table">بحث</h3>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-list-item>

          <v-list-item
            v-for="(payment, index) in details_payments"
            :key="index"
            class="list-item my-2 mx-2"
          >
            <v-list-item-title class="d-flex justify-start">
              <span style="display: flex; align-items: center">
                <h4 style="color: #50535b" class="data_table">نوع العملية :</h4>
                <h4
                  class="data_table"
                  style="color: #393c43"
                  v-if="payment.operation_type == 'subscription'"
                >
                  اشتراك جديد
                </h4>
                <h4 style="color: #393c43" class="data_table" v-else>
                  تجديد اشتراك
                </h4></span
              >
            </v-list-item-title>
            <v-list-item-title class="d-flex justify-center">
              <span style="display: flex; align-items: center">
                <h4 style="color: #50535b" class="data_table">التاريخ :</h4>
                <h4 style="color: #393c43" class="data_table">
                  {{ payment.payment_date }}
                </h4></span
              >
            </v-list-item-title>
            <v-list-item-title class="d-flex justify-end">
              <span style="display: flex; align-items: center">
                <h4 style="color: #50535b" class="data_table">
                  السعر الاجمالي :
                </h4>
                <h4 style="color: #393c43" class="data_table">
                  {{ payment.total_amount | formatNumber2 }}
                </h4>

                <v-btn
                  @click="
                    download_pdf_payment(payment.id, payment.trainer_id, index)
                  "
                  :loading="loading4 == true && checkLoading4 == index"
                  icon
                  style="
                    border-radius: 13px;
                    box-shadow: 0px 0px 0px 0px !important;
                  "
                  class="px-5 py-5 mr-5 btn-download-pdf"
                >
                  <Icon
                    icon="octicon:download-16"
                    class="btn-icon"
                    color="#f97316"
                    width="26"
                  />
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon color="#f97316" class="btn-icon"
                        >mdi-cached</v-icon
                      >
                    </span>
                  </template>
                </v-btn>

                <v-btn
                  icon
                  @click="clearing_cash_payment(payment.id, index)"
                  :loading="loading3 == true && checkLoading == index"
                  :disabled="payment.is_received == 1"
                  style="
                    box-shadow: 0px 0px 0px 0px !important;
                    border-radius: 13px;
                  "
                  :class="
                    payment.is_received == 1 ? 'disabled-btn' : 'btn-payment'
                  "
                  class="mr-2 px-5 btn py-5"
                >
                  <Icon
                    icon="mingcute:delete-3-fill"
                    :class="
                      payment.is_received == 1 ? 'disabled-class' : 'btn-icon'
                    "
                    color="#ef4444"
                    width="26"
                  />
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon color="#ef4444" class="btn-icon"
                        >mdi-cached</v-icon
                      >
                    </span>
                  </template>
                </v-btn>
              </span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list v-else style="max-height: 550px; overflow-y: auto" class="list">
          <v-list-item
            v-for="(n, index) in 5"
            :key="'A' + index"
            class="list-item py-1 my-2 mx-2"
          >
            <v-skeleton-loader
              class="mx-auto"
              width="100%"
              type="list-item-avatar-two-line"
            ></v-skeleton-loader>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import InputDate from "@/components/layout/InputDate.vue";
import Selects from "@/components/layout/Selects.vue";
export default {
  components: { InputDate, Selects },
  data() {
    return {
      date_from: "",
      date_to: "",

      asc_desc: null,
      subscription_type: null,
      types_subscriptions: [
        { type: "اعتيادي", value: 0 },
        { type: "ذهبي", value: 1 },
      ],
      ascending_descending: [
        { type: "تصاعدي", value: "asc" },
        { type: "تنازلي", value: "desc" },
      ],
      checkLoading: null,
      checkLoading4: null,
    };
  },
  created() {
    this.get_details_payments();
  },
  computed: {
    details_payments() {
      return this.$store.state.admin_payments.details_payments;
    },
    loading() {
      return this.$store.state.admin_payments.details_payments_loading;
    },
    loading2() {
      return this.$store.state.admin_payments.download_pdf_loading;
    },
    loading3() {
      return this.$store.state.admin_payments.mark_received_loading;
    },
    loading4() {
      return this.$store.state.admin_payments.download_pdf2_loading;
    },
    pageCount() {
      return this.$store.state.admin_payments.pageCount2;
    },
  },
  methods: {
    get_details_payments() {
      this.$store.dispatch("admin_payments/get_details_payments");
    },

    onScroll(event) {
      var payment_state = this.$store.state.admin_payments.payment_state;
      const container = event.target;
      const isScrollingDown = container.scrollTop > this.lastScrollTop;
      this.lastScrollTop = container.scrollTop;
      if (
        isScrollingDown &&
        container.scrollTop + container.clientHeight >=
          container.scrollHeight - 50 &&
        !this.loading &&
        payment_state == "done"
      ) {
        this.get_details_payments();
      }
    },
    go_back() {
      this.$router.push("/admin/financial-record-trainers");
    },

    search() {
      if (this.date_from != null && this.date_from.length > 0) {
        this.$store.state.admin_payments.date_from = this.date_from;
        this.$store.state.admin_payments.date_to = this.date_to;
      }

      this.$store.state.admin_payments.sort = this.asc_desc.value;
      this.$store.state.admin_payments.params_details.page = 1;
      this.$store.state.admin_payments.params_details.itemsPerPage = 100;
      this.$store.state.admin_payments.details_payments = [];
      this.get_details_payments();
    },

    reset_table() {
      this.$store.dispatch("admin_payments/details_payments_table");
      this.get_details_payments();
      this.date_to = null;
      this.date_from = null;
    },

    download_pdf(trainer_id) {
      if (this.date_from != null && this.date_from.length > 0) {
        this.$store.state.admin_payments.date_from = this.date_from;
        this.$store.state.admin_payments.date_to = this.date_to;
      }

      this.$store.state.admin_payments.sort = this.asc_desc.value;

      let data = {};
      data["trainer_id"] = trainer_id;
      this.$store.dispatch("admin_payments/download_pdf_payments", data);
    },
    download_pdf_payment(payment_id, trainer_id, index) {
      this.checkLoading4 = index;
      let data = {};
      data["payment_id"] = payment_id;
      data["trainer_id"] = trainer_id;
      this.$store.dispatch("admin_payments/download_pdf_payment", data);
    },

    clearing_cash_payment(payment_id, index) {
      this.checkLoading = index;
      let data = {};
      data["payment_id"] = payment_id;
      this.$store.dispatch("admin_payments/mark_received", data);
    },
  },

  watch: {
    params: {
      handler() {
        this.get_details_payments();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.card {
  background-color: #f3f3f4 !important; /*f3f3f4*/
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 25px !important;
}
.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px !important;
}

.list-item {
  background-color: #f3f3f4;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 16px !important;
}
.list-item-filter {
  background-color: #ffffff;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 16px 16px 0px 0px !important;
}
.list-item-filter2 {
  background-color: #ffffff;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 0px 0px 16px 16px !important;
}
.list {
  background-color: #d5d6d7;
}
.btn-download-pdf {
  background-color: #ffffff !important;
  border: 1px solid #f97316;
  transition: all 0.4s ease;
}
.btn-download-pdf:hover {
  background-color: #f97316 !important;
}
.btn-download-pdf:hover .btn-icon {
  color: #ffffff !important;
}

.btn-payment {
  background-color: #ffffff !important;
  border: 1px solid #ef4444;
  transition: all 0.4s ease;
}

.btn-payment:hover {
  background-color: #ef4444 !important;
}

.btn-payment .btn-icon {
  color: #ef4444;
  transition: color 0.4s ease;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #d1d1d1 !important;
  border: 1px solid #ffffff !important;
}

.btn-payment:hover .btn-icon {
  color: #ffffff !important;
}
.disabled-class {
  color: #ffffff !important;
}
.disabled-btn {
  background-color: #d1d1d1 !important;
  border: 1px solid #ffffff !important;
}
</style>
