<template>
  <v-row>
    <v-col
      cols="12"
      sm="12"
      md="12"
      lg="12"
      class="pb-10"
      v-if="loading == false"
    >
      <v-card class="mx-auto card" width="100%">
        <v-card-title>
          <h3 class="font-toolbar-title">السجل المالي</h3>
        </v-card-title>
        <v-divider class="ml-10"></v-divider>
        <v-card-title class="mt-1 d-flex justify-center">
          <span style="display: flex; align-items: center">
            <Icon
              class="icon_download"
              icon="solar:user-bold"
              color="#F97316"
              width="25"
            />
            <h4
              class="d-flex justify-center data_table mr-2"
              style="color: #50535b"
            >
              عدد الالعبين المسجلين : {{ payments_today.trainee_count }}
            </h4>
          </span>
        
          <span style="display: flex; align-items: center" class="mx-10">
            <v-img width="200" src="@/assets/StepsHorizontal.png"> </v-img>
          </span>

          <span style="display: flex; align-items: center">
            <Icon
              class="icon_download"
              icon="solar:user-bold"
              color="#F97316"
              width="25"
            />
            <h4
              class="d-flex justify-center data_table mr-2"
              style="color: #50535b"
            >
              عدد الالعبين المسجلين : {{ payments_today.trainer_count }}
            </h4>
          </span>
        </v-card-title>
        <v-card-title class="mt-1 d-flex justify-center">
          <v-date-picker
            v-model="filter_date"
            full-width
            locale="ar-iq"
            :first-day-of-week="6"
            color="#F97316"
            class="mx-5 d-flex justify-center data_table"
          ></v-date-picker>
        </v-card-title>

        <h3 class="d-flex justify-center mt-3">
          <v-btn
            color="#F97316"
            style="border-radius: 10px !important"
            @click="redirect"
          >
            
            <h3 style="color: #ffffff" class="data_table ml-2">عرض السجل المالي</h3>
            <Icon
              class="icon_download"
              icon="fluent:payment-28-filled"
              color="#ffffff"
              width="25"
            />
          </v-btn>
        </h3>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
      lg="12"
      class="pb-8"
      v-if="loading == false"
    >
      <v-card class="mx-auto card" width="100%">
        <v-list two-line>
          <v-list-item>
            <v-list-item-action>
              <Icon
                class="icon_download"
                icon="solar:user-bold"
                color="#F97316"
                width="25"
              />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <h3 style="color: #50535b" class="font-semiBold">
                  عدد المدربين المحدثين اليوم :
                </h3></v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-action>
              <h3 style="color: #50535b" class="font-semiBold">
                {{ payments_today.trainee_count }}
              </h3>
            </v-list-item-action>
          </v-list-item>

          <template v-for="(membership, index) in payments_today.membership_statistics">
            <v-list-item :key="index">
              <template>
                <v-list-item-action>
                  <Icon
                    class="icon_download"
                    icon="proicons:dollar"
                    color="#F97316"
                    width="25"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <h3 style="color: #50535b" class="font-semiBold">
                      {{ membership.title }}
                    </h3></v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <h3 style="color: #f28218" class="font-semiBold">
                    {{ membership.total_received | formatNumber2 }}
                  </h3>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider v-if="index < payments_today.membership_statistics.length" :key="index" class="mx-8"></v-divider>
          </template>
        </v-list>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
      lg="12"
      class="mt-2 px-10"
      v-if="loading == true"
    >
      <v-skeleton-loader
        type="card-heading ,image,article,article"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  created() {
    this.get_payments_today();
  },
  computed: {
    payments_today() {
      return this.$store.state.admin_payments.payments_today;
    },

    loading() {
      return this.$store.state.admin_payments.payments_today_loading;
    },

    filter_date: {
      set(val) {
        this.$store.state.admin_payments.filter_date = val;
      },
      get() {
        return this.$store.state.admin_payments.filter_date;
      },
    },
  },
  methods: {
    get_payments_today() {
      this.$store.dispatch("admin_payments/get_payments_today");
    },
    redirect() {
      this.$router.push("/admin/financial-record-trainers");
    },
  },
  watch: {
    filter_date: {
      handler() {
        this.get_payments_today();
      },
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
}
.v-application--is-rtl .v-list-item__action:first-child,
.v-application--is-rtl .v-list-item__icon:first-child {
  margin-left: 5px;
}
.list-item-m {
  background-color: #f97300;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 25px !important;
}
@media (min-width: 390px) and (max-width: 428px) {
  .input-settings23 {
    padding: 0px 150px 0px 0px;
  }
}
@media (min-width: 429px) and (max-width: 767px) {
  .input-settings23 {
    padding: 0px 50px 0px 0px;
  }
}

@media (min-width: 768px) {
  .input-settings23 {
    padding: 0px 100px 0px 0px;
  }
}

/* Large screens (desktop) */
@media (min-width: 1024px) {
  .input-settings23 {
    padding: 0px 250px 0px 0px;
  }
}

/* Extra large screens */
@media (min-width: 1440px) {
  .input-settings23 {
    padding: 0px 250px 0px 0px;
  }
}
</style>
