import axios from "axios";
const payments = {
  namespaced: true,
  state: () => ({
    payments: [],
    details_payments: [],
    payments_today: null,
    payments_loading: false,
    payments_today_loading: false,
    details_payments_loading: false,
    pageCount: 1,
    payments_query: "",
    trainee_id: localStorage.getItem("trainee_id_payments") || "",
    previous_trainee_id:null,
    filter_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
    params_details: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
    payment_state: "done",
  }),
  mutations: {
    GET_PAYMENTS(state, payments) {
      state.payments.splice(0, state.payments.length);
      payments.forEach((element) => {
        state.payments.push(element);
      });
    },
    setIdTraineeGetPaymentDetails(state, trainee_id) {
      state.trainee_id = trainee_id;
    },

    GET_PAYMENTS_DETAILS_DREPDWON(state, payment) {
      if (payment.length === 0) {
        state.payment_state = "finished";
        if (state.params_details.page > 1) state.params_details.page -= 1;
        return;
      }

      state.params_details.page += 1;
      const newRequests = payment.filter(
        (payment) => !state.details_payments.some((r) => r.id === payment.id)
      );

      state.details_payments.push(...newRequests);

      state.payment_state = "done";
    },

    GET_PAYMENTS_TODAY(state, payments) {
      state.payments_today = payments;
    },
  },
  actions: {
    async reset_table({ state }) {
      state.payments = [];
      state.pageCount = 1;
      state.payments_loading = false;
      state.params = {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 10,
      };
    },

    get_payments({ commit, state, rootState }) {
      state.payments_loading = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        let trainee_name = "";
        if (
          state.payments_query != undefined &&
          state.payments_query != null &&
          state.payments_query.length > 0
        )
          trainee_name = `&trainee_name=${state.payments_query}`;
        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/payments/trainer" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            trainee_name,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;

            commit("GET_PAYMENTS", response.data.result);

            state.payments_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.payments_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_details_payments({ commit, state, rootState }) {
      state.details_payments_loading = true;
      let data = state.params_details;

      if (state.trainee_id !== state.previous_trainee_id) {
        state.details_payments = []; // Clear existing data
        state.params_details.page = 1; // Reset the page to 1
        state.previous_trainee_id = state.trainee_id; // Store the current trainer_id to detect changes
      }
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        let trainee_id = "";
        if (
          state.trainee_id &&
          state.trainee_id !== null &&
          state.trainee_id !== ""
        ) {
          trainee_id = `&trainee_id=${state.trainee_id}`;
        }

        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/payments/byTrainee" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            trainee_id,
          method: "get",
        })
          .then((response) => {
            commit("GET_PAYMENTS_DETAILS_DREPDWON", response.data.result);

            state.details_payments_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.details_payments_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_payments_today({ commit, state, rootState }) {
      state.payments_today_loading = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        var filter_date = "";
        if (state.filter_date != null) {
          filter_date = "&filter_date=" + state.filter_date;
        }

        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/payments/today" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            filter_date,
          method: "get",
        })
          .then((response) => {
            commit("GET_PAYMENTS_TODAY", response.data.result);

            state.payments_today_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.payments_today_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default payments;
