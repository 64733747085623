<template>
  <v-container fluid class="mt-0">
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" class="pb-6">
        <v-card class="mx-auto mt-8 card-table" width="100%">
          <v-row class="d-flex justify-center mb-9">
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-data-table
                :headers="headers"
                :options.sync="pagination"
                :items="courses"
                :page.sync="pagination.page"
                :items-per-page="pagination.itemsPerPage"
                hide-default-footer
                :loading="loading"
                loading-text="جاري التحميل يرجى الأنتظار"
                height="600"
                :mobile-breakpoint="0"
                disable-sort
              >
                <template v-slot:top>
                  <v-toolbar flat class="mt-2 mb-2 toolbar">
                    <v-toolbar-title class="font-toolbar-title py-1"
                      >جدول تمارين اخرى والكارديو</v-toolbar-title
                    >
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="reset_table"
                          fab
                          icon
                          x-small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <Icon
                            icon="solar:smartphone-update-bold"
                            color="#f28218"
                            width="32"
                          />
                        </v-btn>
                      </template>
                      <span>تحديث معلومات الجدول</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="
                            courses_length != 0
                              ? add_exercise()
                              : go_to_exercises()
                          "
                          fab
                          icon
                          x-small
                          v-bind="attrs"
                          v-on="on"
                          class="mr-3 mt-1"
                        >
                          <Icon
                            icon="hugeicons:equipment-gym-03"
                            color="#f28218"
                            width="33"
                          />
                        </v-btn>
                      </template>
                      <span>اضافة تمرين</span>
                    </v-tooltip>
                  </v-toolbar>
                </template>

                <template v-slot:item="{ item, index }">
                  <tr>
                    <td
                      class="text-start font-weight-black"
                      style="color: #50535b"
                    >
                      <h3 class="data_table">
                        <span style="color: #f97300">{{ index + 1 }}.</span>
                        {{ item.title }}
                      </h3>
                    </td>

                    <td class="text-center font-weight-black py-3">
                      <v-sheet
                        color="#f28218"
                        class="mx-auto py-1 sheet"
                        height="45"
                        width="145"
                      >
                        <v-btn
                          @click="change_is_after(item, 0)"
                          style="border-radius: 12px !important"
                          :class="
                            item.is_after == 0
                              ? 'selected-btn'
                              : 'selected-btn-off'
                          "
                        >
                          <h3 class="data_table">قبل</h3>
                        </v-btn>
                        <v-btn
                          @click="change_is_after(item, 1)"
                          style="border-radius: 12px !important"
                          :class="
                            item.is_after == 1
                              ? 'selected-btn'
                              : 'selected-btn-off'
                          "
                        >
                          <h3 class="data_table">بعد</h3>
                        </v-btn>
                      </v-sheet>
                    </td>

                    <td
                      class="text-center font-weight-black"
                      style="direction: ltr"
                    >
                      <template v-for="(digit, index) in item.course_length">
                        <v-btn
                          :key="index"
                          class="mx-2"
                          style="box-shadow: 0px 0px 0px 0px !important"
                          :color="
                            Array.isArray(item.count_of_day) &&
                            item.count_of_day.includes(digit)
                              ? '#F97300'
                              : '#F3F3F4'
                          "
                          fab
                          small
                        >
                          <h3
                            class="data_table"
                            :style="{
                              color:
                                Array.isArray(item.count_of_day) &&
                                item.count_of_day.includes(digit)
                                  ? '#ffffff'
                                  : '#f28218',
                            }"
                          >
                            {{ digit }}
                          </h3>
                        </v-btn>
                      </template>
                    </td>
                    <td class="text-center font-weight-black">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            icon
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            class="ml-4"
                            @click="
                              courses_length != 0
                                ? edit_course()
                                : go_to_exercises()
                            "
                          >
                            <Icon
                              icon="fluent:notepad-edit-20-filled"
                              color="#F97300"
                              width="32"
                            />
                          </v-btn>
                        </template>
                        <span>تعديل الوجبة</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            icon
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            @click="delete_course(item.id)"
                          >
                            <Icon
                              icon="lets-icons:trash-duotone-line"
                              color="#C62828"
                              width="35"
                            />
                          </v-btn>
                        </template>
                        <span>حذف تمرين</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <div class="text-center py-5">
                <v-row>
                  <TraineeInformationUpdateBar />
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <PopAdditionalCourses
        :value="pop_additional_courses"
        :courses_length="courses_length"
        v-on:popClose="pop_additional_courses = !pop_additional_courses"
      />
      <PopEditAdditionalCourses
        :value="pop_edit"
        :course="data_editor"
        :courses_length="courses_length"
        v-on:popClose="pop_edit = !pop_edit"
      />
      <PopDelete
        :value="pop_delete"
        v-on:popClose="pop_delete = !pop_delete"
        v-on:deleteCourse="deleteCourse()"
      />
    </v-row>
  </v-container>
</template>
<script>
import TraineeInformationUpdateBar from "@/components/layout/TraineeInformationUpdateBar.vue";
import PopAdditionalCourses from "@/components/additional_courses/PopAdditionalCourses.vue";
import PopEditAdditionalCourses from "@/components/additional_courses/PopEditAdditionalCourses.vue";
import PopDelete from "@/components/additional_courses/PopDelete.vue";
export default {
  components: {
    PopAdditionalCourses,
    PopDelete,
    PopEditAdditionalCourses,
    TraineeInformationUpdateBar,
  },
  data() {
    return {
      selected: 1,
      items: [5, 10, 15, 20, 25],
      pagination: {},
      date_from: "",
      date_to: "",
      exercise: "",
      before_after_core_exercises: "",
      exercise_days: "",
      headers: [
        {
          text: "التمرين",
          value: "exercise",
          class: "secondary white--text subtitle-1",
          align: "center",
          width: "200px",
        },

        {
          text: "قبل / بعد التمارين الأساسية",
          value: "before_after_core_exercises",
          class: "secondary white--text subtitle-1",
          align: "center",
          width: "170px",
        },
        {
          text: "أيام التمرين",
          value: "exercise_days",
          class: "secondary white--text subtitle-1",
          align: "center",
          width: "240px",
        },
        {
          text: "العمليات",
          value: "calories",
          class: "secondary white--text title",
          align: "center",
          width: "150px",
        },
      ],

      pop_additional_courses: false,

      pop_delete: false,
      data_deleted: null,

      pop_edit: false,
      data_editor: null,
    };
  },
  computed: {
    pageCount() {
      return this.$store.state.courses.pageCount;
    },
    courses() {
      return this.$store.state.courses.additional_courses;
    },
    courses_length() {
      return this.$store.state.courses.courses.length;
    },
    loading() {
      return this.$store.state.courses.additional_courses_loading;
    },
    params: {
      set(val) {
        this.$store.state.courses.params = val;
      },
      get() {
        return this.$store.state.courses.params;
      },
    },
  },
  methods: {
    get_additional_courses() {
      let pagination = this.pagination;
      let par = {
        ...pagination,
      };
      this.params = par;
      this.$store.dispatch("courses/get_additional_courses");
    },
    query_change() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.get_additional_courses();
        this.pagination.page = 1;
      }, 1000);
    },

    reset_table() {
      this.$store.dispatch("courses/reset_table_courses");
      this.get_additional_courses();
    },

    add_exercise() {
      this.pop_additional_courses = true;
    },

    edit_course(item) {
      this.pop_edit = true;
      this.data_editor = item;
    },
    delete_course(id) {
      this.pop_delete = true;
      this.data_deleted = id;
    },
    deleteCourse() {
      let data = {};
      data["course_id"] = this.data_deleted;
      this.$store
        .dispatch("courses/delete_additional_course", data)
        .then(() => {
          this.pop_delete = false;
          this.data_deleted = null;
        });
    },
    go_to_exercises() {
      let snack_message = {};
      snack_message["color"] = "#FB8C00";
      snack_message["icon"] = "cuida:alert-outline";
      snack_message["text"] = "لا يمكنك اضافة تمرين بدون تمارين اساسية";
      this.$store.commit("SNACK_MESSAGE", snack_message);
      setTimeout(() => {
        this.$store.commit("TIME_OUT", snack_message);
      }, 4000);
    },
    change_is_after(item, value) {
      let data = {};
      data["course_id"] = item.id;
      data["subscribtion_session_id"] =
        this.$store.state.subscribtion_session_id;
      data["title"] = item.title;
      data["count_of_day"] = item.count_of_day;
      data["is_after"] = value;

      this.$store.dispatch("courses/edit_additional_course", data).then(() => {
        this.$emit("popClose");
      });
    },
  },
  watch: {
    pagination: {
      handler() {
        this.get_additional_courses();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.card-table {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 12px !important;
  border: 1px solid #cbccce;
}
.profile {
  cursor: pointer;
}

.toggle-switch {
  background-color: white;
  border: 1px solid #dcdcdc;
  border-radius: 20px;
  width: fit-content;
}
.selected-btn {
  background-color: white !important;
  color: #f28218 !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.selected-btn-off {
  background-color: #f28218 !important;
  color: white !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.sheet {
  border-radius: 15px !important;
}
.sheet2 {
  border-radius: 10px !important;
}
v-btn:before {
  background-color: #ffffff !important;
}
</style>
