<template>
  <v-container fluid class="mt-0">
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" class="pb-6">
        <v-card class="mx-auto mt-8 card" width="100%">
          <v-toolbar dark color="#F97300" dense class="d-flex justify-center">
            <v-toolbar-title align-center class="font-semiBold py-1">
              المنهاج الغذائي القديم</v-toolbar-title
            >
          </v-toolbar>
          <v-list
            two-line
            class="list py-0"
            style="max-height: 500px; overflow-y: auto"
            ref="scrollContainer"
          >
            <v-list-item
              v-for="(meal, index) in previous_courses"
              :key="index"
              class="pa-0"
            >
              <v-list-item-title class="d-flex justify-start">
                <v-expansion-panels class="d-flex justify-center" :flat="true">
                  <v-expansion-panel :value="panels[index]">
                    <v-expansion-panel-header
                      disable-icon-rotate
                      expand-icon=""
                      class="d-flex justify-start"
                      style="background-color: #ffedd5"
                      @click="add_panel2(index)"
                    >
                      <span style="display: flex; align-items: center">
                        <Icon
                          :icon="
                            panels2.includes(index)
                              ? 'ic:outline-close'
                              : 'ph:list-bold'
                          "
                          color="F97300"
                          width="25"
                        />

                        <h4 class="mr-3 data_table" style="color: #f97300">
                          {{ mealTitles[meal.title] }}
                        </h4>
                      </span>
                      <template v-slot:actions>
                        <span style="display: flex; align-items: center">
                          <v-sheet
                            class="sheet d-flex justify-center"
                            height="35"
                            width="100"
                            color="#f97316"
                            style="
                              border-radius: 8px !important;
                              box-shadow: 0px 0px 0px 0px !important;
                            "
                          >
                            <span style="display: flex; align-items: center">
                              <h4
                                style="color: #ffffff"
                                class="data_table mx-1"
                              >
                                {{ formatTime(meal.time) }}
                              </h4></span
                            >
                          </v-sheet>
                        </span>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content> </v-expansion-panel-content>
                    <v-expansion-panel-content
                      v-for="(ingredient, index2) in meal.ingredients"
                      :key="index2"
                    >
                      <span style="display: flex; align-items: center">
                        <h4
                          class="mb-4 mt-3 data_table"
                          style="
                            white-space: normal;
                            word-wrap: break-word;
                            max-width: 500px;
                            color: #50535b;
                          "
                        >
                          <span style="color: #f97300">{{ index2 + 1 }}.</span>
                          {{ ingredient.title }}
                        </h4>

                        <v-spacer></v-spacer>

                        <v-sheet
                          v-if="index2 != 0"
                          color="#f28218"
                          class="d-flex justify-center py-1 sheet mr-10"
                          height="37"
                          width="120"
                          style="min-width: 120px"
                        >
                          <v-btn
                            small
                            style="border-radius: 12px !important"
                            :class="
                              ingredient.is_before == 0
                                ? 'selected-btn'
                                : 'selected-btn-off'
                            "
                          >
                            <h3 class="data_table">قبل</h3>
                          </v-btn>
                          <v-btn
                            small
                            style="border-radius: 12px !important"
                            :class="
                              ingredient.is_before == 1
                                ? 'selected-btn'
                                : 'selected-btn-off'
                            "
                          >
                            <h3 class="data_table">بعد</h3>
                          </v-btn>
                        </v-sheet>
                      </span>
                      <v-divider
                        v-if="index2 < meal.ingredients.length - 1"
                        :key="index2"
                      ></v-divider>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="loading == true">
              <h3 class="mx-auto font-semiBold" style="color: #50535b">
                جاري التحميل يرجى الأنتظار
              </h3>
            </v-list-item>

            <v-list-item
              v-if="previous_courses.length == 0 && loading == false"
            >
              <h3 class="mx-auto font-semiBold py-10" style="color: #000000">
                لايوجد وجبات
              </h3>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" class="pb-6">
        <v-card class="mx-auto mt-8 card" width="100%">
          <v-toolbar dark color="#F97300" dense class="d-flex justify-center">
            <v-toolbar-title align-center class="font-semiBold py-1">
              المنهاج الغذائي الجديد</v-toolbar-title
            >
            <v-btn fab icon x-small @click="popTimeBetweenMeals()" class="mr-1">
              <Icon icon="tabler:clock-filled" color="fffffff" width="30" />
            </v-btn>
          </v-toolbar>
          <v-list
            two-line
            class="list py-0"
            style="max-height: 500px; overflow-y: auto"
            ref="scrollContainer"
          >
            <v-list-item
              v-for="(meal, index) in meals"
              :key="index"
              class="pa-0"
            >
              <v-list-item-title class="d-flex justify-start">
                <v-expansion-panels class="d-flex justify-center" :flat="true">
                  <v-expansion-panel :value="panels[index]">
                    <v-expansion-panel-header
                      disable-icon-rotate
                      expand-icon=""
                      class="d-flex justify-start"
                      style="background-color: #ffedd5"
                      @click="add_panel(index)"
                    >
                      <span style="display: flex; align-items: center">
                        <Icon
                          :icon="
                            panels.includes(index)
                              ? 'ic:outline-close'
                              : 'ph:list-bold'
                          "
                          color="F97300"
                          width="25"
                        />

                        <h4 class="mr-3 data_table" style="color: #f97300">
                          {{ mealTitles[meal.title] }}
                        </h4>
                      </span>
                      <template v-slot:actions>
                        <span style="display: flex; align-items: center">
                          <v-sheet
                            @click="PopEditMeal(meal)"
                            class="sheet d-flex justify-center"
                            height="35"
                            width="100"
                            color="#f97316"
                            style="
                              border-radius: 8px !important;
                              box-shadow: 0px 0px 0px 0px !important;
                            "
                          >
                            <span style="display: flex; align-items: center">
                              <h4
                                style="color: #ffffff"
                                class="data_table mx-1"
                              >
                                {{ formatTime(meal.time) }}
                              </h4></span
                            >
                          </v-sheet>
                        </span>
                        <v-btn
                          fab
                          icon
                          x-small
                          @click="popDeleteMeal(meal.id)"
                          class="mr-1"
                        >
                          <Icon
                            icon="material-symbols:close"
                            color="F97300"
                            width="30"
                          />
                        </v-btn>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                    
                      <!-- v-model="title_ingredient" -->
                      <v-text-field
                        :ref="'ingredient-' + index"
                      v-model="localIngredients[index]"
                        type="text"
                        label="اضافة مكونات وجبة"
                        class="data_table py-2"
                        color="#F97316"
                        rounded
                        solo
                        outlined
                        clearable
                        hide-details="auto"
                        persistent-hint
                        @keydown="handleKeydown"
                        @keyup.enter="add_ingredient(meal.id, index)"
                      />
                    </v-expansion-panel-content>
                    <v-expansion-panel-content
                      v-for="(ingredient, index2) in meal.ingredients"
                      :key="index2"
                    >
                      <span
                        style="display: flex; align-items: center"
                        @dblclick="
                          ingredient.temp == true
                            ? edit_ingredient_temp(ingredient)
                            : edit_ingredient_main(ingredient)
                        "
                      >
                        <h4
                          v-if="edit != ingredient.id"
                          class="mb-4 mt-3 data_table"
                          style="
                            white-space: normal;
                            word-wrap: break-word;
                            max-width: 500px;
                            color: #50535b;
                          "
                        >
                          <span style="color: #f97300">{{ index2 + 1 }}.</span>
                          {{ ingredient.title }}
                        </h4>
                        <v-text-field
                          v-if="edit == ingredient.id"
                          v-model="edit_ingredient"
                          type="text"
                          label="تعديل المكون"
                          class="data_table py-2"
                          color="#F97316"
                          rounded
                          solo
                          outlined
                          clearable
                          hide-details="auto"
                          persistent-hint
                          @keydown="handleKeydown2"
                          @keyup.enter="
                            ingredient.temp == true
                              ? update_ingredient_temp(ingredient)
                              : update_ingredient(ingredient)
                          "
                        />
                        <v-spacer></v-spacer>

                        <v-sheet
                          v-if="index2 != 0"
                          color="#f28218"
                          class="d-flex justify-center py-1 sheet mr-10"
                          height="37"
                          width="120"
                          style="min-width: 120px"
                        >
                          <v-btn
                            @click="
                              ingredient.temp == true
                                ? change_is_after_temp(ingredient, 0)
                                : change_is_after_main(ingredient, 0)
                            "
                            small
                            style="border-radius: 12px !important"
                            :class="
                              ingredient.is_before == 0
                                ? 'selected-btn'
                                : 'selected-btn-off'
                            "
                          >
                            <h3 class="data_table">قبل</h3>
                          </v-btn>
                          <v-btn
                            @click="
                              ingredient.temp == true
                                ? change_is_after_temp(ingredient, 1)
                                : change_is_after_main(ingredient, 1)
                            "
                            small
                            style="border-radius: 12px !important"
                            :class="
                              ingredient.is_before == 1
                                ? 'selected-btn'
                                : 'selected-btn-off'
                            "
                          >
                            <h3 class="data_table">بعد</h3>
                          </v-btn>
                        </v-sheet>

                        <v-btn
                          @click="
                            ingredient.temp == true
                              ? delete_ingredient_temp(ingredient)
                              : popDeleteIngredient(ingredient.id)
                          "
                          fab
                          icon
                          x-small
                          style="min-width: 25px"
                          class="mx-auto btn-close mb-4 mt-3 mr-3"
                          elevation="4"
                        >
                          <Icon
                            icon="solar:close-square-bold"
                            color="#F28218"
                            width="25"
                          />
                          <template v-slot:loader>
                            <span class="custom-loader">
                              <v-icon color="white">mdi-cached</v-icon>
                            </span>
                          </template>
                        </v-btn>
                      </span>
                      <v-divider
                        v-if="index2 < meal.ingredients.length - 1"
                        :key="index2"
                      ></v-divider>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="loading == true">
              <h3 class="mx-auto font-semiBold" style="color: #50535b">
                جاري التحميل يرجى الأنتظار
              </h3>
            </v-list-item>

            <v-list-item v-if="meals.length == 0 && loading == false">
              <h3 class="mx-auto font-semiBold py-10" style="color: #000000">
                لايوجد وجبات
              </h3>
            </v-list-item>
            <v-list-item class="list-item2 py-0 mt-1" v-if="loading == false">
              <v-list-item-title
                class="d-flex justify-start"
                @click="add_meals"
              >
                <span style="display: flex; align-items: center">
                  <v-progress-circular
                    v-if="loading2"
                    indeterminate
                    color="#f97316"
                    size="20"
                    class="mr-2"
                  ></v-progress-circular>
                  <Icon
                    v-if="!loading2"
                    class="mr-1"
                    icon="ic:baseline-plus"
                    color="#f97316"
                    width="30"
                  />

                  <h3 class="mr-3 data_table" style="color: #f97300">
                    اضافة وجبة
                  </h3>
                </span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <TraineeInformationUpdateBar
        :style="meals.length < 4 ? ' margin-top: 140px;' : 'margin-top: 25px;'"
        v-on:add_ingredients="add_ingredients()"
        :value="1"
        :loading_add="loading4"
      />
      <PopDelete
        :value="pop_delete"
        v-on:popClose="pop_delete = !pop_delete"
        v-on:deleteMeal="deleteMeal()"
      />

      <popDeleteIngredients
        :value="pop_delete_ingredients"
        v-on:popClose="pop_delete_ingredients = !pop_delete_ingredients"
        v-on:deleteIngredient="deleteIngredient()"
      />
      <TimeBetweenMeals
        :value="pop_time"
        v-on:popClose="pop_time = !pop_time"
      />
      <PopEditMeal
        :value="pop_edit"
        :meal="data_editor"
        v-on:popClose="pop_edit = !pop_edit"
      />
    </v-row>
  </v-container>
</template>
<script>
import moment from "moment";
import TraineeInformationUpdateBar from "@/components/layout/TraineeInformationUpdateBar.vue";
import PopDelete from "@/components/meals/PopDelete.vue";
import PopEditMeal from "@/components/meals/PopEditMeal.vue";
import popDeleteIngredients from "@/components/meals/popDeleteIngredients.vue";
import TimeBetweenMeals from "@/components/meals/TimeBetweenMeals.vue";
export default {
  components: {
    PopDelete,
    PopEditMeal,
    TimeBetweenMeals,
    popDeleteIngredients,
    TraineeInformationUpdateBar,
  },
  data() {
    return {
      items: [5, 10, 15, 20, 25],
      pagination: {},
      panels: [],
      panels2: [],

      before_after_core_exercises: "",

      meal_id: null,
      pop_add_meals: false,
      pop_add_ingredients: false,

      pop_delete_ingredients: false,
      data_intgredient_deleted: null,
      pop_delete: false,
      data_deleted: null,

      pop_edit: false,
      data_editor: null,

      items_exercises: [
        { type: "قبل", value: 0 },
        { type: "بعد", value: 1 },
      ],
      AllRules: [(value) => !!value || "هذا الحقل مطلوب"],

      pop_time: false,

      add_meal: false,
      check_index_meal: false,
      spacebarCount: 0,
      spacebarCount2: 0,
      localIngredients: [],
      title_ingredient: "",
      edit_ingredient: null,
      edit: null,
      ingredients: [],
      mealTitles: {
        1: "الوجبة الاولى",
        2: "الوجبة الثانية",
        3: "الوجبة الثالثة",
        4: "الوجبة الرابعة",
        5: "الوجبة الخامسة",
        6: "الوجبة السادسة",
        7: "الوجبة السابعة",
        8: "الوجبة الثامنة",
        9: "الوجبة التاسعة",
        10: "الوجبة العاشرة",
        11: "الوجبة الحادية عشرة",
        12: "الوجبة الثانية عشرة",
        13: "الوجبة الثالثة عشرة",
        14: "الوجبة الرابعة عشرة",
        15: "الوجبة الخامسة عشرة",
      },
    };
  },

  created() {
    this.get_meals();
    // this.getBasicExercisesPrevious();
  },
  computed: {
    pageCount() {
      return this.$store.state.meals.pageCount;
    },
    previous_courses() {
      return this.$store.state.courses.previous_courses;
    },
    meals() {
      return this.$store.state.meals.meals;
    },
    loading() {
      return this.$store.state.meals.meals_loading;
    },
    loading2() {
      return this.$store.state.meals.add_meals_loading;
    },
    loading3() {
      return this.$store.state.meals.edit_meal_loading;
    },
    loading4() {
      return this.$store.state.meals.add_ingredient_loading;
    },
    timeDifference() {
      return this.$store.state.meals.timeDifference;
    },

    params: {
      set(val) {
        this.$store.state.meals.params = val;
      },
      get() {
        return this.$store.state.meals.params;
      },
    },
  },
  methods: {
    get_meals() {
      this.$store.dispatch("meals/get_meals");
    },

    add_meals() {
      if (this.meals.length != 15) {
        let days = this.$store.state.meals.meals.map((meal) =>
          parseInt(meal.title)
        ); // Extract days
        let missingDay = null;

        // Sort days in ascending order
        days.sort((a, b) => a - b);

        if (days[0] > 1) {
          missingDay = 1; // If the smallest day is greater than 1, the first day is missing
        } else {
          // Check for gaps between consecutive days
          for (let i = 0; i < days.length - 1; i++) {
            if (days[i + 1] - days[i] > 1) {
              // Find the first missing day
              missingDay = days[i] + 1;
              break;
            }
          }
        }

        var day;
        if (missingDay != null) {
          day = missingDay;
        } else {
          day = this.meals.length + 1;
        }

        let data = {};
        data["meal_plan_id"] = this.$store.state.meals.meal_plan_id;
        data["title"] = String(day);
        if (this.meals.length === 0) {
          data["time"] = "08:00"; // Default time for the first meal
        } else {
          // Parse the time of the last meal and add the time difference
          const lastMealTime = this.meals[this.meals.length - 1].time;
          const [hours, minutes] = lastMealTime.split(":").map(Number);
          const totalMinutes = hours * 60 + minutes + this.timeDifference;

          // Calculate the new time in HH:mm format
          const newHours = Math.floor(totalMinutes / 60) % 24;
          const newMinutes = totalMinutes % 60;
          data["time"] = `${String(newHours).padStart(2, "0")}:${String(
            newMinutes
          ).padStart(2, "0")}`;
        }
        this.$store.dispatch("meals/addMeals", data);
      } else {
        let snack_message = {};
        snack_message["color"] = "#FB8C00";
        snack_message["icon"] = "cuida:alert-outline";
        snack_message["text"] = "لقد وصلت الى عدد الاقصى لعدد الوجبات";
        this.$store.commit("SNACK_MESSAGE", snack_message);
        setTimeout(() => {
          this.$store.commit("TIME_OUT", snack_message);
        }, 5000);
      }
    },

    popDeleteMeal(id) {
      this.data_deleted = id;
      this.pop_delete = true;
    },
    popTimeBetweenMeals() {
      this.pop_time = true;
    },
    popDeleteIngredient(id) {
      this.data_intgredient_deleted = id;
      this.pop_delete_ingredients = true;
    },
    PopEditMeal(item) {
      this.data_editor = item;
      this.pop_edit = true;
    },

    deleteMeal() {
      let data = {};
      data["meal_id"] = this.data_deleted;
      this.$store.dispatch("meals/deleteMeals", data).then(() => {
        this.pop_delete = false;
        this.data_deleted = null;
      });
    },
    deleteIngredient() {
      let data = {};
      data["ingredient_id"] = this.data_intgredient_deleted;
      this.$store.dispatch("meals/deleteIngredientInMeal", data).then(() => {
        this.pop_delete_ingredients = false;
        this.data_intgredient_deleted = null;
      });
    },

    reset_table() {
      this.$store.dispatch("meals/reset_table");
      this.get_meals();
    },
    add_panel(index) {
      if (!this.panels.includes(index)) {
        this.panels.push(index);
      } else {
        this.panels = this.panels.filter(
          (panelsIndex) => panelsIndex !== index
        );
      }
    },
    add_panel2(index) {
      if (!this.panels2.includes(index)) {
        this.panels2.push(index);
      } else {
        this.panels2 = this.panels2.filter(
          (panelsIndex) => panelsIndex !== index
        );
      }
    },
    formatTime(time) {
      const formattedTime = moment(time, "hh:mm A").format("hh:mm");
      const period = moment(time, "hh:mm A").format("A");
      if (period === "AM") {
        return `${formattedTime} صباحاً`;
      } else if (period === "PM") {
        return `${formattedTime}  مساءً`;
      }
      return formattedTime; // Fallback
    },

    generateRandomId() {
      // Generate a random number between 1 and 10,000
      return Math.floor(Math.random() * 10000) + 1;
    },

    // add_ingredient(meal_id) {
    //   if (this.title_ingredient.trim() !== "") {
    //     var randomId = this.generateRandomId();
    //     let data = {};
    //     data["id"] = randomId;
    //     data["meal_id"] = meal_id;
    //     data["title"] = this.title_ingredient;
    //     data["is_before"] = 0;
    //     data["temp"] = true;
    //     this.ingredients.push(data);

    //     this.$store.state.meals.meals.forEach((meal) => {
    //       if (meal.id == meal_id) {
    //         meal.ingredients.push(data);
    //       }
    //     });
    //     this.title_ingredient = "";
    //   }
    // },
    add_ingredient(meal_id, index) {
      // Access the unique ref
      const ingredient = this.localIngredients[index].trim();

      if (ingredient !== "") {
        const randomId = this.generateRandomId();
        let data = {
          id: randomId,
          meal_id: meal_id,
          title: ingredient,
          is_before: 0,
          temp: true,
        };

        // Add to ingredients array
        this.ingredients.push(data);

        // Update the specific meal's ingredients
        this.$store.state.meals.meals.forEach((meal) => {
          if (meal.id === meal_id) {
            meal.ingredients.push(data);
          }
        });

        this.$set(this.localIngredients, index, "");
      }
    },
    add_ingredients() {
      if (this.ingredients[0].meal_id != null) {
        let data = {};
        data["meal_id"] = this.ingredients[0].meal_id;
        data["ingredients"] = this.ingredients;

        this.$store.dispatch("meals/addIngredients", data).then(() => {
          this.ingredients = [];
        });
      }
    },

    update_ingredient_temp(ingredient) {
      var index_local = this.ingredients.findIndex(
        (e) => e.id == ingredient.id
      );
      this.ingredients[index_local].title = this.edit_ingredient;

      let index = this.$store.state.meals.meals.findIndex(
        (e) => e.id == ingredient.meal_id
      );
      let index_ingredient = this.$store.state.meals.meals[
        index
      ].ingredients.findIndex((e) => e.id == ingredient.id);
      this.$store.state.meals.meals[index].ingredients[index_ingredient].title =
        this.edit_ingredient;
    },

    edit_ingredient_temp(ingredient) {
      if (this.edit == ingredient.id) {
        this.edit = null;
      } else {
        this.edit_ingredient = ingredient.title;
        this.edit = ingredient.id;
      }
    },
    update_ingredient(ingredient) {
      let data = {};
      data["ingredient_id"] = ingredient.id;
      data["is_before"] = ingredient.is_before;
      data["title"] = this.edit_ingredient;
      this.$store.dispatch("meals/updateIngredient", data).then(() => {
        this.edit = null;
      });
    },
    change_is_after_main(ingredient, value) {
      let data = {};
      data["ingredient_id"] = ingredient.id;
      data["is_before"] = value;
      data["title"] = ingredient.title;
      this.$store.dispatch("meals/updateIngredient", data).then(() => {
        this.edit = null;
      });
    },
    edit_ingredient_main(ingredient) {
      if (this.edit == ingredient.id) {
        this.edit = null;
      } else {
        this.edit_ingredient = ingredient.title;
        this.edit = ingredient.id;
      }
    },

    delete_ingredient_temp(ingredient) {
      var index_local = this.ingredients.findIndex(
        (e) => e.id == ingredient.id
      );
      this.ingredients.splice(index_local, 1);

      let index = this.$store.state.meals.meals.findIndex(
        (e) => e.id == ingredient.meal_id
      );
      let index_ingredient = this.$store.state.meals.meals[
        index
      ].ingredients.findIndex((e) => e.id == ingredient.id);
      this.$store.state.meals.meals[index].ingredients.splice(
        index_ingredient,
        1
      );
    },

    change_is_after_temp(ingredient, value) {
      var index_local = this.ingredients.findIndex(
        (e) => e.id == ingredient.id
      );
      this.ingredients[index_local].is_before = value;

      let index = this.$store.state.meals.meals.findIndex(
        (e) => e.id == ingredient.meal_id
      );
      let index_ingredient = this.$store.state.meals.meals[
        index
      ].ingredients.findIndex((e) => e.id == ingredient.id);
      this.$store.state.meals.meals[index].ingredients[
        index_ingredient
      ].is_before = value;
    },

    handleKeydown(event) {
      // Check if spacebar (keyCode 32) is pressed
      if (event.key === " ") {
        this.spacebarCount++;

        // If spacebar is pressed twice, insert a comma '+'
        if (this.spacebarCount === 2) {
          this.title += "+";
          this.spacebarCount = 0; // Reset count after inserting comma
        }
      } else {
        // Reset count if other key is pressed
        this.spacebarCount = 0;
      }
    },
    handleKeydown2(event) {
      // Check if spacebar (keyCode 32) is pressed
      if (event.key === " ") {
        this.spacebarCount2++;

        // If spacebar is pressed twice, insert a comma '+'
        if (this.spacebarCount2 === 2) {
          this.edit_title += "+";
          this.spacebarCount2 = 0; // Reset count after inserting comma
        }
      } else {
        // Reset count if other key is pressed
        this.spacebarCount2 = 0;
      }
    },
  },
  watch: {
    pagination: {
      handler() {
        this.get_meals();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.profile {
  cursor: pointer;
}

.toggle-switch {
  background-color: white;
  border: 1px solid #dcdcdc;
  border-radius: 20px;
  width: fit-content;
}
.selected-btn {
  background-color: white !important;
  color: #f28218 !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.selected-btn-off {
  background-color: #f28218 !important;
  color: white !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.sheet {
  border-radius: 15px !important;
}
.p-btn {
  background-color: #f97316 !important;
  box-shadow: 0px 0px 0px 0px !important;
}

.list-item2 {
  /* border: 2px solid #f97316 !important; */
  border-radius: 4px !important;
  background-color: #ffedd5;
  box-shadow: 0px 0px 0px 0px !important;
  cursor: pointer;
}
.sheet {
  border-radius: 25px !important;
}
.btn-close {
  box-shadow: 0px 0px 0px 0px !important;
}
</style>
