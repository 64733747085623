<template>
  <v-row style="background-color: #fdfdfd">
    <v-col cols="12" sm="12" md="12" lg="12" v-if="loading == false">
      <v-card
        class="mx-auto card"
        width="100%"
        style="background-color: #fdfdfd"
      >
        <v-card-title>
          <h3 class="font-toolbar-title">السجل المالي</h3>

          <v-switch
            v-model="disabled_payment"
            inset
            color="#f97316"
            class="mb-3 mt-3 mr-5"
            hide-details="auto"
            @change="saveDisabledPaymentStatus"
          ></v-switch>
          <v-spacer> </v-spacer>
          <v-col align-self="center" cols="6" sm="6" md="2" lg="2">
            <v-pagination
              v-model="params.page"
              :length="pageCount"
              circle
              color="#f97316"
              class="data_table"
            ></v-pagination>
          </v-col>
          <v-col align-self="center" cols="6" sm="6" md="2" lg="2">
            <v-select
              v-model="params.itemsPerPage"
              :items="items"
              outlined
              rounded
              item-color="orange darken-1"
              color="#f97316"
              single-line
              hide-details
              class="data_table"
            ></v-select>
          </v-col>
          <v-text-field
            v-if="$vuetify.breakpoint.smAndUp"
            v-model="trainer_name"
            @input="query_change"
            label="إبحث عن مدرب لرؤية سجله المالي  ..."
            class="data_table"
            color="#f97316"
            solo
            rounded
            clearable
            hide-details
          />
        </v-card-title>

        <v-divider class="ml-10"></v-divider>
      </v-card>
    </v-col>
    <v-col cols="12" sm="12" md="12" lg="12" v-else>
      <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="12"
      lg="12"
      class="mx-0 px-0"
      v-if="loading == false"
    >
      <v-row class="pa-0 ma-0">
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="4"
          class="pb-8"
          v-for="(payment, index) in trainers_payments"
          :key="index"
        >
          <v-card class="mx-auto card" width="90%">
            <v-toolbar
              dark
              color="#F97316"
              style="cursor: pointer"
              @click="payment_details(payment.trainer_id)"
            >
              <v-toolbar-title class="mx-auto toolbar-title">
                <span style="display: flex; align-items: center">
                  <v-avatar size="25">
                    <v-img src="@/assets/arrow.png"> </v-img>
                  </v-avatar>
                  <h4 class="data_table mr-5 ml-4">
                    {{ payment.trainer_name }}
                  </h4>
                  <v-avatar size="40">
                    <img
                      src="@/assets/Elift-logo.png"
                      width="40"
                      height="40"
                      alt="error"
                    />
                  </v-avatar>
                </span>
              </v-toolbar-title>
            </v-toolbar>
            <v-list two-line>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <h4 style="color: #50535b" class="data_table">
                      رقم الهاتف
                    </h4></v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <h4 style="color: #50535b" class="data_table">
                    {{ payment.phone }}
                  </h4>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <h4 style="color: #50535b" class="data_table">
                      الحالة
                    </h4></v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <h4
                    style="color: #50535b"
                    class="data_table"
                    v-if="payment.status == true"
                  >
                    نشط
                  </h4>
                  <h4 style="color: #50535b" class="data_table" v-else>خامل</h4>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <h4 style="color: #50535b" class="data_table">
                      تاريخ اخر تسديد
                    </h4></v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <h4
                    style="color: #50535b"
                    class="data_table"
                    v-if="payment.last_payment_date != null"
                  >
                    {{ payment.last_payment_date }}
                  </h4>
                  <h4 style="color: #50535b" class="data_table" v-else>
                    لايوجد
                  </h4>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <h4 style="color: #50535b" class="data_table">
                      المبلغ المطلوب سداده
                    </h4></v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <h4 style="color: #50535b" class="data_table">
                    {{ payment.total_app_subscription_amount | formatNumber2 }}
                  </h4>
                </v-list-item-action>
              </v-list-item>

              <!-- <v-divider></v-divider> -->
              <v-list-item>
                <v-btn
                  @click="download_pdf(payment.trainer_id)"
                  :loading="loading3"
                  style="
                    border-radius: 13px;
                    box-shadow: 0px 0px 0px 0px !important;
                  "
                  class="px-8 py-5 btn-download-pdf"
                >
                  <Icon
                    icon="octicon:download-16"
                    class="btn-icon"
                    color="#f97316"
                    width="26"
                  />
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon color="white">mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>

                <v-btn
                  @click="clearing_financial_account(payment.trainer_id, index)"
                  :loading="loading2 == true && checkLoading == index"
                  :disabled="payment.is_received == 1 || disabled_payment == 1"
                  style="
                    box-shadow: 0px 0px 0px 0px !important;
                    border-radius: 13px;
                  "
                  :class="
                    payment.is_received == 1 || disabled_payment == 1
                      ? ''
                      : 'btn-payment'
                  "
                  class="mr-2 btn px-10 py-5"
                >
                  <h3
                    class="data_table ml-1"
                    :class="
                      payment.is_received == 1 || disabled_payment == 1
                        ? ''
                        : 'btn-icon'
                    "
                  >
                    تصفية الحساب
                  </h3>
                  <Icon
                    icon="mingcute:delete-3-fill"
                    :class="
                      payment.is_received == 1 || disabled_payment == 1
                        ? ''
                        : 'btn-icon'
                    "
                    :color="
                      disabled_payment == 1 || payment.is_received == 1
                        ? '#ffffff'
                        : '#ef4444'
                    "
                    width="26"
                  />
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon color="#ef4444" class="btn-icon"
                        >mdi-cached</v-icon
                      >
                    </span>
                  </template>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-row v-if="loading == true" class="pb-3">
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="4"
        v-for="(n, index) in 6"
        :key="'A' + index"
      >
        <v-card class="mx-auto card" width="88%" style="cursor: pointer">
          <v-skeleton-loader type="card-heading ,image"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="loading == false && trainers_payments.length == 0"
      class="pb-3 d-flex justify-center"
    >
      <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center">
        <v-card class="d-flex justify-center mt-11 card" width="88%">
          <v-card-title class="mx-auto">
            <h2 class="data_table">لايوجد سجل مالي لمدربين</h2>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      checkLoading: null,
      disabled_payment: 0,
      items: [5, 10, 15, 25, 50, 100],
    };
  },
  created() {
    this.loadDisabledPaymentStatus();
    this.get_payments();
  },
  computed: {
    trainers_payments() {
      return this.$store.state.admin_payments.trainers_payments;
    },
    loading() {
      return this.$store.state.admin_payments.trainers_payments_loading;
    },
    loading2() {
      return this.$store.state.admin_payments.mark_all_received_loading;
    },
    loading3() {
      return this.$store.state.admin_payments.download_pdf_loading;
    },
    pageCount() {
      return this.$store.state.admin_payments.pageCount;
    },
    params: {
      set(val) {
        this.$store.state.admin_payments.params = val;
      },
      get() {
        return this.$store.state.admin_payments.params;
      },
    },
    trainer_name: {
      set(val) {
        this.$store.state.admin_payments.trainer_name = val;
      },
      get() {
        return this.$store.state.admin_payments.trainer_name;
      },
    },
  },
  methods: {
    get_payments() {
      this.$store.dispatch("admin_payments/get_trainers_payments");
    },
    query_change() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.params.page = 1;
        this.get_payments();
      }, 1000);
    },
    payment_details(id) {
      localStorage.setItem("trainer_id_payments", id);
      this.$store.commit("admin_payments/setIdTrainerGetPaymentDetails", id);
      this.$router.push("/admin/details-trainers-financial-record");
    },
    clearing_financial_account(trainer_id, index) {
      this.checkLoading = index;
      let data = {};
      data["trainer_id"] = trainer_id;
      this.$store.dispatch("admin_payments/mark_all_received", data);
    },

    saveDisabledPaymentStatus() {
      localStorage.setItem(
        "disabled_payment",
        JSON.stringify(this.disabled_payment)
      );
    },
    loadDisabledPaymentStatus() {
      const savedStatus = localStorage.getItem("disabled_payment");
      if (savedStatus !== null) {
        this.disabled_payment = JSON.parse(savedStatus); // Parse the JSON string
      }
    },
    download_pdf(trainer_id) {
      let data = {};
      data["trainer_id"] = trainer_id;
      this.$store.dispatch("admin_payments/download_pdf_payments", data);
    },
  },

  watch: {
    params: {
      handler() {
        this.get_payments();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 25px !important;
}

.btn-download-pdf {
  background-color: #ffffff !important;
  border: 1px solid #f97316;
  transition: all 0.4s ease;
}
.btn-download-pdf:hover {
  background-color: #f97316 !important;
}
.btn-download-pdf:hover .btn-icon {
  color: #ffffff !important;
}

.btn-payment {
  background-color: #ffffff !important;
  border: 1px solid #ef4444;
  transition: all 0.4s ease;
}

.btn-payment:hover {
  background-color: #ef4444 !important;
}

.btn-payment .btn-icon {
  color: #ef4444;
  transition: color 0.4s ease;
}

.btn-payment:hover .btn-icon {
  color: #ffffff !important;
}

.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px !important;
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  color: #ffffff !important;
  background-color: #d1d1d1 !important;
  border: 1px solid #ffffff !important;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg .btn-icon {
  color: #ffffff !important;
}
.v-application--is-rtl .v-list-item__action:first-child,
.v-application--is-rtl .v-list-item__icon:first-child {
  margin-left: 5px;
}
</style>
