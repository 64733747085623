<template>
  <v-row justify="center">
    <v-dialog :value="value" max-width="700px" persistent scrollable>
      <v-card class="card mx-auto" width="100%" height="100%">
        <v-toolbar elevation="0">
          <v-btn
            icon
            fab
            color="#C62828"
            x-small
            v-on:click="$emit('popClose')"
            class="mr-1"
          >
            <Icon
              class="icon_download"
              icon="ic:outline-close"
              color="#000000"
              width="25"
            />
          </v-btn>

          <v-toolbar-title class="mx-auto pl-16" style="color: #50535b">
            <h3 style="color: #50535b" class="font-semiBold">اضافة و تعديل التمارين</h3>
          </v-toolbar-title>
        </v-toolbar>
        <v-form ref="add_courses">
          <v-card-title>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <Input
                  @update-value="title = $event"
                  :value="title"
                  type="text"
                  label="اسم التمرين"
                  :rules="RulseAll"
                />
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="12"
                class="d-flex justify-center"
              >
                <v-btn
                  large
                  @click="add_courses"
                  :loading="loading"
                  color="#f28218"
                  class="btn-a py-5 mx-auto"
                  elevation="4"
                >
                  <h3 style="color: white;" class="data_table">اضافة</h3>
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon color="white">mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
        </v-form>

        <v-simple-table
          :height="290"
          class="mt-5 mb-5"
          v-if="course != null && course.exercises != null"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-start data_table" width="300px"><h3>اسم التمرين</h3></th>
                <th class="text-end ml-10 data_table" width="350px">
                  <h2></h2>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(exercise, index) in course.exercises" :key="index">
                <td
                  class="text-start font-weight-black data_table py-6"
                  v-if="edit != index"
                >
                  <span style="color: #f97300">{{ index + 1 }}.</span>
                  {{ exercise.title }}
                </td>
                <td
                  class="text-start font-weight-black py-2"
                  v-if="edit == index"
                >
                  <v-text-field
                    v-model="edit_title"
                    type="text"
                    label="تعديل التمرين"
                    class="data_table"
                    color="#F97316"
                    rounded
                    solo
                    outlined
                    clearable
                    hide-details="auto"
                    persistent-hint
                    :disabled="loading2 == true"
                    @keyup.enter="update_exercise(exercise.id)"
                    :rules="RulseAll"
                  />
                </td>
                <td class="text-end data_table">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        icon
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        @click="edit_exercise(exercise, index)"
                      >
                        <Icon
                          icon="rivet-icons:pencil-solid"
                          color="F97300"
                          width="25"
                        />
                        <template v-slot:loader>
                          <span class="custom-loader">
                            <v-icon color="white">mdi-cached</v-icon>
                          </span>
                        </template>
                      </v-btn>
                    </template>
                    <span>تعديل تمرين</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Input from "@/components/layout/Input.vue";
export default {
  components: { Input },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    course: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      title: null,
      edit_title: null,
      edit: null,
      RulseAll: [(value) => !!value || "هذا الحقل مطلوب"],
    };
  },

  computed: {
    loading() {
      return this.$store.state.courses.add_courses_loading;
    },
    loading2() {
      return this.$store.state.courses.update_course_loading;
    },
  },
  methods: {
    add_courses() {
      if (this.$refs.add_courses.validate()) {
        let data = {};
        data["subscribtion_session_id"] =
          this.$store.state.subscribtion_session_id;
        data["title"] = this.title;
        data["type"] = "main";
        data["day"] = this.course.day;

        this.$store.dispatch("courses/add_courses", data).then(() => {
          this.$refs.add_courses.reset();
        });
      }
    },

    edit_exercise(exercise, index) {
      if (this.edit == index) {
        this.edit = null;
      } else {
        this.edit_title = exercise.title;
        this.edit = index;
      }
    },
    update_exercise(id) {
      if (this.edit_title != null && this.edit_title != "") {
        let data = {};
        data["course_id"] = id;
        data["title"] = this.edit_title;
        this.$store.dispatch("courses/update_course", data).then(() => {
          this.edit_title = null;
          this.edit = null;
        });
      }
    },
  },
  watch: {
    edit_title(newVal) {
      // Match sequences of digits to add hyphens only between numbers
      let formatted = newVal.replace(/\d+/g, (match) => {
        return match.split("").join("-");
      });

      // Update the value to the formatted string
      this.edit_title = formatted;
    },
    title(newVal) {
      // Match sequences of digits to add hyphens only between numbers
      let formatted = newVal.replace(/\d+/g, (match) => {
        return match.split("").join("-");
      });

      // Update the value to the formatted string
      this.title = formatted;
    },
  },
  
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.btn-a {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 174px !important;
}

</style>
