<template>
  <v-text-field
    class="data_table"
    :value="value"
    :type="type"
    :label="label"
    color="#F97316"
    :disabled="disabled"
    :prepend-inner-icon="icon"
    solo
    clearable
    lazy-rules
    @input="$emit('update-value', $event)"
    :rules="rules"
    hide-details="auto"
    :hint="hint"
    persistent-hint
  />
</template>
<script>
export default {
  props: {
    type: {
      type: [String, Number],
      default: null,
    },
    label: String,
    value: {
      type: [String, Number],
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      required: false,
    },
    hint: {
      type: String,
      required: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
.v-select__selection--comma {
  min-height: 22px !important;
}
.v-text-field input {
  line-height: 28px;
}
.v-input .v-label {
  height: 27px !important;
  line-height: 16px !important;
  letter-spacing: normal !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 0px 0px 0px 0px !important;
  padding: 12px;
  border-radius: 19px
}
.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #f3f3f3 !important;
}
</style>
