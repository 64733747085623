<template>
  <v-container class="register" fluid>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-card class="card" width="1200">
          <v-row class="justify-center">
            <v-col cols="12" sm="12" md="8" lg="8" class="justify-center">
              <v-card-title class="d-flex justify-center mt-2" v-if="e1 != 3">
                <h3 style="color: #f97316" class="font-semiBold">
                  قم بإنشاء حساب
                </h3>
              </v-card-title>
              <v-card-title class="d-flex justify-center mt-n5" v-if="e1 != 3">
                <h5 class="data_table" style="color: #6f6f70">
                  قم بملئ هذه الإستمارة بالمعلومات المطلوبة
                </h5>
              </v-card-title>
              <v-card-title
                class="d-flex justify-center mt-0 py-0"
                v-if="e1 == 3"
              >
                <v-avatar size="100">
                  <img
                    src="@/assets/Elift-logo.png"
                    width="70"
                    height="70"
                    alt="error"
                  />
                </v-avatar>
              </v-card-title>
              <v-card-title class="d-flex justify-center mt-2" v-if="e1 == 3">
                <h4 class="data_table">الصور</h4>
              </v-card-title>

              <v-stepper
                v-model="e1"
                outlined
                v-if="e1 != 3"
                class="data_table"
              >
                <v-stepper-header style="box-shadow: 0px 0px !important">
                  <v-stepper-step :complete="e1 > 1" color="#f97316" step="1">
                    <h4 class="data_table" style="color: #393c43">
                      المعلومات الشخصية
                    </h4>
                  </v-stepper-step>

                  <v-divider
                    :style="e1 == 2 ? 'border-color: #f97316 !important;' : ''"
                  ></v-divider>

                  <v-stepper-step
                    :complete="e1 > 2"
                    :color="e1 > 2 ? '#f97316' : '#f97316'"
                    step="2"
                  >
                    <h4 class="data_table" style="color: #393c43">
                      بيانات المستخدم
                    </h4>
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-form ref="step1" @keyup.enter="step1">
                    <v-stepper-content step="1" class="py-0">
                      <v-card class="mb-15" height="250px">
                        <v-card-text>
                          <Input
                            @update-value="name = $event"
                            :value="name"
                            type="text"
                            label="الأسم الثلاثي"
                            :rules="NameRules"
                          />
                          <Input
                            @update-value="number_phone = $event"
                            :value="number_phone"
                            type="number"
                            label="رقم الهاتف"
                            :rules="NumberPhonelRules"
                            class="py-5"
                          />

                          <Input
                            @update-value="address = $event"
                            :value="address"
                            type="text"
                            label="العنوان"
                            :rules="NameRules"
                            class=""
                          />
                        </v-card-text>
                      </v-card>

                      <v-btn color="#f97316" @click="step1">
                        <h4 style="color: white">التالي</h4>
                      </v-btn>
                    </v-stepper-content>
                  </v-form>
                  <v-form ref="step2" @keyup.enter="step1">
                    <v-stepper-content step="2" class="py-0">
                      <v-card class="mb-15" height="250px">
                        <v-card-text>
                          <InputDate
                            @update-value="birth_date = $event"
                            @clearInput="birth_date = null"
                            :value="birth_date"
                            color="#f97316"
                            label="تاريخ ميلاد"
                          />

                          <v-text-field
                            v-model="password"
                            color="#F97316"
                            rounded
                            solo
                            clearable
                            class="font-weight-black pt-1 mt-4 password"
                            :type="showPassword ? 'text' : 'password'"
                            label="كلمة المرور"
                            :append-icon="
                              showPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            @click:append="showPassword = !showPassword"
                            :rules="PasswordsRules"
                          />

                          <v-text-field
                            color="#F97316"
                            v-model="confirm_password"
                            rounded
                            solo
                            clearable
                            class="font-weight-black password"
                            :type="showPassword ? 'text' : 'password'"
                            label="تاكيد كلمة المرور"
                            :append-icon="
                              showPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            @click:append="showPassword = !showPassword"
                            :rules="ConfirmPasswordRules"
                          />
                        </v-card-text>
                      </v-card>

                      <v-btn color="#f97316" @click="step2">
                        <h4 style="color: white" class="data_table">
                          التالي
                        </h4>
                      </v-btn>

                      <v-btn text class="mr-2 " @click="e1 = 1">
                        <h4 class="data_table">
                          رجوع
                        </h4>
                     
                      </v-btn>
                    </v-stepper-content>
                  </v-form>
                </v-stepper-items>
              </v-stepper>
              <v-col cols="12" sm="12" md="12" lg="12" v-if="e1 == 3">
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  :includeStyling="true"
                  :useCustomSlot="true"
                  @vdropzone-removed-file="removeFile"
                  @vdropzone-success="uploadSuccess"
                  @vdropzone-error="fileUploadError"
                >
                  <div class="dropzone-custom-content">
                    <v-img
                      src="../../assets/Upload-icon.svg"
                      width="70"
                      class="mx-auto"
                    ></v-img>
                    <h3 class="dropzone-custom-title mt-5 data_table">
                      قم بإفلات صورك هنا أو قم بالضغط لإختيار صورة
                    </h3>
                    <div class="subtitle data_table">الصيغ المدعومة: JPEG, PNG ,JPG</div>
                  </div>
                </vue-dropzone>

                <v-btn
                  @click="step3"
                  :loading="loading"
                  rounded
                  color="#f97316"
                  class="mt-4 py-7 d-flex justify-center"
                  style="box-shadow: 0px 0px 0px 0px !important"
                  large
                  block
                  elevation="4"
                >
                  <v-avatar size="25">
                    <v-img src="@/assets/arrow.png"> </v-img>
                  </v-avatar>
                  <h3 class="mr-1 ml-4 data_table" style="color: white; ">
                    التالي
                  </h3>

                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon color="white">mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Input from "@/components/layout/Input.vue";
import InputDate from "@/components/layout/InputDate.vue";
export default {
  components: { Input, InputDate },
  data: () => ({
    name: null,
    address: null,
    number_phone: null,
    password: null,
    confirm_password: null,
    showPassword: false,
    contact_us: false,
    e1: 1,
    birth_date: null,

    images: [],

    dropzoneOptions: {
      url: "https://efredgvrergv34345435.online/api/api/upload_file",
      method: "post",
      thumbnailWidth: 200,
      maxFilesize: 2,
      parallelUploads: 1,
      uploadMultiple: false,
      addRemoveLinks: true,
      acceptedFiles: ".png,.jpeg,.jpg",
      maxFiles: 3,

      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"), // or wherever your token is stored
      },
    },

    NameRules: [
      (value) => !!value || "هذا الحقل مطلوب",
      (value) => (value || "").length >= 3 || "الحد الادنى لعدد الاحرف هوه 3",
    ],
  }),
  mounted() {
    this.remove_local_storage();
  },
  computed: {
    NumberPhonelRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) =>
          (value || "").length >= 11 || "الحد الادنى لعدد الارقام هوه 11",
        (value) =>
          (value || "").length < 12 || "الحد الاقصى لعدد الارقام هوه 11",
      ];
    },
    PasswordsRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 8 || "الحد الادنى لعدد الاحرف هوه 8",
      ];
    },
    ConfirmPasswordRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 8 || "الحد الادنى لعدد الاحرف هوه 8",
        (value) => value === (this.password || "") || "كلمة المرور غير متطابقه",
      ];
    },

    loading() {
      return this.$store.state.loading_register;
    },
  },
  methods: {
    step1() {
      if (this.$refs.step1.validate()) {
        localStorage.setItem("full_name", this.name);
        localStorage.setItem("number_phone", this.number_phone);
        localStorage.setItem("address", this.address);
        this.e1 = 2;
      }
    },
    step2() {
      if (this.$refs.step2.validate()) {
        localStorage.setItem("password", this.password);
        localStorage.setItem("birth_date", this.birth_date);
        this.e1 = 3;
      }
    },
    step3() {
      let data = {};
      data["full_name"] = localStorage.getItem("full_name");
      data["phone"] = localStorage.getItem("number_phone");
      data["address"] = localStorage.getItem("address");
      data["birth_date"] = localStorage.getItem("birth_date");

      data["password"] = localStorage.getItem("password");
      data["images"] = this.images;
      this.$store.dispatch("register", data).then(() => {
        this.remove_local_storage();
        this.$router.push({ name: "login" });
      });
    },
    remove_local_storage() {
      localStorage.removeItem("full_name");
      localStorage.removeItem("number_phone");
      localStorage.removeItem("address");
      localStorage.removeItem("birth_date");
      localStorage.removeItem("password");
    },

    redirect() {
      this.$router.push({ name: "register" });
    },
    ContactUs() {
      this.contact_us = true;
    },

    uploadSuccess(file, response) {
      this.images.push(response["file"]);
    },
    fileUploadError() {
      let snack_message = {};
      snack_message["color"] = "#ef4444";
      snack_message["icon"] = "ri:close-circle-fill";
      snack_message["text"] = "لا يمكنك تحميل ملفات من هذا النوع.";
      this.$store.commit("SNACK_MESSAGE", snack_message);
      setTimeout(() => {
        this.$store.commit("TIME_OUT", snack_message);
      }, 4000);
    },

    removeFile(file) {
      const fileIndex = this.images.findIndex((img) => img === file.dataURL);
      if (fileIndex !== -1) {
        this.images.splice(fileIndex, 1);
      }
    },
  },
};
</script>
<style scoped>
.card {
  margin: auto;
  border-radius: 15px;
  box-shadow: 0px 0px !important;
}

.theme--light.v-sheet--outlined {
  border: thin solid rgba(0, 0, 0, 0) !important;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0px 0px !important;
}

.dropzone-custom-content {
  margin-top: 50px;
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #000000;
}

.subtitle {
  color: #314b5f;
}
.dropzone {
  height: 710px;
  border-radius: 10px;
  border-style: dotted !important;
}
@media (min-width: 1182px) {
  /* Adjust for web (desktop and larger screens) */
  .dropzone {
    height: 300px;
  }
}
.vue-dropzone {
  background-color: #f8f8ff !important;
}
.dz-preview {
  height: 150px !important;
  display: block !important;
}
.dz-details {
  top: 0px !important;
}
.dz-error-message {
  top: 150px !important;
}
</style>
<style>
.vue-dropzone > .dz-preview .dz-details {
  background-color: #f97316 !important;
}
.vue-dropzone > .dz-preview .dz-remove {
  position: absolute !important;
  z-index: 30 !important;
  color: white !important;
  padding: 10px !important;
  bottom: 15px !important; /* Keep this if you want it near the bottom */
  left: 50% !important;
  margin-left: 0px !important;
  transform: translateX(-50%) !important; /* Centers horizontally */
  border: 2px white solid !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  font-size: 0.8rem !important;
  font-weight: 800 !important;
  letter-spacing: 1.1px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 500 !important;
}
</style>
