<template>
  <v-row justify="center">
    <v-dialog :value="value" persistent max-width="700">
      <v-card class="card mx-auto" height="100%">
        <v-toolbar elevation="0">
          <v-btn
            icon
            fab
            color="#C62828"
            x-small
            v-on:click="$emit('popClose')"
            class="mr-1"
          >
            <Icon
              class="d-flex justify-center"
              icon="ic:outline-close"
              color="#000000"
              width="25"
            />
          </v-btn>

          <v-toolbar-title class="mx-auto pl-16" style="color: #50535b">
            <h3 class="font-semiBold">تعديل الصورة الشخصية</h3>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-title class="mt-4 d-flex justify-center">
          <v-row class="">
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-card color="#F3F3F4" class="card">
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  :includeStyling="true"
                  :useCustomSlot="true"
                  @vdropzone-removed-file="removeFile"
                  @vdropzone-success="uploadSuccess"
                  @vdropzone-error="fileUploadError"
                >
                  <div class="dropzone-custom-content">
                    <v-img
                      src="@/assets/upload_image.png"
                      width="70"
                      class="mx-auto"
                    ></v-img>
                  </div>
                </vue-dropzone>
              </v-card>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions class="mt-3 pb-5">
          <v-btn
            large
            @click="update_image"
            :loading="loading"
            color="#f28218"
            class="btn-a py-5 mx-auto"
            elevation="4"
          >
            <h3 style="color: white" class="data_table">حفظ</h3>
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon color="white">mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      image: "",
      dropzoneOptions: {
        url: "https://efredgvrergv34345435.online/api/api/upload_file",
        method: "post",
        thumbnailWidth: 200,
        maxFilesize: 2,
        parallelUploads: 1,
        uploadMultiple: false,
        addRemoveLinks: true,
        acceptedFiles: ".pdf,.png,.jpeg,.jpg",
        maxFiles: 3,

        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"), // or wherever your token is stored
        },
      },
    };
  },
  computed: {
    loading() {
      return this.$store.state.trainer.update_image_trainer_loading;
    },
  },
  methods: {
    update_image() {
      let data = {};
      data["image"] = this.image;

      this.$store.dispatch("trainer/update_image_trainer", data).then(() => {
        this.$emit("popClose");
      });
    },

    uploadSuccess(file, response) {
      this.image = response["file"];
    },
    fileUploadError() {
      let snack_message = {};
      snack_message["color"] = "#ef4444";
      snack_message["icon"] = "ri:close-circle-fill";
      snack_message["text"] = "لا يمكنك تحميل ملفات من هذا النوع.";
      this.$store.commit("SNACK_MESSAGE", snack_message);
      setTimeout(() => {
        this.$store.commit("TIME_OUT", snack_message);
      }, 4000);
    },

    removeFile() {
      this.image = "";
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.btn-a {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 174px !important;
}
/*  dropzone */

.vue-dropzone > .dz-preview .dz-remove {
  /* margin-right: 0px !important; */
  margin-left: 0 !important;
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 500 !important;
}
.dropzone-custom-content {
  margin-top: 50px;
  text-align: center;
}

.dropzone-custom-title {
  font-family: "Cairo", sans-serif;
  margin-top: 0;
  color: #000000;
}

.subtitle {
  font-family: "Cairo", sans-serif;
  color: #314b5f;
}
.dropzone {
  height: 270px;
  border-radius: 25px;
  border-color: #ffffff;
  padding: 0px 20px !important;
  display: flex !important;
  justify-content: center !important;
}
@media (min-width: 1182px) {
  /* Adjust for web (desktop and larger screens) */
  .dropzone {
    height: 230px !important;
  }
}

.vue-dropzone {
  background-color: #f3f3f3 !important;
}
.dz-preview {
  height: 100px !important;
  display: block !important;
}

.dz-details {
  top: 0px !important;
}
.dz-error-message {
  top: 150px !important;
}
</style>
