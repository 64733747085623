<template>
  <v-row justify="center">
    <v-dialog :value="value" persistent max-width="700">
      <v-form ref="add_group">
        <v-card class="card mx-auto">
          <v-toolbar elevation="0">
            <v-btn
              icon
              fab
              color="#C62828"
              x-small
              v-on:click="$emit('popClose')"
              class="mr-1"
            >
              <Icon
                class="d-flex justify-center"
                icon="ic:outline-close"
                color="#000000"
                width="25"
              />
            </v-btn>

            <v-toolbar-title class="mx-auto pl-16" style="color: #50535b">
              <h3 class="font-semiBold">حدد الوقت بين الوجبات</h3>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-title class="mt-4 d-flex justify-start"
            ><h5
              class="data_table"
              style="
                color: #50535b;
                white-space: normal;
                word-wrap: break-word;
                text-align: start;
                max-width: 99%;
              "
            >
              سيتم تحديد الفارق الزمني بين وجبة وأخرى بناء على المكتوب أدناه
            </h5>

            <v-row class="mt-5">
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="12"
                class="d-flex justify-center"
              >
                <span style="display: flex; align-items: center">
                  <v-btn
                    icon
                    large
                    style="background-color: #f97316"
                    class="mx-2"
                    @click="plus_time"
                  >
                    <Icon icon="ic:round-plus" color="#ffffff" width="20" />
                  </v-btn>

                  <v-text-field
                    :value="formattedTimeDifference"
                    class="data_table"
                    outlined
                    solo
                    hide-details
                    type="text"
                    readonly
                    style="text-align: center"
                  />
                  <v-btn
                    icon
                    large
                    style="background-color: #f97316"
                    class="mx-2"
                    @click="minus_time"
                  >
                    <Icon icon="line-md:minus" color="#ffffff" width="20" />
                  </v-btn>
                </span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-actions class="mt-3 pb-5">
            <v-btn
              large
              @click="update_time_between_meals"
              :loading="loading2"
              color="#f28218"
              class="btn-a py-5 mx-auto"
              elevation="4"
            >
              <h3 style="color: white" class="data_table">حفظ</h3>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      timeDifference: 15,
    };
  },
  created() {
    const savedTime = localStorage.getItem("timeDifference");
    if (savedTime) {
      this.timeDifference = parseInt(savedTime, 10);
    }
  },
  computed: {
    formattedTimeDifference() {
      // Convert to hours if >= 60 minutes
      if (this.timeDifference >= 60) {
        const hours = Math.floor(this.timeDifference / 60);
        const minutes = this.timeDifference % 60;
        return `${hours} ساعة${minutes > 0 ? ` و ${minutes} دقيقة` : ""}`;
      } else {
        return `${this.timeDifference} دقيقة`;
      }
    },

    loading() {
      return this.$store.state.news.add_groups_loading;
    },
    loading2() {
      return this.$store.state.meals.update_time_between_meals_loading;
    },
  },
  methods: {
    update_time_between_meals() {
      let data = {};
      data["meal_plan_id"] = this.$store.state.meals.meal_plan_id;
      data["time"] = this.timeDifference;
      this.$store.dispatch("meals/updateTimeBetweenMeals", data).then(() => {
        this.$emit("popClose");
      });
    },
    plus_time() {
      if (this.timeDifference < 240) {
        this.timeDifference += 15;
        localStorage.setItem("timeDifference", this.timeDifference);
        this.$store.commit("meals/setTimeDifference", this.timeDifference);
      }
    },
    minus_time() {
      if (this.timeDifference != 15) {
        this.timeDifference -= 15;
        localStorage.setItem("timeDifference", this.timeDifference);
        this.$store.commit("meals/setTimeDifference", this.timeDifference);
      }
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.btn-a {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 174px !important;
}
</style>
