<template>
  <v-row justify="center">
    <v-dialog :value="value" persistent max-width="700">
      <v-card class="card mx-auto">
        <v-toolbar elevation="0">
          <v-btn
            icon
            fab
            color="#C62828"
            x-small
            v-on:click="$emit('popClose')"
            class="mr-1"
          >
            <Icon
              class="d-flex justify-center"
              icon="ic:outline-close"
              color="#000000"
              width="25"
            />
          </v-btn>

          <v-toolbar-title class="mx-auto pl-16">
            <h3 style="color: #50535b" class="font-semiBold">تغير اشتراك</h3>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-title class="mt-4 d-flex justify-start">
          <h4
            class="pb-5 data_table"
            style="
              color: #50535b;
              white-space: normal;
              word-wrap: break-word;
              text-align: start;
              max-width: 99%;
            "
          >
            ملاحظه سوف يتم تغير الاشتراك الحالي الى الاشتراك الجديد
          </h4>
        </v-card-title>
        <v-card-actions class="mt-3 pb-5">
          <v-btn
            large
            @click="$emit('confirmReset')"
            color="#f97316"
            class="btn-a py-5 mx-auto"
            elevation="4"
          >
            <h3 style="color: white" class="data_table ml-2">تاكيد</h3>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.btn-a {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 174px !important;
}
</style>
