<template>
  <v-row justify="center">
    <v-dialog :value="value" max-width="700px" persistent scrollable>
      <v-card class="card mx-auto" width="100%" height="100%">
        <v-toolbar elevation="0" class="">
          <v-btn
            icon
            fab
            color="#C62828"
            x-small
            v-on:click="$emit('popClose')"
            class="mr-1"
          >
            <Icon
              class="icon_download"
              icon="ic:outline-close"
              color="#000000"
              width="25"
            />
          </v-btn>

          <v-toolbar-title class="mx-auto pl-16" style="color: #50535b">
            <h3 style="color: #50535b" class="font-semiBold">تعديل تمرين</h3>
          </v-toolbar-title>
        </v-toolbar>

        <v-form ref="edit_additional_course">
          <v-card-text class="mt-7">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <Input
                  @update-value="course_info.title = $event"
                  :value="course_info.title"
                  type="text"
                  label="اسم التمرين"
                  :rules="RulseAll"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <Selects
                  @update-value="is_before = $event"
                  :value="is_before"
                  color="#f97316"
                  itemValue="value"
                  itemText="type"
                  :items="befor_after"
                  label="قبل /و بعد الوجبة"
                  :rules="RulseAll"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <template v-for="(digit, index) in course_info.course_length">
                  <v-btn
                    :key="index"
                    @click="
                      course_info.count_of_day.includes(index + 1)
                        ? course_info.count_of_day.splice(
                            course_info.count_of_day.indexOf(index + 1),
                            1
                          )
                        : course_info.count_of_day.push(index + 1)
                    "
                    class="mx-2"
                    style="box-shadow: 0px 0px 0px 0px !important"
                    :color="
                      course_info.count_of_day.includes(digit)
                        ? '#F97300'
                        : '#F3F3F4'
                    "
                    fab
                    small
                  >
                    <h3
                      class="data_table"
                      :style="{
                        color: course_info.count_of_day.includes(digit)
                          ? '#ffffff'
                          : '#f28218',
                      }"
                    >
                      {{ digit }}
                    </h3>
                  </v-btn>
                </template>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mt-2 pb-5 mr-3">
            <v-btn
              large
              @click="edit_additional_course"
              :loading="loading"
              color="#f28218"
              class="btn-a py-5 mx-auto"
              elevation="4"
            >
              <h3 style="color: white" class="data_table">تعديل</h3>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Input from "@/components/layout/Input.vue";
import Selects from "@/components/layout/Selects.vue";

export default {
  components: { Input, Selects },
  data() {
    return {
      is_before: "",
      course_info: {},
      befor_after: [
        { type: "قبل", value: 0 },
        { type: "بعد", value: 1 },
      ],
      RulseAll: [(value) => !!value || "هذا الحقل مطلوب"],
    };
  },
  props: {
    course: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    loading() {
      return this.$store.state.courses.edit_course_loading;
    },
  },
  methods: {
    edit_additional_course() {
      if (this.$refs.edit_additional_course.validate()) {
        let data = {};
        data["course_id"] = this.course_info.id;
        data["subscribtion_session_id"] =
          this.$store.state.subscribtion_session_id;
        data["title"] = this.course_info.title;
        data["count_of_day"] = Number(this.course_info.count_of_day.join(""));
        //  data["course_length"] = Number(this.courses_length);
        data["is_after"] = this.is_before.value;

        this.$store
          .dispatch("courses/edit_additional_course", data)
          .then(() => {
            this.$emit("popClose");
          });
      }
    },
  },
  watch: {
    course() {
      this.course_info = { ...this.course };

      if (this.course_info.is_after == 0) {
        this.is_before = { type: "قبل", value: 0 };
      } else {
        this.is_before = { type: "بعد", value: 1 };
      }
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.btn-a {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 174px !important;
}
</style>
