import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Users from "../views/Users.vue";
import Calisthenics from "../views/Calisthenics.vue";
import Financial_Record from "../views/Financial_Record.vue";
import FinancialRecordPlayers from "../views/FinancialRecordPlayers.vue";
import detailsPlayeresFinancialRecord from "../views/detailsPlayeresFinancialRecord.vue";
import Notifications from "../views/Notifications.vue";
import ViewPendingTrainingRequests from "../views/ViewPendingTrainingRequests.vue";
import News from "../views/News.vue";
import TraineesProfile from "../views/TraineesProfile.vue";
import Settings from "../views/Settings.vue";

// Admin
import HomeAdmin from "../views/Admin/Home.vue";
import UsersAdmin from "../views/Admin/Users.vue";
import NotificationsAdmin from "../views/Admin/Notifications.vue";
import TrainerRegistrationRequests from "../views/Admin/TrainerRegistrationRequests.vue";
import Coach_Profile from "../views/Admin/Coach_Profile.vue";
import NewsAdmin from "../views/Admin/News.vue";
import MemberShips from "../views/Admin/MemberShips.vue";
import FinancialFecordAdmin from "../views/Admin/FinancialFecord.vue";
import FinancialRecordTrainerAdmin from "../views/Admin/FinancialRecordTrainer.vue";
import DetailsTrainersFinancialRecordAdmin from "../views/Admin/detailsTrainersFinancialRecord.vue";

import Login from "../views/login/Login.vue";
import Register from "../views/login/Register.vue";
import ForgotPassword from "../views/login/ForgotPassword.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "home_admin",
    component: HomeAdmin,
    beforeEnter: loggedInAdmin,
  },
  {
    path: "/admin/users",
    name: "admin_users",
    component: UsersAdmin,
    beforeEnter: loggedInAdmin,
  },
  {
    path: "/admin/notifications",
    name: "admin_notifications",
    component: NotificationsAdmin,
    beforeEnter: loggedInAdmin,
  },
  {
    path: "/admin/trainer-registration-requests",
    name: "TrainerRegistrationRequests",
    component: TrainerRegistrationRequests,
    beforeEnter: loggedInAdmin,
  },
  {
    path: "/admin/coach-profile",
    name: "Coach_Profile",
    component: Coach_Profile,
    beforeEnter: loggedInAdmin,
  },
  {
    path: "/admin/news",
    name: "News",
    component: NewsAdmin,
    beforeEnter: loggedInAdmin,
  },
  {
    path: "/admin/memberships",
    name: "MemberShips",
    component: MemberShips,
    beforeEnter: loggedInAdmin,
  },
  {
    path: "/admin/financial-record",
    name: "FinancialFecord",
    component: FinancialFecordAdmin,
    beforeEnter: loggedInAdmin,
  },
  {
    path: "/admin/financial-record-trainers",
    name: "FinancialRecordTrainer",
    component: FinancialRecordTrainerAdmin,
    beforeEnter: loggedInAdmin,
  },
  {
    path: "/admin/details-trainers-financial-record",
    name: "detailsTrainersFinancialRecord",
    component: DetailsTrainersFinancialRecordAdmin,
    beforeEnter: loggedInAdmin,
  },

  {
    path: "/",
    name: "home",
    component: Home,
    beforeEnter: loggedIn,
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    beforeEnter: loggedIn,
  },
  {
    path: "/calisthenics",
    name: "Calisthenics",
    component: Calisthenics,
    beforeEnter: loggedIn,
  },
  {
    path: "/financial-record",
    name: "financial_record",
    component: Financial_Record,
    beforeEnter: loggedIn,
  },
  {
    path: "/financial-record-players",
    name: "Financial_record_players",
    component: FinancialRecordPlayers,
    beforeEnter: loggedIn,
  },
  {
    path: "/details-playeres-financial-record",
    name: "detailsPlayeresFinancialRecord",
    component: detailsPlayeresFinancialRecord,
    beforeEnter: loggedIn,
  },
  {
    path: "/news",
    name: "news",
    component: News,
    beforeEnter: loggedIn,
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
    beforeEnter: loggedIn,
  },
  {
    path: "/view-pending-training-requests",
    name: "View_pending_training_requests",
    component: ViewPendingTrainingRequests,
    beforeEnter: loggedIn,
  },
  {
    path: "/trainees-profile",
    name: "trainees_profile",
    component: TraineesProfile,
    beforeEnter: loggedIn,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    beforeEnter: loggedIn,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    beforeEnter: redirect,
    meta: {
      hideNavbar: true,
      title: "تسجيل حساب جديد Elift",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: redirect,
    meta: {
      hideNavbar: true,
      title: "تسجيل الدخول Elift",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    beforeEnter: redirect,
    meta: {
      hideNavbar: true,
      title: " Elift",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function loggedInAdmin(to, from, next) {
  const token = localStorage.getItem("token");
  const user_type = localStorage.getItem("user_type");
  if (token && user_type == 200) {
    next();
  } else {
    next("/login");
  }
}
function loggedIn(to, from, next) {
  const token = localStorage.getItem("token");
  const user_type = localStorage.getItem("user_type");
  if (token && user_type == 0) {
    next();
  } else {
    next("/login");
  }
}
function redirect(to, from, next) {
  const token = localStorage.getItem("token");
  const user_type = localStorage.getItem("user_type");
  if (token && user_type == 0) {
    next("/");
  } else if (token && user_type == 200) {
    next("/home");
  } else {
    next();
  }
}

export default router;
