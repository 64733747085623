import { render, staticRenderFns } from "./PopReasonComment.vue?vue&type=template&id=0bf5e682&scoped=true"
import script from "./PopReasonComment.vue?vue&type=script&lang=js"
export * from "./PopReasonComment.vue?vue&type=script&lang=js"
import style0 from "./PopReasonComment.vue?vue&type=style&index=0&id=0bf5e682&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bf5e682",
  null
  
)

export default component.exports