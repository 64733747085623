<template>
  <v-container fluid class="mt-0">
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" class="pb-6">
        <v-card class="mx-auto mt-8 card-table" width="100%">
          <v-row class="d-flex justify-center mb-9">
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-data-table
                :headers="headers"
                :options.sync="pagination"
                :items="notes"
                :page.sync="pagination.page"
                :items-per-page="pagination.itemsPerPage"
                hide-default-footer
                :loading="loading"
                loading-text="جاري التحميل يرجى الأنتظار"
                height="600"
                :mobile-breakpoint="0"
                disable-sort
              >
                <template v-slot:top>
                  <v-toolbar flat class="mt-2 mb-2 toolbar">
                    <v-toolbar-title class="font-toolbar-title py-1"
                      >توصيات المدرب</v-toolbar-title
                    >
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          icon
                          x-small
                          v-bind="attrs"
                          v-on="on"
                          @click="reset_table"
                        >
                          <Icon
                            icon="solar:smartphone-update-bold"
                            color="#f28218"
                            width="32"
                          />
                        </v-btn>
                      </template>
                      <span>تحديث معلومات الجدول</span>
                    </v-tooltip>
                    <v-spacer> </v-spacer>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      lg="6"
                      class="d-flex justify-end"
                    >
                      <span style="display: flex; align-items: center">
                        <v-text-field
                          ref="titleField"
                          class="data_table"
                          v-model="title"
                          :disabled="loading2 == true"
                          type="text"
                          label="اكتب توصية ..."
                          solo
                          rounded
                          clearable
                          lazy-rules
                          color="#f28218"
                          hide-details="auto"
                          persistent-hint
                          style="width: 400px; text-align: center"
                          @keyup.enter="add_notes"
                        ></v-text-field>
                      </span>
                    </v-col>
                  </v-toolbar>
                </template>

                <template v-slot:item="{ item, index }">
                  <tr>
                    <td
                      class="text-start font-weight-black"
                      style="color: #50535b"
                    >
                      <h3 class="data_table">
                        <span style="color: #f97300">{{ index + 1 }}.</span>
                        {{ item.title }}
                      </h3>
                    </td>
                    <td class="text-center font-weight-black data_table">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="popEditNote(item)"
                            class="ml-4"
                            fab
                            icon
                            x-small
                            v-bind="attrs"
                            v-on="on"
                          >
                            <Icon
                              icon="fluent:notepad-edit-20-filled"
                              color="#F97300"
                              width="32"
                            />
                          </v-btn>
                        </template>
                        <span>تعديل الملاحظه</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            icon
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            @click="popDeleteNote(item.id)"
                          >
                            <Icon
                              icon="lets-icons:trash-duotone-line"
                              color="#C62828"
                              width="35"
                            />
                          </v-btn>
                        </template>
                        <span>حذف الملاحظة</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <div class="text-center py-5">
                <v-row>
                  <TraineeInformationUpdateBar />
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <popAddNote
          :value="pop_add_notes"
          v-on:popClose="pop_add_notes = !pop_add_notes"
        />
        <PopEditNote
          :value="pop_edit"
          :note="data_editor"
          v-on:popClose="pop_edit = !pop_edit"
        />
        <PopDelete
          :value="pop_delete"
          v-on:popClose="pop_delete = !pop_delete"
          v-on:deleteNote="deleteNote()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TraineeInformationUpdateBar from "@/components/layout/TraineeInformationUpdateBar.vue";
import popAddNote from "@/components/notes/popAddNote.vue";
import PopEditNote from "@/components/notes/PopEditNote.vue";
import PopDelete from "@/components/notes/PopDelete.vue";
export default {
  components: {
    popAddNote,
    PopDelete,
    PopEditNote,
    TraineeInformationUpdateBar,
  },
  data() {
    return {
      items: [5, 10, 15, 20, 25],
      pagination: {},

      pop_add_notes: false,

      pop_delete: false,
      data_deleted: null,

      pop_edit: false,
      data_editor: null,

      headers: [
        {
          text: "الملاحظة",
          value: "title",
          class: "secondary white--text subtitle-1",
          align: "start",
          width: "200px",
        },

        {
          text: "العمليات",
          value: "calories",
          class: "secondary white--text subtitle-1",
          align: "center",
          width: "170px",
        },
      ],

      title: "",
      RulseAll: [(value) => !!value || "هذا الحقل مطلوب"],

      AllRules: [(value) => !!value || "هذا الحقل مطلوب"],
    };
  },
  computed: {
    //   server() {
    //     return this.$store.state.server;
    //   },
    pageCount() {
      return this.$store.state.notes.pageCount;
    },
    notes() {
      return this.$store.state.notes.notes;
    },
    loading() {
      return this.$store.state.notes.notes_loading;
    },
    loading2() {
      return this.$store.state.notes.add_note_loading;
    },

    params: {
      set(val) {
        this.$store.state.notes.params = val;
      },
      get() {
        return this.$store.state.notes.params;
      },
    },
  },
  methods: {
    get_notes() {
      let pagination = this.pagination;
      let par = {
        ...pagination,
      };
      this.params = par;
      this.$store.dispatch("notes/get_notes");
    },

    add_ingredients() {
      this.pop_add_ingredients = true;
    },

    popAddNote() {
      this.pop_add_notes = true;
    },
    popDeleteNote(id) {
      this.data_deleted = id;
      this.pop_delete = true;
    },
    deleteNote() {
      let data = {};
      data["note_id"] = this.data_deleted;
      this.$store.dispatch("notes/deleteNote", data).then(() => {
        this.pop_delete = false;
        this.data_deleted = null;
      });
    },

    popEditNote(item) {
      this.data_editor = item;
      this.pop_edit = true;
    },

    reset_table() {
      this.$store.dispatch("notes/reset_table");
      this.get_notes();
    },
    add_notes() {
      if (this.title != null && this.title != "") {
        let data = {};
        data["subscribtion_session_id"] =
          this.$store.state.subscribtion_session_id;
        data["title"] = this.title;

        this.$store.dispatch("notes/addNote", data).then(() => {
          this.title = "";
          this.$nextTick(() => {
            this.$refs.titleField.focus();
          });
        });
      }
    },
  },
  watch: {
    pagination: {
      handler() {
        this.get_notes();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.card-table {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 12px !important;
  border: 1px solid #cbccce;
}
.profile {
  cursor: pointer;
}

.toggle-switch {
  background-color: white;
  border: 1px solid #dcdcdc;
  border-radius: 20px;
  width: fit-content;
}
.selected-btn {
  background-color: white !important;
  color: #f28218 !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.selected-btn-off {
  background-color: #f28218 !important;
  color: white !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.sheet {
  border-radius: 15px !important;
}
.p-btn {
  background-color: #f97316 !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.data_table {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 500 !important;
}
</style>
