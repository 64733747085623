<template>
  <v-select
    :value="value"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    item-color="orange darken-1"
    return-object
    class="data_table"
    :label="label"
    :disabled="disabled"
    color="#f28218"
    rounded
    clearable
    solo
    lazy-rules
    @input="$emit('update-value', $event)"
    :hint="hint"
    persistent-hint
    :rules="rules"
    hide-details="auto"
  ></v-select>
</template>
<script>
export default {
  props: {
    label: String,
    value: {
      type: [Object, Number, String],
      default: null,
    },
    itemText: {
      type: [String],
      required: true,
    },
    itemValue: {
      type: [String, Number],
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    rules: {
      type: Array,
      required: false,
    },
    hint: {
      type: String,
      required: false,
    },
  },
  methods: {},
};
</script>
<style>
.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  line-height: 2.2;
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 500 !important;
}
</style>
