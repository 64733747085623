<template>
  <v-row>
    <v-col cols="12" sm="12" md="12" lg="12" class="pb-8">
      <v-card class="mx-auto card" width="98%">
        <v-toolbar dark color="#F97316">
          <v-toolbar-title class="mx-auto font-toolbar-title2">
            طلبات التدريب المعلقة
          </v-toolbar-title>
        </v-toolbar>
        <v-list
          class="list"
          style="max-height: 200px; overflow-y: auto"
          @scroll.native="onScroll"
          ref="scrollContainer"
        >
          <v-list-item
            v-for="(request, index) in requests"
            :key="index"
            class="py-1 my-2 mx-2 list-item"
          >
            <v-list-item-avatar>
              <img
                v-if="request.trainee.images.length > 0"
                :src="server + request.trainee.images[0].url"
                width="50"
                height="50"
                alt="error"
                class="name_coach"
              />
              <Icon icon="solar:user-bold" color="#f97316" width="30" v-else />
            </v-list-item-avatar>
            <v-list-item-content dir="rtl">
              <v-list-item-title class="data_table">{{
                request.trainee.full_name
              }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <span>
                <v-btn
                  icon
                  style="background-color: #f97316"
                  @click="acceptRequest(request.id, index)"
                  :loading="index == check_index ? accept_loading : null"
                >
                  <Icon
                    icon="material-symbols:check"
                    color="#ffffff"
                    width="30"
                  />
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon color="white">mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
                <v-btn
                  icon
                  style="background-color: #f97316"
                  class="mr-2"
                  @click="rejectRequest(request.id, index)"
                  :loading="index == check_index ? reject_loading : null"
                >
                  <Icon
                    icon="material-symbols:close"
                    color="#ffffff"
                    width="30"
                  />
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon color="white">mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
                <v-btn
                  icon
                  style="background-color: #f97316"
                  class="mr-2"
                  @click="display_pending_training(request)"
                >
                  <Icon icon="mdi:eye" color="#ffffff" width="25" />
                </v-btn>
              </span>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="loading == true">
            <h3 class="mx-auto font-semiBold" style="color: #000000">
              جاري التحميل يرجى الأنتظار
            </h3>
          </v-list-item>

          <v-list-item v-if="requests.length == 0 && loading == false">
            <h3 class="mx-auto font-semiBold" style="color: #000000">
              لايوجد طلبات معلقة
            </h3>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      lastScrollTop: 0, // To track scroll direction
      check_index: null,
    };
  },
  created() {
    this.$store.dispatch("training_requests/get_all_trainer_request");
  },
  computed: {
    server() {
      return this.$store.state.server;
    },
    requests() {
      return this.$store.state.training_requests.requests;
    },
    loading() {
      return this.$store.state.training_requests.trainer_requests_loading;
    },
    accept_loading() {
      return this.$store.state.training_requests.accept_request_loading;
    },
    reject_loading() {
      return this.$store.state.training_requests.reject_request_loading;
    },
  },
  methods: {
    display_pending_training(request) {
      let info_member_ship = {};
      info_member_ship["membership_id"] = request.membership_id;
      info_member_ship["app_subscription_month"] =
        request.app_subscription_month;
      info_member_ship["request_type"] = request.request_type;

      localStorage.setItem(
        "info_member_ship",
        JSON.stringify(info_member_ship)
      );
      this.$store.commit("trainees/setInfoMemberShip", info_member_ship);

      localStorage.setItem("pending_trainee_id", request.trainee.id);
      localStorage.setItem("trainer_request_id", request.id);
      this.$store.commit("trainees/setIdTraineePending", request.trainee.id);
      this.$router.push({ name: "View_pending_training_requests" });
    },
    onScroll(event) {
      var requests_state = this.$store.state.training_requests.requests_state;
      const container = event.target;
      const isScrollingDown = container.scrollTop > this.lastScrollTop;
      this.lastScrollTop = container.scrollTop;
      if (
        isScrollingDown &&
        container.scrollTop + container.clientHeight >=
          container.scrollHeight - 50 &&
        !this.loading &&
        requests_state == "done"
      ) {
        this.$store.dispatch("training_requests/get_all_trainer_request");
      }
    },
    acceptRequest(id, index) {
      this.check_index = index;
      let data = {};
      data["trainer_request_id"] = id;
      this.$store.dispatch("training_requests/acceptRequest", data);
    },
    rejectRequest(id, index) {
      this.check_index = index;
      let data = {};
      data["trainer_request_id"] = id;
      this.$store.dispatch("training_requests/rejectRequest", data);
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
  background-color: #d4d6d7; /*f3f3f4*/
}

.list-item {
  background-color: #ffffff;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
}
.list {
  background-color: #d5d6d7;
  border-radius: 20px !important;
}
</style>
