<template>
  <v-dialog :value="value" max-width="700px" persistent scrollable>
    <v-card class="card mx-auto" width="100%" height="100%">
      <v-toolbar elevation="0">
        <v-btn
          icon
          fab
          color="#C62828"
          x-small
          v-on:click="$emit('popClose')"
          class="mr-1"
        >
          <Icon
            class="icon_download"
            icon="ic:outline-close"
            color="#000000"
            width="25"
          />
        </v-btn>

        <v-toolbar-title class="mx-auto pl-16" style="color: #50535b">
          <h3 style="color: #50535b" class="font-semiBold">
            ما هو سبب التعليق
          </h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-form ref="add_message">
        <v-card-text class="mt-7">
          <h3
            class="pb-5 data_table"
            style="
              color: #50535b;
              white-space: normal;
              word-wrap: break-word;
              text-align: start;
              max-width: 99%;
            "
          >
            سيتم إشعار المدرب بهذا التعليق , مع سببه.
          </h3>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <Textarea
                @update-value="ban_reasone = $event"
                :value="ban_reasone"
                type="text"
                label="اكتب سبب التعليق للمدرب ..."
                :rules="RulseAll"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-2 pb-5 mr-3">
          <v-btn
            large
            @click="add_message"
            color="#f28218"
            class="btn-a py-5 mx-auto"
            elevation="4"
          >
         
            <h3 class="data_table ml-1" style="color: white;">
              تأكيد وتعليق
            </h3>
            <Icon icon="uim:lock-alt" color="#ffffff" width="26" />
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import Textarea from "@/components/layout/Textarea.vue";

export default {
  components: { Textarea },
  data() {
    return {
      ban_reasone: null,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    RulseAll() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 3 || "الحد الادنى لعدد الاحرف هوه 3",
        (value) =>
          (value || "").length < 512 || "الحد الاقصى لعدد الاحرف هوه 510",
      ];
    },
  },
  methods: {
    add_message() {
      this.$emit("ban", this.ban_reasone);
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.btn-a {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 174px !important;
}
</style>
