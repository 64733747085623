<template>
  <v-container fluid class="mt-0">
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        class="pb-8"
        v-if="loading == false"
      >
        <v-card class="mx-auto card" width="98%">
          <v-toolbar dark color="#F97316">
            <v-toolbar-title class="mx-auto font-toolbar-title2">
              طلب تدريب
            </v-toolbar-title>
          </v-toolbar>
          <v-card-title class="d-flex justify-center">
            <span style="display: flex; align-items: center">
              <v-sheet
                class="sheet d-flex justify-center"
                height="40"
                width="40"
                color="#f97316"
                style="
                  border-radius: 13px;
                  box-shadow: 0px 0px 0px 0px !important;
                "
              >
                <span style="display: flex; align-items: center">
                  <Icon icon="flowbite:user-solid" color="#ffffff" width="30"
                /></span>
              </v-sheet>
              <h4 style="color: #393c43" class="mx-3 data_table">
                اسم اللاعب : {{ player_pending.trainee.full_name }}
              </h4>
            </span>

            <span style="display: flex; align-items: center">
              <v-sheet
                class="sheet d-flex justify-center"
                height="40"
                width="40"
                color="#f97316"
                style="
                  border-radius: 13px;
                  box-shadow: 0px 0px 0px 0px !important;
                "
              >
                <span style="display: flex; align-items: center">
                  <Icon icon="flowbite:phone-solid" color="#ffffff" width="26"
                /></span>
              </v-sheet>
              <h4 style="color: #393c43" class="mx-3 data_table">
                {{ player_pending.trainee.phone }}
              </h4>
            </span>
            <span style="display: flex; align-items: center">
              <v-sheet
                class="sheet d-flex justify-center"
                height="40"
                width="40"
                color="#f97316"
                style="
                  border-radius: 13px;
                  box-shadow: 0px 0px 0px 0px !important;
                "
              >
                <span style="display: flex; align-items: center">
                  <Icon icon="fluent:home-32-filled" color="#ffffff" width="26"
                /></span>
              </v-sheet>
              <h4
                style="color: #393c43"
                class="mx-3 data_table"
                v-if="
                  player_pending.trainee.profile != null &&
                  player_pending.trainee.profile.address != null
                "
              >
                {{ player_pending.trainee.profile.address }}
              </h4>
              <h4 style="color: #393c43" class="mx-3 data_table" v-else>
                لايوجد
              </h4>
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row class="text-center">
              <v-col cols="6" sm="4" md="2" lg="3">
                <v-card class="mx-auto card2">
                  <v-card-title class="pb-1 d-flex justify-center">
                    <Icon
                      icon="flowbite:user-solid"
                      color="#f97316"
                      width="26"
                    />
                  </v-card-title>
                  <v-card-title class="py-0 d-flex justify-center">
                    <h5
                      class="font-semiBold"
                      v-if="
                        player_pending.trainee.profile != null &&
                        player_pending.trainee.profile.weight != null
                      "
                    >
                      {{ player_pending.trainee.profile.weight }}kg
                    </h5>
                    <h5 class="font-semiBold" v-else>لايوجد</h5>
                  </v-card-title>
                  <v-card-title class="pt-0 d-flex justify-center">
                    <h5 style="color: #f97316" class="data_table">الوزن</h5>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="6" sm="4" md="2" lg="3">
                <v-card class="mx-auto card2">
                  <v-card-title class="pb-1 d-flex justify-center">
                    <Icon
                      icon="flowbite:user-solid"
                      color="#f97316"
                      width="26"
                    />
                  </v-card-title>
                  <v-card-title class="py-0 d-flex justify-center">
                    <h5
                      class="font-semiBold"
                      v-if="
                        player_pending.trainee.profile != null &&
                        player_pending.trainee.profile.age != null
                      "
                    >
                      {{ player_pending.trainee.profile.age }} سنة
                    </h5>
                    <h5 class="font-semiBold" v-else>لايوجد</h5>
                  </v-card-title>
                  <v-card-title class="pt-0 d-flex justify-center">
                    <h5 style="color: #f97316" class="data_table">العمر</h5>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="6" sm="4" md="2" lg="3">
                <v-card class="py-0 card2">
                  <v-card-title class="pb-1 d-flex justify-center">
                    <Icon
                      icon="flowbite:user-solid"
                      color="#f97316"
                      width="26"
                    />
                  </v-card-title>
                  <v-card-title class="py-0 d-flex justify-center">
                    <h5
                      class="font-semiBold"
                      v-if="
                        player_pending.trainee.profile != null &&
                        player_pending.trainee.profile.length != null
                      "
                    >
                      {{ player_pending.trainee.profile.length }}cm
                    </h5>
                    <h5 class="font-semiBold" v-else>لايوجد</h5>
                  </v-card-title>
                  <v-card-title class="pt-0 d-flex justify-center">
                    <h5 style="color: #f97316" class="data_table">الطول</h5>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="6" sm="4" md="2" lg="3">
                <v-card class="py-0 card2">
                  <v-card-title class="pb-1 d-flex justify-center">
                    <Icon
                      icon="flowbite:user-solid"
                      color="#f97316"
                      width="26"
                    />
                  </v-card-title>
                  <v-card-title class="py-0 d-flex justify-center">
                    <h5
                      class="font-semiBold"
                      v-if="
                        player_pending.trainee.profile != null &&
                        player_pending.trainee.profile.physical_disabilities !=
                          null
                      "
                    >
                      {{ player_pending.trainee.profile.physical_disabilities }}
                    </h5>
                    <h5 class="font-semiBold" v-else>غير معرف</h5>
                  </v-card-title>
                  <v-card-title class="pt-0 d-flex justify-center">
                    <h5 style="color: #f97316" class="data_table">
                      الاعاقات الجسدية
                    </h5>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="6" sm="4" md="2" lg="3">
                <v-card class="py-0 card2">
                  <v-card-title class="pb-1 d-flex justify-center">
                    <Icon
                      icon="flowbite:user-solid"
                      color="#f97316"
                      width="26"
                    />
                  </v-card-title>
                  <v-card-title class="py-0 d-flex justify-center">
                    <h5
                      class="font-semiBold"
                      v-if="
                        player_pending.trainee.profile != null &&
                        player_pending.trainee.profile.years_of_training > 2
                      "
                    >
                      {{ player_pending.trainee.profile.years_of_training }}
                      سنوات
                    </h5>
                    <h5
                      class="font-semiBold"
                      v-if="
                        player_pending.trainee.profile != null &&
                        player_pending.trainee.profile.years_of_training < 2
                      "
                    >
                      {{ player_pending.trainee.profile.years_of_training }}
                      سنة
                    </h5>
                    <h5
                      class="font-semiBold"
                      v-if="
                        player_pending.trainee.profile == null ||
                        player_pending.trainee.profile.years_of_training == null
                      "
                    >
                      لايوجد
                    </h5>
                  </v-card-title>
                  <v-card-title class="pt-0 d-flex justify-center">
                    <h5 style="color: #f97316" class="data_table">
                      العمر التدريبي
                    </h5>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="6" sm="4" md="2" lg="3">
                <v-card class="py-0 card2">
                  <v-card-title class="pb-1 d-flex justify-center">
                    <Icon
                      icon="flowbite:user-solid"
                      color="#f97316"
                      width="26"
                    />
                  </v-card-title>
                  <v-card-title class="py-0 d-flex justify-center">
                    <h5
                      class="font-semiBold"
                      v-if="
                        player_pending.trainee.profile != null &&
                        player_pending.trainee.profile.sleep_quality == 'poor'
                      "
                    >
                      سيئة
                    </h5>
                    <h5
                      class="font-semiBold"
                      v-if="
                        player_pending.trainee.profile != null &&
                        player_pending.trainee.profile.sleep_quality == 'poor'
                      "
                    >
                      سيئة
                    </h5>
                    <h5
                      class="font-semiBold"
                      v-else-if="
                        player_pending.trainee.profile != null &&
                        player_pending.trainee.profile.sleep_quality ==
                          'average'
                      "
                    >
                      متوسطة
                    </h5>
                    <h5
                      class="font-semiBold"
                      v-else-if="
                        player_pending.trainee.profile != null &&
                        player_pending.trainee.profile.sleep_quality == 'good'
                      "
                    >
                      جيدة
                    </h5>
                    <h5
                      class="font-semiBold"
                      v-else-if="
                        player_pending.trainee.profile != null &&
                        player_pending.trainee.profile.sleep_quality ==
                          'excellent'
                      "
                    >
                      ممتازة
                    </h5>
                    <h5 class="font-semiBold" v-else>لايوجد</h5>
                  </v-card-title>
                  <v-card-title class="pt-0 d-flex justify-center">
                    <h5 style="color: #f97316" class="data_table">
                      جودة النوم
                    </h5>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="6" sm="4" md="2" lg="3">
                <v-card class="py-0 card2">
                  <v-card-title class="pb-1 d-flex justify-center">
                    <Icon
                      icon="flowbite:user-solid"
                      color="#f97316"
                      width="26"
                    />
                  </v-card-title>
                  <v-card-title class="py-0 d-flex justify-center">
                    <h5 class="font-semiBold">
                      {{ filteredMembership.title }}
                    </h5>
                  </v-card-title>
                  <v-card-title class="pt-0 d-flex justify-center">
                    <h5 style="color: #f97316" class="data_table">
                      نوع الاشتراك
                    </h5>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="6" sm="4" md="2" lg="3">
                <v-card class="py-0 card2">
                  <v-card-title class="pb-1 d-flex justify-center">
                    <Icon
                      icon="flowbite:user-solid"
                      color="#f97316"
                      width="26"
                    />
                  </v-card-title>
                  <v-card-title class="py-0 d-flex justify-center">
                    <h5 class="font-semiBold">
                      {{ info_member_ship.app_subscription_month }} شهر
                    </h5>
                  </v-card-title>
                  <v-card-title class="pt-0 d-flex justify-center">
                    <h5 style="color: #f97316" class="data_table">
                      مدة الاشتراك
                    </h5>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card class="card mx-auto" width="1200">
            <v-carousel
              hide-delimiters
              class="mt-5 carousel"
              v-if="player_pending.trainee.images.length > 0"
            >
              <v-carousel-item
                v-for="(image, index) in player_pending.trainee.images"
                :key="index"
              >
                <div class="carousel-image-container">
                  <img
                    :src="server + image.url"
                    alt="Trainer Image"
                    class="carousel-image"
                  />
                </div>
              </v-carousel-item>
            </v-carousel>
            <v-carousel hide-delimiters class="mt-5 carousel" v-else>
              <v-carousel-item>
                <div class="carousel-image-container">
                  <img
                    src="@/assets/profile.jpeg"
                    alt="Default Profile"
                    class="carousel-image"
                  />
                </div>
              </v-carousel-item>
            </v-carousel>
          </v-card>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              large
              style="background-color: #84cc16"
              class="mr-2 btn"
              :loading="accept_loading"
              :disabled="reject_loading"
              @click="acceptRequest(player_pending.id)"
            >
              <Icon
                icon="akar-icons:check-box-fill"
                color="#ffffff"
                width="26"
              />
              <h3 style="color: #ffffff" class="data_table mr-1">موافقة</h3>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
            <v-btn
              large
              style="background-color: #ef4444"
              class="mr-2 btn"
              :disabled="accept_loading"
              :loading="reject_loading"
              @click="rejectRequest(player_pending.id)"
            >
              <Icon icon="solar:close-square-bold" color="#ffffff" width="26" />
              <h3 style="color: #ffffff" class="data_table mr-1">رفض</h3>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        class="pb-8"
        v-if="loading == true"
      >
        <v-row v-if="loading == true" class="d-flex justify-center">
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="2"
            v-for="(n, index) in 12"
            :key="'A' + index"
          >
            <v-skeleton-loader
              max-width="510"
              type="actions"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-skeleton-loader
              class="mx-auto"
              max-width="510"
              height="350"
              type="image, actions"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("trainer/getMemberShips");
    this.$store.dispatch("trainees/get_info_trainee_to_display");
  },
  computed: {
    server() {
      return this.$store.state.server;
    },
    member_Ships() {
      return this.$store.state.trainer.member_Ships;
    },
    info_member_ship() {
      return this.$store.state.trainees.info_member_ship;
    },
    filteredMembership() {
      // Find the membership with the matching membership_id
      return this.member_Ships.find(
        (membership) => membership.id === this.info_member_ship.membership_id
      );
    },
    player_pending() {
      return this.$store.state.trainees.player_pending;
    },
    loading() {
      return this.$store.state.trainees.info_trainee_loading;
    },
    accept_loading() {
      return this.$store.state.training_requests.accept_request_loading;
    },
    reject_loading() {
      return this.$store.state.training_requests.reject_request_loading;
    },
  },
  methods: {
    acceptRequest() {
      let data = {};
      data["trainer_request_id"] = localStorage.getItem("trainer_request_id");
      this.$store.dispatch("training_requests/acceptRequest", data).then(() => {
        this.$router.push({ name: "home" });
      });
    },
    rejectRequest() {
      let data = {};
      data["trainer_request_id"] = localStorage.getItem("trainer_request_id");
      this.$store.dispatch("training_requests/rejectRequest", data).then(() => {
        this.$router.push({ name: "home" });
      });
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
  background-color: #ffffff; /*f3f3f4*/
}
.card2 {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
  background-color: #f3f3f4; /*f3f3f4*/
}
.carousel {
  border-radius: 20px !important;
}
.btn {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 25px 174px !important;
}

.carousel {
  max-height: 400px; /* Set maximum carousel height */
}

.carousel-image-container {
  display: flex; /* Center the image */
  justify-content: center;
  align-items: center;
  height: 100%; /* Take full height of the carousel item */
  overflow: hidden; /* Prevent content overflow */
}

.carousel-image {
  max-width: 100%; /* Ensure the image width fits */
  max-height: 100%; /* Ensure the image height fits */
  object-fit: contain; /* Prevent cropping while maintaining aspect ratio */
}
</style>
