<template>
  <v-container fluid class="mt-0">
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" class="pb-6">
        <v-card class="mx-auto mt-8 card-table" width="100%">
          <v-row class="d-flex justify-center mb-9">
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-data-table
                :headers="headers"
                :items="trainees"
                :options.sync="pagination"
                :page.sync="pagination.page"
                :items-per-page="pagination.itemsPerPage"
                hide-default-footer
                :loading="loading || false"
                loading-text="جاري التحميل يرجى الأنتظار"
                height="600"
                disable-sort
                :mobile-breakpoint="0"
              >
                <template v-slot:top>
                  <v-toolbar flat class="mt-2 mb-2 toolbar">
                    <v-toolbar-title class="font-toolbar-title"
                      >جدول اللاعبين</v-toolbar-title
                    >
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="reset_table"
                          fab
                          icon
                          x-small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <Icon
                            icon="solar:smartphone-update-bold"
                            color="#f97316"
                            width="32"
                          />
                        </v-btn>
                      </template>
                      <span>تحديث معلومات الجدول</span>
                    </v-tooltip>

                    <v-spacer></v-spacer>
                    <v-text-field
                      v-if="$vuetify.breakpoint.smAndUp"
                      v-model="trainee_query"
                      @input="query_change"
                      append-icon="mdi-magnify"
                      label="بحث"
                      solo
                      class="font-weight-black data_table"
                      color="#f97316"
                      clearable
                      hide-details
                    />
                  </v-toolbar>
                </template>

                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-center font-weight-black data_table py-3">
                      <v-badge
                        v-if="
                          item.images.length > 0 &&
                          item.profile != null &&
                          item.profile.is_hero == 1
                        "
                        offset-x="54"
                        offset-y="15"
                        avatar
                        overlap
                        color="ffffff"
                      >
                        <template v-slot:badge>
                          <v-avatar>
                            <Icon icon="emojione:sports-medal" width="25" />
                          </v-avatar>
                        </template>

                        <v-avatar size="50">
                          <img
                            :src="server + item.images[0].url"
                            width="50"
                            height="50"
                            alt="error"
                            class="name_coach"
                          />
                        </v-avatar>
                      </v-badge>

                      <v-badge
                        v-else-if="
                          item.images.length == 0 &&
                          item.profile != null &&
                          item.profile.is_hero == 1
                        "
                        offset-x="54"
                        offset-y="15"
                        avatar
                        overlap
                        color="ffffff"
                      >
                        <template v-slot:badge>
                          <v-avatar>
                            <Icon icon="emojione:sports-medal" width="25" />
                          </v-avatar>
                        </template>

                        <v-avatar size="50">
                          <img
                            src="@/assets/Elift-logo.png"
                            width="50"
                            height="50"
                            alt="error"
                            class="name_coach"
                          />
                        </v-avatar>
                      </v-badge>
                      <v-avatar
                        size="50"
                        v-else-if="
                          item.images.length > 0 &&
                          item.profile != null &&
                          item.profile.is_hero == 0
                        "
                      >
                        <img
                          :src="server + item.images[0].url"
                          width="50"
                          height="50"
                          alt="error"
                          class="name_coach2"
                        />
                      </v-avatar>
                      <v-avatar size="50" v-else>
                        <img
                          src="@/assets/Elift-logo.png"
                          width="50"
                          height="50"
                          alt="error"
                          class="name_coach2"
                        />
                      </v-avatar>
                    </td>
                    <td class="text-center font-weight-black data_table py-3">
                      {{ item.full_name }}
                    </td>
                    <td class="text-center font-weight-black data_table py-3">
                      <h4
                        class="data_table"
                        v-if="
                          item.profile != null && item.profile.address != null
                        "
                      >
                        {{ item.profile.address }}
                      </h4>
                      <h4 class="data_table" v-else style="color: red">
                        لايوجد
                      </h4>
                    </td>
                    <td class="text-center font-weight-black data_table py-3">
                      {{ item.phone }}
                    </td>
                    <td class="text-center font-weight-black py-3">
                      <h4
                        class="data_table"
                        v-if="
                          item.app_subscriptions != null &&
                          item.app_subscriptions.membership
                        "
                      >
                        {{ item.app_subscriptions.membership.title }}
                      </h4>

                      <h4 class="data_table" v-else style="color: red">
                        لايوجد
                      </h4>
                    </td>
                    <td class="text-center font-weight-black py-3">
                      <h4
                        v-if="item.active_subscription != null"
                        class="data_table"
                      >
                        {{
                          calculateRemainingDays(item.active_subscription.end_date)
                        }}
                      </h4>
                      <h4 class="data_table" v-else style="color: red">
                        لايوجد
                      </h4>
                    </td>

                    <td class="text-center font-weight-black data_table py-3">
                      <v-btn
                        color="#f28218"
                        style="border-radius: 10px !important"
                        @click="open_player_information(item.id)"
                      >
                        <h4 class="data_table" style="color: #ffffff">
                          فتح معلومات اللاعب
                        </h4>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <div class="text-center py-5">
                <v-row>
                  <v-col cols="12" sm="6" md="3" lg="3">
                    <v-pagination
                      v-model="pagination.page"
                      :length="pageCount"
                      circle
                      class="data_table"
                      color="#f97316"
                    ></v-pagination>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" lg="3">
                    <v-select
                      v-model="pagination.itemsPerPage"
                      :items="items"
                      color="#f97316"
                      item-color="orange lighten-1"
                      solo
                      single-line
                      hide-details
                      label="عدد العناصر"
                      class="d-flex justify-center data_table px-2"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      items: [5, 10, 15, 20, 25],
      pagination: {},
      headers: [
        {
          text: "صورة الاعب",
          value: "archive_number",
          class: "secondary white--text subtitle-1",
          align: "center",
          width: "170px",
        },
        {
          text: "إسم اللاعب",
          value: "file_number_save",
          class: "secondary white--text subtitle-1",
          align: "center",
          width: "180px",
        },
        {
          text: "العنوان",
          value: "title",
          class: "secondary white--text subtitle-1",
          align: "center",
          width: "150px",
        },
        {
          text: "رقم الهاتف",
          value: "exports",
          class: "secondary white--text subtitle-1",
          align: "center",
          width: "150px",
        },
        {
          text: "نوع الاشتراك",
          value: "imports",
          class: "secondary white--text subtitle-1",
          align: "center",
          width: "180px",
        },
        {
          text: "الايام الاشتراك",
          value: "imports",
          class: "secondary white--text subtitle-1",
          align: "center",
          width: "180px",
        },
        {
          text: "العمليات",
          value: "files",
          class: "secondary white--text subtitle-1",
          align: "center",
          width: "130px",
        },
      ],
    };
  },
  computed: {
    server() {
      return this.$store.state.server;
    },
    pageCount() {
      return this.$store.state.trainees.pageCount;
    },

    trainees() {
      return this.$store.state.trainees.trainees;
    },
    loading() {
      return this.$store.state.trainees.trainees_loading;
    },
    params: {
      set(val) {
        this.$store.state.trainees.params = val;
      },
      get() {
        return this.$store.state.trainees.params;
      },
    },
    trainee_query: {
      set(val) {
        this.$store.state.trainees.trainee_query = val;
      },
      get() {
        return this.$store.state.trainees.trainee_query;
      },
    },
  },
  methods: {
    get_trainees() {
      let pagination = this.pagination;
      let par = {
        ...pagination,
      };
      this.params = par;
      this.$store.dispatch("trainees/get_trainees");
    },
    query_change() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.get_trainees();
        this.pagination.page = 1;
      }, 1000);
    },

    reset_table() {
      this.$store.dispatch("trainees/reset_table");
      this.get_trainees();
    },
    open_player_information(id) {
      localStorage.setItem("trainee_id", id);
      this.$store.commit("trainees/setIdTrainee", id);
      this.$router.push({ name: "trainees_profile" });
    },
    calculateRemainingDays(endDate) {
      const currentDate = new Date();
      const subscriptionEndDate = new Date(endDate);

      // Calculate the difference in time (milliseconds)
      const timeDifference = subscriptionEndDate - currentDate;

      // Convert time difference to days
      const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      // Return the days remaining or handle expired subscriptions
      return daysRemaining > 0 ? `باقي ${daysRemaining} يوم` : "الاشتراك منتهي";
    },
  },
  watch: {
    pagination: {
      handler() {
        this.get_trainees();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.card-table {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 12px !important;
  border: 1px solid #cbccce;
}
.profile {
  cursor: pointer;
}
.name_coach {
  border-color: #ffc200 !important;
  border-style: solid;
  border-width: 2px;
}
.name_coach2 {
  border-color: #f28218 !important;
  border-style: solid;
  border-width: 2px;
}
.data_table {
  font-family: "Readex Pro", sans-serif !important;
  font-weight: 500 !important;
}
</style>
