<template>
  <v-row justify="center">
    <v-dialog :value="value" max-width="700px" persistent scrollable>
      <v-card class="card mx-auto" width="100%" height="100%">
        <v-toolbar elevation="0">
          
            <v-btn
              icon
              fab
              color="#C62828"
              x-small
              v-on:click="$emit('popClose')"
              class="mr-1"
            >
              <Icon
                class="icon_download"
                icon="ic:outline-close"
                color="#000000"
                width="25"
              />
            </v-btn>
         

          <v-toolbar-title class="mx-auto pl-16" style="color: #50535b">
            <h3 style="color: #50535b" class="font-semiBold">اضافة توصية</h3>
          </v-toolbar-title>
        </v-toolbar>

        <v-form ref="add_notes">
          <v-card-text class="mt-7">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <Textarea
                  @update-value="title = $event"
                  :value="title"
                  type="text"
                  label="اكتب توصية ..."
                  :rules="RulseAll"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mt-2 pb-5 mr-3">
            <v-btn
              large
              @click="add_notes"
              :loading="loading"
              color="#f28218"
              class="btn-a py-5 mx-auto"
              elevation="4"
            >
              <h3 style="color: white;" class="data_table">اضافة</h3>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Textarea from "@/components/layout/Textarea.vue";

export default {
  components: { Textarea },
  data() {
    return {
      title: null,
      RulseAll: [(value) => !!value || "هذا الحقل مطلوب"],
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    loading() {
      return this.$store.state.notes.add_note_loading;
    },
  },
  methods: {
    add_notes() {
      if (this.$refs.add_notes.validate()) {
        let data = {};
        data["subscribtion_session_id"] =
          this.$store.state.subscribtion_session_id;
        data["title"] = this.title;

        this.$store.dispatch("notes/addNote", data).then(() => {
          this.$emit("popClose");
        });
      }
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.btn-a {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 174px !important;
}
</style>
