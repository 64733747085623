<template>
  <v-container fluid class="mt-0">
    <v-row v-if="loading == false">
      <v-col cols="12" sm="12" md="12" lg="12" class="mt-2">
        <v-row class="pa-0 ma-0">
          <v-col
            cols="12"
            sm="4"
            md="2"
            lg="2"
            class="ma-0 px-0"
            v-for="(member, index) in member_ships"
            :key="index"
          >
            <v-btn
              @click="membership_details(member, index)"
              large
              class="btn"
              :color="member_active == index ? '#f97316' : '#F5F5F5'"
              style="border-radius: 16px"
            >
              <h4
                :style="
                  member_active == index ? 'color: #ffffff' : 'color:#757575'
                "
                class="data_table ml-1"
              >
                {{ member.title }}
              </h4>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="4" md="2" lg="2" class="ma-0 px-0">
            <v-btn
              @click="add_mempership"
              :loading="loading2"
              large
              class="btn"
              color="#000000"
              style="border-radius: 16px"
            >
              <h4 style="color: #ffffff" class="data_table ml-1">
                إضافة إشتراك
              </h4>
              <Icon icon="ic:baseline-plus" color="#ffffff" width="26" />
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="12" class="pb-8">
        <v-divider style="color: #babbbe"></v-divider>
        <v-form ref="add_member_ships">
          <v-card class="card">
            <v-card-text class="mt-3 py-0">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <h3 class="mb-1 mt-4 mr-2 data_table" style="color: #50535b">
                    حالة الإشتراك
                  </h3>
                  <v-list class="my-0">
                    <v-list-item class="list-item py-0">
                      <v-list-item-title class="d-flex justify-start my-0">
                        <v-switch
                          v-model="status"
                          inset
                          color="#f97316"
                          class="mb-4 mt-3"
                          hide-details="auto"
                        ></v-switch>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <h3 class="mb-1 mt-4 mr-2 data_table" style="color: #50535b">
                    إسم الإشتراك
                  </h3>
                  <Input
                    @update-value="title = $event"
                    :value="title"
                    type="text"
                    label="اكتب عنوانك هنا"
                    :rules="TitleRules"
                    class="mt-5"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <h3 class="mb-1 mt-4 mr-2 data_table" style="color: #50535b">
                    سعر الإشتراك
                  </h3>
                  <Input
                    @update-value="price = $event"
                    :value="price"
                    type="text"
                    label="اكتب عنوانك هنا"
                    :rules="PriceRules"
                    class="mt-5"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" class="mt-0 pt-0">
                  <h3 class="mb-1 mt-4 mr-2 data_table" style="color: #50535b">
                    وصف الإشتراك
                  </h3>
                  <Textarea
                    @update-value="description = $event"
                    :value="description"
                    :rows="2"
                    type="text"
                    label="تمنحك باقة 'Gold' تجربة رياضية استثنائية مع ميزات متقدمة مصممة لتلبية احتياجاتك. احصل على وصول كامل إلى جميع المرافق الرياضية المتميزة، خطط تدريبية مخصصة، وتقارير أداء شاملة. بالإضافة إلى ذلك، استمتع بدعم مستمر من فريق المدربين المحترفين للحصول على تجربة تدريب فريدة من نوعها."
                    class="mt-5"
                  />
                </v-col>
              </v-row>
              <v-col cols="12" sm="12" md="12" lg="12" class="mt-2">
                <h3 class="mb-8 mt-1 data_table" style="color: #50535b">
                  مميزات الإشتراك
                </h3>
                <!-- <v-sheet
                  class="mx-auto sheet d-flex justify-center"
                  height="50"
                  width="170"
                  color="#f3f3f3"
                >
                <span style="display: flex; align-items: center">  -->

                <v-row class="pa-0 ma-0" justify="start">
                  <v-col
                    v-for="(feature, index) in features"
                    :key="index"
                    class="ma-2 px-0"
                    cols="auto"
                  >
                    <v-btn
                      large
                      class="btn"
                      :color="feature_active == index ? '#f97316' : '#F5F5F5'"
                      style="
                        border-radius: 16px;
                        white-space: nowrap;
                        padding: 12px 16px;
                      "
                    >
                      <div class="d-flex align-center">
                        <v-btn
                          icon
                          small
                          class="ml-3"
                          @click="deleteFeature(feature.id)"
                        >
                          <Icon
                            icon="material-symbols:close-rounded"
                            color="#111214"
                            width="25"
                          />
                          <template v-slot:loader>
                            <span class="custom-loader">
                              <v-icon color="#f97300">mdi-cached</v-icon>
                            </span>
                          </template>
                        </v-btn>
                        <h4
                          @click="active_features(feature, index)"
                          :style="
                            feature_active == index
                              ? 'color: #ffffff'
                              : 'color:#757575'
                          "
                          class="data_table ml-2"
                        >
                          {{ feature.feature }}
                        </h4>
                        <Icon
                          icon="solar:star-bold"
                          :color="feature_active == index ? 'ffffff' : 'f97316'"
                          width="26"
                        />
                      </div>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="4" md="2" lg="2" class="ma-2 px-0">
                    <v-btn
                      @click="pop_add_features"
                      large
                      class="btn"
                      color="#F97300"
                      style="border-radius: 16px"
                    >
                      <h4 style="color: #ffffff" class="data_table ml-1">
                        إضافة ميزة
                      </h4>
                      <Icon
                        icon="ic:baseline-plus"
                        color="#ffffff"
                        width="26"
                      />
                      <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon color="white">mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-card-text>

            <v-card-actions>
              <v-btn
                @click="update_membership"
                :loading="loading3"
                block
                x-large
                class="btn mt-4"
                color="#f97316"
                style="border-radius: 16px"
              >
                <h3 style="color: #ffffff" class="data_table">حفظ</h3>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon color="white">mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
      <UpdateFeatureMembership
        :value="pop_update_feature"
        :title_feature="title_feature"
        v-on:popClose="pop_update_feature = !pop_update_feature"
        v-on:update_feature="update_feature"
      />
      <PopAddFeatureMembership
        :value="pop_add_feature"
        v-on:popClose="pop_add_feature = !pop_add_feature"
        v-on:add_features="add_features"
      />

      <PopDeleteFeature
        :value="pop_delete_feature"
        v-on:popClose="pop_delete_feature = !pop_delete_feature"
        v-on:delete_feature="delete_feature"
      />
    </v-row>
    <v-row v-if="loading == true">
      <v-col
        cols="12"
        sm="12"
        md="2"
        lg="2"
        v-for="(n, index) in 6"
        :key="'A12' + index"
      >
        <v-skeleton-loader type="actions"></v-skeleton-loader>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        v-for="(n, index) in 4"
        :key="'A13' + index"
      >
        <v-card class="mx-auto card" width="100%" height="100%">
          <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="2"
        lg="2"
        v-for="(n, index) in 12"
        :key="'A' + index"
      >
        <v-card class="mx-auto card" width="100%">
          <v-skeleton-loader type="actions"></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Input from "@/components/layout/Input.vue";
import Textarea from "@/components/layout/Textarea.vue";
import UpdateFeatureMembership from "@/components/admin/PopUpdateFeatureMembership.vue";
import PopAddFeatureMembership from "@/components/admin/PopAddFeatureMembership.vue";
import PopDeleteFeature from "@/components/admin/PopDeleteFeature.vue";

export default {
  components: {
    Input,
    Textarea,
    UpdateFeatureMembership,
    PopAddFeatureMembership,
    PopDeleteFeature,
  },
  data() {
    return {
      title: "",
      price: "",
      status: "",
      description: "",
      features: [],
      feature_active: 0,
      membership_id: null,
      member_active: 0,
      pop_update_feature: false,
      pop_add_feature: false,
      title_feature: null,

      pop_delete_feature: false,
      id_delete_feature: null,
    };
  },
  created() {
    this.get_member_ships();
  },
  computed: {
    member_ships() {
      return this.$store.state.admin_mempership.member_ships;
    },
    loading() {
      return this.$store.state.admin_mempership.member_ships_loading;
    },
    loading2() {
      return this.$store.state.admin_mempership.add_mempership_loading;
    },
    loading3() {
      return this.$store.state.admin_mempership.update_mempership_loading;
    },
    loading4() {
      return this.$store.state.admin_mempership.add_feature_loading;
    },
    TitleRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 3 || "الحد الادنى لعدد الاحرف هوه 3",
        (value) =>
          (value || "").length < 256 || "الحد الاقصى لعدد الاحرف هوه 255",
      ];
    },
    PriceRules() {
      return [
        (value) => !!value || "هذا الحقل مطلوب",
        (value) => (value || "").length >= 1 || "الحد الادنى لعدد الاحرف هوه 1",
        (value) => (value || "").length < 4 || "الحد الاقصى لعدد الاحرف هوه 3",
      ];
    },
  },
  methods: {
    get_member_ships() {
      this.$store.dispatch("admin_mempership/get_member_ships").then(() => {
        this.membership_details(this.member_ships[0], 0);
      });
    },
    add_mempership() {
      let data = {};
      data["title"] = "اعتيادي";
      data["price"] = "10";
      this.$store.dispatch("admin_mempership/add_memper_ship", data);
    },

    membership_details(member, index) {
      this.title = member.title;
      this.status = member.status;
      this.price = member.price;
      this.description = member.description;
      this.member_active = index;
      this.membership_id = member.id;
      this.features = member.features;
    },

    update_membership() {
      let data = {};
      data["membership_id"] = this.membership_id;
      data["title"] = this.title;
      data["price"] = this.price;
      data["status"] = this.status;
      data["description"] = this.description;
      this.$store.dispatch("admin_mempership/update_memper_ship", data);
    },

    add_features(feature) {
      let data = {};
      data["membership_id"] = this.membership_id;
      data["feature"] = feature;
      this.$store.dispatch("admin_mempership/add_features", data).then(() => {
        this.pop_add_feature = false;
      });
    },
    pop_add_features() {
      this.pop_add_feature = true;
    },

    deleteFeature(id) {
      this.pop_delete_feature = true;
      this.id_delete_feature = id;
    },
    delete_feature() {
      let data = {};
      data["feature_id"] = this.id_delete_feature;
      this.$store.dispatch("admin_mempership/delete_feature", data).then(() => {
        this.pop_delete_feature = false;
      });
    },

    update_feature(feature) {
      let data = {};
      data["feature_id"] = this.feature_id;
      data["feature"] = feature;
      this.$store.dispatch("admin_mempership/update_feature", data).then(() => {
        this.pop_update_feature = false;
      });
    },
    active_features(feature, index) {
      this.feature_id = feature.id;
      this.title_feature = feature.feature;
      this.pop_update_feature = true;
      this.feature_active = index;
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
}
.list-item {
  background-color: #f3f3f4;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 19px !important;
}
</style>
