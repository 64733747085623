import axios from "axios";
const trainer = {
  namespaced: true,
  state: () => ({
    profile: [],
    member_Ships: [],
    member_ships_loading: false,
    profile_loading: null,

    // achievements
    achievements: [],
    achievements_loading: false,
    add_achievement_loading: false,
    delete_achievement_loading: false,
    update_image_trainer_loading: false,
    achievements_state: "done",
    achievements_params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 3,
    },
  }),
  mutations: {
    SELECT_APP_SUBSCRIPTION(state, AppSubscription) {
      state.AppSubscription = AppSubscription;
    },
    GET_PROFILE(state, profile) {
      state.profile = profile;
    },

    MEMBER_SHIPS(state, member_Ships) {
      state.member_Ships.splice(0, state.member_Ships.length);
      member_Ships.forEach((element) => {
        state.member_Ships.push(element);
      });
    },

    GET_ACHIEVEMENTS_DREPDWON(state, achievements) {
      if (achievements.length === 0) {
        state.achievements_state = "finished";
        if (state.achievements_params.page > 1)
          state.achievements_params.page -= 1;
        return;
      }
      state.achievements_params.page += 1;
      const newRequests = achievements.filter(
        (achievements) =>
          !state.achievements.some((r) => r.id === achievements.id)
      );

      state.achievements.push(...newRequests);

      state.achievements_state = "done";
    },
    ADD_ACHIEVEMENTS(state, achievement) {
      state.achievements.push(achievement);
    },
    DELETE_ACHIEVEMENTS(state, achievement) {
      let index = state.achievements.findIndex((e) => e.id == achievement.id);
      state.achievements.splice(index, 1);
    },
  },
  actions: {
    getMemberShips({ commit, state, rootState }) {
      state.member_ships_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/getMemberShips",
          method: "get",
        })
          .then((response) => {
            commit("MEMBER_SHIPS", response.data.result);
            state.member_ships_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.member_ships_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    
    update_profile({ commit, state, rootState }, data) {
      state.profile_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/update-profile",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("GET_PROFILE", response.data.result[0]);
            state.profile_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.profile_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_profile({ commit, state, rootState }) {
      state.profile_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/profile",
          method: "get",
        })
          .then((response) => {
            commit("GET_PROFILE", response.data.result[0]);
            state.profile_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.profile_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    get_achievements({ commit, state, rootState }) {
      state.achievements_loading = true;
      let data = state.achievements_params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;
        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/achievements" +
            "?skip=" +
            skip +
            "&limit=" +
            limit,
          method: "get",
        })
          .then((response) => {
            commit("GET_ACHIEVEMENTS_DREPDWON", response.data.result);

            state.achievements_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.achievements_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    add_achievement({ commit, state, rootState }, data) {
      state.add_achievement_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/achievement/create",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_ACHIEVEMENTS", response.data.result[0]);
            state.add_achievement_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_achievement_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    delete_achievement({ commit, state, rootState }, data) {
      state.delete_achievement_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/achievement/delete",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("DELETE_ACHIEVEMENTS", response.data.result[0]);
            state.delete_achievement_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.delete_achievement_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    update_image_trainer({ commit, state, rootState }, data) {
      state.update_image_trainer_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/update-image",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("GET_PROFILE", response.data.result[0]);
            state.update_image_trainer_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.update_image_trainer_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default trainer;
