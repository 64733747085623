<template>
  <v-textarea
    class="data_table"
    :value="value"
    :type="type"
    :label="label"
    :rows="rows"

    solo
    rounded
    clearable
    lazy-rules
    color="#f28218"
    hide-details="auto"
    @input="$emit('update-value', $event)"
    :hint="hint"
    persistent-hint
    :rules="rules"></v-textarea>
</template>
<script>
  export default {
    props: {
      type: String,
      label: String,
      value: String,
      rules: {
        type: Array,
        required: false,
      },
      rows: {
        type: Number,
        default: 4,
      },
      hint: {
        type: String,
        required: false,
      },
    },
    methods: {},
  };
</script>
