<template>
  <v-container fluid class="mt-0">
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" class="">
        <v-card class="card">
          <v-tabs v-model="tab" background-color="#ffffff" centered>
            <v-tabs-slider color="#F97300"></v-tabs-slider>

            <v-tab @click="setTab(0)">
              <h3 style="color: #50535b" class="data_table">
                التمارين الأساسية
              </h3></v-tab
            >

            <v-tab @click="setTab(1)">
              <h3 style="color: #50535b" class="data_table">
                المنهاج الغذائي
              </h3></v-tab
            >

            <v-tab @click="setTab(2)">
              <h3 style="color: #50535b" class="data_table">
                تمارين أخرى والكارديو
              </h3></v-tab
            >
            <v-tab @click="setTab(3)">
              <h3 style="color: #50535b" class="data_table">
                توصيات المدرب
              </h3></v-tab
            >
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="i in 4" :key="i">
              <BasicExercises v-if="tab == 0" />
              <NutritionalCurriculum v-else-if="tab == 1" />
              <OtherExercisesCardio v-else-if="tab == 2" />
              <TrainerRecommendations v-else-if="tab == 3" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import OtherExercisesCardio from "../components/Calisthenics/OtherExercisesCardio";
import NutritionalCurriculum from "../components/Calisthenics/NutritionalCurriculum";
import BasicExercises from "../components/Calisthenics/BasicExercises";
import TrainerRecommendations from "../components/Calisthenics/TrainerRecommendations";
export default {
  components: {
    OtherExercisesCardio,
    NutritionalCurriculum,
    BasicExercises,
    TrainerRecommendations,
  },
  data() {
    return {};
  },
  created() {
     // Ensure the tab value is synced with Vuex and localStorage on load
     const savedTab = localStorage.getItem("tab");
    if (savedTab !== null) {
      this.$store.commit("meals/setTab", parseInt(savedTab));
    }
    
    if (localStorage.getItem("subscribtion_session_id") == null) {
      this.$router.push({ name: "users" });
    }
   
  },
  computed: {
    tab: {
      get() {
        return this.$store.state.meals.tab;
      },
      set(value) {
        this.$store.commit("meals/setTab", value);
        localStorage.setItem("tab", value);
      },
    },
  },
  methods: {
    setTab(tab) {
      this.tab = tab;
    },
  },
};
</script>
<style>
.v-application .primary--text {
  color: #f97300 !important;
  caret-color: #f97300 !important;
}
</style>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
}
</style>
