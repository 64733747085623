import axios from "axios";
const notes = {
  namespaced: true,
  state: () => ({
    notes: [],
    notes_loading: false,
    add_note_loading: false,
    delete_note_loading: false,
    pageCount: 1,

    params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 50,
    },
  }),
  mutations: {
    ADD_NOTE(state, note) {
      state.notes.push(note);
    },

    EDIT_NOTE(state, note) {
      let index = state.notes.findIndex((e) => e.id === note.id);
      if (index !== -1) {
        // Update the element in place
        state.notes[index] = note;
      }
    },
    DELETE_NOTE(state, note) {
      let index = state.notes.findIndex((e) => e.id == note.id);
      state.notes.splice(index, 1);
    },
    GET_NOTES(state, notes) {
      state.notes.splice(0, state.notes.length);
      notes.forEach((element) => {
        state.notes.push(element);
      });
    },
  },
  actions: {
    async reset_table({ state }) {
      state.notes = [];
      state.pageCount = 1;
      state.notes_loading = false;
      state.params = {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 50,
      };
    },

    get_notes({ commit, state, rootState }) {
      state.notes_loading = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;
        let subscribtion_session_id = "";
        subscribtion_session_id = `&subscribtion_session_id=${localStorage.getItem(
          "subscribtion_session_id"
        )}`;

        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/notes" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            subscribtion_session_id,
          method: "get",
        })
          .then((response) => {
            state.pageCount = response.data.count;

            commit("GET_NOTES", response.data.result);

            state.notes_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.notes_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    addNote({ commit, state, rootState }, data) {
      state.add_note_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/note/create",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("ADD_NOTE", response.data.result[0]);
            state.add_note_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.add_note_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    editNote({ commit, state, rootState }, data) {
      state.edit_note_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/note/update",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("EDIT_NOTE", response.data.result[0]);
            state.edit_note_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.edit_note_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },

    deleteNote({ commit, state, rootState }, data) {
      state.delete_note_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/trainer/note/delete",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("DELETE_NOTE", response.data.result[0]);
            state.delete_note_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.delete_note_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default notes;
