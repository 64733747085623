<template>
  <v-container fluid class="mt-0">
    <v-row>
      <CardAnalysis />
    
      <v-col cols="12" sm="12" md="12" lg="12" class="">
        <PendingTrainingRequests :files="files" />
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12" class="">
        <AddUpdatePlayer :files="files" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardAnalysis from "../components/home/CardAnalysis";
import PendingTrainingRequests from "../components/home/PendingTrainingRequests";
import AddUpdatePlayer from "../components/home/AddUpdatePlayer";
export default {
  components: { CardAnalysis,PendingTrainingRequests ,AddUpdatePlayer},
  name: "Home",
  data() {
    return {

      files: [
        {
          color: "blue",
          icon: "mdi-clipboard-text",
          id: "45454564554",
          title: "علي طه",
          type: 1,
        },
        {
          color: "amber",
          icon: "mdi-gesture-tap-button",
          id: "45422564004",
          title: "مصطفى محمد",
          type: 2,
        },
      ],
    };
  },
};
</script>
<style scoped>
.card {
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
  background-color: #d4d6d7; /*f3f3f4*/
}
.list-item {
  background-color: #ffffff;
  box-shadow: 0px 0px 0px 0px !important;
  border-radius: 20px !important;
}
</style>
