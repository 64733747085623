<template>
  <v-row justify="center">
    <v-dialog :value="value" persistent max-width="700">
      <v-card class="card mx-auto">
        <v-toolbar elevation="0">
          <v-btn
            icon
            fab
            color="#C62828"
            x-small
            v-on:click="$emit('popClose')"
            class="mr-1"
          >
            <Icon
              class="d-flex justify-center"
              icon="ic:outline-close"
              color="#000000"
              width="25"
            />
          </v-btn>

          <v-toolbar-title class="mx-auto pl-16" style="color: #50535b">
            <h3 class="font-semiBold">إختر عنواناً للمجموعة</h3>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-title class="mt-4 d-flex justify-center">
          <h5
           class="data_table"
            style="
              color: #50535b;
              white-space: normal;
              word-wrap: break-word;
              text-align: start;
              max-width: 99%;
            "
          >
            سيتم حفظ الاشخاص المحددين بمجموعة خاصة يمكنك ارسال المنشورات اليها
            بصورة مباشرة دون الحاجة الى تحديدهم مجددا
          </h5>

          <v-row class="mt-5">
            <v-col cols="12" sm="12" md="12" lg="12">
              <Input
                @update-value="title = $event"
                :value="title"
                type="text"
                label=" فئة1 ..."
                :rules="RulseAll"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions class="mt-3 pb-5">
          <v-btn
            large
            @click="send_title_groups"
            :loading="loading"
            color="#f28218"
            class="btn-a py-5 mx-auto"
            elevation="4"
          >
            <h3 style="color: white;" class="data_table">حفظ</h3>
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon color="white">mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Input from "@/components/layout/Input.vue";
export default {
  components: { Input },
  props: {
    value: Boolean,
    title_group: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      title: null,
      RulseAll: [
        (value) => !!value || "هذا الحقل مطلوب",

        (value) => (value || "").length >= 3 || "الحد الادنى لعدد الاحرف هوه 3",
        (value) =>
        (value || "").length < 85 || "الحد الاقصى لعدد الاحرف هوه 84",
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.state.news.update_groups_loading;
    },
  },
  methods: {
    send_title_groups() {
      this.$emit("update_group", this.title);
    },
  },
  watch: {
    title_group: {
      handler(val) {
        this.title = val;
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.btn-a {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 174px !important;
}
</style>
