<template>
  <v-row justify="center">
    <v-dialog :value="value" max-width="700px" persistent scrollable>
      <v-card class="card mx-auto" width="100%" height="100%">
        <v-toolbar elevation="0">
          <v-btn
            icon
            fab
            color="#C62828"
            x-small
            v-on:click="$emit('popClose')"
            class="mr-1"
          >
            <Icon
              class="icon_download"
              icon="ic:outline-close"
              color="#000000"
              width="25"
            />
          </v-btn>

          <v-toolbar-title class="mx-auto pl-16" style="color: #50535b">
            <h3 style="color: #50535b" class="font-semiBold">
              تعديل وقت الوجبة
            </h3>
          </v-toolbar-title>
        </v-toolbar>

        <v-form ref="edit_meal">
          <v-card-text class="mt-7">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <!-- <InputTime
                  @update-value="meal_info.time = $event"
                  :value="meal_info.time"
                  color="#f97316"
                  label="التوقيت"
                  :rules="RulseAll"
                /> -->
                <v-text-field
                  class="data_table"
                   style="direction: rtl; text-align: right;"
                  v-model="meal_info.time"
                  type="time"
                  label="الوقت"
                  color="#F97316"
                  solo
                  clearable
                  lazy-rules
                  hide-details="auto"
                  persistent-hint
                  append-icon
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mt-2 pb-5 mr-3">
            <v-btn
              large
              @click="edit_meal"
              :loading="loading"
              color="#f28218"
              class="btn py-5 mx-auto"
              elevation="4"
            >
              <h3 style="color: white" class="data_table">تعديل</h3>
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon color="white">mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
// import InputTime from "@/components/layout/InputTime.vue";

export default {
  // components: { InputTime },
  data() {
    return {
      title: null,
      meal_info: {},
      RulseAll: [(value) => !!value || "هذا الحقل مطلوب"],
    };
  },
  props: {
    meal: {
      type: Object,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    loading() {
      return this.$store.state.meals.edit_meal_loading;
    },
  },
  methods: {
    edit_meal() {
      if (this.$refs.edit_meal.validate()) {
        let data = {};
        data["meal_id"] = this.meal_info.id;
        data["time"] = this.meal_info.time;

        this.$store.dispatch("meals/editMeal", data).then(() => {
          this.$emit("popClose");
        });
      }
    },
  },
  watch: {
    meal() {
      this.meal_info = this.meal;
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 25px !important;
  box-shadow: 0px 0px 0px 0px !important;
}
.btn {
  border-radius: 13px !important;
  box-shadow: 0px 0px 0px 0px !important;
  padding: 0 174px !important;
}
.custom-text-field .v-input__control {
  text-align: right; /* Align the text to the right */
}
</style>
