import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import training_requests from "./modules/training_requests";
import trainees from "./modules/trainees";
import trainer from "./modules/trainer";
import meals from "./modules/meals";
import notes from "./modules/notes";
import payments from "./modules/payments";
import news from "./modules/news";
import notifications from "./modules/notifications";
import courses from "./modules/courses";

import router from "@/router";

import admin_users from "./modules/admin_users";
import admin_requests from "./modules/admin_requests";
import admin_notifications from "./modules/admin_notifications";
import admin_mempership from "./modules/admin_mempership";
import admin from "./modules/admin";
import admin_payments from "./modules/admin_payments";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //https://efredgvrergv34345435.online/api
    // http://127.0.0.1:8000
    server: "https://efredgvrergv34345435.online/api",
    snackbar: false,
    snack_message: {},
    token: localStorage.getItem("token"),
    // user_type: localStorage.getItem("user_type"),
    name: localStorage.getItem("name") ?? null,
    loading_login: false,

    subscribtion_session_id:
      localStorage.getItem("subscribtion_session_id") ?? null,
    previous_session_id: localStorage.getItem("previous_session_id") ?? null,
    // loading_check_user_type: false,
    // loading_user_profile: false,

    loading_register: false,
    logout_loading: false,

    // forgot password
    phone: localStorage.getItem("phone") ?? null,
    send_otp_loading: false,
    check_otp_loading: false,
    change_password_loading: false,
  },

  mutations: {
    SNACK_MESSAGE(state, snack_message) {
      state.snack_message = snack_message;
      state.snackbar = true;
    },
    TIME_OUT(state) {
      state.snackbar = false;
      state.snack_message = null;
    },

    CLEAR_USER() {
      localStorage.removeItem("token");
      localStorage.removeItem("user_type");
      localStorage.removeItem("name");
      localStorage.removeItem("trainer_id_payments");
      localStorage.removeItem("trainee_weight");
      localStorage.removeItem("trainee_id_payments");
      localStorage.removeItem("trainee_id");
      localStorage.removeItem("subscribtion_session_id");
      localStorage.removeItem("remaining_meal_plan_days");
      localStorage.removeItem("remaining_course_days");
      localStorage.removeItem("previous_session_id");
      localStorage.removeItem("pending_trainee_id");
      localStorage.removeItem("meal_plan_id");

      delete axios.defaults.headers.common["Authorization"];
      location.reload();
    },
    SELECT_SUBSCRIBTION_SESSION_ID(state, id) {
      state.subscribtion_session_id = id;
    },
    SELECT_previous_session_ID(state, id) {
      state.previous_session_id = id;
    },

    SAVE_PHONE(state, phone) {
      localStorage.setItem("phone", phone);
      state.phone = phone;
    },
  },
  actions: {
    login({ commit, state }, data) {
      state.loading_login = true;
      return new Promise((resolve) => {
        axios({
          url: `${state.server}/api/trainer/login`,
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        })
          .then((response) => {
            const token = response.data.token;
            state.loading_login = false;
            Vue.prototype.$http.defaults.headers.common["Authorization"] =
              "Bearer " + token;
            localStorage.setItem("token", token);
            localStorage.setItem("name", response.data.result[0].full_name);
            if (response.data.result[0].phone == "07810238491") {
              localStorage.setItem("user_type", 200);
              router.push("/home");
            } else {
              localStorage.setItem("user_type", 0);
              router.push("/");
            }

            resolve(response);
          })
          .catch((error) => {
            state.loading_login = false;

            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
    register({ commit, state }, data) {
      state.loading_register = true;
      return new Promise((resolve) => {
        axios({
          url: `${state.server}/api/trainer/register`,
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        })
          .then((response) => {
            state.loading_register = false;
            resolve(response);
          })
          .catch((error) => {
            state.loading_register = false;

            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },

    logout_trainer({ commit, state }) {
      state.logout_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${state.server}` + "/api/trainer/logout",
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            state.logout_loading = false;
            commit("CLEAR_USER");

            resolve(response);
          })
          .catch(() => {
            state.logout_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة في الاتصال بالخادم";
            snack_message["show"] = true;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
    logout_admin({ commit, state }) {
      state.logout_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${state.server}` + "/api/admin/logout",
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            state.logout_loading = false;
            commit("CLEAR_USER");

            resolve(response);
          })
          .catch(() => {
            state.logout_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة في الاتصال بالخادم";
            snack_message["show"] = true;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
    send_otp({ commit, state }, data) {
      state.send_otp_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${state.server}` + "/api/trainer/send-otp",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            state.send_otp_loading = false;
            commit("SAVE_PHONE", response.data.result[0]);

            resolve(response);
          })
          .catch(() => {
            state.send_otp_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة في الاتصال بالخادم";
            snack_message["show"] = true;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
    check_otp({ commit, state }, data) {
      state.check_otp_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${state.server}` + "/api/trainer/check-otp",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            state.check_otp_loading = false;
            localStorage.setItem("otp", data.otp);

            resolve(response);
          })
          .catch((error) => {
            state.check_otp_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            snack_message["show"] = true;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
    change_password({ commit, state }, data) {
      state.change_password_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${state.server}` + "/api/trainer/change-password",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            state.change_password_loading = false;
            localStorage.setItem("otp", data.otp);
            localStorage.removeItem("otp");
            localStorage.removeItem("phone");
            resolve(response);
          })
          .catch((error) => {
            state.change_password_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            snack_message["show"] = true;
            commit("SNACK_MESSAGE", snack_message);
            setTimeout(() => {
              commit("TIME_OUT", snack_message);
            }, 4000);
          });
      });
    },
  },
  getters: {
    snack_bar(state) {
      return !!state.snackbar;
    },
    logged_in(state) {
      return !!state.token;
    },
  },
  modules: {
    training_requests,
    trainees,
    trainer,
    meals,
    notes,
    payments,
    news,
    notifications,
    courses,
    admin_users,
    admin_notifications,
    admin_requests,
    admin,
    admin_mempership,
    admin_payments,
  },
});
