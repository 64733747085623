import axios from "axios";
const admin_requests = {
  namespaced: true,
  state: () => ({
    requests: [],
    info_trainer: null,
    trainer_id: localStorage.getItem("trainer_id") ?? null,
    admin_requests_loading: false,
    requests_state: "done",
    reject_request_loading: false,
    accept_request_loading: false,
    info_trainer_loading: false,
    delete_trainer_loading: false,
    ban_trainer_loading: false,
    open_ban_trainer_loading: false,

    params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },

    // Statistics
    statistics: [],
    loading_statistics: false,
    start_date: null,
    end_date: null,

    // trainer
    trainers: [],
    trainer_query: "",
    trainer_loading: false,
    params_trainers: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
  }),
  mutations: {
    setIdTrainer(state, trainer_id) {
      state.trainer_id = trainer_id;
    },

    GET_All_REQUEST_DREPDWON(state, requests) {
      if (requests.length === 0) {
        state.requests_state = "finished";
        if (state.params.page > 1) state.params.page -= 1;
        return;
      }
      state.params.page += 1;
      const newRequests = requests.filter(
        (request) => !state.requests.some((r) => r.id === request.id)
      );

      state.requests.push(...newRequests);

      state.requests_state = "done";
    },

    REQUEST_STATUS_CHANGED(state, request) {
      let index = state.requests.findIndex((e) => e.id == request.id);
      state.requests.splice(index, 1);
    },
    GET_STATISTICS(state, statistics) {
      let data = [
        {
          icon: "mdi:user",
          color: "#A855F7",
          title: "اجمالي عدد اللاعبين",
          number: statistics.trainees,
          type: "لاعب",
        },
        {
          icon: "ph:users-three-bold",
          color: "#84CC16",
          title: " اجمالي عدد المدربين",
          number: statistics.trainers,
          type: "لاعب",
        },
        {
          icon: "streamline:arrow-reload-horizontal-1-solid",
          color: "#EF4444",
          title: " الإشتراكات المنتهية",
          number: statistics.inactive_or_expired_subscriptions,
          type: "لاعب",
        },
        {
          icon: "mdi:users",
          color: "#F97316",
          title: "اجمالي اشتراكات اليوم",
          number: statistics.total_subscriptions,
          type: "لاعب",
        },
        {
          icon: "flowbite:dollar-outline",
          color: "#3B82F6",
          title: "اجمالي واردات اليوم",
          number: statistics.total_received,
          type: "دينار",
        },
        {
          icon: "flowbite:dollar-outline",
          color: "#EF4444",
          title: "عدد الإشتراكات الفعالة",
          number: statistics.active_subscriptions,
          type: "لاعب",
        },
      ];
      state.statistics = data;
    },

    GET_INFO_TRAINER(state, trainer) {
      state.info_trainer = trainer;
    },
    GET_TRAINERS_SEARCH(state, trainers) {
      state.trainers.splice(0, state.trainers.length);
      trainers.forEach((element) => {
        state.trainers.push(element);
      });
    },
  },
  actions: {
    get_all_trainer_request({ commit, state, rootState }) {
      state.admin_requests_loading = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        axios({
          url:
            `${rootState.server}` +
            "/api/admin/inActivetrainers" +
            "?skip=" +
            skip +
            "&limit=" +
            limit,
          method: "get",
        })
          .then((response) => {
            commit("GET_All_REQUEST_DREPDWON", response.data.result);

            state.admin_requests_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.admin_requests_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    acceptRequest({ commit, state, rootState }, data) {
      state.accept_request_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/trainer/activate",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("REQUEST_STATUS_CHANGED", response.data.result[0]);
            state.accept_request_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.accept_request_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    rejectRequest({ commit, state, rootState }, data) {
      state.reject_request_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/trainer/reject",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("REQUEST_STATUS_CHANGED", response.data.result[0]);
            state.reject_request_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.reject_request_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_statistics({ commit, state, rootState }) {
      state.loading_statistics = true;
      return new Promise((resolve) => {
        let start_date = "";
        start_date = `?start_date=${state.start_date}`;
        let end_date = "";
        end_date = `&end_date=${state.end_date}`;
        axios({
          url:
            `${rootState.server}` +
            "/api/admin/getStatistics" +
            start_date +
            end_date,
          method: "get",
        })
          .then((response) => {
            commit("GET_STATISTICS", response.data.result);

            state.loading_statistics = false;
            resolve(response);
          })
          .catch(() => {
            state.loading_statistics = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_trainers_from_search({ commit, state, rootState }) {
      state.trainer_loading = true;
      let data = state.params_trainers;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;
        let query = "";
        if (
          state.trainer_query != undefined &&
          state.trainer_query != null &&
          state.trainer_query.length > 0
        )
          query = `&query=${state.trainer_query}`;
        axios({
          url:
            `${rootState.server}` +
            "/api/admin/trainers" +
            "?skip=" +
            skip +
            "&limit=" +
            limit +
            query,
          method: "get",
        })
          .then((response) => {
            commit("GET_TRAINERS_SEARCH", response.data.result);

            state.trainer_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.trainer_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    get_info_trainer({ commit, state, rootState }) {
      state.info_trainer_loading = true;
      return new Promise((resolve) => {
        var trainer_id = localStorage.getItem("trainer_id");
        axios({
          url:
            `${rootState.server}` +
            "/api/admin/trainer_detail" +
            "?trainer_id=" +
            trainer_id,
          method: "get",
        })
          .then((response) => {
            commit("GET_INFO_TRAINER", response.data.result[0]);

            state.info_trainer_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.info_trainer_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    add_ban({ commit, state, rootState }, data) {
      state.ban_trainer_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/trainer/ban",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("GET_INFO_TRAINER", response.data.result[0]);
            state.ban_trainer_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.ban_trainer_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    open_ban({ commit, state, rootState }, data) {
      state.open_ban_trainer_loading = true;
      return new Promise((resolve) => {
        axios({
          url: `${rootState.server}` + "/api/admin/trainer/open_ban",
          data: data,
          headers: {
            "Content-Type": "application/json",
          },
          method: "post",
        })
          .then((response) => {
            commit("GET_INFO_TRAINER", response.data.result[0]);
            state.open_ban_trainer_loading = false;
            let snack_message = {};
            snack_message["color"] = "#21ce8f";
            snack_message["icon"] = "clarity:success-standard-solid";
            snack_message["text"] = response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
            resolve(response);
          })
          .catch((error) => {
            state.open_ban_trainer_loading = false;
            let snack_message = {};
            snack_message["color"] = "#ef4444";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = error.response.data.message;
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
  },
};
export default admin_requests;
