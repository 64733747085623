import axios from "axios";
const notifications = {
  namespaced: true,
  state: () => ({
    notifications: [],

    notifications_loading: false,
    notifications_state: "done",

    params: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 10,
    },
  }),
  mutations: {
  

    GET_All_NOTIFICATIONS_DREPDWON(state, notifications) {
      if (notifications.length === 0) {
        state.notifications_state = "finished";
        if (state.params.page > 1) state.params.page -= 1;
        return;
      }
      state.params.page += 1;
      const newNotifications = notifications.filter(
        (notifications) => !state.notifications.some((r) => r.id === notifications.id)
      );
      state.notifications.push(...newNotifications);
      state.notifications_state = "done";
    },
  },
  actions: {
    get_notifications({ commit, state, rootState }) {
      state.notifications_loading = true;
      let data = state.params;
      return new Promise((resolve) => {
        let skip = (data.page - 1) * data.itemsPerPage;
        let limit = data.itemsPerPage;

        axios({
          url:
            `${rootState.server}` +
            "/api/trainer/notifications" +
            "?skip=" +
            skip +
            "&limit=" +
            limit,
          method: "get",
        })
          .then((response) => {

            commit("GET_All_NOTIFICATIONS_DREPDWON", response.data.result);

            state.notifications_loading = false;
            resolve(response);
          })
          .catch(() => {
            state.notifications_loading = false;
            let snack_message = {};
            snack_message["color"] = "#B71C1C";
            snack_message["icon"] = "ri:close-circle-fill";
            snack_message["text"] = "حدث مشكلة اثناء الاتصال بالخادم";
            commit("SNACK_MESSAGE", snack_message, { root: true });
            setTimeout(() => {
              commit("TIME_OUT", snack_message, { root: true });
            }, 4000);
          });
      });
    },
    
  },
};
export default notifications;
